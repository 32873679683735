<template>
  <div class="properties">
    <ListProperties v-if="$route.params.action === 'lista'" />
    <ListPropertiesInvestor v-if="$route.params.action === 'investidor'" />
    <ListPropertiesToApprove v-if="$route.params.action === 'aguardando'" />
    <EditProperty v-if="$route.params.action === 'editar'" />
    <ViewProperty v-if="$route.params.action === 'ver'" />
  </div>
</template>

<script>
import EditProperty from "@/components/properties/EditProperty.vue";
import ListProperties from "@/components/properties/ListProperties.vue";
import ListPropertiesInvestor from "@/components/properties/ListPropertiesInvestor.vue";
import ListPropertiesToApprove from "@/components/properties/ListPropertiesToApprove.vue";
import ViewProperty from "@/components/properties/ViewProperty.vue";

export default {
  components: {
    ListProperties,
    ViewProperty,
    EditProperty,
    ListPropertiesToApprove,
    ListPropertiesInvestor,
  },
};
</script>
