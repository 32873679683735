<template>
    <div class="page">
        <a-page-header class="bg" sub-title="" @back="() => $router.go(-1)">
            <template slot="backIcon">
                <a-icon type="left-circle" /> Voltar
            </template>
        </a-page-header>
        <a-form :form="form" @submit="handleSubmit">
            <a-row id="print" :gutter="25">
                <a-col
                    class="gutter-row sidebar"
                    :span="$root.windowWidth > 1240 ? 6 : 24"
                >
                    <a-row>
                        <a-col class="box a-center bg mb-20" :span="24">
                            <a-form-item class="travel-avatar-wrapper mb-0">
                                <a-upload
                                    v-decorator="['logo']"
                                    list-type="picture-card"
                                    class="avatar-uploader"
                                    :show-upload-list="false"
                                    :before-upload="beforeUpload"
                                    @change="handleChange"
                                >
                                    <div class="image-uploader-container">
                                        <img
                                            v-if="haveTheField('logo')"
                                            :src="haveTheField('logo')"
                                            alt="logo"
                                            style="max-width: 102px"
                                        />
                                        <div v-else>
                                            <a-icon
                                                :type="
                                                    loading ? 'loading' : 'plus'
                                                "
                                            />
                                            <div class="ant-upload-text">
                                                Logo
                                            </div>
                                        </div>
                                    </div>
                                </a-upload>
                                <a-button
                                    v-if="haveTheField('logo')"
                                    type="danger"
                                    ghost
                                    class="remove"
                                    shape="circle"
                                    @click="removeLogo()"
                                >
                                    <a-icon type="delete" />
                                </a-button>
                            </a-form-item>

                            <div class="rate mb-10">
                                <a-rate v-model="companyRate" allow-half>
                                    <a-icon slot="character" type="star" />
                                </a-rate>
                            </div>

                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>Ver detalhes</span>
                                </template>
                                <a @click="view(company.id)">
                                    <a-icon type="view-svg" />
                                </a>
                            </a-tooltip>
                        </a-col>

                        <a-col class="box bg mb-20" :span="24">
                            <div class="line">
                                <div class="title">Proprietário</div>
                                <div class="text">
                                    <a-form-item
                                        class="mb-10 underline-wrapper text"
                                    >
                                        <a-select
                                            class="underline-input"
                                            placeholder="Proprietário"
                                            show-search
                                            v-decorator="['owner_id']"
                                            style="width: 100%"
                                        >
                                            <a-select-option
                                                :value="company.owner.id"
                                            >
                                                {{ company.owner.first_name }}
                                                {{ company.owner.last_name }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </div>
                            </div>
                        </a-col>

                        <a-col class="box mb-20 extra" :span="24">
                            <a-row type="flex">
                                <a-col flex="auto">
                                    <a-form-item style="display: none">
                                        <a-input
                                            v-decorator="['company_status']"
                                        >
                                        </a-input>
                                    </a-form-item>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col
                    class="gutter-row"
                    :span="$root.windowWidth > 1240 ? 18 : 24"
                >
                    <div class="box tabs bg">
                        <a-tabs default-active-key="data">
                            <a-tab-pane key="data" tab="Dados" forceRender>
                                <DefaultDataFormEditCompany
                                    :company="company"
                                    :form="form"
                                />
                            </a-tab-pane>
                        </a-tabs>

                        <a-row type="flex" justify="end">
                            <a-col>
                                <a-button
                                    html-type="submit"
                                    class="update-btn"
                                    style="width: 120px"
                                    :loading="loadingUserUpdate"
                                    >Salvar</a-button
                                >
                            </a-col>
                        </a-row>
                    </div>
                </a-col>
            </a-row>
        </a-form>
    </div>
</template>

<script>
import { parse, format } from "date-fns";
import DefaultDataFormEditCompany from "./forms/DefaultDataFormEditCompany.vue";
import capabilities from "../../mixins/general/capabilities.js";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
};

export default {
    mixins: [capabilities],
    components: { DefaultDataFormEditCompany },
    filters: {
        formatDate(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
    },
    data() {
        return {
            form: this.$form.createForm(this),
            company: {},
            theLogo: "",
            companyRate: 0,
            roles: [
                {
                    name: "Administrador",
                    value: "admin",
                },
                {
                    name: "Afiliado",
                    value: "company",
                },
                {
                    name: "Gerente de Negócios",
                    value: "business-manager",
                },
            ],
            columns: [
                {
                    title: "Nome",
                    key: "first_name",
                    scopedSlots: { customRender: "first_name" },
                },
                {
                    title: "Celular",
                    key: "mobile_phone",
                    scopedSlots: { customRender: "mobile_phone" },
                },
                {
                    title: "CPF",
                    key: "cpf",
                    scopedSlots: { customRender: "cpf" },
                },

                {
                    title: "Data de cadastro",
                    key: "created",
                    scopedSlots: { customRender: "created" },
                },
                {
                    title: "",
                    key: "action",
                    scopedSlots: { customRender: "action" },
                    align: "right",
                },
            ],
        };
    },
    beforeMount() {
        setTimeout(() => {
            let body = document.body;
            body.classList.add("conectar");
            body.classList.add("users");
            body.classList.add("edit");
        }, 20);

        if (
            !this.$root.checkCapabilities(
                this.capabilities,
                "companies",
                "edit"
            )
        ) {
            this.$router.push("/");
        }

        this.getCompany();
    },
    beforeDestroy() {
        let body = document.body;
        body.classList.remove("users");
        body.classList.remove("edit");
    },
    methods: {
        getCompany() {
            this.$http
                .post("/company/details.php", {
                    id: this.$route.params.id,
                })
                .then(({ data }) => {
                    this.company = data;

                    setTimeout(() => {
                        this.form.setFieldsValue({
                            [`owner_id`]: data.owner.id,
                            [`cnpj`]: data.cnpj,
                            [`company_name`]: data.company_name,
                            [`trading_name`]: data.trading_name,
                            [`website`]: data.website,
                            [`company_type`]: data.company_type,
                            [`company_email`]: data.email,
                            [`company_phone`]: data.phone,
                            [`company_mobile_phone`]: data.mobile_phone,
                            [`company_cep`]: data.cep,
                            [`company_location_street`]: data.location_street,
                            [`company_location_number`]: data.location_number,
                            [`company_location_neighborhood`]:
                                data.location_neighborhood,
                            [`company_location_city`]: data.location_city,
                            [`company_location_state`]: data.location_state,
                            [`company_location_complement`]:
                                data.location_complement,
                            [`logo`]: data.logo,
                            [`company_status`]: data.status,
                        });
                    }, 200);
                    this.companyRate =
                        this.data.meta.rate != undefined
                            ? parseFloat(this.company.meta.rate)
                            : 0;
                    document.title = `${data.id} - ${data.trading_name} - Conectar`;
                })
                .catch(({ response }) => {
                    response;
                });
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                console.log("ERROS:", err, "VALORES", {
                    ...values,
                });
                if (!err) {
                    this.$http
                        .post("/company/update.php", {
                            ...values,
                            id: this.$route.params.id,
                            rate: this.companyRate,
                        })
                        .then(({ data }) => {
                            this.loadingUserUpdate = false;
                            this.$message.success(data.message);
                            this.$router.push(`/afiliados/lista`);
                        })
                        .catch(({ response }) => {
                            response;
                            this.loadingUserUpdate = false;
                        });
                }
            });
        },
        formatDateTimeline(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            theDate = format(theDate, "dd/MM/yyyy HH:mm");

            return `${theDate.split(" ")[0]} <br> ${theDate.split(" ")[1]} `;
        },
        handleChange(info) {
            if (this.isValidFile) {
                getBase64(info.file.originFileObj, (imageBase64) => {
                    this.imageBase64 = imageBase64;
                    this.theLogo = imageBase64;
                    console.log("aquio", imageBase64);

                    setTimeout(() => {
                        this.form.setFieldsValue({
                            logo: imageBase64,
                        });
                    }, 1);
                });
            }
        },
        beforeUpload(file) {
            const isValidImage =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "image/svg+xml";
            if (!isValidImage) {
                this.$message.error("Este arquivo não é válido!");
                return;
            }
            const validSize = file.size / 1024 / 1024 < 2;
            if (!validSize) {
                this.$message.error("A imagem deve ter menos que 2MB!");
                return;
            }
            this.isValidFile = true;
            return isValidImage && validSize;
        },
        removeLogo() {
            this.company.logo = "";
            this.form.setFieldsValue({
                logo: "",
            });
            this.theLogo = "";
        },
        haveTheField(field) {
            if (field == "logo") {
                return this.form.getFieldValue(field) || this.company[field];
            } else {
                return this.form.getFieldValue(field) || this.company[field];
            }
        },
        view(id) {
            this.$router.push(
                `/${this.$route.fullPath.split("/")[1]}/ver/${id}`
            );
        },
    },
};
</script>
