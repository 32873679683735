<template>
    <a-form :form="form" @submit="handleSubmit">
        <a-row v-if="registerCompany.activeStep > 1">
            <a-col class="company-user mb-10" :span="24">
                <font class="txt1"
                    >{{ this.form.getFieldValue("trading_name") }}
                </font>

                <font v-if="this.form.getFieldValue('first_name')" class="txt2">
                    - {{ this.form.getFieldValue("first_name") }}
                    {{ this.form.getFieldValue("last_name") }}
                </font>
            </a-col>
        </a-row>

        <a-progress
            class="mb-10"
            :stroke-color="{
                '0%': '#1d3557',
                '100%': '#457b9d',
            }"
            :percent="registerCompany.percents"
        />

        <a-row :gutter="16" v-show="registerCompany.activeStep == '1'">
            <h3>Dados da empresa</h3>
            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="this.form.getFieldValue('cnpj') ? 'filled' : ''"
                        >CNPJ</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="CNPJ"
                        v-mask="'##.###.###/####-##'"
                        v-decorator="[
                            'cnpj',
                            {
                                rules: [
                                    {
                                        required: validateFirstStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('company_name')
                                ? 'filled'
                                : ''
                        "
                        >Razão Social</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Razão Social"
                        v-decorator="[
                            'company_name',
                            {
                                rules: [
                                    {
                                        required: validateFirstStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('trading_name')
                                ? 'filled'
                                : ''
                        "
                        >Nome Fantasia</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Nome Fantasia"
                        v-decorator="[
                            'trading_name',
                            {
                                rules: [
                                    {
                                        required: validateFirstStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('company_phone')
                                ? 'filled'
                                : ''
                        "
                        >Telefone fixo</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Telefone fixo"
                        v-mask="['(##) ####-####']"
                        v-decorator="[
                            'company_phone',
                            {
                                rules: [
                                    {
                                        required: validateFirstStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('company_email')
                                ? 'filled'
                                : ''
                        "
                        >E-mail da Empresa
                    </label>
                    <a-input
                        class="underline-input"
                        v-decorator="[
                            'company_email',
                            {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Escreva um e-mail válido.',
                                    },
                                    {
                                        required: validateFirstStep(),
                                        message: 'Escreva seu e-mail no campo.',
                                    },
                                ],
                            },
                        ]"
                        placeholder="E-mail da Empresa"
                        value=""
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('company_cep')
                                ? 'filled'
                                : ''
                        "
                        >CEP</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="CEP"
                        v-mask="['#####-###']"
                        v-decorator="[
                            'company_cep',
                            {
                                rules: [
                                    {
                                        required: validateFirstStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                        @change="getLocationCompany()"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('company_location_street')
                                ? 'filled'
                                : ''
                        "
                        >Endereço</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Endereço"
                        v-decorator="[
                            'company_location_street',
                            {
                                rules: [
                                    {
                                        required: validateFirstStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="8">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('company_location_number')
                                ? 'filled'
                                : ''
                        "
                        >Número</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Número"
                        v-decorator="[
                            'company_location_number',
                            {
                                rules: [
                                    {
                                        required: validateFirstStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="16">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue(
                                'company_location_neighborhood'
                            )
                                ? 'filled'
                                : ''
                        "
                        >Bairro</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Bairro"
                        v-decorator="[
                            'company_location_neighborhood',
                            {
                                rules: [
                                    {
                                        required: validateFirstStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="18">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('company_location_city')
                                ? 'filled'
                                : ''
                        "
                        >Cidade</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Cidade"
                        v-decorator="[
                            'company_location_city',
                            {
                                rules: [
                                    {
                                        required: validateFirstStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="6">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('company_location_state')
                                ? 'filled'
                                : ''
                        "
                        >Estado</label
                    >
                    <a-select
                        class="underline-input"
                        show-search
                        placeholder="ES"
                        v-decorator="[
                            'company_location_state',
                            {
                                rules: [
                                    {
                                        required: validateFirstStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of states"
                            :key="index"
                            :value="item.initials"
                        >
                            {{ item.initials.toUpperCase() }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue(
                                'company_location_complement'
                            )
                                ? 'filled'
                                : ''
                        "
                        >Complemento</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Complemento"
                        v-decorator="['company_location_complement']"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="24">
                <a-button
                    class="mt-20"
                    type="primary"
                    size="large"
                    @click="goToSecondStep"
                    style="width: 100%"
                >
                    Próximo
                </a-button>
            </a-col>
        </a-row>

        <a-row :gutter="16" v-show="registerCompany.activeStep == '2'">
            <h3>Dados do proprietário da empresa</h3>
            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('first_name')
                                ? 'filled'
                                : ''
                        "
                        >Primeiro nome</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Primeiro nome"
                        v-decorator="[
                            'first_name',
                            {
                                rules: [
                                    {
                                        required: validateSecondStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('last_name') ? 'filled' : ''
                        "
                        >Sobrenome</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Sobrenome"
                        v-decorator="[
                            'last_name',
                            {
                                rules: [
                                    {
                                        required: validateSecondStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="this.form.getFieldValue('cpf') ? 'filled' : ''"
                        >CPF</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="CPF"
                        v-mask="'###.###.###-##'"
                        v-decorator="[
                            'cpf',
                            {
                                rules: [
                                    {
                                        required: validateSecondStep(),
                                        min: 9,
                                        message: 'Preencha todos números.',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('profession')
                                ? 'filled'
                                : ''
                        "
                        >Profissão</label
                    >

                    <a-select
                        class="underline-input"
                        placeholder="Profissão"
                        show-search
                        v-decorator="[
                            'profession',
                            {
                                rules: [
                                    {
                                        required: validateSecondStep(),
                                        message: 'Obrigatório.',
                                    },
                                ],
                            },
                        ]"
                        style="width: 100%"
                    >
                        <a-select-option
                            v-for="(item, index) in professions"
                            :key="index"
                            :value="item"
                        >
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="8">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="this.form.getFieldValue('rg') ? 'filled' : ''"
                        >RG</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="RG"
                        v-mask="['##.###.###', '#.###.###']"
                        v-decorator="[
                            'rg',
                            {
                                rules: [
                                    {
                                        required: validateSecondStep(),
                                        message: 'Obrigatório',
                                    },
                                    {
                                        min: 9,
                                        message: 'Preencha todos números',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="8">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('rg_doc_emission')
                                ? 'filled'
                                : ''
                        "
                        >Orgão Emissor</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Órgão Emissor"
                        v-decorator="[
                            'rg_doc_emission',
                            {
                                rules: [
                                    {
                                        required: validateSecondStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="8">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('rg_doc_emission_state')
                                ? 'filled'
                                : ''
                        "
                        >Estado</label
                    >
                    <a-select
                        class="underline-input"
                        show-search
                        placeholder="ES"
                        v-decorator="[
                            'rg_doc_emission_state',
                            {
                                rules: [
                                    {
                                        required: validateSecondStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of states"
                            :key="index"
                            :value="item.initials"
                        >
                            {{ item.initials.toUpperCase() }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('mobile_phone')
                                ? 'filled'
                                : ''
                        "
                        >Telefone celular</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Telefone celular"
                        v-mask="['(##) # ####-####']"
                        v-decorator="[
                            'mobile_phone',
                            {
                                rules: [
                                    {
                                        required: validateSecondStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('phone') ? 'filled' : ''
                        "
                        >Telefone fixo
                    </label>
                    <a-input
                        class="underline-input"
                        placeholder="Telefone fixo"
                        v-mask="['(##) ####-####']"
                        v-decorator="['phone']"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="14">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('email') ? 'filled' : ''
                        "
                        >E-mail
                    </label>
                    <a-input
                        class="underline-input"
                        v-decorator="[
                            'email',
                            {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Escreva um e-mail válido.',
                                    },
                                    {
                                        required: validateSecondStep(),
                                        message: 'Escreva seu e-mail no campo.',
                                    },
                                ],
                            },
                        ]"
                        placeholder="E-mail"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col v-if="$root.isAdmin()" :span="10">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="this.form.getFieldValue('role') ? 'filled' : ''"
                        >Função do usuário</label
                    >

                    <a-select
                        class="underline-input"
                        placeholder="Função do usuário"
                        v-decorator="[
                            'role',
                            {
                                rules: [
                                    {
                                        required: validateSecondStep(),
                                        message: 'Obrigatório.',
                                    },
                                ],
                            },
                        ]"
                        style="width: 100%"
                    >
                        <a-select-option
                            v-for="(item, index) in roles"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col
                v-if="!$root.isAdmin()"
                :span="24"
                class="mb-20 mt-30"
                style="display: none"
            >
                <a-form-item>
                    <input
                        type="text"
                        v-decorator="['role', { initialValue: 'user' }]"
                    />
                </a-form-item>
            </a-col>

            <a-col :span="24" class="mb-20 mt-30">
                <a-switch v-model="userPassword" size="small" />
                Deseja enviar definição de senha para
                <strong>
                    {{
                        this.form.getFieldValue("email")
                            ? this.form.getFieldValue("email")
                            : "o email informado"
                    }}</strong
                >?
            </a-col>

            <a-col v-if="setPassword()" :span="12">
                <a-form-item>
                    <a-input
                        class="underline-input"
                        v-decorator="[
                            'password',
                            {
                                rules: [
                                    {
                                        required: setPassword(),
                                        message: 'Digite a senha',
                                    },
                                ],
                            },
                        ]"
                        type="password"
                        placeholder="Digite a senha"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col v-if="setPassword()" :span="12">
                <a-form-item>
                    <a-input
                        class="underline-input"
                        v-decorator="[
                            'confirm_password',
                            {
                                rules: [
                                    {
                                        required: setPassword(),
                                        message: 'Repita a senha.',
                                    },
                                    {
                                        validator: compareToFirstPassword,
                                    },
                                ],
                            },
                        ]"
                        type="password"
                        placeholder="Repita a senha"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="5">
                <a-button
                    class="mt-20"
                    type="primary"
                    size="large"
                    @click="backToFirstStep"
                    ghost
                    style="width: 100%"
                >
                    <a-icon type="left" />
                </a-button>
            </a-col>

            <a-col :span="19">
                <a-button
                    class="mt-20"
                    type="primary"
                    size="large"
                    @click="goToLastStep"
                    style="width: 100%"
                >
                    Próximo
                </a-button>
            </a-col>
        </a-row>

        <a-row :gutter="16" v-show="registerCompany.activeStep == '3'">
            <h3>Endereço do proprietário da empresa</h3>
            <a-col :span="12">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="this.form.getFieldValue('cep') ? 'filled' : ''"
                        >CEP</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="CEP"
                        v-mask="['#####-###']"
                        v-decorator="[
                            'cep',
                            {
                                rules: [
                                    {
                                        required: validateThirdStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                        @change="getLocation()"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('location_street')
                                ? 'filled'
                                : ''
                        "
                        >Endereço</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Endereço"
                        v-decorator="[
                            'location_street',
                            {
                                rules: [
                                    {
                                        required: validateThirdStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="8">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('location_number')
                                ? 'filled'
                                : ''
                        "
                        >Número</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Número"
                        v-decorator="[
                            'location_number',
                            {
                                rules: [
                                    {
                                        required: validateThirdStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="16">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('location_neighborhood')
                                ? 'filled'
                                : ''
                        "
                        >Bairro</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Bairro"
                        v-decorator="[
                            'location_neighborhood',
                            {
                                rules: [
                                    {
                                        required: validateThirdStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="18">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('location_city')
                                ? 'filled'
                                : ''
                        "
                        >Cidade</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Cidade"
                        v-decorator="[
                            'location_city',
                            {
                                rules: [
                                    {
                                        required: validateThirdStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="6">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('location_state')
                                ? 'filled'
                                : ''
                        "
                        >Estado</label
                    >
                    <a-select
                        class="underline-input"
                        show-search
                        placeholder="ES"
                        v-decorator="[
                            'location_state',
                            {
                                rules: [
                                    {
                                        required: validateThirdStep(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of states"
                            :key="index"
                            :value="item.initials"
                        >
                            {{ item.initials.toUpperCase() }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            this.form.getFieldValue('location_complement')
                                ? 'filled'
                                : ''
                        "
                        >Complemento</label
                    >
                    <a-input
                        class="underline-input"
                        placeholder="Complemento"
                        v-decorator="['location_complement']"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="5">
                <a-button
                    class="mt-20"
                    type="primary"
                    size="large"
                    @click="backToSecondStep"
                    style="width: 100%"
                    ghost
                >
                    <a-icon type="left" />
                </a-button>
            </a-col>

            <a-col :span="19">
                <a-form-item class="mt-20">
                    <a-button
                        type="primary"
                        size="large"
                        html-type="submit"
                        :loading="loading"
                        style="width: 100%"
                    >
                        Cadastrar
                    </a-button>
                </a-form-item>
            </a-col>
        </a-row>
    </a-form>
</template>

<script>
import axios from "axios";
import professions from "../../../lists/professions.json";
import states from "../../../lists/states.json";

export default {
    metaInfo: {
        htmlAttrs: {
            lang: "pt-BR",
        },
        title: "Cadastro – Conectar",
        meta: [
            {
                name: "descrition",
                content: "Página de cadastro de usuários.",
            },
        ],
    },
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
            userPassword: true,
            professions,
            states,
            selectedPersonType: "Pessoa Física",
            sent: false,
            personTypes: [
                { label: "Pessoa Física", value: "Pessoa Física" },
                { label: "Pessoa Jurídica", value: "Pessoa Jurídica" },
            ],
            registerCompany: {
                activeStep: 1,
                percents: 10,
                firstStep: true,
                secondStep: false,
                thirdStep: false,
            },
            roles: [
                {
                    name: "Administrador",
                    value: "admin",
                },
                {
                    name: "Afiliado",
                    value: "user",
                },
                {
                    name: "Gerente de Negócios",
                    value: "business-manager",
                },
                {
                    name: "Administrador Corporativo",
                    value: "corporate-admin",
                },
            ],
        };
    },
    mounted() {},
    methods: {
        goToSecondStep(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                console.log(err, values);
                if (!err) {
                    this.registerCompany.activeStep = 2;
                    this.registerCompany.firstStep = false;
                    this.registerCompany.secondStep = true;
                    this.registerCompany.thirdStep = false;
                    this.registerCompany.percents = 50;
                } else {
                    this.registerCompany.activeStep = 1;
                    this.registerCompany.firstStep = true;
                    this.registerCompany.secondStep = false;
                    this.registerCompany.thirdStep = false;
                    this.registerCompany.percents = 10;
                }
            });
        },
        backToFirstStep() {
            this.registerCompany.activeStep = 1;
            this.registerCompany.firstStep = true;
            this.registerCompany.secondStep = false;
            this.registerCompany.thirdStep = false;
            this.registerCompany.percents = 10;
        },
        goToLastStep(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                console.log(err, values);
                if (!err) {
                    this.registerCompany.activeStep = 3;
                    this.registerCompany.firstStep = false;
                    this.registerCompany.secondStep = false;
                    this.registerCompany.thirdStep = true;
                    this.registerCompany.percents = 90;
                } else {
                    this.registerCompany.activeStep = 2;
                    this.registerCompany.firstStep = false;
                    this.registerCompany.secondStep = true;
                    this.registerCompany.thirdStep = false;
                    this.registerCompany.percents = 50;
                }
            });
        },
        backToSecondStep() {
            this.registerCompany.activeStep = 2;
            this.registerCompany.firstStep = false;
            this.registerCompany.secondStep = true;
            this.registerCompany.thirdStep = false;
            this.registerCompany.percents = 50;
        },
        validateFirstStep() {
            let flag = false;

            if (this.registerCompany.activeStep == 1) {
                flag = true;
            }

            return flag;
        },
        validateSecondStep() {
            let flag = false;

            if (this.registerCompany.activeStep == 2) {
                flag = true;
            }

            return flag;
        },
        validateThirdStep() {
            let flag = false;

            if (this.registerCompany.activeStep == 3) {
                flag = true;
            }

            return flag;
        },
        dynamicValidationCompany() {
            let flag = false;
            return flag;
        },
        handleSubmit(e) {
            e.preventDefault();
            this.loading = true;
            this.form.validateFields((err, values) => {
                console.log(err, values);

                let userData = values;

                userData = {
                    ...userData,
                    agreed_contract: 1,
                    rate: 0,
                    status: 1,
                    is_approved: 0,
                    sendEmail: this.userPassword,
                    created_by:
                        this.$store.state.userData.role != "admin"
                            ? this.$store.state.userData.id
                            : 0,
                };

                if (this.userPassword) {
                    userData = {
                        ...userData,
                        password: "Cf$QK?6/2vcDjSe" + new Date().getTime(),
                        confirm_password:
                            "Cf$QK?6/2vcDjSe" + new Date().getTime(),
                    };
                }

                console.log(err, userData);

                if (!err) {
                    this.$http
                        .post("/user/create.php", userData)
                        .then(({ data }) => {
                            this.$http
                                .post("/company/create.php", {
                                    ...values,
                                    owner_id: data.id,
                                    status: 0,
                                })
                                .then(({ data }) => {
                                    data;
                                    this.loading = false;
                                    this.registerCompany.percents = 100;

                                    this.$emit("listUsersAfterCreate", true);
                                    this.$emit("closeDrawerAfterCreate", true);
                                    this.$message.success(
                                        "Usuário e empresa criados com sucesso!"
                                    );

                                    setTimeout(() => {
                                        this.form.setFieldsValue({
                                            first_name: "",
                                            last_name: "",
                                            email: "",
                                            cpf: "",
                                            mobile_phone: "",
                                            phone: "",
                                            birthday: "",
                                            rg: "",
                                            rg_doc_emission: "",
                                            rg_doc_emission_state: "",
                                            company: "",
                                            profession: "",
                                            cep: "",
                                            location_street: "",
                                            location_number: "",
                                            location_state: "",
                                            location_city: "",
                                            location_neighborhood: "",
                                            location_complement: "",
                                        });
                                    }, 500);
                                })
                                .catch(({ response }) => {
                                    this.$message.error(response.data.message);
                                    this.loading = false;
                                });
                        })
                        .catch(({ response }) => {
                            this.$message.error(response.data.message);
                            this.form.setFieldsValue({
                                email: "",
                            });
                            this.registerCompany.activeStep = 2;
                            this.registerCompany.firstStep = false;
                            this.registerCompany.secondStep = true;
                            this.registerCompany.thirdStep = false;
                            this.registerCompany.percents = 50;

                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            });
        },
        async compareToFirstPassword(rule, value, callback) {
            rule;
            const form = this.form;
            if (value && value !== form.getFieldValue("password")) {
                callback("As senhas digitadas não são iguais.");
            } else {
                callback();
            }
        },
        setPassword() {
            return !this.userPassword;
        },
        getLocation() {
            console.log("aa");
            setTimeout(() => {
                if (this.form.getFieldValue("cep")) {
                    if (
                        this.form.getFieldValue("cep").replace("-", "")
                            .length == 8
                    ) {
                        this.cepLoading = true;
                        axios
                            .get(
                                "https://viacep.com.br/ws/" +
                                    this.form
                                        .getFieldValue("cep")
                                        .replace("-", "") +
                                    "/json/"
                            )
                            .then(({ data }) => {
                                data;
                                this.cepLoading = false;
                                this.form.setFieldsValue({
                                    location_street: data.logradouro,
                                    location_state: data.uf,
                                    location_city: data.localidade,
                                    location_neighborhood: data.bairro,
                                    location_complement: data.complemento,
                                });
                            })
                            .catch(({ response }) => {
                                response;
                                this.$message.error(
                                    "Houve um problema ao recuperar a localização."
                                );
                                this.cepLoading = false;
                            });
                    }
                }
            }, 110);
        },
        getLocationCompany() {
            console.log("aa");
            setTimeout(() => {
                if (this.form.getFieldValue("company_cep")) {
                    if (
                        this.form.getFieldValue("company_cep").replace("-", "")
                            .length == 8
                    ) {
                        this.cepLoading = true;
                        axios
                            .get(
                                "https://viacep.com.br/ws/" +
                                    this.form
                                        .getFieldValue("company_cep")
                                        .replace("-", "") +
                                    "/json/"
                            )
                            .then(({ data }) => {
                                data;
                                this.cepLoading = false;
                                this.form.setFieldsValue({
                                    company_location_street: data.logradouro,
                                    company_location_state: data.uf,
                                    company_location_city: data.localidade,
                                    company_location_neighborhood: data.bairro,
                                    company_location_complement:
                                        data.complemento,
                                });
                            })
                            .catch(({ response }) => {
                                response;
                                this.$message.error(
                                    "Houve um problema ao recuperar a localização."
                                );
                                this.cepLoading = false;
                            });
                    }
                }
            }, 110);
        },
        closeSuccessModal() {
            setTimeout(() => {
                this.$router.push(`/login`);
            }, 500);
        },
    },
};
</script>
