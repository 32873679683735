<template>
    <div class="page">
        <a-page-header class="bg">
            <template slot="backIcon">
                <a-icon type="left-circle" /> Voltar
            </template>
            <a-row type="flex" justify="space-between">
                <a-col>
                    <h1>Administradores</h1>
                </a-col>
                <a-col>
                    <a-button class="red-btn" @click="newUser">
                        + Adicionar usuário
                    </a-button>
                </a-col>
            </a-row>
        </a-page-header>

        <a-tabs default-active-key="users">
            <a-tab-pane key="users" tab="Usuários" force-render>
                <a-row class="mb-30" type="flex" justify="space-between">
                    <a-col>
                        <a-input
                            class="underline-input"
                            placeholder="Localizar usuário..."
                            @change="searchUsers"
                        >
                            <a-icon
                                slot="prefix"
                                type="search"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-col>
                    <a-col> </a-col>
                </a-row>
                <a-table
                    :columns="columns"
                    :data-source="listUsers"
                    :loading="loadingUsers"
                    :pagination="false"
                    :scroll="{ x: 1300 }"
                >
                    <a slot="id" slot-scope="text, record">{{ record.id }}</a>
                    <a slot="first_name" slot-scope="text, record">
                        <a-row
                            :gutter="15"
                            type="flex"
                            justify="start"
                            align="middle"
                        >
                            <a-col>
                                <a-avatar
                                    :size="54"
                                    :src="record.avatar"
                                    icon="user"
                                />
                            </a-col>
                            <a-col>
                                <div class="name">
                                    {{ record.first_name }}
                                    {{ record.last_name }}
                                </div>
                                <div class="email">{{ record.email }}</div>
                            </a-col>
                        </a-row>
                    </a>
                    <a slot="mobile_phone" slot-scope="text, record">{{
                        record.mobile_phone
                    }}</a>
                    <a slot="cpf" slot-scope="text, record">{{ record.cpf }}</a>
                    <a slot="company" slot-scope="text, record">{{
                        record.meta["company"] != undefined
                            ? record.meta.company.trading_name
                            : "Não possui"
                    }}</a>
                    <a slot="created" slot-scope="text, record">{{
                        record.created | formatDate
                    }}</a>
                    <span slot="action" slot-scope="record">
                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Editar</span>
                            </template>
                            <a @click="edit(record)">
                                <a-icon type="edit" />
                            </a>
                        </a-tooltip>

                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Ver</span>
                            </template>
                            <a class="ml-10" @click="view(record)"
                                ><img
                                    class="relative"
                                    src="../../assets/images/view.png"
                                    alt="ver"
                                    width="20"
                                    style="top:-4px"
                            /></a>
                        </a-tooltip>

                        <a-popconfirm
                            title="Tem certeza que deseja apagar este usuário?"
                            ok-text="Sim"
                            cancel-text="Não"
                            @confirm="deleteUser(record.id)"
                        >
                            <a-icon
                                slot="icon"
                                type="question-circle-o"
                                style="color: red"
                            />
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>Apagar</span>
                                </template>
                                <a class="ml-10"><a-icon type="delete"/></a>
                            </a-tooltip>
                        </a-popconfirm>
                    </span>
                </a-table>
            </a-tab-pane>

            <a-tab-pane key="disabled-users" tab="Desativados" force-render>
                <a-row class="mb-30" type="flex" justify="space-between">
                    <a-col>
                        <a-input
                            class="underline-input"
                            placeholder="Localizar usuário..."
                            @change="searchDisabledUsers"
                        >
                            <a-icon
                                slot="prefix"
                                type="search"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-col>
                    <a-col> </a-col>
                </a-row>
                <a-table
                    :columns="columns"
                    :data-source="listDisabledUsers"
                    :loading="loadingDisabledUsers"
                    :pagination="false"
                    :scroll="{ x: 1300 }"
                >
                    <a slot="id" slot-scope="text, record">{{ record.id }}</a>
                    <a slot="first_name" slot-scope="text, record">
                        <a-row
                            :gutter="15"
                            type="flex"
                            justify="start"
                            align="middle"
                        >
                            <a-col>
                                <a-avatar
                                    :size="54"
                                    :src="record.avatar"
                                    icon="user"
                                />
                            </a-col>
                            <a-col>
                                <div class="name">
                                    {{ record.first_name }}
                                    {{ record.last_name }}
                                </div>
                                <div class="email">{{ record.email }}</div>
                            </a-col>
                        </a-row>
                    </a>
                    <a slot="mobile_phone" slot-scope="text, record">{{
                        record.mobile_phone
                    }}</a>
                    <a slot="cpf" slot-scope="text, record">{{ record.cpf }}</a>
                    <a slot="company" slot-scope="text, record">{{
                        record.meta["company"] != undefined
                            ? record.meta.company.trading_name
                            : "Não possui"
                    }}</a>
                    <a slot="created" slot-scope="text, record">{{
                        record.created | formatDate
                    }}</a>
                    <span slot="action" slot-scope="record">
                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Editar</span>
                            </template>
                            <a @click="edit(record)">
                                <a-icon type="edit" />
                            </a>
                        </a-tooltip>

                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Ver</span>
                            </template>
                            <a class="ml-10" @click="view(record)"
                                ><img
                                    class="relative"
                                    src="../../assets/images/view.png"
                                    alt="ver"
                                    width="20"
                                    style="top:-4px"
                            /></a>
                        </a-tooltip>

                        <a-popconfirm
                            title="Tem certeza que deseja apagar este usuário?"
                            ok-text="Sim"
                            cancel-text="Não"
                            @confirm="deleteUser(record.id)"
                        >
                            <a-icon
                                slot="icon"
                                type="question-circle-o"
                                style="color: red"
                            />
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>Apagar</span>
                                </template>
                                <a class="ml-10"><a-icon type="delete"/></a>
                            </a-tooltip>
                        </a-popconfirm>
                    </span>
                </a-table>
            </a-tab-pane>
        </a-tabs>
        <a-drawer
            title="Adicionar Usuário"
            placement="right"
            :width="
                $root.reponsive([
                    {
                        min: 0,
                        max: 600,
                        span: '100%',
                    },
                    {
                        min: 601,
                        max: 9999,
                        span: '600px',
                    },
                ])
            "
            class="conectar-drawer"
            :closable="true"
            :visible="drawers.createUsers"
            @close="closeUsersDrawer"
        >
            <create-users
                v-if="drawers.createUsers"
                @listUsersAfterCreate="listUsersAfterCreate"
                @closeDrawerAfterCreate="closeDrawerAfterCreate"
            />
        </a-drawer>
    </div>
</template>

<script>
import _ from "lodash";
import { parse, format } from "date-fns";
import CreateUsers from "./forms/CreateUsers.vue";
export default {
    components: { CreateUsers },
    filters: {
        formatDate(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
    },
    data() {
        return {
            listUsers: [],
            listDisabledUsers: [],
            loadingUsers: true,
            loadingDisabledUsers: false,
            drawers: {
                users: false,
                createUsers: false,
            },
            columns: [
                {
                    title: "ID",
                    key: "id",
                    scopedSlots: { customRender: "id" },
                    width: 60,
                },
                {
                    title: "Nome",
                    key: "first_name",
                    scopedSlots: { customRender: "first_name" },
                    width: 340,
                },
                {
                    title: "Celular",
                    key: "mobile_phone",
                    scopedSlots: { customRender: "mobile_phone" },
                },
                {
                    title: "CPF",
                    key: "cpf",
                    scopedSlots: { customRender: "cpf" },
                },

                {
                    title: "Data de cadastro",
                    key: "created",
                    scopedSlots: { customRender: "created" },
                },
                {
                    title: "",
                    key: "action",
                    scopedSlots: { customRender: "action" },
                    align: "right",
                    fixed: "right",
                    width: 120,
                },
            ],
        };
    },
    beforeMount() {
        setTimeout(() => {
            let body = document.body;
            body.classList.add("conectar");
            body.classList.add("list");
            body.classList.add("users");
        }, 20);
        document.title = "Administradores - Conectar";
        this.getUsers();
        this.getDisabledUsers();
    },
    beforeDestroy() {
        let body = document.body;
        body.classList.remove("users");
        body.classList.remove("list");
    },
    methods: {
        searchUsers: _.debounce(function(e) {
            this.loadingUsers = true;
            if (e.target.value) {
                this.$http
                    .post(
                        `/user/search.php?s=${e.target.value}&role=admin&status=1&is_approved=1`
                    )
                    .then(({ data }) => {
                        this.loadingUsers = false;
                        this.listUsers = data.data;
                    })
                    .catch(({ response }) => {
                        response;
                        this.loadingUsers = false;
                        this.listUsers = [];
                    });
            } else {
                this.getUsers();
            }
        }, 600),
        searchDisabledUsers: _.debounce(function(e) {
            this.loadingDisabledUsers = true;
            if (e.target.value) {
                this.$http
                    .post(
                        `/user/search.php?s=${e.target.value}&role=admin&status=0&is_approved=1`
                    )
                    .then(({ data }) => {
                        this.loadingDisabledUsers = false;
                        this.listDisabledUsers = data.data;
                    })
                    .catch(({ response }) => {
                        response;
                        this.loadingDisabledUsers = false;
                        this.listDisabledUsers = [];
                    });
            } else {
                this.getDisabledUsers();
            }
        }, 600),
        closeUsersDrawer() {
            this.drawers.createUsers = false;
        },
        listUsersAfterCreate() {
            setTimeout(() => {
                this.getUsers();
                this.getDisabledUsers();
            }, 1000);
        },
        closeDrawerAfterCreate() {
            setTimeout(() => {
                this.drawers.createUsers = false;
            }, 500);
        },
        newUser() {
            this.drawers.createUsers = true;
        },
        getUsers() {
            this.loadingUsers = true;
            this.$http
                .post("/user/read.php", {
                    role: ["admin"],
                    is_approved: "1",
                    status: "1",
                    page: 1,
                    per_page: 10,
                })
                .then(({ data }) => {
                    this.loadingUsers = false;
                    this.listUsers = data.data;
                })
                .catch(({ response }) => {
                    response;
                    this.loadingUsers = false;
                    this.listUsers = [];
                });
        },
        getDisabledUsers() {
            this.loadingDisabledUsers = true;
            this.$http
                .post("/user/read.php", {
                    role: ["admin"],
                    is_approved: "1",
                    status: "0",
                    page: 1,
                    per_page: 10,
                })
                .then(({ data }) => {
                    this.loadingDisabledUsers = false;
                    this.listDisabledUsers = data.data;
                })
                .catch(({ response }) => {
                    response;
                    this.loadingDisabledUsers = false;
                    this.listDisabledUsers = [];
                });
        },
        deleteUser(id) {
            this.loadingUsers = true;
            this.loadingDisabledUsers = true;
            this.$http
                .post("/user/delete.php", {
                    id,
                })
                .then(({ data }) => {
                    data;
                    this.getUsers();
                    this.getDisabledUsers();
                    this.$message.success("Apagado!");
                })
                .catch(({ response }) => {
                    response;
                    this.loadingUsers = false;
                    this.loadingDisabledUsers = false;
                });
        },
        view(user) {
            this.$router.push("/usuarios/ver/" + user.id);
        },
        edit(user) {
            this.$router.push("/usuarios/editar/" + user.id);
        },
    },
};
</script>
