<template>
    <a-form class="login-form" :form="form" @submit="handleSubmit">
        <a @click="$router.go(-1)"> <a-icon type="arrow-left" /> Voltar </a>

        <div class="logo">
            <img src="../../assets/images/conectar.png" alt="logo" />
        </div>

        <div v-show="sent">
            <a-alert
                class="mt-20"
                type="info"
                message="Sucesso!"
                show-icon
                banner
            >
                <div slot="description" style="font-size: 13px">
                    Um e-mail de redefinição de senha foi enviado para o seu
                    endereço de e-mail, mas pode levar alguns minutos para
                    aparecer na sua caixa de entrada. <br />
                    <br />
                    Aguarde pelo menos 10 minutos antes de tentar novamente ou
                    verifique sua caixa de spam.
                </div>
            </a-alert>
        </div>

        <div v-show="!sent">
            <h1 class="mt-30">ESQUECEU SUA SENHA?</h1>

            <div class="mb-20" style="line-height: 1.4; font-size: 13px">
                Esqueceu usa senha? Não se preocupe, insira seu e-mail no campo
                abaixo, clique em solicitar e em alguns minutos você receberá no
                seu e-mail de cadastro um link para cadastrar uma nova senha!
            </div>

            <a-form-item class="underline-wrapper mb-20">
                <!-- <a-input
                    class="underline-input"
                    size="large"
                    v-decorator="[
                        'email',
                        {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'Escreva um e-mail válido.',
                                },
                                {
                                    required: true,
                                    message: 'Escreva seu e-mail no campo.',
                                },
                            ],
                        },
                    ]"
                    placeholder="Insira seu e-mail"
                    value="ledilson@viajarturismo.com.br"
                >
                    <a-icon
                        slot="prefix"
                        type="mail"
                        style="color: rgba(0, 0, 0, 0.25)"
                    />
                </a-input> -->
                <a-form-item class="underline-outer">
                    <a-input
                        class="underline-input"
                        size="large"
                        v-decorator="['access_key']"
                        placeholder="Insira seu E-mail ou CPF"
                        @keyup="detectFieldValue"
                    >
                        <a-icon
                            slot="prefix"
                            :type="isEmail ? 'mail' : 'idcard'"
                            style="color: rgba(0, 0, 0, 0.25)"
                        />
                    </a-input>
                </a-form-item>
            </a-form-item>

            <a-form-item>
                <a-button
                    type="primary"
                    size="large"
                    shape="round"
                    html-type="submit"
                    :loading="loading"
                >
                    Enviar
                </a-button>
            </a-form-item>
        </div>
    </a-form>
</template>

<script>
export default {
    metaInfo: {
        htmlAttrs: {
            lang: "pt-BR",
        },
        title: "Esqueci minha senha – Conectar",
        meta: [
            {
                name: "descrition",
                content: "Página para recuperação de senhas do usuário.",
            },
        ],
    },
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
            sent: false,
            isEmail: true,
        };
    },
    mounted() {},
    methods: {
        recoverPassword(userData) {
            userData.isCPF = this.isCPF;
            userData.isEmail = this.isEmail;

            if (this.isCPF) {
                let theKey = userData.access_key;
                userData.access_key = `${theKey.substring(
                    0,
                    3
                )}.${theKey.substring(3, 6)}.${theKey.substring(
                    6,
                    9
                )}-${theKey.substring(9, 11)}${theKey.substring(11, 110)}`;
            }

            this.$http
                .post("/user/forgot-password.php", userData)
                .then(({ data }) => {
                    console.log(data.message);
                    this.$message.success("Sucesso!");
                    this.loading = false;
                    this.sent = true;
                })
                .catch(({ response }) => {
                    this.$message.error(response.data.message);
                    this.loading = false;
                    this.sent = false;
                });
        },
        detectFieldValue(val) {
            if (val.target.value.length > 2) {
                if (
                    Number.isInteger(
                        parseInt(
                            val.target.value
                                .replace(".", "")
                                .replace(".", "")
                                .replace("-", "")
                        )
                    )
                ) {
                    //
                    console.log("é numeroi");
                    this.isCPF = true;
                    this.isEmail = false;
                } else {
                    this.isCPF = false;
                    this.isEmail = true;
                }

                // if (val.target.value.includes("@")) {
                //     this.isEmail = true;
                //     this.isCPF = false;
                // } else {
                //     this.isEmail = false;
                //     this.isCPF = true;
                // }
            } else {
                this.isEmail = true;
                this.isCPF = false;
                console.log("vazio");
            }
        },
        handleSubmit(e) {
            e.preventDefault();
            this.loading = true;
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.recoverPassword(values);
                } else {
                    this.loading = false;
                    this.sent = false;
                }
            });
        },
    },
};
</script>
