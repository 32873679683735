<template>
    <a-form :form="form" @submit="handleSubmit">
        <a-row :gutter="16">
            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('company_name')
                                    ? 'filled'
                                    : ''
                            "
                            >Razão Social</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Razão Social"
                            v-decorator="[
                                'company_name',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon
                                slot="prefix"
                                type="right-circle"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 600,
                                span: 24,
                            },
                            {
                                min: 601,
                                max: 9999,
                                span: 12,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('trading_name')
                                    ? 'filled'
                                    : ''
                            "
                            >Nome Fantasia</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Nome Fantasia"
                            v-decorator="[
                                'trading_name',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon
                                slot="prefix"
                                type="right-circle"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 600,
                                span: 24,
                            },
                            {
                                min: 601,
                                max: 9999,
                                span: 12,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('cnpj') ? 'filled' : ''
                            "
                            >CNPJ</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="CNPJ"
                            v-mask="'##.###.###/####-##'"
                            v-decorator="[
                                'cnpj',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon
                                slot="prefix"
                                type="right-circle"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('company_email')
                                    ? 'filled'
                                    : ''
                            "
                            >E-mail da Empresa
                        </label>
                        <a-input
                            class="underline-input"
                            v-decorator="[
                                'company_email',
                                {
                                    rules: [
                                        {
                                            type: 'email',
                                            message:
                                                'Escreva um e-mail válido.',
                                        },
                                        {
                                            required: true,
                                            message:
                                                'Escreva seu e-mail no campo.',
                                        },
                                    ],
                                },
                            ]"
                            placeholder="E-mail da Empresa"
                            value=""
                        >
                            <a-icon
                                slot="prefix"
                                type="mail"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 600,
                                span: 24,
                            },
                            {
                                min: 601,
                                max: 9999,
                                span: 12,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('company_phone')
                                    ? 'filled'
                                    : ''
                            "
                            >Telefone fixo</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Telefone fixo"
                            v-mask="['(##) ####-####']"
                            v-decorator="[
                                'company_phone',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon
                                slot="prefix"
                                type="phone"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 600,
                                span: 24,
                            },
                            {
                                min: 601,
                                max: 9999,
                                span: 12,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('company_cep')
                                    ? 'filled'
                                    : ''
                            "
                            >CEP</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="CEP"
                            v-mask="['#####-###']"
                            v-decorator="[
                                'company_cep',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                            @keyup="getLocationCompany()"
                        >
                            <a-icon
                                slot="prefix"
                                type="environment"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue(
                                    'company_location_street'
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Endereço</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Endereço"
                            v-decorator="[
                                'company_location_street',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon
                                slot="prefix"
                                type="environment"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 600,
                                span: 24,
                            },
                            {
                                min: 601,
                                max: 9999,
                                span: 8,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue(
                                    'company_location_number'
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Número</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Número"
                            v-decorator="[
                                'company_location_number',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon
                                slot="prefix"
                                type="environment"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 600,
                                span: 24,
                            },
                            {
                                min: 601,
                                max: 9999,
                                span: 16,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue(
                                    'company_location_complement'
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Complemento</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Complemento"
                            v-decorator="['company_location_complement']"
                        >
                            <a-icon
                                slot="prefix"
                                type="environment"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 600,
                                span: 24,
                            },
                            {
                                min: 601,
                                max: 9999,
                                span: 10,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('company_location_city')
                                    ? 'filled'
                                    : ''
                            "
                            >Cidade</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Cidade"
                            v-decorator="[
                                'company_location_city',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon
                                slot="prefix"
                                type="environment"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 600,
                                span: 24,
                            },
                            {
                                min: 601,
                                max: 9999,
                                span: 4,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue(
                                    'company_location_state'
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Estado</label
                        >
                        <a-select
                            class="underline-input"
                            show-search
                            placeholder="ES"
                            v-decorator="[
                                'company_location_state',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option
                                v-for="(item, index) of states"
                                :key="index"
                                :value="item.initials"
                            >
                                {{ item.initials.toUpperCase() }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 600,
                                span: 24,
                            },
                            {
                                min: 601,
                                max: 9999,
                                span: 10,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue(
                                    'company_location_neighborhood'
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Bairro</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Bairro"
                            v-decorator="[
                                'company_location_neighborhood',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon
                                slot="prefix"
                                type="environment"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="24" class="mb-20 mt-30">
                    <a-switch v-model="addCompanyOwner" size="small" />
                    Deseja informar o proprietário da empresa?
                </a-col>

                <a-col v-if="addCompanyOwner" :span="20">
                    <a-form-item class="mb-10 underline-wrapper">
                        <a-auto-complete
                            :data-source="
                                listAffiliates.map(
                                    ({ first_name, last_name, id, role }) => ({
                                        value: id,
                                        text: `${id} - ${first_name} ${last_name} (${userRole(
                                            role
                                        )})`,
                                    })
                                )
                            "
                            style="width: 100%"
                            placeholder="Buscar afiliados..."
                            @search="searchUsers"
                            @select="onOwnerSelected"
                        >
                            <a-input class="underline-input">
                                <a-icon slot="prefix" type="search" />
                            </a-input>
                        </a-auto-complete>
                    </a-form-item>
                </a-col>

                <a-col v-if="addCompanyOwner" :span="4">
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('owner_id')
                                    ? 'filled'
                                    : ''
                            "
                            >ID</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="ID"
                            disabled
                            v-decorator="[
                                'owner_id',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <!-- {{ loading }} -->

                <a-col :span="24">
                    <a-form-item class="underline-wrapper a-center mt-30">
                        <a-button
                            type="primary"
                            size="large"
                            html-type="submit"
                            :loading="loading"
                        >
                            Cadastrar empresa
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-row>
    </a-form>
</template>

<script>
import axios from "axios";
// import subYears from "date-fns/subYears";
// import { format } from "date-fns";
import states from "../../../lists/states.json";
import _ from "lodash";

export default {
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
            loadingUsers: false,
            addCompanyOwner: false,
            listAffiliates: [],
            searchTerm: "",
            states,
        };
    },
    mounted() {
        // this.form.setFieldsValue({
        //     cnpj: "8888.8888.8888/0001",
        //     company_name: "EMPRESA LTDA",
        //     trading_name: "Conectar",
        //     website: "conectar.com.br",
        //     company_type: "Franqueada",
        //     company_email: "led1@conectarinvestimentos.com",
        //     company_mobile_phone: "31 99999 - 9999",
        //     company_phone: "31 9999 - 9999",
        //     company_cep: "35400000",
        //     company_location_street: "Rua teste",
        //     company_location_number: "1000",
        //     company_location_neighborhood: "Bairro teste",
        //     company_location_city: "Cidade teste",
        //     company_location_state: "MG",
        //     company_location_complement: "Apt",
        //     logo: "",
        // });
    },
    methods: {
        searchUsers: _.debounce(function(term) {
            this.loadingUsers = true;
            if (term) {
                this.searchTerm = term;
                let params = `?page=1&per_page=50&s=${term}&status=1&is_approved=1&role=user,corporate-admin,business-manager,corporate-affiliate`;
                if (!this.$root.isAdmin()) {
                    params += "&created_by=" + this.$store.state.companyData.id;
                }

                this.$http
                    .post(`/user/list.php` + params)
                    .then(({ data }) => {
                        this.loadingUsers = false;
                        this.listAffiliates = data.data;
                    })
                    .catch(({ response }) => {
                        response;
                        this.loadingUsers = false;
                        this.listAffiliates = [];
                    });
            }
        }, 600),
        onOwnerSelected(id) {
            this.form.setFieldsValue({
                [`owner_id`]: id,
            });
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                this.loading = true;
                let companyData = values;
                companyData = {
                    ...companyData,
                    agreed_contract: 1,
                    rate: 0,
                    created_by:
                        this.$store.state.userData.role != "admin"
                            ? this.$store.state.userData.id
                            : 0,
                };

                console.log(err, companyData);

                if (!err) {
                    this.$http
                        .post("/company/create.php", companyData)
                        .then(({ data }) => {
                            data;
                            this.loading = false;
                            this.$emit("listCompaniesAfterCreate", true);
                            this.$emit("closeDrawerAfterCreate", true);
                            this.$message.success(data.message);

                            setTimeout(() => {
                                this.form.setFieldsValue({
                                    owner_id: "",
                                    cnpj: "",
                                    company_name: "",
                                    trading_name: "",
                                    company_email: "",
                                    company_mobile_phone: "",
                                    company_phone: "",
                                    company_cep: "",
                                    company_location_street: "",
                                    company_location_number: "",
                                    company_location_neighborhood: "",
                                    company_location_city: "",
                                    company_location_state: "",
                                    company_location_complement: "",
                                    logo: "",
                                });
                            }, 500);
                        })
                        .catch(({ response }) => {
                            this.$message.error(response.data.message);
                        });
                } else {
                    this.loading = false;
                }
            });
        },
        userRole(role) {
            if (role == "admin") {
                role = "Administrador";
            }

            if (role == "user") {
                role = "Afiliado";
            }

            if (role == "business-manager") {
                role = "Gerente de Negócios";
            }

            if (role == "corporate-admin") {
                role = "Administrador Corporativo";
            }

            return role;
        },
        getLocationCompany() {
            setTimeout(() => {
                if (this.form.getFieldValue("company_cep")) {
                    if (
                        this.form.getFieldValue("company_cep").replace("-", "")
                            .length == 8
                    ) {
                        this.cepLoading = true;
                        axios
                            .get(
                                "https://viacep.com.br/ws/" +
                                    this.form
                                        .getFieldValue("company_cep")
                                        .replace("-", "") +
                                    "/json/"
                            )
                            .then(({ data }) => {
                                data;
                                this.cepLoading = false;
                                this.form.setFieldsValue({
                                    company_location_street: data.logradouro,
                                    company_location_state: data.uf,
                                    company_location_city: data.localidade,
                                    company_location_neighborhood: data.bairro,
                                    company_location_complement:
                                        data.complemento,
                                });
                            })
                            .catch(({ response }) => {
                                response;
                                this.$message.error(
                                    "Houve um problema ao recuperar a localização."
                                );
                                this.cepLoading = false;
                            });
                    }
                }
            }, 110);
        },
    },
};
</script>
