<template>
    <div class="page">
        <a-page-header class="bg" sub-title="">
            <template slot="backIcon">
                <a-icon type="left-circle" /> Voltar
            </template>

            <a-row type="flex" justify="space-between">
                <a-col>
                    <h1>Aguardando aprovação</h1>
                </a-col>
            </a-row>
        </a-page-header>
        <a-tabs default-active-key="waiting">
            <a-tab-pane key="waiting" tab="Aguardando" forceRender>
                <a-row class="mb-30" type="flex" justify="space-between">
                    <a-col>
                        <a-input
                            class="underline-input"
                            placeholder="Localizar usuário..."
                            @change="searchWaitingUsers"
                        >
                            <a-icon
                                slot="prefix"
                                type="search"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-col>
                    <a-col> </a-col>
                </a-row>
                <a-table
                    :columns="columns"
                    :data-source="listWaitingUsers"
                    :loading="loadingWaitingUsers"
                    :pagination="false"
                    :scroll="{ x: 1300 }"
                >
                    <a slot="id" slot-scope="text, record">{{ record.id }}</a>
                    <a slot="first_name" slot-scope="text, record">
                        <a-row
                            :gutter="15"
                            type="flex"
                            justify="start"
                            align="middle"
                        >
                            <a-col class="profile-pic">
                                <a-avatar
                                    :size="54"
                                    :src="record.avatar"
                                    icon="user"
                                />
                                <a-icon
                                    v-if="record.role == 'corporate-admin'"
                                    type="star"
                                    theme="filled"
                                />
                            </a-col>
                            <a-col>
                                <div class="name">
                                    {{ record.first_name }}
                                    {{ record.last_name }}
                                </div>
                                <div class="email">{{ record.email }}</div>
                            </a-col>
                        </a-row>
                    </a>
                    <a slot="mobile_phone" slot-scope="text, record">{{
                        record.mobile_phone
                    }}</a>
                    <a slot="cpf" slot-scope="text, record">{{ record.cpf }}</a>
                    <a slot="company" slot-scope="text, record">
                        <a-button
                            v-if="record.meta['company'] != undefined"
                            type="link"
                            @click="viewCompany(record)"
                            style="padding: 0;"
                        >
                            {{ record.meta.company.trading_name }}
                        </a-button>
                    </a>
                    <a slot="created" slot-scope="text, record">{{
                        record.created | formatDate
                    }}</a>
                    <span slot="action" slot-scope="record">
                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Editar</span>
                            </template>
                            <a @click="edit(record)">
                                <a-icon type="edit" />
                            </a>
                        </a-tooltip>

                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Ver</span>
                            </template>
                            <a class="ml-10" @click="view(record)">
                                <img
                                    class="relative"
                                    src="../../assets/images/view.png"
                                    alt="ver"
                                    width="20"
                                    style="top: -4px"
                            /></a>
                        </a-tooltip>



                        <a-divider
                            type="vertical"
                            style="margin: 0 16px 0 12px"
                        />
                        <a-dropdown
                            overlayClassName="actions-dropdown"
                            :trigger="['click']"
                        >
                            <a style="font-size: 15px; color: #ccc">
                                <i
                                    class="fas fa-ellipsis-v"
                                    style="color: #aaa"
                                ></i>
                            </a>

                            <a-menu slot="overlay">
                                <a-menu-item>
                                    <a-popconfirm
                                        title="Tem certeza que deseja APROVAR?"
                                        ok-text="Sim"
                                        cancel-text="Não"
                                        @confirm="approveUser(record)"
                                        :icon="false"
                                    >
                                        <i class="far fa-check-circle"></i>
                                        Aprovar
                                    </a-popconfirm>
                                </a-menu-item>

                                <a-menu-item>
                                    <a-popconfirm
                                        title="Tem certeza que deseja REPROVAR?"
                                        ok-text="Sim"
                                        cancel-text="Não"
                                        @confirm="disapproveUser(record)"
                                        :icon="false"
                                    >
                                        <i class="far fa-times-circle"></i>
                                        Reprovar
                                    </a-popconfirm>
                                </a-menu-item>

                                <a-menu-item>
                                    <a-popconfirm
                                        title="Tem certeza que deseja apagar?"
                                        ok-text="Sim"
                                        placement="left"
                                        cancel-text="Não"
                                        @confirm="deleteUser(record.id)"
                                    >
                                        <a-icon
                                            slot="icon"
                                            type="question-circle-o"
                                        />
                                        <i class="far fa-trash-alt"></i> Apagar
                                    </a-popconfirm>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </span>
                </a-table>
            </a-tab-pane>
            <a-tab-pane key="unnaproved" tab="Reprovados" forceRender>
                <a-row class="mb-30" type="flex" justify="space-between">
                    <a-col>
                        <a-input
                            class="underline-input"
                            placeholder="Localizar usuário..."
                            @change="searchUnnaprovedUsers"
                        >
                            <a-icon
                                slot="prefix"
                                type="search"
                                style="color: rgba(0, 0, 0, 0.25)"
                            />
                        </a-input>
                    </a-col>
                    <a-col> </a-col>
                </a-row>
                <a-table
                    :columns="columns"
                    :data-source="listUnnaprovedUsers"
                    :loading="loadingUnnaprovedUsers"
                    :pagination="false"
                    :scroll="{ x: 1300 }"
                >
                    <a slot="id" slot-scope="text, record">{{ record.id }}</a>
                    <a slot="first_name" slot-scope="text, record">
                        <a-row
                            :gutter="15"
                            type="flex"
                            justify="start"
                            align="middle"
                        >
                            <a-col class="profile-pic">
                                <a-avatar
                                    :size="54"
                                    :src="record.avatar"
                                    icon="user"
                                />
                                <a-icon
                                    v-if="record.role == 'corporate-admin'"
                                    type="star"
                                    theme="filled"
                                />
                            </a-col>
                            <a-col>
                                <div class="name">
                                    {{ record.first_name }}
                                    {{ record.last_name }}
                                </div>
                                <div class="email">{{ record.email }}</div>
                            </a-col>
                        </a-row>
                    </a>
                    <a slot="mobile_phone" slot-scope="text, record">{{
                        record.mobile_phone
                    }}</a>
                    <a slot="cpf" slot-scope="text, record">{{ record.cpf }}</a>
                    <a slot="company" slot-scope="text, record">
                        <a-button
                            v-if="record.meta['company'] != undefined"
                            type="link"
                            @click="viewCompany(record)"
                        >
                            {{ record.meta.company.trading_name }}
                        </a-button>
                    </a>
                    <a slot="created" slot-scope="text, record">{{
                        record.created | formatDate
                    }}</a>
                    <span slot="action" slot-scope="record">
                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Editar</span>
                            </template>
                            <a @click="edit(record)">
                                <a-icon type="edit" />
                            </a>
                        </a-tooltip>

                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Ver</span>
                            </template>
                            <a class="ml-10" @click="view(record)"
                                ><img
                                    class="relative"
                                    src="../../assets/images/view.png"
                                    alt="ver"
                                    width="20"
                                    style="top: -4px"
                            /></a>
                        </a-tooltip>

                        <a-popconfirm
                            title="Tem certeza que deseja APROVAR?"
                            ok-text="Sim"
                            cancel-text="Não"
                            @confirm="approveUser(record)"
                            :icon="false"
                        >
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>Aprovar</span>
                                </template>
                                <a class="ml-10"
                                    ><img
                                        src="../../assets/images/check.png"
                                        alt="ver"
                                        width="20"
                                /></a>
                            </a-tooltip>
                        </a-popconfirm>

                        <a-popconfirm
                            title="Tem certeza que deseja apagar este usuário?"
                            ok-text="Sim"
                            cancel-text="Não"
                            @confirm="deleteUser(record.id)"
                        >
                            <a-icon
                                slot="icon"
                                type="question-circle-o"
                                style="color: red"
                            />
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>Apagar</span>
                                </template>
                                <a class="ml-10"> <a-icon type="delete" /> </a>
                            </a-tooltip>
                        </a-popconfirm>
                    </span>
                </a-table>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import _ from "lodash";
import { parse, format } from "date-fns";
export default {
    filters: {
        formatDate(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
    },
    data() {
        return {
            listWaitingUsers: [],
            listUnnaprovedUsers: [],
            loadingWaitingUsers: true,
            loadingUnnaprovedUsers: true,
            columns: [
                {
                    title: "ID",
                    key: "id",
                    width: 60,
                    scopedSlots: { customRender: "id" },
                },
                {
                    title: "Nome",
                    key: "first_name",
                    scopedSlots: { customRender: "first_name" },
                    width: 340,
                },
                {
                    title: "Celular",
                    key: "mobile_phone",
                    scopedSlots: { customRender: "mobile_phone" },
                    width: 160,
                },
                // {
                //     title: "CPF",
                //     key: "cpf",
                //     scopedSlots: { customRender: "cpf" },
                // },
                {
                    title: "Empresa",
                    key: "company",
                    scopedSlots: { customRender: "company" },
                    width: 160,
                },
                {
                    title: "Data de cadastro",
                    key: "created",
                    scopedSlots: { customRender: "created" },
                    width: 160,
                },
                {
                    title: "",
                    key: "action",
                    scopedSlots: { customRender: "action" },
                    align: "right",

                   // fixed: "right",
                },
            ],
        };
    },
    beforeMount() {

        if (!this.$root.isAdmin()) {
      this.$router.push(`/`);
    }


        setTimeout(() => {
            let body = document.body;
            body.classList.add("conectar");
            body.classList.add("list");
            body.classList.add("users");
            body.classList.add("unnaproved");
        }, 20);

        document.title = "Aguardando Aprovação - Conectar";
        this.getWaitingUsers();
        this.getUnnaprovedUsers();
    },
    beforeDestroy() {
        let body = document.body;
        body.classList.remove("users");
        body.classList.remove("list");
        body.classList.remove("unnaproved");
    },
    methods: {
        searchWaitingUsers: _.debounce(function (e) {
            this.loadingWaitingUsers = true;
            if (e.target.value) {
                this.$http
                    .post(
                        `/user/search.php?s=${e.target.value}&role=user,corporate-admin,corporate-affiliate,business-manager,investor&status=0&is_approved=0`
                    )
                    .then(({ data }) => {
                        this.loadingWaitingUsers = false;
                        this.listWaitingUsers = data.data;
                    })
                    .catch(({ response }) => {
                        response;
                        this.loadingWaitingUsers = false;
                        this.listWaitingUsers = [];
                    });
            } else {
                this.getWaitingUsers();
            }
        }, 600),
        searchUnnaprovedUsers: _.debounce(function (e) {
            this.loadingUnnaprovedUsers = true;
            if (e.target.value) {
                this.$http
                    .post(
                        `/user/search.php?s=${e.target.value}&role=user,corporate-admin,corporate-affiliate,business-manager,investor&status=0&is_approved=disapproved`
                    )
                    .then(({ data }) => {
                        this.loadingUnnaprovedUsers = false;
                        this.listUnnaprovedUsers = data.data;
                    })
                    .catch(({ response }) => {
                        response;
                        this.loadingUnnaprovedUsers = false;
                        this.listUnnaprovedUsers = [];
                    });
            } else {
                this.getUnnaprovedUsers();
            }
        }, 600),
        getWaitingUsers() {
            this.$http
                .post("/user/read.php", {
                    role: [
                        "user",
                        "corporate-admin",
                        "business-manager",
                        "corporate-affiliate",
                        "investor",
                    ],
                    is_approved: 0,
                    page: 1,
                    per_page: 10,
                })
                .then(({ data }) => {
                    this.loadingWaitingUsers = false;
                    this.listWaitingUsers = data.data;
                })
                .catch(({ response }) => {
                    response;
                    this.listWaitingUsers = [];
                    this.loadingWaitingUsers = false;
                });
        },
        getUnnaprovedUsers() {
            this.$http
                .post("/user/read.php", {
                    role: [
                        "user",
                        "corporate-admin",
                        "business-manager",
                        "corporate-affiliate",
                    ],
                    is_approved: "disapproved",
                    page: 1,
                    per_page: 10,
                })
                .then(({ data }) => {
                    data;
                    this.loadingUnnaprovedUsers = false;
                    this.listUnnaprovedUsers = data.data;
                })
                .catch(({ response }) => {
                    response;
                    this.loadingUnnaprovedUsers = false;
                });
        },
        approveUser(user) {
            this.loadingWaitingUsers = true;
            this.loadingUnnaprovedUsers = true;
            this.$http
                .post("/user/approve.php", {
                    id: user.id,
                    status: true,
                    is_approved: true,
                    modified_by: {
                        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                        id: this.$store.state.userData.id,
                    },
                    modified_user: {
                        name: `${user.first_name} ${user.last_name}`,
                        id: user.id,
                        email: user.email,
                    },
                })
                .then(({ data }) => {
                    this.getUnnaprovedUsers();
                    this.getWaitingUsers();
                    this.$message.success(data.message, 7);
                })
                .catch(({ response }) => {
                    response;
                    this.loadingWaitingUsers = false;
                    this.loadingUnnaprovedUsers = false;
                });
        },
        disapproveUser(user) {
            this.loadingWaitingUsers = true;
            this.loadingUnnaprovedUsers = true;
            this.$http
                .post("/user/approve.php", {
                    id: user.id,
                    status: false,
                    is_approved: "disapproved",
                    modified_by: {
                        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                        id: this.$store.state.userData.id,
                    },
                    modified_user: {
                        name: `${user.first_name} ${user.last_name}`,
                        id: user.id,
                        email: user.email,
                    },
                })
                .then(({ data }) => {
                    this.getUnnaprovedUsers();
                    this.getWaitingUsers();
                    this.$message.success(data.message, 7);
                })
                .catch(({ response }) => {
                    response;
                    this.loadingWaitingUsers = false;
                    this.loadingUnnaprovedUsers = false;
                });
        },
        deleteUser(id) {
            this.loadingWaitingUsers = true;
            this.loadingUnnaprovedUsers = true;
            this.$http
                .post("/user/delete.php", {
                    id,
                })
                .then(({ data }) => {
                    data;
                    this.getWaitingUsers();
                    this.getUnnaprovedUsers();
                    this.$message.success("Apagado!");
                })
                .catch(({ response }) => {
                    response;
                    this.loadingWaitingUsers = false;
                    this.loadingUnnaprovedUsers = false;
                });
        },
        view(user) {
            this.$router.push("/afiliados/ver/" + user.id);
        },
        viewCompany(user) {
            this.$router.push("/empresas/ver/" + user.meta.company.id);
        },
        edit(user) {
            this.$router.push("/afiliados/editar/" + user.id);
        },
    },
};
</script>