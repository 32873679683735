var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/images/conectar.png"),"alt":"logo"}})]),_c('h1',{staticClass:"mb-20"},[_vm._v("Login")]),_c('a-form-item',{staticClass:"underline-outer"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['access_key']),expression:"['access_key']"}],staticClass:"underline-input",attrs:{"size":"large","placeholder":"Insira seu E-mail ou CPF"},on:{"keyup":_vm.detectFieldValue}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":_vm.isEmail ? 'mail' : 'idcard'},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"underline-wrapper mb-20"},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                    rules: [
                        {
                            required: true,
                            message: 'Escreva sua senha.',
                        } ],
                } ]),expression:"[\n                'password',\n                {\n                    rules: [\n                        {\n                            required: true,\n                            message: 'Escreva sua senha.',\n                        },\n                    ],\n                },\n            ]"}],staticClass:"underline-input",attrs:{"size":"large","type":"password","placeholder":"Insira sua senha"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"remember-forgot mb-20"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remember',
                {
                    valuePropName: 'checked',
                    initialValue: true,
                } ]),expression:"[\n                'remember',\n                {\n                    valuePropName: 'checked',\n                    initialValue: true,\n                },\n            ]"}]},[_vm._v(" Lembrar ")]),_c('a',{staticClass:"login-form-forgot",on:{"click":function($event){return _vm.forgotPassword()}}},[_vm._v(" Esqueceu sua senha? ")])],1),_c('a-form-item',[_c('a-button',{staticClass:"mb-20",attrs:{"type":"primary","size":"large","html-type":"submit","loading":_vm.loading,"shape":"round"}},[_vm._v(" LOGIN ")]),_c('a-button',{staticClass:"register",attrs:{"size":"large","shape":"round"},on:{"click":function($event){return _vm.$router.push("/cadastro")}}},[_vm._v(" CADASTRAR ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }