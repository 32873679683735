import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import App from "./App.vue";
import store from "./store/index";
import Antd from "ant-design-vue";
import VueMeta from "vue-meta";
import VueTheMask from "vue-the-mask";
import Money from "v-money";
import VueUploadComponent from "vue-upload-component";
import VueCurrencyInput from "vue-currency-input";
import VueViewer from "v-viewer";
import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";
import "@fortawesome/fontawesome-free/js/all.js";
import * as GmapVue from "gmap-vue";
import "./registerServiceWorker";
import "./plugins/axios";
import "ant-design-vue/dist/antd.less";
import "viewerjs/dist/viewer.css";
import money from "v-money";

Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: "BRL",
  },
});

Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: " #",
  precision: 2,
  masked: false,
});
Vue.use(VueFileAgent);
Vue.use(GmapVue, {
  load: {
    key: "AIzaSyCX6BPwWurP41ORUNTBichqXhiyhyzihok",
    libraries: "places",
  },
  installComponents: true,
});

Vue.use(VueViewer);
Vue.component("file-upload", VueUploadComponent);
Vue.use(VueMeta);
Vue.use(Vuex);
Vue.use(Antd);
Vue.use(Money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: " #",
  precision: 2,
});

Vue.use(VueTheMask);
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.isLoggedIn) {
      next({
        name: "Login",
      });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

new Vue({
  router,
  store,
  data: {
    token: store.state.token,
    isLoggedIn: store.state.isLoggedIn,
    userData: {},
    windowWidth: window.innerWidth,
    gmapsKey: "AIzaSyDPE8tTa5I-bBgAP4kGtDkP4HPnVzYUksM",
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
  beforeMount() {
    this.getGmapsKey();
  },
  methods: {
    resizeWindow() {
      this.windowWidth = window.innerWidth;
    },
    getGmapsKey() {
      this.$http
        .post("/option/get.php", {
          id: 1,
        })
        .then(({ data }) => {
          this.gmapsKey = data.value;
        })
        .catch(({ response }) => {
          response;
        });
    },
    isAdmin() {
      return this.$store.state.userData.role === "admin";
    },
    isInvestor() {
      return this.$store.state.userData.role === "investor";
    },
    isUser() {
      return this.$store.state.userData.role === "user";
    },
    isCorporateAdmin() {
      return this.$store.state.userData.role === "corporate-admin";
    },
    isBusinessManager() {
      return this.$store.state.userData.role === "business-manager";
    },
    checkCapabilities(capabilities, module, allow) {
      let flag = false;
      capabilities.forEach((section) => {
        if (section.module == module) {
          section.roles.forEach((role) => {
            if (role.slug == this.$store.state.userData.role) {
              if (role.capabilitiesList.includes(allow)) {
                flag = true;
              }
            }
          });
        }
      });
      return flag;
    },
    haveAvatar() {
      let flag = false;
      if (this.$store.state.userData.avatar) {
        flag = true;
      }
      return flag;
    },
    reponsive(windowSizes) {
      let theSize = 24;
      windowSizes.forEach((size) => {
        if (size.min <= this.windowWidth && size.max >= this.windowWidth) {
          theSize = size.span;
        }
      });
      return theSize;
    },
  },
  render: (h) => h(App),
}).$mount("#app");
