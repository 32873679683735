<template>
  <div>
    <a-row type="flex" justify="end">
      <a-col
        v-if="
          $root.haveAvatar() &&
            $root.checkCapabilities(capabilities, 'properties', 'create')
        "
        class="top-bar-item"
        :flex="$root.windowWidth > 600 ? '200px' : '60px'"
      >
        <div class="action immobiles" @click="requestProperty">
          <img
            class="relative"
            src="@/assets/images/request-property.png"
            alt="ver"
            width="28"
            style="top: -11px"
          />
          <font class="font" style="font-weight: 500">
            {{ $root.windowWidth > 600 ? "Solicitar ativo" : "" }}
          </font>
        </div>

        <a-drawer
          class="property-drawer"
          placement="right"
          :width="$root.windowWidth > 600 ? '740px' : ''"
          :closable="true"
          :visible="drawers.requestProperty"
          @close="closePropertyDrawer"
        >
          <template slot="title">
            <img
              class="relative mr-5"
              src="@/assets/images/request-property-white.png"
              alt="ver"
              width="30"
              style="top: -2px"
            />
            SOLICITAR ATIVO
          </template>

          <RequestProperty
            v-if="drawers.requestProperty"
            @listPropertiesAfterCreate="listPropertiesAfterCreate"
            @closeDrawerAfterCreate="closePropertyDrawer"
          />
        </a-drawer>
      </a-col>

      <a-col
        v-if="
          $root.haveAvatar() &&
            $root.checkCapabilities(capabilities, 'properties', 'create')
        "
        class="top-bar-item"
        :flex="$root.windowWidth > 600 ? '200px' : '60px'"
      >
        <div class="action immobiles" @click="createProperty">
          <img
            class="relative"
            src="@/assets/images/add-property.png"
            alt="ver"
            width="30"
            style="top: -11px"
          />
          <font class="font" style="font-weight: 500">
            {{ $root.windowWidth > 600 ? "Cadastrar ativo" : "" }}
          </font>
        </div>

        <a-drawer
          class="property-drawer"
          placement="right"
          :width="$root.windowWidth > 600 ? '740px' : ''"
          :closable="true"
          :visible="drawers.propety"
          @close="closePropertyDrawer"
        >
          <template slot="title">
            <img
              class="relative mr-5"
              src="@/assets/images/add-property-white.png"
              alt="ver"
              width="30"
              style="top: -2px"
            />
            CADASTRAR ATIVO
          </template>
          <CreateProperty
            v-if="drawers.propety"
            @listPropertiesAfterCreate="listPropertiesAfterCreate"
            @closeDrawerAfterCreate="closePropertyDrawer"
          />
        </a-drawer>
      </a-col>

      <a-col
        v-if="$root.isAdmin() && $root.haveAvatar()"
        class="top-bar-item configs"
        flex="60px"
      >
        <router-link class="action" tag="div" to="/configuracoes">
          <img
            class="relative"
            src="@/assets/images/settings.png"
            alt="ver"
            width="30"
            style="top: -7px"
          />
        </router-link>
      </a-col>
      <a-col
        v-if="$root.haveAvatar() && totalUnread > 0"
        class="top-bar-item notifications"
        flex="60px"
      >
        <a-popover
          placement="bottom"
          trigger="click"
          width="300px"
          class="notifications"
        >
          <template slot="content">
            <ListNotifications @totalUnread="totalUnreadedNotifications" />
          </template>

          <template slot="title">
            <span>Notificações</span>
          </template>

          <div class="action">
            <a-badge :count="totalUnread">
              <img
                class="relative"
                src="@/assets/images/bell.png"
                alt="ico"
                width="30"
                style="top: -7px"
              />
            </a-badge>
          </div>
        </a-popover>
      </a-col>
      <a-col
        class="top-bar-item user-profile"
        :flex="$root.windowWidth > 600 ? '200px' : '60px'"
      >
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <div class="action">
              <a-avatar
                size="large"
                icon="user"
                :src="
                  $store.state.userData.avatar
                    ? $store.state.userData.avatar
                    : ''
                "
              />

              {{
                $root.windowWidth > 600
                  ? `${$store.state.userData.first_name} ${$store.state.userData.last_name}`
                  : ""
              }}
            </div>
          </a>
          <a-menu class="profile-dropdown" slot="overlay" style="width: 150px;">
            <router-link
              class="ant-dropdown-menu-item"
              exact-active-class="ant-menu-item-selected"
              tag="li"
              key="0"
              to="/perfil"
              ><a-icon type="user" /> <span>Meu perfil</span></router-link
            >
            <a-menu-divider />

            <a-menu-item key="3" @click="logOut()">
              <a-icon type="logout" /> Sair
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// libs
import { mapState, mapActions } from "vuex";

// components
import CreateProperty from "../../components/properties/CreateProperty.vue";
import RequestProperty from "../../components/properties/RequestProperty.vue";
import ListNotifications from "../../components/notifications/ListNotifications.vue";

export default {
  name: "HeaderMobile",
  props: {
    totalUnread: String,
  },
  components: {
    CreateProperty,
    RequestProperty,
    ListNotifications,
  },
  data() {
    return {
      menu: [],
      openMobileMenu: false,
      drawers: {
        propety: false,
        requestProperty: false,
        user: false,
      },
    };
  },
  computed: {
    ...mapState(["capabilities"]),
  },
  mounted() {
    setTimeout(() => {
      this.menu = [
        {
          name: "Afiliados Aguardando",
          link: "/afiliados/aguardando-aprovacao",
          fwIcon: "",
          antdIcon: "solution",
          show: this.$root.isAdmin(),
        },
        {
          name: "Afiliados",
          link: "/afiliados/lista",
          fwIcon: "",
          antdIcon: "team",
          show:
            this.$root.isAdmin() ||
            this.$root.checkCapabilities(
              this.$store.state.capabilities,
              "users",
              "list"
            )
              ? true
              : false,
        },
        {
          name: "Investidores",
          link: "/afiliados/investidores",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
        {
          name: "Proprietários de terreno",
          link: "/afiliados/proprietarios-terreno",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
        {
          name: "Gestores regionais",
          link: "/afiliados/gestores-regionais",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
        {
          name: "Intermediadores de negócios",
          link: "/afiliados/intermediadores-negocios",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
      ];
    }, 1000);
  },
  methods: {
    ...mapActions(["setCapabilities"]),
    requestProperty() {
      this.drawers.requestProperty = true;
    },
    totalUnreadedNotifications(total) {
      this.totalUnread = total;
    },
    createProperty() {
      this.drawers.propety = true;
    },
    closePropertyDrawer() {
      this.drawers.propety = false;
      this.drawers.requestProperty = false;
    },
    listPropertiesAfterCreate() {
      this.$router.push("/ativos/lista");
    },
    logOut() {
      let user = JSON.parse(localStorage.userData);
      if (this.tokenExpired) {
        this.expiredTokenModal = true;
      } else {
        this.$http
          .post("/system-logs/create.php", {
            id: user.id,
            content: `<font class="user">${user.first_name} ${user.last_name}</font> deslogou do sistema.`,
            action: "loggedout",
          })
          .then(({ data }) => {
            data;
            localStorage.removeItem("userData");
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
            window.open("/login", "_self");
          })
          .catch(({ response }) => {
            response;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
