var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a',{on:{"click":function($event){return _vm.$router.push('/login')}}},[_c('a-icon',{attrs:{"type":"arrow-left"}}),_vm._v(" Voltar ")],1),_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/images/conectar.png"),"alt":"logo"}})]),(_vm.$route.meta.slug === 'key')?_c('h1',{staticClass:"mt-30"},[_vm._v("Alterar Senha")]):_vm._e(),(_vm.$route.meta.slug === 'password')?_c('h1',{staticClass:"mt-30"},[_vm._v(" Definição de Senha ")]):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                    rules: [
                        {
                            required: true,
                            message:
                                _vm.$route.meta.slug === 'key'
                                    ? 'Escreva sua nova senha.'
                                    : 'Escreva a sua senha.',
                        } ],
                } ]),expression:"[\n                'password',\n                {\n                    rules: [\n                        {\n                            required: true,\n                            message:\n                                $route.meta.slug === 'key'\n                                    ? 'Escreva sua nova senha.'\n                                    : 'Escreva a sua senha.',\n                        },\n                    ],\n                },\n            ]"}],staticClass:"underline-input",attrs:{"type":"password","placeholder":_vm.$route.meta.slug === 'key'
                    ? 'Escreva sua nova senha.'
                    : 'Escreva a sua senha'}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'confirm',
                {
                    rules: [
                        {
                            required: true,
                            message:
                                _vm.$route.meta.slug === 'key'
                                    ? 'Repita a sua nova senha.'
                                    : 'Repita a sua senha.',
                        },
                        {
                            validator: _vm.compareToFirstPassword,
                        } ],
                } ]),expression:"[\n                'confirm',\n                {\n                    rules: [\n                        {\n                            required: true,\n                            message:\n                                $route.meta.slug === 'key'\n                                    ? 'Repita a sua nova senha.'\n                                    : 'Repita a sua senha.',\n                        },\n                        {\n                            validator: compareToFirstPassword,\n                        },\n                    ],\n                },\n            ]"}],staticClass:"underline-input",attrs:{"type":"password","placeholder":_vm.$route.meta.slug === 'key'
                    ? 'Repita sua nova senha'
                    : 'Repita a sua senha'}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"mt-20",attrs:{"shape":"round","type":"primary","size":"large","html-type":"submit","loading":_vm.loading}},[_vm._v(" Definir senha ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }