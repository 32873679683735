<template>
  <div class="page">
    <a-page-header class="bg" sub-title=" " @back="() => $router.go(-1)">
      <template slot="backIcon">
        <a-icon type="left-circle" /> Voltar
      </template>
    </a-page-header>

    <a-row
      v-if="$root.checkCapabilities(capabilities, 'properties', 'list')"
      id="print"
      :gutter="25"
    >
      <a-col
        class="gutter-row sidebar"
        :span="$root.windowWidth > 1240 ? 6 : 24"
      >
        <a-row>
          <a-col class="box a-center bg mb-20" :span="24">
            <div class="name mt-10">{{ property.name }}</div>
            <div v-if="property.meta.is_owner == 'yes'" class="email mb-10">
              {{ property.owner.first_name }}
              {{ property.owner.last_name }}
            </div>

            <div v-if="property.meta.is_owner == 'no'" class="email mb-10">
              {{ property.meta.owner_first_name }}
              {{ property.meta.owner_last_name }}
            </div>

            <div class="rate mb-10">
              <a-rate v-model="propertyRate" disabled>
                <a-icon slot="character" type="star" />
              </a-rate>
            </div>

            <span
              v-if="
                $store.state.userData.id == property.owner.id || $root.isAdmin()
              "
            >
              <a-tooltip
                v-if="
                  $root.checkCapabilities(capabilities, 'properties', 'edit')
                "
                placement="top"
              >
                <template slot="title">
                  <span>Editar</span>
                </template>
                <a @click="edit(property.id)">
                  <a-icon type="edit" />
                </a>
              </a-tooltip>
            </span>

            <a
              class="ml-20 relative"
              @click="printDocs()"
              style="font-size: 20px; color: #999; top: -2px"
            >
              <a-icon type="printer" />
            </a>
          </a-col>

          <a-col class="box bg mb-20" :span="24">
            <div class="line">
              <div class="title">Identificação</div>
              <div class="text">
                {{ property.id }}
              </div>
            </div>
            <div class="line">
              <div class="title">Status</div>
              <div class="text">
                <a-tag v-if="property.status == 'disabled'" color="red">
                  {{ formatStatus(property.status) }}
                </a-tag>

                <a-tag v-if="property.status == 'active'" color="green">
                  {{ formatStatus(property.status) }}
                </a-tag>

                <a-tag v-if="property.status == 'negotiation'" color="orange">
                  {{ formatStatus(property.status) }}
                </a-tag>

                <a-tag v-if="property.status == 'blocked'" color="">
                  {{ formatStatus(property.status) }}
                </a-tag>
              </div>
            </div>

            <div v-if="property.owner.meta.company" class="line">
              <div class="title">Empresa</div>
              <div class="text">
                <a-row type="flex" justify="space-between">
                  <a-col>
                    {{ property.owner.meta.company.trading_name }}
                  </a-col>
                  <a-col>
                    <a-icon
                      class="link-btn"
                      type="login"
                      @click="
                        $router.push(
                          '/empresas/ver/' + property.owner.meta.company.id
                        )
                      "
                    />
                  </a-col>
                </a-row>
              </div>
            </div>

            <div class="line" v-if="property.meta.is_owner == 'no'">
              <div class="title">Proprietário</div>
              <div class="text">
                <a-row type="flex" justify="space-between">
                  <a-col>
                    {{ property.meta.owner_first_name }}
                    {{ property.meta.owner_last_name }}
                  </a-col>
                </a-row>
              </div>
            </div>

            <div class="line">
              <div class="title">Cadastrado por</div>
              <div class="text">
                <a-row type="flex" justify="space-between">
                  <a-col>
                    {{ property.owner.first_name }}
                    {{ property.owner.last_name }}
                  </a-col>
                  <a-col
                    v-if="
                      $store.state.userData.id == property.owner.id ||
                        $root.isAdmin()
                    "
                  >
                    <a-icon
                      v-if="
                        $root.checkCapabilities(
                          this.capabilities,
                          'users',
                          'view'
                        )
                      "
                      class="link-btn"
                      type="login"
                      @click="
                        $router.push('/afiliados/ver/' + property.owner.id)
                      "
                    />
                  </a-col>
                </a-row>
              </div>
            </div>

            <div class="line">
              <div class="title">Valor</div>
              <div class="text" v-if="property.meta.negotiate_value != '1'">
                {{ property.value ? "R$ " + formatPrice(property.value) : "" }}
              </div>
              <div class="text" v-if="property.meta.negotiate_value == '1'">
                A negociar
              </div>
            </div>

            <div class="line">
              <div class="title">Data do cadastro</div>
              <div class="text">
                {{ property.created | formatDate }}
              </div>
            </div>

            <div class="line">
              <div class="title">Última atualização</div>
              <div class="text">
                {{ property.modified | formatDate }}
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col class="gutter-row" :span="$root.windowWidth > 1240 ? 18 : 24">
        <div class="box tabs bg">
          <a-tabs default-active-key="data">
            <a-tab-pane key="data" tab="Informações" forceRender>
              <h3 v-show="property.cep">
                Localização
              </h3>

              <a-row class="data-row" :gutter="20">
                <a-col
                  v-show="property.cep"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">CEP</div>
                  <div class="value">
                    {{ property.cep }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.location_street"
                  :span="$root.windowWidth > 961 ? 16 : 24"
                >
                  <div class="label">Endereço</div>
                  <div class="value">
                    {{ property.location_street }}
                  </div>
                </a-col>

                <a-col
                  v-show="property.location_number"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">Número</div>
                  <div class="value">
                    {{ property.location_number }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.location_complement"
                  :span="$root.windowWidth > 961 ? 7 : 24"
                >
                  <div class="label">Complemento</div>
                  <div class="value">
                    {{ property.location_complement }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.location_neighborhood"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">Bairro</div>
                  <div class="value">
                    {{ property.location_neighborhood }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.location_city"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">Cidade</div>
                  <div class="value">
                    {{ property.location_city }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.location_state"
                  :span="$root.windowWidth > 961 ? 2 : 24"
                >
                  <div class="label">Estado</div>
                  <div class="value">
                    {{ property.location_state }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.population"
                  :span="$root.windowWidth > 961 ? 7 : 24"
                >
                  <div class="label">Habitantes</div>
                  <div class="value">
                    {{ property.population }}
                  </div>
                </a-col>
              </a-row>

              <h3>
                Dados Gerais
              </h3>

              <a-row class="data-row" :gutter="20">
                <a-col
                  v-show="property.registration"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">Matrícula</div>
                  <div class="value">
                    {{ property.registration }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.onus"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">Possui ônus</div>
                  <div class="value">
                    {{ property.onus }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.meta.zone"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">Zoneamento</div>
                  <div class="value">
                    {{ property.meta.zone }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.business_type"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">Vocação</div>
                  <div class="value">
                    {{ property.business_type }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.situation"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">Situação</div>
                  <div class="value">
                    {{ property.situation }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.zone"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">Zoneamento</div>
                  <div class="value">
                    {{ property.meta.zone }}
                  </div>
                </a-col>
                <a-col
                  v-show="property.metreage"
                  :span="$root.windowWidth > 961 ? 4 : 24"
                >
                  <div class="label">Metragem</div>
                  <div class="value">
                    {{ property.metreage }}
                    m²
                  </div>
                </a-col>
              </a-row>

              <h3>
                Descrição do Ativo
              </h3>

              <a-row class="data-row" :gutter="20">
                <a-col v-show="property.property_description" :span="24">
                  <div class="value">
                    {{ property.property_description }}
                  </div>
                </a-col>
              </a-row>

              <h3>
                Proposta comercial
              </h3>

              <a-row class="data-row" :gutter="20">
                <a-col v-show="property.commercial_proposal" :span="24">
                  <div class="value">
                    {{ property.commercial_proposal }}
                  </div>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="files" tab="Arquivos" forceRender>
              <div
                v-if="
                  $store.state.userData.id == property.owner.id ||
                    $root.isAdmin()
                "
              >
                <h3>
                  Matrícula do terreno
                </h3>

                <property-files
                  :files="categorizeFiles(property.meta.area_registration_docs)"
                />

                <h3 class="mt-30">
                  Fotos do terreno
                </h3>

                <property-files
                  :files="categorizeFiles(property.meta.property_pictures)"
                />
              </div>
              <div class="mt-20 a-center" v-else>
                Os arquivos só podem ser visualizados apenas pelo proprietário
                do terreno. Solicite os arquivos ao administrador.

                <a-button
                  v-if="!requestFilesToAdmin"
                  type="primary"
                  @click="onClickRequestFilesToAdmin"
                  class="mt-30"
                  >Solicitar arquivos</a-button
                >

                <div v-if="requestFilesToAdmin" class="mt-30">
                  Solcitação enviada! Em breve se, aprovado você receberá os
                  arquivos.
                </div>
              </div>
            </a-tab-pane>

            <a-tab-pane key="kmz" tab="KMZ" forceRender>
              <h3>
                Marcação do terreno (KMZ)
              </h3>

              <div id="mapa" style="width:100%; height: 500px;"></div>

              <GmapMap>
                <kmlLayer :clickable="true" />
              </GmapMap>

              <div v-if="property.meta.kmz_file" class="mt-20 mb-20">
                <a :href="property.meta.kmz_file[0].url">
                  Baixar KMZ
                </a>
              </div>
            </a-tab-pane>
            <a-tab-pane
              v-if="
                $store.state.userData.id == property.owner.id || $root.isAdmin()
              "
              class="notes"
              key="notes"
              tab="Anotações"
              forceRender
            >
              <div class="label">
                Nova anotação:
              </div>

              <vue-editor
                v-model="writtenNote"
                placeholder="Digite o texto aqui"
                :editor-toolbar="customToolbar"
              ></vue-editor>

              <div class="obs mb-10">
                <span style="color: red">*importante:</span>
                Após a sua anotação ser salva não será possível removê-la.
              </div>

              <a-row type="flex" justify="end">
                <a-col>
                  <a-button
                    @click="publishNote"
                    class="update-btn"
                    style="width: 120px"
                    :loading="loadingPropertyNotesUpdate"
                    >Publicar nota</a-button
                  >
                </a-col>
              </a-row>

              <input style="display: none" v-model="notesField" />

              <a-spin
                v-if="loadingNotes"
                class="mt-30"
                tip="Carregando notas..."
              >
                <div class="spin-content">
                  ...
                </div>
              </a-spin>
              <a-timeline
                v-if="listNotes.length > 0 && !loadingNotes"
                mode="left"
                class="conectar-timeline mt-20 mb-20"
                style="    height: 270px"
              >
                <a-timeline-item
                  v-for="(note, index) in listNotes"
                  :key="index"
                  color="green"
                >
                  <div class="datetime">
                    <a-icon type="clock-circle-o" />
                    <div
                      class="time"
                      v-html="formatDateTimeline(note.created)"
                    ></div>
                  </div>
                  <div class="author" v-html="note.user.first_name" />
                  <div class="content" v-html="note.content" />
                </a-timeline-item>
              </a-timeline>
              <div v-if="listNotes.length == 0">
                Nenhuma anotação registrada até o momento.
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-col>
    </a-row>

    <PrintProperty :property="property" />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { parse, format } from "date-fns";
import PropertyFiles from "./PropertyFiles.vue";
import capabilities from "../../mixins/general/capabilities.js";
import PrintProperty from "./PrintProperty.vue";

export default {
  mixins: [capabilities],
  name: "ViewProperty",
  components: { VueEditor, PropertyFiles, PrintProperty },
  filters: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
  data() {
    return {
      url: "",
      form: this.$form.createForm(this),
      property: {},
      propertyRate: 0,
      theLogo: "",
      writtenNote: "",
      loadingPropertyNotesUpdate: false,
      notesField: "[]",
      notes: [],
      loadingNotes: false,
      requestFilesToAdmin: false,
      listNotes: [],
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"],
        [
          "align",
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          "link",
          "blockquote",
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["clean"],
      ],
      columns: [
        {
          title: "ID",
          key: "id",
          scopedSlots: { customRender: "id" },
        },
        {
          title: "Nome",
          key: "first_name",
          scopedSlots: { customRender: "first_name" },
        },
        {
          title: "Celular",
          key: "mobile_phone",
          scopedSlots: { customRender: "mobile_phone" },
        },
        {
          title: "CPF",
          key: "cpf",
          scopedSlots: { customRender: "cpf" },
        },

        {
          title: "Data de cadastro",
          key: "created",
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "right",
        },
      ],
    };
  },
  beforeMount() {
    setTimeout(() => {
      let body = document.body;
      body.classList.add("conectar");
      body.classList.add("users");
      body.classList.add("view");
    }, 20);
    this.getProperty();
    // if (
    //     !this.$root.checkCapabilities(
    //         this.capabilities,
    //         "properties",
    //         "view"
    //     )
    // ) {
    //     this.$router.push("/");
    // }
  },
  mounted() {
    setTimeout(() => {
      let map = new window.google.maps.Map(document.getElementById("mapa"), {
        zoom: 16,
      });

      let kml_parameters = {
        url: this.property.meta.kmz_file[0].url,
        map: map,
      };
      new window.google.maps.KmlLayer(kml_parameters);
    }, 2000);
  },

  beforeDestroy() {
    let body = document.body;
    body.classList.remove("users");
    body.classList.remove("view");
  },
  methods: {
    onClickRequestFilesToAdmin() {
      this.$http
        .post("/notification/send.php", {
          user_id: 1,
          created_by: this.$store.state.userData.id,
          title: "Solicitação de arquivos",
          content: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} (ID ${this.$store.state.userData.id}) solicitou que envie os arquivos do ativo ID ${this.property.id}.`,
          action: "",
          receiver: "group",
          role: "admin"
        })
        .then(() => {
          this.$message.success("Solicitação enviada com sucesso!");
          this.requestFilesToAdmin = true;
        })
        .catch(({ response }) => {
          this.$message.error(response.message);
          this.requestFilesToAdmin = false;
        });
    },
    formatPrice(value) {
      if (value) {
        return parseFloat(value).toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        });
      }
    },
    show() {
      this.$viewerApi({
        images: this.images,
      });
    },
    categorizeFiles(filesArr) {
      let categorizedFiles = {};
      let jpgFiles = [];
      let jpegFiles = [];
      let pngFiles = [];
      let pdfFiles = [];
      let gifFiles = [];

      if (filesArr != undefined) {
        console.log("filesArr a", JSON.parse(filesArr));
        JSON.parse(filesArr).forEach((file) => {
          //console.log("filesArr", file.url.includes(".jpg"));
          if (file.url.includes(".jpg")) {
            // console.log("filesArr entro", file.url);
            jpgFiles.push(file.url);

            //jpgFiles += file.url+"|";
          }

          if (file.url.includes(".jpeg")) {
            jpegFiles.push(file.url);
          }

          if (file.url.includes(".png")) {
            pngFiles.push(file.url);
          }

          if (file.url.includes(".pdf")) {
            pdfFiles.push(file.url);
          }

          if (file.url.includes(".gif")) {
            gifFiles.push(file.url);
          }
        });
      }

      categorizedFiles.jpg = { files: jpgFiles, section: "Imagens" };
      categorizedFiles.jpeg = { files: jpegFiles, section: "Imagens" };
      categorizedFiles.png = { files: pngFiles, section: "Imagens" };
      categorizedFiles.pdf = { files: pdfFiles, section: "PDF" };
      categorizedFiles.gif = { files: gifFiles, section: "Imagens" };
      // jpgFiles.push({ files: file.url, section: "Imagens" });

      console.log("filesArr", categorizedFiles, "aaa", jpgFiles);

      return categorizedFiles;
    },
    renderFiles(filesArr) {
      let theFiles = "";

      if (filesArr != undefined) {
        JSON.parse(filesArr).forEach((file) => {
          //theFiles += `<img src="${file.url}" />`;
          theFiles += `${file.url}`;
        });
      }

      return theFiles;
    },
    renderPictures(filesArr) {
      let theFiles = JSON.parse(filesArr).map((file) => {
        return file.url;
      });
      return theFiles;
    },
    formatStatus(status) {
      let theStatus = "";
      if (status == "active") {
        theStatus = "Ativo";
      }

      if (status == "disabled") {
        theStatus = "Desativado";
      }

      if (status == "blocked") {
        theStatus = "Bloqueado";
      }

      if (status == "negotiation") {
        theStatus = "Em Negociação";
      }

      return theStatus;
    },
    getNotes() {
      this.loadingNotes = true;
      this.$http
        .post("/note/list.php", {
          mid: this.property.id,
        })
        .then(({ data }) => {
          this.listNotes = data.data;
          this.loadingNotes = false;
        })
        .catch(({ response }) => {
          // this.$message.error(response.data.message);
          response;
          this.loadingNotes = false;
        });
    },
    publishNote() {
      if (this.writtenNote) {
        this.$http
          .post("/note/create.php", {
            mid: this.property.id,
            user_id: this.$store.state.userData.id,
            module: "property",
            content: this.writtenNote,
          })
          .then(({ data }) => {
            this.loadingPropertyNotesUpdate = false;
            this.$message.success(data.message);
            this.getNotes();
            this.writtenNote = undefined;
          })
          .catch(({ response }) => {
            this.loadingPropertyNotesUpdate = false;
            this.$message.error(response.data.message);
          });
      } else {
        this.$message.error("Escreva algo no campo de anotações.");
      }
    },
    getProperty() {
      this.$http
        .post("/property/details.php", {
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.property = data;

          this.property.meta.kmz_file = JSON.parse(data.meta.kmz_file);

          this.getNotes();

          this.propertyRate =
            this.property.meta.rate != undefined
              ? `${this.property.meta.rate}`
              : "0";

          document.title = `${data.id} - ${data.name} - Conectar`;
        })
        .catch(({ response }) => {
          response;
        });
    },
    formatDateTimeline(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      theDate = format(theDate, "dd/MM/yyyy HH:mm");

      return `${theDate.split(" ")[0]} <br> ${theDate.split(" ")[1]} `;
    },
    logColor(log) {
      let logColor = "green";

      if (log.action == "loggedin") {
        logColor = "green";
      }

      if (log.action == "loggedout") {
        logColor = "purple";
      }

      return logColor;
    },
    printDocs() {
      // document.title = `${this.property.id} - ${this.property.first_name}`;
      window.print();
      //document.title = `${this.property.id} - ${this.property.first_name}`;
    },
    edit(id) {
      this.$router.push(`/${this.$route.fullPath.split("/")[1]}/editar/${id}`);
    },
    view(id) {
      setTimeout(() => {
        window.open("/afiliados/ver/" + id, "_blank");
      }, 500);
    },
  },
};
</script>

<style>
#print-area {
  display: none;
}
@media print {
  .ant-layout-sider,
  header.ant-layout-header,
  .ant-page-header,
  .box.extra,
  .gutter-row.sidebar,
  .box.bg.tabs,
  #whatsapp,
  .update-btn {
    display: none !important;
  }

  #print-area {
    display: block;
  }

  .sidebar.ant-col,
  .gutter-row.ant-col.ant-col-18 {
    width: 100%;
  }

  .conectar.edit .box.bg {
    box-shadow: none !important;
    border: 1px solid #eee;
  }
}
</style>
