import { render, staticRenderFns } from "./HeaderDesktop.vue?vue&type=template&id=6d7f01bc&scoped=true&"
import script from "./HeaderDesktop.vue?vue&type=script&lang=js&"
export * from "./HeaderDesktop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d7f01bc",
  null
  
)

export default component.exports