<template>
    <div>
        <h3>Dados pessoais</h3>

        <a-row class="data-row" :gutter="20">
            <a-col :span="$root.windowWidth > 1240 ? 5 : 24">
                <div class="label">Nome</div>
                <div class="value">{{ user.first_name }}</div>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 5 : 24">
                <div class="label">Sobrenome</div>
                <div class="value">{{ user.last_name }}</div>
            </a-col>
            <a-col v-show="user.cpf" :span="$root.windowWidth > 1240 ? 4 : 24">
                <div class="label">CPF</div>
                <div class="value">{{ user.cpf }}</div>
            </a-col>
            <a-col
                v-show="user.meta.rg"
                :span="$root.windowWidth > 1240 ? 5 : 24"
            >
                <div class="label">RG</div>
                <div class="value">
                    {{ user.meta.rg }} - {{ user.meta.rg_doc_emission
                    }}{{ user.meta.rg_doc_emission_state }}
                </div>
            </a-col>
            <a-col
                v-show="user.meta.birthday"
                :span="$root.windowWidth > 1240 ? 4 : 24"
            >
                <div class="label">Nascimento</div>
                <div class="value">{{ user.meta.birthday | formatDate }}</div>
            </a-col>
            <a-col
                v-show="user.meta.profession"
                :span="$root.windowWidth > 1240 ? 10 : 24"
            >
                <div class="label">Profissão</div>
                <div class="value">{{ user.meta.profession }}</div>
            </a-col>
        </a-row>

        <h3>Dados de contato</h3>

        <a-row class="data-row" :gutter="20">
            <a-col :span="$root.windowWidth > 1240 ? 10 : 24">
                <div class="label">E-mail</div>
                <div class="value">{{ user.email }}</div>
            </a-col>
            <a-col
                v-show="user.phone"
                :span="$root.windowWidth > 1240 ? 5 : 24"
            >
                <div class="label">Telefone fixo</div>
                <div class="value">{{ user.phone }}</div>
            </a-col>
            <a-col
                v-show="user.mobile_phone"
                :span="$root.windowWidth > 1240 ? 5 : 24"
            >
                <div class="label">Telefone celular</div>
                <div class="value">{{ user.mobile_phone }}</div>
            </a-col>
        </a-row>

        <h3 v-show="user.meta.cep">Endereço</h3>

        <a-row class="data-row" :gutter="20">
            <a-col
                v-show="user.meta.cep"
                :span="$root.windowWidth > 1240 ? 5 : 24"
            >
                <div class="label">CEP</div>
                <div class="value">{{ user.meta.cep }}</div>
            </a-col>
            <a-col
                v-show="user.meta.location_street"
                :span="$root.windowWidth > 1240 ? 19 : 24"
            >
                <div class="label">Endereço</div>
                <div class="value">{{ user.meta.location_street }}</div>
            </a-col>
            <a-col
                v-show="user.meta.location_number"
                :span="$root.windowWidth > 1240 ? 3 : 24"
            >
                <div class="label">Número</div>
                <div class="value">{{ user.meta.location_number }}</div>
            </a-col>
            <a-col
                v-show="user.meta.location_complement"
                :span="$root.windowWidth > 1240 ? 7 : 24"
            >
                <div class="label">Complemento</div>
                <div class="value">{{ user.meta.location_complement }}</div>
            </a-col>
            <a-col
                v-show="user.meta.location_neighborhood"
                :span="$root.windowWidth > 1240 ? 5 : 24"
            >
                <div class="label">Bairro</div>
                <div class="value">{{ user.meta.location_neighborhood }}</div>
            </a-col>
            <a-col
                v-show="user.meta.location_city"
                :span="$root.windowWidth > 1240 ? 5 : 24"
            >
                <div class="label">Cidade</div>
                <div class="value">{{ user.meta.location_city }}</div>
            </a-col>
            <a-col
                v-show="user.meta.location_state"
                :span="$root.windowWidth > 1240 ? 4 : 24"
            >
                <div class="label">Estado</div>
                <div class="value">{{ user.meta.location_state }}</div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { format, parse } from "date-fns";
export default {
    name: "DefaultDataForm",
    props: {
        user: Object,
        form: Object,
    },
    filters: {
        formatDate(date) {
            if (date != undefined) {
                if (date.length == 8) {
                    let theDate = parse(date, "yyyyMMdd", new Date());
                    return format(theDate, "dd/MM/yyyy");
                }

                if (date.length == 10) {
                    return date;
                }

                if (date.length > 22) {
                    let theDate = parse(
                        date.split("T")[0],
                        "yyyy-MM-dd",
                        new Date()
                    );
                    return format(theDate, "dd/MM/yyyy");
                }
            }
        },
    },
    data() {
        return {};
    },
    beforeMount() {},
    methods: {},
};
</script>
