export default {
  data() {
    return {
      listProperties: [],
      loadingProperties: true,
      filters: {
        loadingResults: false,
        users: {
          list: [],
          selected: [],
        },
        products: {
          list: [],
          selected: [],
        },
        clients: {
          list: [],
          selected: [],
        },
        regions: {
          list: [],
          selected: [],
        },
        population: {
          selected: [],
        },
        businessType: {
          selected: [],
        },
        period: {
          selected: [],
        },
        metreage: {
          selected: undefined,
        },
        searchTerm: "",
        price: {
          min: "",
          max: "",
        },
        ownerId: "",
        order: "desc",
        orderBy: "created",
        states: {
          selected: [],
        },
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
      },
      columns: [
        {
          title: "ID",
          key: "id",
          scopedSlots: { customRender: "id" },
          width: 70,
          sorter: true,
        },
        {
          title: "Nome do Ativo",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 200,
          sorter: true,
        },

        {
          title: "",
          key: "status",
          scopedSlots: { customRender: "status" },
        },

        {
          title: "Estado",
          key: "location_state",
          scopedSlots: { customRender: "location_state" },
          sorter: true,
        },
        {
          title: "Metragem",
          key: "metreage",
          scopedSlots: { customRender: "metreage" },
          sorter: true,
        },
        {
          title: "Tipo",
          key: "business_type",
          scopedSlots: { customRender: "business_type" },
          sorter: true,
        },
        {
          title: "Cadastrado por",
          key: "owner",
          scopedSlots: { customRender: "owner" },
          width: 200,
        },
        {
          title: "Classificação",
          key: "rate",
          scopedSlots: { customRender: "rate" },
          width: 180,
        },
        {
          title: "Data de cadastro",
          key: "created",
          scopedSlots: { customRender: "created" },
          sorter: true,
        },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "right",
          fixed: "right",
          width: 116,
        },
      ],
      businessTypeArr: [
        {
          label: "Projeto Loteamento",
          value: "Projeto Loteamento",
        },
        {
          label: "Projeto Comercial",
          value: "Projeto Comercial",
        },
        {
          label: "Projeto Residencial",
          value: "Projeto Residencial",
        },
        {
          label: "Misto (Todos eles)",
          value: "Misto (Todos eles)",
        },
      ],
      metreageArr: [
        {
          label: "até 5.000 m²",
          value: "0-5000",
        },
        {
          label: "5.000 a 50.000 m²",
          value: "5000-50000",
        },
        {
          label: "50.000 a 100.000 m²",
          value: "50000-100000",
        },
        {
          label: "100.000 a 300.000 m²",
          value: "100000-300000",
        },
        {
          label: "300.000 a 500.000 m²",
          value: "300000-500000",
        },
        {
          label: "acima de 500.000 m²",
          value: "500000-10000000",
        },
      ],
      populationArr: [
        {
          label: "0 a 50.000 habitantes",
          value: "0 a 50.000 habitantes",
        },
        {
          label: "De 50.000 a 100.000 habitantes",
          value: "De 50.000 a 100.000 habitantes",
        },
        {
          label: "De 100.000 a 500.000 habitantes",
          value: "De 100.000 a 500.000 habitantes",
        },
        {
          label: "Acima de 500.000 habitantes",
          value: "Acima de 500.000 habitantes",
        },
      ],
    };
  },
  methods: {
    changePage(current) {
      this.pagination.page = current;
      this.getProperties();
    },
    changePageSize(current, pageSize) {
      this.pagination.page = current;
      this.pagination.perPage = pageSize;
      this.getProperties();
    },
    propertiesTableChange(pagination, filters, sorter) {
      this.filters.order = sorter.order != undefined ? sorter.order : "desc";
      this.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getProperties();
    },
    getProperties() {
      this.loadingProperties = true;

      let filters = "";
      if (this.activePropertyTab == "properties")
        filters += "&status=active,negotiation";

      if (this.activePropertyTab == "inactivated-properties")
        filters += "&status=disabled,blocked";

      if (!this.$root.isAdmin() && !this.$root.isInvestor())
        filters += "&owner_id=" + this.$store.state.userData.id;

      if (this.filters.ownerId) filters += "&owner_id=" + this.filters.ownerId;

      if (this.filters.regions.selected.length > 0)
        filters += `&region=${this.filters.regions.selected}`;

      if (this.filters.states.selected.length > 0)
        filters += `&state=${this.filters.states.selected}`;

      if (this.filters.population.selected.length > 0)
        filters += `&population=${this.filters.population.selected}`;

      if (this.filters.businessType.selected.length > 0)
        filters += `&business_type=${this.filters.businessType.selected}`;

      if (this.filters.metreage.selected)
        filters += `&metreage=${this.filters.metreage.selected}`;

      if (this.filters.period.selected.length > 0)
        filters += `&period=${this.filters.period.selected[0]}|${this.filters.period.selected[1]}`;

      if (this.filters.searchTerm) filters += `&s=${this.filters.searchTerm}`;

      if (this.filters.price.min || this.filters.price.max) {
        let min = this.filters.price.min
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(",", ".");
        let max = this.filters.price.max
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(",", ".");

        if (min) if (!max) max = "10000000";
        if (max) if (!min) min = "0";

        filters += `&price-range=${min}-${max}`;
      }

      filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

      this.$http
        .get(
          `/property/list.php?page=${this.pagination.page}&per_page=${this.pagination.perPage}${filters}&is_approved=1`
        )
        .then(({ data }) => {
          this.pagination.total = data.meta.total;
          this.pagination.totalPages = data.meta.total_pages;
          this.listProperties = data.data;
          this.loadingProperties = false;
          this.afterLoadProperties();
        })
        .catch(({ response }) => {
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;
          this.listProperties = response.data.data;
          this.loadingProperties = false;
        });
    },
  },
};
