<template>
  <div class="page">
    <aPageHeader class="bg">
      <template slot="backIcon">
        <a-icon type="left-circle" /> Voltar
      </template>
      <aRow type="flex" justify="space-between">
        <aCol>
          <h1>Ativos com meu perfil</h1>
        </aCol>
        <aCol>
          <aRow :gutter="10" style="padding-right: 20px">
            <aCol :span="24">
              <aButton
                v-if="
                  $root.checkCapabilities(capabilities, 'properties', 'create')
                "
                @click="requestProperty"
                class="red-btn ghost mr-20 mb-10"
                key="3"
                ghost
              >
                Solicitar ativo
              </aButton>
              <aButton
                v-if="
                  $root.checkCapabilities(capabilities, 'properties', 'create')
                "
                @click="createProperty"
                class="red-btn"
                key="3"
              >
                + Adicionar ativo
              </aButton>
            </aCol>
          </aRow>
        </aCol>
      </aRow>
    </aPageHeader>

    <div v-if="$root.checkCapabilities(capabilities, 'properties', 'list')">
      <a-list
        class="my-profile mb-20 mt-10"
        size="small"
        bordered
        :data-source="data"
      >
        <div slot="header">
          <h2><a-icon type="user" /> Meu perfil</h2>
        </div>
        <a-list-item>
          <b>Vocação da área: </b>
          {{ user.business_type_of_interest | formatArr }}
          <a-divider type="vertical" /> <b> Tamanho das áreas (m²): </b>
          {{ user.area_size_of_interest | formatArr }}
          <a-divider type="vertical" /> <b> Estados de interesse:</b>
          {{ user.state_of_interest | formatArr }}
          <a-divider type="vertical" /> <b> Número de habitantes da cidade: </b>
          {{ user.population_of_interest | formatArr }}
        </a-list-item>
      </a-list>

      <aTable
        :columns="columns"
        :data-source="listProperties"
        :loading="loadingProperties"
        :pagination="false"
        :scroll="{ x: 1300 }"
        @change="propertiesTableChange"
      >
        <a slot="id" slot-scope="text, record">
          {{ record.id }}
        </a>

        <a slot="name" slot-scope="text, record">
          {{ record.name }}
        </a>

        <a slot="status" slot-scope="text, record">
          <div v-if="record.status" class="status-ball" :class="record.status">
            <a-tooltip placement="top">
              <template slot="title">
                {{ formatStatus(record.status) }}
              </template>
              •
            </a-tooltip>
          </div>
        </a>

        <div slot="location_state" slot-scope="text, record">
          {{ record.location_state }}
        </div>
        <a slot="metreage" slot-scope="text, record"
          >{{ parseFloat(record.metreage).toLocaleString() }} m²
        </a>
        <a slot="business_type" slot-scope="text, record">{{
          record.business_type
        }}</a>
        <a slot="owner" slot-scope="text, record">
          <div v-if="$root.checkCapabilities(capabilities, 'users', 'view')">
            <aButton
              v-if="$root.checkCapabilities(capabilities, 'users', 'view')"
              style="padding: 0"
              type="link"
              @click="viewOwner(record.owner.id)"
            >
              {{ record.owner.first_name }}
              {{ record.owner.last_name }}
            </aButton>
          </div>

          <div v-if="!$root.checkCapabilities(capabilities, 'users', 'view')">
            {{ record.owner.first_name }}
            {{ record.owner.last_name }}
          </div>
        </a>
        <a slot="rate" slot-scope="text, record">
          <a-rate v-model="record.meta.rate" allow-half disabled>
            <a-icon slot="character" type="star" />
          </a-rate>
        </a>
        <a slot="created" slot-scope="text, record">{{
          record.created | formatDate
        }}</a>
        <span slot="action" slot-scope="record">
          <a-tooltip v-if="$root.isAdmin()" placement="top">
            <template slot="title">
              <span>Editar</span>
            </template>
            <a @click="edit(record)">
              <a-icon type="edit" />
            </a>
          </a-tooltip>

          <a-tooltip
            v-if="$root.checkCapabilities(capabilities, 'properties', 'view')"
            placement="top"
          >
            <template slot="title">
              <span>Ver</span>
            </template>
            <a class="ml-10" @click="view(record)"><a-icon type="eye"/></a>
          </a-tooltip>

          <a-popconfirm
            v-if="$root.checkCapabilities(capabilities, 'properties', 'delete')"
            title="Tem certeza que deseja apagar este ativo?"
            ok-text="Sim"
            cancel-text="Não"
            @confirm="deleteProperty(record.id)"
          >
            <a-icon slot="icon" type="question-circle-o" />

            <a class="ml-10"><a-icon type="delete" /> </a>
          </a-popconfirm>
        </span>
      </aTable>

      <div class="a-center mt-30" style="padding-bottom: 100px">
        <aPagination
          show-size-changer
          :default-current="pagination.page"
          :total="pagination.total"
          @change="changePage"
          @showSizeChange="changePageSize"
        />
      </div>
    </div>

    <aDrawer
      class="property-drawer"
      placement="right"
      :width="$root.windowWidth > 600 ? '740px' : ''"
      :closable="true"
      :visible="drawers.requestProperty"
      @close="closePropertyDrawer"
    >
      <template slot="title">
        <img
          class="relative mr-5"
          src="@/assets/images/request-property-white.png"
          alt="ver"
          width="30"
          style="top:-2px"
        />
        SOLICITAR ATIVO
      </template>

      <RequestProperty
        v-if="drawers.requestProperty"
        @listPropertiesAfterCreate="listPropertiesAfterCreate"
        @closeDrawerAfterCreate="closePropertyDrawer"
      />
    </aDrawer>

    <aDrawer
      class="property-drawer"
      placement="right"
      :width="$root.windowWidth > 740 ? '740px' : '100%'"
      :closable="true"
      :visible="drawers.propety"
      @close="closePropertyDrawer"
    >
      <template slot="title"
        ><a-icon type="radius-setting" class="mr-10" /> Cadastrar Ativo
        Imobiliário
      </template>
      <create-property
        v-if="drawers.propety"
        @listPropertiesAfterCreate="listPropertiesAfterCreate"
        @closeDrawerAfterCreate="closePropertyDrawer"
      />
    </aDrawer>
  </div>
</template>

<script>
import CreateProperty from "./CreateProperty.vue";
import capabilities from "@/mixins/general/capabilities.js";
import RequestProperty from "./RequestProperty.vue";
import propertiesMixins from "@/mixins/property/propertiesMixins";
import reuseThings from "@/mixins/general/reuseThings";

export default {
  name: "ListPropertiesInvestor",
  components: { CreateProperty, RequestProperty },
  mixins: [capabilities, propertiesMixins, reuseThings],
  data() {
    return {
      activePropertyTab: "properties",
      drawers: {
        propety: false,
        requestProperty: false,
      },
      user: {},
    };
  },
  filters: {
    formatArr(arr) {
      let txt = "";
      if (arr !== undefined && arr !== "") {
        JSON.parse(arr).forEach((item) => {
          txt += `${item}, `;
        });
      }

      return txt ? txt.slice(0, -2) : "Não informado";
    },
  },
  beforeMount() {
    setTimeout(() => {
      let body = document.body;
      body.classList.add("conectar");
      body.classList.add("properties");
      body.classList.add("list");
      body.classList.add("unnaproved");
    }, 20);

    document.title = "Ativos - Conectar";

    if (!this.$root.isInvestor()) this.$router.push("/");

    //this.getProperties();
  },
  beforeDestroy() {
    let body = document.body;
    body.classList.remove("users");
    body.classList.remove("list");
    body.classList.remove("unnaproved");
  },
  mounted() {
    this.getUserById();
  },
  methods: {
    getUserById() {
      this.$http
        .post("/user/read-one.php", {
          id: this.$store.state.userData.id,
        })
        .then(({ data }) => {
          this.user = data;

          this.filters.states.selected = this.user.state_of_interest
            ? JSON.parse(this.user.state_of_interest)
            : ["Não informado"];
          this.filters.businessType.selected = this.user
            .business_type_of_interest
            ? JSON.parse(this.user.business_type_of_interest)
            : ["Não informado"];

          this.filters.population.selected = this.user.population_of_interest
            ? JSON.parse(this.user.population_of_interest)
            : ["Não informado"];

          this.filters.metreage.selected = this.user.area_size_of_interest
            ? JSON.parse(this.user.area_size_of_interest)
            : ["0-0"];

          this.getProperties();
        });
    },
    onChangePropertyTabs() {
      this.getProperties();
    },
    createProperty() {
      this.drawers.propety = true;
    },
    requestProperty() {
      this.drawers.requestProperty = true;
    },
    closePropertyDrawer() {
      this.drawers.propety = false;
      this.drawers.requestProperty = false;
    },
    deleteProperty(id) {
      this.loadingProperties = true;
      this.$http
        .post("/property/delete.php", {
          id,
        })
        .then(() => {
          this.getProperties();
          this.$message.success("Apagado!");
        })
        .catch(() => {
          this.loadingProperties = false;
        });
    },
    closeDrawerAfterCreate() {},
    listPropertiesAfterCreate() {
      this.loadingProperties = true;
      setTimeout(() => {
        this.getProperties();
      }, 1000);
    },
    viewOwner(id) {
      this.$router.push("/afiliados/ver/" + id);
    },
    view(company) {
      this.$router.push("/ativos/ver/" + company.id);
    },
    edit(company) {
      this.$router.push("/ativos/editar/" + company.id);
    },
    formatStatus(status) {
      let theStatus = "";
      if (status == "active") theStatus = "Ativo";
      if (status == "disabled") theStatus = "Desativado";
      if (status == "blocked") theStatus = "Bloqueado";
      if (status == "negotiation") theStatus = "Em Negociação";
      return theStatus;
    },
  },
};
</script>

<style lang="sass" scoped>
.my-profile
  font-size: 12px
  color: #333
  h2
    font-size: 14px
    margin: 0
    color: #e73945 !important
  b
    font-weight: 600
</style>
