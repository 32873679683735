var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page settings"},[_c('a-page-header',{staticClass:"bg",on:{"back":function () { return _vm.$router.go(-1); }}},[_c('template',{slot:"backIcon"},[_c('a-icon',{attrs:{"type":"left-circle"}})],1),_c('h1',[_vm._v("Configurações")])],2),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"users",attrs:{"tab":"Usuários"}},[_c('a-alert',{staticClass:"mb-30",attrs:{"type":"info","message":"Utilize os campos abaixo para informar as permissões dos usuários do sistema:","banner":""}}),_c('a-collapse',{staticClass:"collapse-modules",attrs:{"default-active-key":"0","bordered":false}},_vm._l((_vm.settings.capabilities),function(module,index){return _c('a-collapse-panel',{key:index,attrs:{"header":'Módulo: ' + module.name}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},_vm._l((module.roles),function(role,index){return _c('a-row',{key:index},[_c('a-checkbox-group',{staticStyle:{"width":"100%"},on:{"change":_vm.onChange},model:{value:(role.capabilitiesList),callback:function ($$v) {_vm.$set(role, "capabilitiesList", $$v)},expression:"role.capabilitiesList"}},[_c('a-col',{attrs:{"span":24}},[_c('a-row',[_c('a-col',{staticClass:"name",class:_vm.$root.windowWidth <
                                                        960
                                                            ? 'mb-10 mt-20'
                                                            : '',attrs:{"span":_vm.$root.windowWidth >
                                                        960
                                                            ? 4
                                                            : 24}},[_vm._v(" "+_vm._s(role.name)+" ")]),_vm._l((_vm.options),function(option,index){return _c('a-col',{key:index,attrs:{"span":_vm.$root.windowWidth >
                                                        960
                                                            ? 2
                                                            : 24}},[_c('a-checkbox',{attrs:{"value":option.value,"disabled":role.slug ==
                                                            'admin'
                                                                ? false
                                                                : false}},[_vm._v(" "+_vm._s(option.label)+" ")])],1)})],2)],1)],1)],1)}),1)],1)],1)}),1),_c('a-button',{staticClass:"mt-40",attrs:{"type":"primary","size":"large","html-type":"submit","loading":_vm.loading}},[_vm._v(" SALVAR ALTERAÇÕES ")])],1),_c('a-tab-pane',{key:"notifications",attrs:{"tab":"Notificações","force-render":""}},[_c('a-row',[_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 960 ? 12 : 24}},[_c('a-alert',{staticClass:"mb-30",attrs:{"type":"info","message":"Utilize os campos abaixo para enviar mensagens de notificação para os usuários do sistema:","banner":""}}),_c('a-row',{staticClass:"mb-30",attrs:{"gutter":[20, 20]}},[_c('a-col',{attrs:{"span":24}},[_c('label',{staticClass:"label mb-10"},[_vm._v(" Selecione o destinatário: ")]),_c('a-radio-group',{attrs:{"name":"radioGroup"},model:{value:(_vm.notification.receiver),callback:function ($$v) {_vm.$set(_vm.notification, "receiver", $$v)},expression:"notification.receiver"}},[_c('a-radio',{attrs:{"value":"all"}},[_vm._v(" Todos usuários do sistema ")]),_c('a-radio',{attrs:{"value":"specific"}},[_vm._v(" Usuário específico ")]),_c('a-radio',{attrs:{"value":"group"}},[_vm._v(" Grupo de usuários ")])],1)],1),(_vm.notification.receiver == 'group')?_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                                        {
                                            min: 0,
                                            max: 620,
                                            span: 24,
                                        },
                                        {
                                            min: 621,
                                            max: 9999,
                                            span: 12,
                                        } ])}},[_c('label',{staticClass:"label"},[_vm._v(" Selecione o grupo: ")]),_c('a-select',{staticClass:"underline-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Função","show-search":""},model:{value:(_vm.notification.role),callback:function ($$v) {_vm.$set(_vm.notification, "role", $$v)},expression:"notification.role"}},_vm._l((_vm.roles),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1):_vm._e(),(_vm.notification.receiver == 'specific')?_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                                        {
                                            min: 0,
                                            max: 620,
                                            span: 24,
                                        },
                                        {
                                            min: 621,
                                            max: 9999,
                                            span: 12,
                                        } ])}},[_c('label',{staticClass:"label mb-10"},[_vm._v(" ID do usuário: ")]),_c('a-input',{staticClass:"underline-input",attrs:{"type":"number","placeholder":"Escreva o ID"},model:{value:(_vm.notification.user_id),callback:function ($$v) {_vm.$set(_vm.notification, "user_id", $$v)},expression:"notification.user_id"}})],1):_vm._e(),_c('div',{staticClass:"clearfix"}),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                                        {
                                            min: 0,
                                            max: 620,
                                            span: 24,
                                        },
                                        {
                                            min: 621,
                                            max: 9999,
                                            span: 12,
                                        } ])}},[_c('label',{staticClass:"label mb-10"},[_vm._v(" Título da notificação: ")]),_c('a-input',{staticClass:"underline-input",attrs:{"placeholder":"Escreva o título"},model:{value:(_vm.notification.title),callback:function ($$v) {_vm.$set(_vm.notification, "title", $$v)},expression:"notification.title"}})],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                                        {
                                            min: 0,
                                            max: 620,
                                            span: 24,
                                        },
                                        {
                                            min: 621,
                                            max: 9999,
                                            span: 12,
                                        } ])}},[_c('label',{staticClass:"label mb-10"},[_vm._v(" Link de destino ")]),_c('a-input',{staticClass:"underline-input",attrs:{"placeholder":"Ex: /ativos/lista ou https://conectarinvestimentos.com"},model:{value:(_vm.notification.url),callback:function ($$v) {_vm.$set(_vm.notification, "url", $$v)},expression:"notification.url"}})],1),_c('a-col',{attrs:{"span":24}},[_c('label',{staticClass:"label mb-10"},[_vm._v(" Selecione o destinatário: ")]),_c('vue-editor',{attrs:{"placeholder":"Digite o texto aqui","editor-toolbar":_vm.customToolbar},model:{value:(_vm.notification.content),callback:function ($$v) {_vm.$set(_vm.notification, "content", $$v)},expression:"notification.content"}})],1),_c('a-col',{attrs:{"span":24}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.notification.loading},on:{"click":function($event){return _vm.sendNotifications()}}},[_vm._v(" Enviar notificação ")])],1)],1)],1)],1)],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'settings',
                    { initialValue: JSON.stringify(_vm.settings) } ]),expression:"[\n                    'settings',\n                    { initialValue: JSON.stringify(settings) },\n                ]"}],staticStyle:{"display":"none"},attrs:{"placeholder":"Settings"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }