import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token"),
    tokenExperation: localStorage.getItem("tokenExpiration"),
    isLoggedIn: localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : false,
    userData: localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : "",
    newImmobile: {},
    capabilities: [],
  },
  mutations: {
    SET_CAPABILITIES(state, capabilities) {
      state.capabilities = capabilities;
    },
  },
  actions: {
    setCapabilities(context) {
      axios
        .post(
          "https://api.app.conectarinvestimentos.com/option/get.php",
          {
            id: 2,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(({ data }) => {
          context.commit(
            "SET_CAPABILITIES",
            JSON.parse(data.value).capabilities
          );
        })
        .catch(({ res }) => {
          res;
        });
    },
  },
  modules: {},
  getters: {
    getCapabilities(state) {
      return state.capabilities;
    },
  },
});
