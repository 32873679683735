<template>
  <div class="cn-filters mb-40">
    <aRow :gutter="30">
      <aCol
        class="filter-col1"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 760,
              span: 24,
            },
            {
              min: 761,
              max: 99999,
              span: 12,
            },
          ])
        "
      >
        <aRow class="filter-fields" :gutter="20">
          <aCol :span="24">
            <aFormItem class="mb-10 underline-wrapper">
              <label class="filled">Regiões</label>
              <a-select
                class="underline-input"
                show-search
                mode="multiple"
                placeholder="Selecione"
                v-model="filters.regions.selected"
                @change="getProperties()"
                width="100%"
              >
                <a-select-option
                  v-for="(item, index) of regions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </aFormItem>
          </aCol>

          <aCol :span="24">
            <aFormItem class="mb-10 underline-wrapper">
              <label class="filled">Estados</label>
              <a-select
                class="underline-input"
                show-search
                mode="multiple"
                placeholder="Selecione"
                v-model="filters.states.selected"
                width="100%"
                @change="getProperties()"
              >
                <a-select-option
                  v-for="(item, index) of states"
                  :key="index"
                  :value="item.initials"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </aFormItem>
          </aCol>

          <aCol :span="24">
            <aFormItem class="underline-wrapper pt-0">
              <label class="filled">Período</label>
              <a-range-picker
                class="mt-10"
                v-model="filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getProperties()"
              />
            </aFormItem>
          </aCol>
        </aRow>
      </aCol>

      <aCol
        class="filter-col2"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 760,
              span: 24,
            },
            {
              min: 761,
              max: 99999,
              span: 12,
            },
          ])
        "
      >
        <aRow class="filter-fields" :gutter="20">
          <aCol
            :span="
              $root.reponsive([
                {
                  min: 0,
                  max: 1260,
                  span: 24,
                },
                {
                  min: 1261,
                  max: 9999,
                  span: 8,
                },
              ])
            "
          >
            <aFormItem class="mb-10 underline-wrapper">
              <label class="filled">Metragem</label>
              <a-select
                class="underline-input"
                placeholder="Selecione"
                v-model="filters.metreage.selected"
                width="100%"
                :allow-clear="true"
                show-search
                @change="getProperties()"
              >
                <a-select-option
                  v-for="(item, i) in metreageArr"
                  :key="i"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </aFormItem>
          </aCol>

          <aCol
            :span="
              $root.reponsive([
                {
                  min: 0,
                  max: 1260,
                  span: 24,
                },
                {
                  min: 1261,
                  max: 9999,
                  span: 16,
                },
              ])
            "
          >
            <aFormItem class="mb-10 underline-wrapper">
              <label class="filled">População</label>
              <a-select
                class="underline-input"
                placeholder="Selecione"
                v-model="filters.population.selected"
                width="100%"
                :allow-clear="true"
                show-search
                @change="getProperties()"
              >
                <a-select-option
                  v-for="(item, i) in populationArr"
                  :key="i"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </aFormItem>
          </aCol>

          <aCol
            :span="
              $root.reponsive([
                {
                  min: 0,
                  max: 1260,
                  span: 24,
                },
                {
                  min: 1261,
                  max: 9999,
                  span: 8,
                },
              ])
            "
          >
            <aFormItem class="mb-10 underline-wrapper">
              <label class="filled">Vocação</label>
              <a-select
                class="underline-input"
                placeholder="Selecione"
                v-model="filters.businessType.selected"
                width="100%"
                :allow-clear="true"
                show-search
                @change="getProperties()"
              >
                <a-select-option
                  v-for="(item, i) in businessTypeArr"
                  :key="i"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </aFormItem>
          </aCol>

          <aCol :span="16">
            <aFormItem class="mb-10 underline-wrapper pt-0">
              <label class="filled">Valor (R$)</label>
              <a-input-group compact style="margin-top: 10px">
                <a-input
                  style=" width: 45%;  border-width: 0 0 1px ; text-align: center; border-radius: 0"
                  placeholder="Min"
                  v-model="filters.price.min"
                  v-currency="{
                    currency: {
                      prefix: '',
                      suffix: '',
                    },
                    distractionFree: false,
                    valueAsInteger: false,
                    precision: 2,
                    autoDecimalMode: true,
                    allowNegative: false,
                  }"
                  @change="getProperties()"
                />
                <a-input
                  style="width: 10%; border: 0; pointer-events: none; backgroundColor: #fff; border: 0"
                  placeholder="a"
                  disabled
                />
                <a-input
                  style="width: 45%; text-align: center; border-width: 0 0 1px ; border-radius: 0"
                  placeholder="Max"
                  v-model="filters.price.max"
                  v-currency="{
                    currency: {
                      prefix: '',
                      suffix: '',
                    },
                    distractionFree: false,
                    valueAsInteger: false,
                    precision: 2,
                    autoDecimalMode: true,
                    allowNegative: false,
                  }"
                  @change="getProperties()"
                />
              </a-input-group>
            </aFormItem>
          </aCol>
        </aRow>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import regions from "@/lists/regions.json";
import states from "@/lists/states.json";
import propertiesMixins from "@/mixins/property/propertiesMixins";

export default {
  name: "PropertiesFilters",
  props: {
    filters: Object,
  },
  mixins: [propertiesMixins],
  data() {
    return {
      regions,
      states,
    };
  },
  mounted() {},
  methods: {
    getProperties() {
      this.$emit("getProperties");
    },
  },
};
</script>

<style lang="sass" scoped></style>
