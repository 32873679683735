export default {
  data() {
    return {
      filters: {
        loadingResults: false,
        users: {
          list: [],
          selected: [],
        },
        status: {
          list: [
            {
              label: "Ativo",
              value: "1",
            },
            {
              label: "Desativado",
              value: "0",
            },
          ],
          selected: [],
        },
        isApproved: {
          list: [
            {
              label: "Aprovado",
              value: "1",
            },
            {
              label: "Reprovadp",
              value: "0",
            },
          ],
          selected: [],
        },
        userProfile: {
          selected: [],
        },
        createdBy: {
          selected: [],
        },
        role: {
          list: [],
          selected: [],
        },
        products: {
          list: [],
          selected: [],
        },
        clients: {
          list: [],
          selected: [],
        },
        regions: {
          list: [],
          selected: [],
        },
        states: {
          selected: [],
        },
        statesOfInterest: {
          selected: [],
        },
        businessOfInterest: {
          selected: [],
        },
        populationOfInterest: {
          selected: [],
        },
        areaSizeOfInterest: "",
        cities: {
          selected: [],
        },
        period: {
          selected: [],
        },
        metreage: {
          selected: undefined,
        },
        searchTerm: "",
        userName: "",
        price: {
          min: "",
          max: "",
        },
        order: "desc",
        orderBy: "created",
      },
      pagination: {
        page: 1,
        perPage: 30,
        total: 0,
        totalPages: 0,
      },
      loadingUsers: false,
      searchTerm: "",
      listUsers: [],
      citiesList: [],
    };
  },
  methods: {
    changePage(current) {
      this.pagination.page = current;
      this.getUsers();
    },
    changePageSize(current, pageSize) {
      this.pagination.page = current;
      this.pagination.perPage = pageSize;
      this.getUsers();
    },
    propertiesTableChange(pagination, filters, sorter) {
      this.filters.order = sorter.order != undefined ? sorter.order : "desc";
      this.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getUsers();
    },
    getUsers(restart) {
      this.loadingUsers = true;
      if (restart) {
        this.pagination.page = 1;
      }

      let filters = "";
      if (this.activeTab == "users") {
        filters += "&status=1&is_approved=1";
      }

      if (this.activeTab == "disabled-users") {
        filters += "&status=0&is_approved=1";
      }

      if (!this.$root.isAdmin()) {
        filters += "&created_by=" + this.$store.state.userData.id;
      }

      if (this.filters.searchTerm) {
        filters += "&s=" + this.filters.searchTerm;
      }

      if (this.filters.states.selected != undefined) {
        if (this.filters.states.selected.length > 0) {
          filters += `&state=${this.filters.states.selected}`;
        }
      }

      if (this.filters.statesOfInterest.selected != undefined) {
        if (this.filters.statesOfInterest.selected.length > 0) {
          filters += `&state_of_interest=${this.filters.statesOfInterest.selected}`;
        }
      }

      if (this.filters.businessOfInterest.selected != undefined) {
        if (this.filters.businessOfInterest.selected.length > 0) {
          filters += `&business_type_of_interest=${this.filters.businessOfInterest.selected}`;
        }
      }

      if (this.filters.populationOfInterest.selected != undefined) {
        if (this.filters.populationOfInterest.selected.length > 0) {
          filters += `&population_of_interest=${this.filters.populationOfInterest.selected}`;
        }
      }

      if (this.filters.areaSizeOfInterest != undefined) {
        if (this.filters.areaSizeOfInterest) {
          filters += `&area_size_of_interest=${this.filters.areaSizeOfInterest}`;
        }
      }

      if (this.filters.cities.selected != undefined) {
        if (this.filters.cities.selected.length > 0) {
          filters += `&city=${this.filters.cities.selected}`;
        }
      }

      if (this.filters.role.selected != undefined) {
        if (this.filters.role.selected.length > 0) {
          filters += `&role=${this.filters.role.selected}`;
        }
      }

      if (this.filters.createdBy.selected != undefined) {
        if (this.filters.createdBy.selected.length > 0) {
          filters += `&created_by=${this.filters.createdBy.selected}`;
        }
      }

      if (this.filters.status.selected != undefined) {
        if (this.filters.status.selected.length > 0) {
          filters += `&status=${this.filters.status.selected}`;
        }
      }

      if (this.filters.isApproved.selected != undefined) {
        if (this.filters.isApproved.selected.length > 0) {
          filters += `&is_approved=${this.filters.isApproved.selected}`;
        }
      }

      if (this.filters.userName) {
        filters += "&user_name=" + this.filters.userName;
      }

      if (this.filters.userProfile.selected.length > 0) {
        filters += `&user_profile=${this.filters.userProfile.selected}`;
      }

      if (this.filters.period.selected.length > 0) {
        filters += `&period=${this.filters.period.selected[0]}|${this.filters.period.selected[1]}`;
      }

      filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

      this.$http
        .get(
          `/user/list-v2.php?page=${this.pagination.page}&per_page=${this.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          this.loadingUsers = false;
          this.listUsers = data.data;
          this.citiesList = data.meta.locations_arr;
          this.pagination.total = data.meta.total;
          this.pagination.totalPages = data.meta.total_pages;
        })
        .catch(({ response }) => {
          this.loadingUsers = false;
          this.listUsers = [];
          this.citiesList = response.meta.locations_arr;
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;
        });
    },
    getCompanies() {
      this.loadingCompanies = true;
      this.$http
        .post("/company/list.php", {
          page: 1,
          per_page: 10,
        })
        .then(({ data }) => {
          this.loadingCompanies = false;
          this.listCompanies.data = data.data;
        })
        .catch(({ response }) => {
          response;
          this.loadingCompanies = false;
          this.listCompanies.data = response.data.data;
        });
    },
  },
};
