<template>
  <div class="page">
    <a-page-header class="bg" sub-title=" " @back="() => $router.go(-1)">
      <template slot="backIcon">
        <a-icon type="left-circle" /> Voltar
      </template>
    </a-page-header>

    <aForm :form="form" @submit="handleSubmit">
      <aRow id="print" :gutter="25">
        <aCol
          class="gutter-row sidebar"
          :span="$root.windowWidth > 961 ? 6 : 24"
        >
          <a-row v-show="!loadingPage">
            <aCol class="box a-center bg mb-20" :span="24">
              <avatar :form="form" :user="user" />

              <a-form-item style="display: none">
                <a-input
                  placeholder="Avatar"
                  v-decorator="[`avatar`]"
                  style="text-transform: none"
                >
                </a-input>
              </a-form-item>
            </aCol>

            <aCol class="box mb-20 extra" :span="24">
              <a-row type="flex">
                <a-col :span="24">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Alterar senha</span>
                    </template>
                    <a-button @click="changePassword()" style="width: 100%">
                      <a-icon type="lock" />
                    </a-button>
                  </a-tooltip>
                </a-col>
              </a-row>
            </aCol>
          </a-row>

          <a-row v-if="loadingPage">
            <a-col class="box a-center bg mb-20" :span="24">
              <a-skeleton active />
            </a-col>
          </a-row>
        </aCol>
        <aCol class="gutter-row" :span="$root.windowWidth > 961 ? 18 : 24">
          <div v-show="!loadingPage" class="box tabs bg">
            <a-tabs default-active-key="data" @change="onChangeTabs">
              <a-tab-pane key="data" tab="Dados" forceRender>
                <DefaultDataFormEdit :user="user" :form="form" />
              </a-tab-pane>

              <a-tab-pane
                v-if="$root.isInvestor()"
                tab="Perfil de interesse"
                forceRender
              >
                <InvestorProfile :user="user" :form="form" />
              </a-tab-pane>
            </a-tabs>
            <a-row type="flex" justify="end">
              <a-col>
                <a-button
                  html-type="submit"
                  class="update-btn"
                  style="width: 120px"
                  :loading="loadingUserUpdate"
                  >Salvar</a-button
                >
              </a-col>
            </a-row>
          </div>
          <div class="box tabs bg" v-if="loadingPage">
            <a-skeleton active /><a-skeleton active /><a-skeleton active />
          </div>
        </aCol>
      </aRow>
    </aForm>

    <aModal
      title="Alterar a senha"
      :footer="false"
      v-model="modals.changePassword"
      width="320px"
    >
      <a-form-model
        v-if="modals.changePassword"
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
      >
        <a-form-model-item class="underline-outer" has-feedback prop="pass">
          <a-input
            class="underline-input"
            v-model="ruleForm.pass"
            autocomplete="off"
            type="password"
            placeholder="Insira sua nova senha"
          />
        </a-form-model-item>
        <a-form-model-item has-feedback prop="checkPass">
          <a-input
            class="underline-input"
            v-model="ruleForm.checkPass"
            autocomplete="off"
            type="password"
            placeholder="Repita a nova senha"
          />
        </a-form-model-item>

        <a-button
          class="update-btn"
          @click="submitForm('ruleForm')"
          style="width: 100%"
          :loading="loadingChangePassword"
        >
          Alterar a senha
        </a-button>
      </a-form-model>
    </aModal>
  </div>
</template>

<script>
import { parse, format } from "date-fns";
import DefaultDataFormEdit from "./forms/DefaultDataFormEdit.vue";
import Avatar from "../atoms/Avatar.vue";
import InvestorProfile from "../users/forms/InvestorProfile.vue";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default {
  components: { DefaultDataFormEdit, Avatar, InvestorProfile },
  filters: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password again"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("Two inputs don't match!"));
      } else {
        callback();
      }
    };
    return {
      form: this.$form.createForm(this),
      loadingPage: false,
      user: {},
      userRate: "0",
      theAvatar: "",
      modals: {
        changePassword: false,
      },
      loadingChangePassword: false,
      loadingStatusChange: false,
      loadingUserUpdate: false,
      updatePassword: {
        password: "",
        repeatPassword: "",
      },
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "change" }],
        checkPass: [{ validator: validatePass2, trigger: "change" }],
      },
      roles: [
        {
          name: "Administrador",
          value: "admin",
        },
        {
          name: "Afiliado",
          value: "user",
        },
        {
          name: "Gerente de Negócios",
          value: "business-manager",
        },
      ],
    };
  },
  beforeMount() {
    setTimeout(() => {
      let body = document.body;
      body.classList.add("conectar");
      body.classList.add("users");
      body.classList.add("edit");
      body.classList.remove("list");
    }, 20);
    this.loadingPage = true;
    this.getUser();
  },
  beforeDestroy() {
    let body = document.body;
    body.classList.remove("users");
    body.classList.remove("edit");
  },
  methods: {
    onChangeTabs(tab) {
      if (tab == "interests") this.investorProfileFieldsAlert = false;
    },
    fileURL(url) {
      this.form.setFieldsValue({
        [`avatar`]: url,
      });
    },
    currentUserId() {
      let userId = this.$store.state.userData.id;
      if (userId != this.user.id) userId = this.user.id;
      return userId;
    },
    getUser() {
      this.$http
        .post("/user/read-one.php", {
          id: this.$store.state.userData.id,
        })
        .then(({ data }) => {
          this.loadingPage = false;
          this.user = data;
          setTimeout(() => {
            this.form.setFieldsValue({
              [`first_name`]: data.first_name,
              [`last_name`]: data.last_name,
              [`cpf`]: data.cpf,
              [`rg`]: data.meta.rg,
              [`rg_doc_emission`]: data.meta.rg_doc_emission,
              [`rg_doc_emission_state`]: data.meta.rg_doc_emission_state,
              [`birthday`]: data.meta.birthday,
              [`status`]: data.status,
              [`avatar`]: data.avatar,
              [`profession`]: data.meta.profession,
              [`email`]: data.email,
              [`phone`]: data.phone,
              [`mobile_phone`]: data.mobile_phone,
              [`role`]: data.role,
              [`cep`]: data.meta.cep,
              [`location_street`]: data.meta.location_street,
              [`location_number`]: data.meta.location_number,
              [`location_neighborhood`]: data.meta.location_neighborhood,
              [`location_city`]: data.meta.location_city,
              [`location_state`]: data.meta.location_state,
              [`location_complement`]: data.meta.location_complement,
            });

            setTimeout(() => {
              this.form.setFieldsValue({
                [`business_type_of_interest`]: data.business_type_of_interest
                  ? JSON.parse(data.business_type_of_interest)
                  : undefined,
                [`area_size_of_interest`]: data.area_size_of_interest
                  ? JSON.parse(data.area_size_of_interest)
                  : undefined,
                [`region_of_interest`]: data.region_of_interest
                  ? JSON.parse(data.region_of_interest)
                  : undefined,
                [`state_of_interest`]: data.state_of_interest
                  ? JSON.parse(data.state_of_interest)
                  : undefined,
                [`population_of_interest`]: data.population_of_interest
                  ? JSON.parse(data.population_of_interest)
                  : undefined,
              });
            }, 100);
          }, 100);

          this.userRate =
            this.user.meta.rate != undefined ? `${this.user.meta.rate}` : "0";
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log("ERROS:", err, "VALORES", {
          ...values,
          id: this.user.id,
          role: this.user.role,
          rate: this.user.meta.rate,
          status: this.user.status,
        });
        if (!err) {
          console.log("ERROS:", err, "VALORES", {
            ...values,
            id: this.user.id,
            rate: this.user.meta.rate,
            role: this.user.role,
            status: this.user.status,
          });

          values.business_type_of_interest = values.business_type_of_interest
            ? JSON.stringify(values.business_type_of_interest)
            : "[]";
          values.area_size_of_interest = values.area_size_of_interest
            ? JSON.stringify(values.area_size_of_interest)
            : "[]";
          values.region_of_interest = values.region_of_interest
            ? JSON.stringify(values.region_of_interest)
            : "[]";
          values.state_of_interest = values.state_of_interest
            ? JSON.stringify(values.state_of_interest)
            : "[]";
          values.population_of_interest = values.population_of_interest
            ? JSON.stringify(values.population_of_interest)
            : "[]";

          this.loadingUserUpdate = true;

          let actions = ["update-user"];

          if (values.email != this.user.email) {
            actions.push("changed-email");
            values.old_email = this.user.email;
          }

          this.$http
            .post("/user/update.php", {
              ...values,
              id: this.user.id,
              rate: this.user.meta.rate,
              role: this.user.role,
              status: this.user.status,
              actions: actions,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
              modified_user: {
                name: `${this.user.first_name} ${this.user.last_name}`,
                id: this.user.id,
              },
            })
            .then(({ data }) => {
              let localUserData = {};
              localUserData.email = this.user.email;
              localUserData.first_name = this.user.first_name;
              localUserData.last_name = this.user.last_name;
              localUserData.cpf = this.user.cpf;
              localUserData.id = this.user.id;
              localUserData.role = this.user.role;
              localUserData.avatar = values.avatar;

              localStorage.setItem("userData", JSON.stringify(localUserData));
              this.loadingUserUpdate = false;
              this.$message.success(data.message);
              window.open("/perfil", "_self");
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingUserUpdate = false;
            });
        }
      });
    },
    submitForm(formName) {
      this.loadingChangePassword = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/user/change-password-auth.php", {
              id: this.user.id,
              password: this.ruleForm.pass,
              confirm: this.ruleForm.checkPass,
              email: this.user.email,
            })
            .then(({ data }) => {
              data;
              this.loadingChangePassword = false;
              this.modals.changePassword = false;
              this.$message.success("Atualizado com sucesso!");
              this.ruleForm = {
                pass: "",
                checkPass: "",
              };
            })
            .catch(({ response }) => {
              response;
              this.loadingChangePassword = false;
              this.$message.error("Houve algum problema ao atualizar a senha.");
            });
        } else {
          return false;
        }
      });
    },
    changePassword() {
      this.modals.changePassword = true;
    },
    handleChange(info) {
      if (this.isValidFile) {
        getBase64(info.file.originFileObj, (imageBase64) => {
          this.imageBase64 = imageBase64;
          this.theAvatar = imageBase64;

          setTimeout(() => {
            this.form.setFieldsValue({
              avatar: imageBase64,
            });
          }, 1);
        });
      }
    },
    beforeUpload(file) {
      const isValidImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isValidImage) {
        this.$message.error("Este arquivo não é válido!");
        return;
      }
      const validSize = file.size / 1024 / 1024 < 2;
      if (!validSize) {
        this.$message.error("A imagem deve ter menos que 2MB!");
        return;
      }
      this.isValidFile = true;
      return isValidImage && validSize;
    },
    removeAvatar() {
      this.user.avatar = "";
      this.form.setFieldsValue({
        avatar: "",
      });
      this.theAvatar = "";
    },
    haveTheField(field) {
      if (field == "avatar") {
        return this.form.getFieldValue(field) || this.user[field];
      } else {
        return this.form.getFieldValue(field) || this.user[field];
      }
    },
  },
};
</script>

<style>
@media print {
  .ant-layout-sider,
  header.ant-layout-header,
  .ant-page-header,
  .box.extra,
  .update-btn {
    display: none !important;
  }

  .sidebar.ant-col,
  .gutter-row.ant-col.ant-col-18 {
    width: 100%;
  }

  .conectar.edit .box.bg {
    box-shadow: none !important;
    border: 1px solid #eee;
  }
}
</style>
