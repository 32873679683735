<template>
  <div class="page">
    <a-page-header class="bg">
      <template slot="backIcon">
        <a-icon type="left-circle" /> Voltar
      </template>
      <a-row type="flex" justify="space-between">
        <a-col>
          <h1>Ativos aguardando</h1>
        </a-col>
        <a-col> </a-col>
      </a-row>
    </a-page-header>

    <div v-if="$root.checkCapabilities(capabilities, 'properties', 'list')">
      <a-tabs
        default-active-key="properties"
        v-model="activePropertyTab"
        @change="onChangePropertyTabs"
      >
        <a-tab-pane key="active" tab="Aguardando"></a-tab-pane>
        <a-tab-pane key="disabled" tab="Reprovados"></a-tab-pane>
      </a-tabs>

      <a-row class="mb-40 mt-20 filter-fields" :gutter="20">
        <a-col
          :span="
            $root.reponsive([
              {
                min: 0,
                max: 600,
                span: 24,
              },
              {
                min: 601,
                max: 9999,
                span: 4,
              },
            ])
          "
        >
          <a-form-item class="mb-10 underline-wrapper">
            <label class="filled">Regiões</label>
            <a-select
              class="underline-input"
              show-search
              mode="multiple"
              placeholder="Selecione"
              v-model="filters.regions.selected"
              @change="getProperties()"
              width="100%"
            >
              <a-select-option
                v-for="(item, index) of regions"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col
          :span="
            $root.reponsive([
              {
                min: 0,
                max: 600,
                span: 24,
              },
              {
                min: 601,
                max: 9999,
                span: 4,
              },
            ])
          "
        >
          <a-form-item class="mb-10 underline-wrapper">
            <label class="filled">Estados</label>
            <a-select
              class="underline-input"
              show-search
              mode="multiple"
              placeholder="Selecione"
              v-model="filters.states.selected"
              width="100%"
              @change="getProperties()"
            >
              <a-select-option
                v-for="(item, index) of states"
                :key="index"
                :value="item.initials"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col
          :span="
            $root.reponsive([
              {
                min: 0,
                max: 600,
                span: 24,
              },
              {
                min: 601,
                max: 9999,
                span: 4,
              },
            ])
          "
        >
          <a-form-item class="mb-10 underline-wrapper">
            <label class="filled">Metragem</label>
            <a-select
              class="underline-input"
              placeholder="Selecione"
              v-model="filters.metreage.selected"
              width="100%"
              :allow-clear="true"
              show-search
              @change="getProperties()"
            >
              <a-select-option value="0-5000">
                até 5.000 m²
              </a-select-option>
              <a-select-option value="5000-50000">
                5.000 a 50.000 m²
              </a-select-option>
              <a-select-option value="50000-100000">
                50.000 a 100.000 m²
              </a-select-option>
              <a-select-option value="100000-300000">
                100.000 a 300.000 m²
              </a-select-option>
              <a-select-option value="300000-500000">
                300.000 a 500.000 m²
              </a-select-option>
              <a-select-option value="500000-10000000">
                acima de 500.000 m²
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col
          :span="
            $root.reponsive([
              {
                min: 0,
                max: 600,
                span: 24,
              },
              {
                min: 601,
                max: 9999,
                span: 6,
              },
            ])
          "
        >
          <a-form-item class="mb-10 underline-wrapper pt-0">
            <label class="filled">Valor (R$)</label>
            <a-input-group compact style="margin-top: 10px">
              <a-input
                style=" width: 45%;  border-width: 0 0 1px ; text-align: center; border-radius: 0"
                placeholder="Min."
                v-model="filters.price.min"
                v-currency="{
                  currency: {
                    prefix: '',
                    suffix: '',
                  },
                  distractionFree: false,
                  valueAsInteger: false,
                  precision: 2,
                  autoDecimalMode: true,
                  allowNegative: false,
                }"
                @change="getProperties()"
              />
              <a-input
                style=" width: 10%; border: 0; pointer-events: none; backgroundColor: #fff; border: 0"
                placeholder="a"
                disabled
              />
              <a-input
                style="width: 45%; text-align: center; border-width: 0 0 1px ; border-radius: 0"
                placeholder="Max"
                v-model="filters.price.max"
                v-currency="{
                  currency: {
                    prefix: '',
                    suffix: '',
                  },
                  distractionFree: false,
                  valueAsInteger: false,
                  precision: 2,
                  autoDecimalMode: true,
                  allowNegative: false,
                }"
                @change="getProperties()"
              />
            </a-input-group>
          </a-form-item>
        </a-col>

        <a-col
          :span="
            $root.reponsive([
              {
                min: 0,
                max: 600,
                span: 24,
              },
              {
                min: 601,
                max: 9999,
                span: 6,
              },
            ])
          "
        >
          <a-form-item class="underline-wrapper pt-0">
            <label class="filled">Período</label>
            <a-range-picker
              class="mt-10"
              v-model="filters.period.selected"
              :format="['DD/MM/YYYY']"
              :value-format="'YYYY-MM-DD'"
              @change="getProperties()"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-table
        :columns="columns"
        :data-source="listProperties"
        :loading="loadingProperties"
        :pagination="false"
        :scroll="{ x: 1300 }"
        @change="propertiesTableChange"
      >
        <a slot="id" slot-scope="text, record">
          {{ record.id }}
        </a>

        <a slot="name" slot-scope="text, record">
          {{ record.name }}
        </a>

        <a slot="status" slot-scope="text, record">
          <div v-if="record.status" class="status-ball" :class="record.status">
            <a-tooltip placement="top">
              <template slot="title">
                {{ formatStatus(record.status) }}
              </template>
              •
            </a-tooltip>
          </div>
        </a>

        <div slot="location_state" slot-scope="text, record">
          {{ record.location_state }}
        </div>
        <a slot="metreage" slot-scope="text, record"
          >{{ parseFloat(record.metreage).toLocaleString() }} m²
        </a>
        <a slot="business_type" slot-scope="text, record">{{
          record.business_type
        }}</a>
        <a slot="owner" slot-scope="text, record">
          <div v-if="$root.checkCapabilities(capabilities, 'users', 'view')">
            <a-button
              v-if="$root.checkCapabilities(capabilities, 'users', 'view')"
              style="padding: 0"
              type="link"
              @click="viewOwner(record.owner.id)"
            >
              {{ record.owner.first_name }}
              {{ record.owner.last_name }}
            </a-button>
          </div>

          <div v-if="!$root.checkCapabilities(capabilities, 'users', 'view')">
            {{ record.owner.first_name }}
            {{ record.owner.last_name }}
          </div>
        </a>
        <a slot="rate" slot-scope="text, record">
          <a-rate v-model="record.meta.rate" allow-half disabled>
            <a-icon slot="character" type="star" />
          </a-rate>
        </a>
        <a slot="created" slot-scope="text, record">{{
          record.created | formatDate
        }}</a>
        <span slot="action" slot-scope="record">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Ver</span>
            </template>
            <a @click="viewProperty(record.id)">
              <img src="@/assets/images/view.png" alt="ver" width="20" />
            </a>
          </a-tooltip>

          <a-popconfirm
            title="Tem certeza que deseja APROVAR?"
            ok-text="Sim"
            cancel-text="Não"
            @confirm="approveProperty(record)"
            :icon="false"
          >
            <a-tooltip placement="top">
              <template slot="title">
                <span>Aprovar</span>
              </template>
              <a class="ml-10"
                ><img src="@/assets/images/check.png" alt="ver" width="20"
              /></a>
            </a-tooltip>
          </a-popconfirm>

          <a-popconfirm
            title="Tem certeza que deseja REPROVAR este ativo?"
            ok-text="Sim"
            cancel-text="Não"
            @confirm="disapproveProperty(record)"
          >
            <a-icon slot="icon" type="question-circle-o" style="color: red" />
            <a-tooltip placement="top">
              <template slot="title">
                <span>Reprovar</span>
              </template>
              <a class="ml-10"
                ><img src="@/assets/images/close.png" alt="ver" width="20"
              /></a>
            </a-tooltip>
          </a-popconfirm>
        </span>
      </a-table>

      <div class="a-center mt-30" style="padding-bottom: 100px">
        <a-pagination
          show-size-changer
          :default-current="pagination.page"
          :total="pagination.total"
          @change="changePage"
          @showSizeChange="changePageSize"
        />
      </div>
    </div>

    <a-drawer
      class="property-drawer"
      placement="right"
      :width="$root.windowWidth > 600 ? '740px' : ''"
      :closable="true"
      :visible="drawers.requestProperty"
      @close="closePropertyDrawer"
    >
      <template slot="title">
        <img
          class="relative mr-5"
          src="@/assets/images/request-property-white.png"
          alt="ver"
          width="30"
          style="top:-2px"
        />
        SOLICITAR ATIVO
      </template>

      <RequestProperty
        v-if="drawers.requestProperty"
        @listPropertiesAfterCreate="listPropertiesAfterCreate"
        @closeDrawerAfterCreate="closePropertyDrawer"
      />
    </a-drawer>

    <a-drawer
      class="property-drawer"
      placement="right"
      :width="$root.windowWidth > 740 ? '740px' : '100%'"
      :closable="true"
      :visible="drawers.propety"
      @close="closePropertyDrawer"
    >
      <template slot="title"
        ><a-icon type="radius-setting" class="mr-10" /> Cadastrar Ativo
        Imobiliário
      </template>
      <create-property
        v-if="drawers.propety"
        @listPropertiesAfterCreate="listPropertiesAfterCreate"
        @closeDrawerAfterCreate="closePropertyDrawer"
      />
    </a-drawer>
  </div>
</template>

<script>
import { parse, format } from "date-fns";

import CreateProperty from "./CreateProperty.vue";
import regions from "../../lists/regions.json";
import states from "../../lists/states.json";
import capabilities from "../../mixins/general/capabilities.js";
import RequestProperty from "./RequestProperty.vue";

export default {
  mixins: [capabilities],
  components: { CreateProperty, RequestProperty },
  filters: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
  data() {
    return {
      states,
      regions,
      activePropertyTab: "active",
      listProperties: [],
      totalUsersWaiting: 0,
      loadingProperties: true,
      listInactiveProperties: [],
      filters: {
        loadingResults: false,
        users: {
          list: [],
          selected: [],
        },
        status: {
          list: [],
          selected: [],
        },
        products: {
          list: [],
          selected: [],
        },
        clients: {
          list: [],
          selected: [],
        },
        regions: {
          list: [],
          selected: [],
        },
        period: {
          selected: [],
        },
        metreage: {
          selected: undefined,
        },
        searchTerm: "",
        price: {
          min: "",
          max: "",
        },
        order: "desc",
        orderBy: "created",
        states: {
          selected: [],
        },
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
      },
      theUser: {
        meta: {},
      },
      drawers: {
        propety: false,
        requestProperty: false,
      },
      columns: [
        {
          title: "ID",
          key: "id",
          scopedSlots: { customRender: "id" },
          width: 70,
          sorter: true,
        },
        {
          title: "Nome do Ativo",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 200,
          sorter: true,
        },

        {
          title: "",
          key: "status",
          scopedSlots: { customRender: "status" },
        },

        {
          title: "Estado",
          key: "location_state",
          scopedSlots: { customRender: "location_state" },
          sorter: true,
        },
        {
          title: "Metragem",
          key: "metreage",
          scopedSlots: { customRender: "metreage" },
          sorter: true,
        },
        {
          title: "Tipo",
          key: "business_type",
          scopedSlots: { customRender: "business_type" },
          sorter: true,
        },
        {
          title: "Cadastrado por",
          key: "owner",
          scopedSlots: { customRender: "owner" },
          width: 200,
        },
        {
          title: "Classificação",
          key: "rate",
          scopedSlots: { customRender: "rate" },
          width: 180,
        },
        {
          title: "Data de cadastro",
          key: "created",
          scopedSlots: { customRender: "created" },
          sorter: true,
        },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "right",
          fixed: "right",
          width: 116,
        },
      ],
    };
  },
  beforeMount() {
    if (!this.$root.isAdmin()) {
      this.$router.push(`/`);
    }

    setTimeout(() => {
      let body = document.body;
      body.classList.add("conectar");
      body.classList.add("properties");
      body.classList.add("list");
      body.classList.add("unnaproved");
    }, 20);

    document.title = "Ativos - Conectar";

    this.filters.status.selected = "active";
    this.getProperties();
  },
  beforeDestroy() {
    let body = document.body;
    body.classList.remove("users");
    body.classList.remove("list");
    body.classList.remove("unnaproved");
  },
  methods: {
    viewProperty(id) {
      this.$router.push(`/ativos/ver/${id}`);
    },
    approveProperty(property) {
      this.$http
        .post("/property/update-field.php", {
          id: property.id,
          field: "is_approved",
          value: true,
        })
        .then(({ data }) => {
          data;
          this.getProperties();

          this.$http
            .post("/property/send-email-to-investors.php", property)
            .then(({ data }) => {
              this.$message.success(data.message, 10);

              this.$http.post("/system-logs/create.php", {
                id: this.$store.state.userData.id,
                user_id: 1,
                action: "send-status",
                content: data.message,
              });
            });

          this.$message.success(
            `Ativo ID ${property.id} aprovado com sucesso!`
          );

          this.$http.post("/system-logs/create.php", {
            id: this.$store.state.userData.id,
            user_id: this.$store.state.userData.id,
            action: "approve-property",
            content: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> aprovou o Ativo ID ${property.id}.`,
          });
        })
        .catch(({ response }) => {
          response;
        });
    },

    disapproveProperty(property) {
      this.$http
        .post("/property/update-field.php", {
          id: property.id,
          field: "status",
          value: "disabled",
        })
        .then(({ data }) => {
          this.getProperties();

          this.$message.success(
            `Ativo ID ${property.id} reprovado com sucesso.`
          );

          data;

          this.$http
            .post("/system-logs/create.php", {
              id: this.$store.state.userData.id,
              user_id: this.$store.state.userData.id,
              action: "disapprove-property",
              content: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> reprovou o Ativo ID ${property.id}.`,
            })
            .then(({ data }) => {
              data;
            });
        })
        .catch(({ response }) => {
          response;
        });
    },
    onChangePropertyTabs(tab) {
      this.filters.status.selected = tab;
      console.log(tab);
      this.getProperties();
    },
    changePage(current) {
      this.pagination.page = current;
      this.getProperties();
    },
    changePageSize(current, pageSize) {
      this.pagination.page = current;
      this.pagination.perPage = pageSize;
      this.getProperties();
    },
    propertiesTableChange(pagination, filters, sorter) {
      console.log("Sorter", sorter, pagination, filters);
      this.filters.order = sorter.order != undefined ? sorter.order : "desc";
      this.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getProperties();
    },
    getProperties() {
      this.loadingProperties = true;

      let filters = "";
      if (this.activePropertyTab == "properties") {
        filters += "&status=active,negotiation";
      }

      if (this.activePropertyTab == "inactivated-properties") {
        filters += "&status=disabled,blocked";
      }

      if (this.filters.status.selected.length > 0) {
        filters += "&status=" + this.filters.status.selected;
      }

      if (!this.$root.isAdmin()) {
        filters += "&owner_id=" + this.$store.state.userData.id;
      }

      if (this.filters.regions.selected.length > 0) {
        filters += `&region=${this.filters.regions.selected}`;
      }

      if (this.filters.states.selected.length > 0) {
        filters += `&state=${this.filters.states.selected}`;
      }

      if (this.filters.metreage.selected) {
        filters += `&metreage=${this.filters.metreage.selected}`;
      }

      if (this.filters.period.selected.length > 0) {
        filters += `&period=${this.filters.period.selected[0]}|${this.filters.period.selected[1]}`;
      }

      if (this.filters.searchTerm) {
        filters += `&s=${this.filters.searchTerm}`;
      }

      if (this.filters.price.min || this.filters.price.max) {
        let min = this.filters.price.min
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(",", ".");
        let max = this.filters.price.max
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(",", ".");

        if (min) {
          if (!max) {
            max = "10000000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&price-range=${min}-${max}`;
      }

      filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

      this.$http
        .get(
          `/property/list.php?page=${this.pagination.page}&per_page=${this.pagination.perPage}${filters}&is_approved=no`
        )
        .then(({ data }) => {
          this.pagination.total = data.meta.total;
          this.pagination.totalPages = data.meta.total_pages;
          this.listProperties = data.data;
          this.loadingProperties = false;
        })
        .catch(({ response }) => {
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;
          this.listProperties = response.data.data;
          this.loadingProperties = false;
        });
    },

    createProperty() {
      this.drawers.propety = true;
    },

    requestProperty() {
      this.drawers.requestProperty = true;
    },

    closePropertyDrawer() {
      this.drawers.propety = false;
      this.drawers.requestProperty = false;
    },
    deleteProperty(id) {
      this.loadingProperties = true;
      this.$http
        .post("/property/delete.php", {
          id,
        })
        .then(({ data }) => {
          data;
          this.getProperties();
          this.$message.success("Apagado!");
        })
        .catch(({ response }) => {
          response;
          this.loadingProperties = false;
        });
    },
    closeDrawerAfterCreate() {},
    listPropertiesAfterCreate() {
      this.loadingProperties = true;
      setTimeout(() => {
        this.getProperties();
      }, 1000);
    },
    viewOwner(id) {
      this.$router.push("/afiliados/ver/" + id);
    },
    view(company) {
      this.$router.push("/ativos/ver/" + company.id);
    },
    edit(company) {
      this.$router.push("/ativos/editar/" + company.id);
    },
    formatStatus(status) {
      let theStatus = "";
      if (status == "active") {
        theStatus = "Ativo";
      }

      if (status == "disabled") {
        theStatus = "Desativado";
      }

      if (status == "blocked") {
        theStatus = "Bloqueado";
      }

      if (status == "negotiation") {
        theStatus = "Em Negociação";
      }

      return theStatus;
    },
  },
};
</script>