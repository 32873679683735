var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Dados da empresa")]),_c('a-row',{staticClass:"data-row",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 10 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("Razão social")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'company_name',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório.',
                                } ],
                        } ]),expression:"[\n                        'company_name',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório.',\n                                },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"Razão social"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 7 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("Nome Fantasia")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'trading_name',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório.',
                                } ],
                        } ]),expression:"[\n                        'trading_name',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório.',\n                                },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"Nome Fantasia"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 5 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("CNPJ")]),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"},{name:"decorator",rawName:"v-decorator",value:([
                        'cnpj',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório.',
                                } ],
                        } ]),expression:"[\n                        'cnpj',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório.',\n                                },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"CNPJ"}})],1)],1)],1),_c('h3',[_vm._v("Dados de contato")]),_c('a-row',{staticClass:"data-row",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 10 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("E-mail")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'company_email',
                        {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'Escreva um e-mail válido.',
                                },
                                {
                                    required: true,
                                    message: 'Escreva seu e-mail no campo.',
                                } ],
                        } ]),expression:"[\n                        'company_email',\n                        {\n                            rules: [\n                                {\n                                    type: 'email',\n                                    message: 'Escreva um e-mail válido.',\n                                },\n                                {\n                                    required: true,\n                                    message: 'Escreva seu e-mail no campo.',\n                                },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"E-mail"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 5 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("Telefone celular")]),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) # ####-####']),expression:"['(##) # ####-####']"},{name:"decorator",rawName:"v-decorator",value:([
                        'company_mobile_phone',
                        {
                            rules: [
                                {
                                    required: false,
                                    message: 'Obrigatório',
                                } ],
                        } ]),expression:"[\n                        'company_mobile_phone',\n                        {\n                            rules: [\n                                {\n                                    required: false,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"Telefone celular"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 4 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("Telefone fixo")]),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####']),expression:"['(##) ####-####']"},{name:"decorator",rawName:"v-decorator",value:([
                        'company_phone',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                } ],
                        } ]),expression:"[\n                        'company_phone',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"Telefone fixo"}})],1)],1)],1),_c('h3',[_vm._v("Endereço")]),_c('a-row',{staticClass:"data-row",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 5 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("CEP")]),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(['#####-###']),expression:"['#####-###']"},{name:"decorator",rawName:"v-decorator",value:([
                        'company_cep',
                        {
                            rules: [
                                { required: true, message: 'Obrigatório' } ],
                        } ]),expression:"[\n                        'company_cep',\n                        {\n                            rules: [\n                                { required: true, message: 'Obrigatório' },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"CEP"},on:{"change":function($event){return _vm.getLocation()}}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 19 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("Endereço")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'company_location_street',
                        {
                            rules: [
                                { required: true, message: 'Obrigatório' } ],
                        } ]),expression:"[\n                        'company_location_street',\n                        {\n                            rules: [\n                                { required: true, message: 'Obrigatório' },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"Endereço"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 3 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("Número")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'company_location_number',
                        {
                            rules: [
                                { required: true, message: 'Obrigatório' } ],
                        } ]),expression:"[\n                        'company_location_number',\n                        {\n                            rules: [\n                                { required: true, message: 'Obrigatório' },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"Número"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 7 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("Complemento")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['company_location_complement']),expression:"['company_location_complement']"}],staticClass:"underline-input",attrs:{"placeholder":"Complemento"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 5 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("Bairro")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'company_location_neighborhood',
                        {
                            rules: [
                                { required: true, message: 'Obrigatório' } ],
                        } ]),expression:"[\n                        'company_location_neighborhood',\n                        {\n                            rules: [\n                                { required: true, message: 'Obrigatório' },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"Bairro"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 5 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("Cidade")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'company_location_city',
                        {
                            rules: [
                                { required: true, message: 'Obrigatório' } ],
                        } ]),expression:"[\n                        'company_location_city',\n                        {\n                            rules: [\n                                { required: true, message: 'Obrigatório' },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"placeholder":"Cidade"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.windowWidth > 1240 ? 4 : 24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{staticClass:"label"},[_vm._v("Estado")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'company_location_state',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                } ],
                        } ]),expression:"[\n                        'company_location_state',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],staticClass:"underline-input",attrs:{"show-search":"","placeholder":"ES"}},_vm._l((_vm.states),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.initials}},[_vm._v(" "+_vm._s(item.initials.toUpperCase())+" ")])}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }