<template>
  <div class="page">
    <a-page-header class="bg" @back="() => $router.go(-1)">
      <template slot="backIcon">
        <a-icon type="left-circle" /> Voltar
      </template>
    </a-page-header>

    <a-row
      v-if="$root.checkCapabilities(this.capabilities, 'users', 'view')"
      id="print"
      :gutter="25"
    >
      <a-col
        class="gutter-row sidebar"
        :span="$root.windowWidth > 1240 ? 6 : 24"
      >
        <a-row v-show="!loadingPage">
          <a-col class="box a-center bg mb-20" :span="24">
            <a-avatar
              class="mt-20 mb-10"
              :size="150"
              icon="user"
              :src="user.avatar"
              page="view-user"
              :edit="true"
            />
            <div class="name">
              {{ user.first_name }}
              {{ user.last_name }}
            </div>
            <div class="email">{{ user.email }}</div>
            <div class="rate mb-10">
              <a-rate v-model="userRate" disabled>
                <a-icon slot="character" type="star" />
              </a-rate>
            </div>
            <a-tooltip
              v-if="$root.checkCapabilities(this.capabilities, 'users', 'edit')"
              placement="top"
            >
              <template slot="title">
                <span>Editar</span>
              </template>
              <a @click="edit(user.id)">
                <a-icon type="edit" />
              </a>
            </a-tooltip>

            <a
              class="ml-20 relative"
              @click="printDocs()"
              style="font-size: 20px; color: #999; top: -2px"
            >
              <a-icon type="printer" />
            </a>
          </a-col>

          <a-col class="box bg mb-20" :span="24">
            <div v-if="user.meta.company" class="line">
              <div class="title">Empresa</div>
              <div class="text">
                <a-row type="flex" justify="space-between">
                  <a-col>
                    {{ user.meta.company.company_name }}
                  </a-col>
                  <a-col>
                    <a-icon
                      class="link-btn"
                      type="login"
                      @click="
                        $router.push('/empresas/ver/' + user.meta.company.id)
                      "
                    />
                  </a-col>
                </a-row>
              </div>
            </div>
            <div class="line">
              <div class="title">Usuário</div>
              <div class="text">
                {{ userRole(user.role) }}
              </div>
            </div>

            <div
              class="line"
              v-if="
                user.role != 'admin' &&
                  user.role != 'investor' &&
                  user.role != 'business-manager'
              "
            >
              <div class="title">Perfil do Afiliado</div>
              <div class="text">
                {{ user.meta.user_profile }}
              </div>
            </div>

            <div v-if="user.totalAffiliates != 0" class="line">
              <div class="title">Afiliados</div>
              <div class="text">
                {{
                  user.totalAffiliates == "1"
                    ? user.totalAffiliates + " afiliado"
                    : user.totalAffiliates + " afiliados"
                }}
              </div>
            </div>

            <div v-if="user.totalProperties != 0" class="line">
              <div class="title">Ativos imobiliários</div>
              <div class="text">
                <a-row type="flex" justify="space-between">
                  <a-col>
                    {{
                      user.totalProperties == "1"
                        ? user.totalProperties + " ativo imobiliário"
                        : user.totalProperties + " ativos imobiliários"
                    }}
                  </a-col>
                  <a-col>
                    <a-icon
                      @click="$router.push('/ativos/lista')"
                      class="link-btn"
                      type="login"
                    />
                  </a-col>
                </a-row>
              </div>
            </div>

            <div v-if="user.created_by" class="line">
              <div class="title">Supervisionado por:</div>
              <div class="text">
                <a-row type="flex" justify="space-between">
                  <a-col>
                    {{ user.created_by.id }} - {{ user.created_by.first_name }}
                    {{ user.created_by.last_name }}
                  </a-col>
                  <a-col> </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row v-if="loadingPage">
          <a-col class="box a-center bg mb-20" :span="24">
            <a-skeleton active />
          </a-col>

          <a-col class="box a-center bg mb-20" :span="24">
            <a-skeleton active />
          </a-col>
        </a-row>
      </a-col>

      <a-col class="gutter-row" :span="$root.windowWidth > 1240 ? 18 : 24">
        <div v-show="!loadingPage" class="box tabs bg">
          <a-tabs default-active-key="data">
            <a-tab-pane key="data" tab="Dados" forcerRender>
              <DefaultDataFormView :user="user" />
            </a-tab-pane>

            <a-tab-pane key="affiliates" force-render>
              <template slot="tab">
                {{
                  user.totalAffiliates == "1"
                    ? user.totalAffiliates + " Afiliado"
                    : user.totalAffiliates + " Afiliados"
                }}
              </template>
              <ListAffiliatesTab
                v-if="user.totalAffiliates != 0"
                :affiliateId="user.id"
              />
              <a-empty
                class="mt-50"
                v-if="user.totalAffiliates == 0"
                description="Nenhum AFILIADO cadastrado por esse usuário até o
                              momento."
              />
            </a-tab-pane>

            <a-tab-pane
              v-if="
                ($root.isAdmin() || $root.isInvestor()) &&
                  user.role == 'investor'
              "
              key="investor"
              tab="Perfil de interesse"
              forcerRender
            >
              <a-row class="data-row" :gutter="20">
                <a-col :span="24">
                  <div class="label">Vocação da área</div>
                  <div class="value">
                    {{ formatJsonView(user.business_type_of_interest) }}
                  </div>
                </a-col>
                <a-col :span="24">
                  <div class="label">
                    Tamanho de áreas (m²)
                  </div>
                  <div class="value">
                    {{ formatJsonView(user.area_size_of_interest, "area") }}
                  </div>
                </a-col>

                <a-col :span="24">
                  <div class="label">
                    Estados de interesse
                  </div>
                  <div class="value">
                    {{ formatJsonView(user.state_of_interest) }}
                  </div>
                </a-col>

                <a-col :span="24">
                  <div class="label">
                    População de interesse
                  </div>
                  <div class="value">
                    {{ formatJsonView(user.population_of_interest) }}
                  </div>
                </a-col>
              </a-row>
            </a-tab-pane>

            <a-tab-pane
              v-if="$root.isAdmin()"
              key="interview"
              tab="Entrevista"
              forcerRender
            >
              <a-row>
                <div class="data-row">
                  <a-col :span="24">
                    <h4>
                      01 - Corretor autônomo ou trabalha em imobiliária?
                    </h4>
                    <a-row :gutter="16">
                      <a-col :span="$root.windowWidth > 1240 ? 8 : 24">
                        <div class="value">
                          {{ user.meta.interview_answer_1 }}
                        </div>
                      </a-col>
                      <a-col
                        v-if="user.meta.interview_answer_1_extra"
                        :span="$root.windowWidth > 1240 ? 8 : 24"
                      >
                        <div class="value">
                          {{ user.meta.interview_answer_1_extra }}
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                </div>

                <div class="data-row">
                  <a-col :span="24">
                    <h4>02 - Atua em quais segmentos?</h4>
                    <a-row :gutter="16">
                      <a-col :span="$root.windowWidth > 1240 ? 8 : 24">
                        <div class="value">
                          {{ user.meta.interview_answer_2 }}
                        </div>
                      </a-col>
                      <a-col
                        v-if="user.meta.interview_answer_2_extra"
                        :span="$root.windowWidth > 1240 ? 8 : 24"
                      >
                        <div class="value">
                          {{ user.meta.interview_answer_2_extra }}
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                </div>

                <div class="data-row">
                  <a-col :span="24">
                    <h4>03 - Possui CRECI?</h4>

                    <a-row :gutter="16">
                      <a-col :span="$root.windowWidth > 1240 ? 3 : 24">
                        <div class="value">
                          {{ user.meta.interview_answer_3 }}
                        </div>
                      </a-col>
                      <a-col
                        v-if="user.meta.interview_answer_3_extra_1"
                        :span="$root.windowWidth > 1240 ? 8 : 24"
                      >
                        <div class="value">
                          {{ user.meta.interview_answer_3_extra_1 }}
                          -
                          {{ user.meta.interview_answer_3_extra_2 }}
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                </div>

                <div class="data-row">
                  <a-col :span="24">
                    <h4>
                      04 - Trabalha como intermediador de ativos há
                    </h4>

                    <a-row :gutter="16">
                      <a-col :span="$root.windowWidth > 1240 ? 8 : 24">
                        <div class="value">
                          {{ user.meta.interview_answer_4 }}
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                </div>

                <div class="data-row">
                  <a-col :span="24">
                    <h4>05 - Renda atual</h4>
                    <a-row :gutter="16">
                      <a-col ::span="$root.windowWidth > 1240 ?8 : 24">
                        <div class="value">
                          {{ user.meta.interview_answer_5 }}
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                </div>

                <div class="data-row">
                  <a-col :span="24">
                    <h4>06 - Outras informações</h4>
                    <div class="value">
                      {{ user.meta.interview_answer_6 }}
                    </div>
                  </a-col>
                </div>
              </a-row>
            </a-tab-pane>

            <a-tab-pane key="properties" forcerRender>
              <template slot="tab">
                {{
                  user.totalProperties == "1"
                    ? user.totalProperties + " Ativo"
                    : user.totalProperties + " Ativos"
                }}
              </template>
              <a-table
                v-if="user.totalProperties != 0"
                :columns="[
                  {
                    title: 'ID',
                    key: 'id',
                    scopedSlots: { customRender: 'id' },
                  },
                  {
                    title: 'Nome do Ativo',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                  },

                  {
                    title: '',
                    key: 'status',
                    scopedSlots: { customRender: 'status' },
                  },

                  {
                    title: 'Estado',
                    key: 'location_state',
                    scopedSlots: {
                      customRender: 'location_state',
                    },
                  },
                  {
                    title: 'Metragem',
                    key: 'metreage',
                    scopedSlots: {
                      customRender: 'metreage',
                    },
                  },
                  {
                    title: 'Tipo',
                    key: 'business_type',
                    scopedSlots: {
                      customRender: 'business_type',
                    },
                  },
                  {
                    title: 'Classificação',
                    key: 'rate',
                    scopedSlots: { customRender: 'rate' },
                  },
                  {
                    title: 'Data de cadastro',
                    key: 'created',
                    scopedSlots: {
                      customRender: 'created',
                    },
                  },
                  {
                    title: '',
                    key: 'action',
                    scopedSlots: { customRender: 'action' },
                    align: 'right',
                  },
                ]"
                :data-source="user.properties.data"
                :pagination="false"
              >
                <a slot="id" slot-scope="text, record">
                  {{ record.id }}
                </a>

                <a slot="name" slot-scope="text, record">
                  {{ record.name }}
                </a>

                <a slot="status" slot-scope="text, record">
                  <div
                    v-if="record.status"
                    class="status-ball"
                    :class="record.status"
                  >
                    <a-tooltip placement="top">
                      <template slot="title">
                        {{ formatStatus(record.status) }}
                      </template>
                      •
                    </a-tooltip>
                  </div>
                </a>

                <div slot="location_state" slot-scope="text, record">
                  {{ record.location_state }}
                </div>
                <a slot="metreage" slot-scope="text, record"
                  >{{ parseFloat(record.metreage).toLocaleString() }}
                  m²
                </a>
                <a slot="business_type" slot-scope="text, record">{{
                  record.business_type
                }}</a>

                <a slot="rate" slot-scope="text, record">
                  <a-rate v-model="record.meta.rate" allow-half disabled>
                    <a-icon slot="character" type="star" />
                  </a-rate>
                </a>
                <a slot="created" slot-scope="text, record">{{
                  record.created | formatDate
                }}</a>
                <span slot="action" slot-scope="record">
                  <!-- <a-tooltip placement="top">
                                        <template slot="title">
                                            <span>Editar</span>
                                        </template>
                                        <a @click="edit(record)">
                                            <a-icon type="edit" />
                                        </a>
                                    </a-tooltip> -->
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Ver</span>
                    </template>
                    <a class="ml-10" @click="view(record)"
                      ><a-icon type="view-svg"
                    /></a>
                  </a-tooltip>
                </span>
              </a-table>

              <a-empty
                class="mt-50"
                v-if="user.totalProperties == 0"
                description="Nenhum ATIVO cadastrado por esse usuário até o
                                momento."
              />
            </a-tab-pane>

            <a-tab-pane
              v-if="$root.isAdmin()"
              key="logs"
              tab="Histórico"
              forcerRender
            >
              <a-timeline
                v-if="user.logs.data.length > 0"
                mode="left"
                class="conectar-timeline"
              >
                <a-timeline-item
                  v-for="(log, index) in user.logs.data"
                  :key="index"
                  :color="logColor(log)"
                >
                  <div class="datetime">
                    <a-icon type="clock-circle-o" />
                    <div
                      class="time"
                      v-html="formatDateTimeline(log.created)"
                    ></div>
                  </div>
                  <span v-html="log.content" />
                </a-timeline-item>
              </a-timeline>
              <a-empty
                class="mt-50"
                v-if="user.logs.data.length == 0"
                description="Nenhuma atividade registrada até o momento."
              />
            </a-tab-pane>
          </a-tabs>
        </div>
        <div class="box tabs bg" v-if="loadingPage">
          <a-skeleton active /><a-skeleton active /><a-skeleton
            active
          /><a-skeleton active />
        </div>
      </a-col>
    </a-row>

    <PrintUser :user="user" />
  </div>
</template>

<script>
import { parse, format } from "date-fns";
import DefaultDataFormView from "./forms/DefaultDataFormView.vue";
import capabilities from "../../mixins/general/capabilities.js";
import PrintUser from "./PrintUser.vue";
import investorAreaSizes from "@/lists/investorAreaSizes.json";
import ListAffiliatesTab from "./tabs/ListAffiliatesTab.vue";

export default {
  mixins: [capabilities],
  components: { DefaultDataFormView, PrintUser, ListAffiliatesTab },
  filters: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
  data() {
    return {
      loadingPage: false,
      investorAreaSizes,
      user: {
        meta: {
          company: false,
        },
      },
      userRate: 0,
      theAvatar: "",
    };
  },
  beforeMount() {
    setTimeout(() => {
      let body = document.body;
      body.classList.add("conectar");
      body.classList.add("users");
      body.classList.add("view");
    }, 20);

    this.loadingPage = true;
    this.getUser();
  },
  beforeDestroy() {
    let body = document.body;
    body.classList.remove("users");
    body.classList.remove("view");
  },
  methods: {
    getUser() {
      this.$http
        .post("/user/read-one.php", {
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.loadingPage = false;
          this.user = data;
          this.theAvatar = data.avatar;
          this.userRate =
            this.user.meta.rate != undefined ? this.user.meta.rate : 0;
          document.title = `${data.id} - ${data.first_name} ${data.last_name} - Conectar`;
        })
        .catch(({ response }) => {
          response;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);
        if (!err) {
          //
        }
      });
    },
    formatDateTimeline(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      theDate = format(theDate, "dd/MM/yyyy HH:mm");

      return `${theDate.split(" ")[0]} <br> ${theDate.split(" ")[1]} `;
    },
    theArea(area) {
      let theArea = area;
      this.investorAreaSizes.forEach((a) => {
        if (a.value == area) {
          theArea = a.label;
        }
      });
      return theArea;
    },
    formatJsonView(data, type) {
      let text = "";
      if (data != undefined && data != "")
        JSON.parse(data).forEach((item) => {
          if (type == undefined) {
            text += `${item}, `;
          }
          if (type == "area") {
            text += `${this.theArea(item)}, `;
          }
        });

      return text ? text.slice(0, -2) : "";
    },
    userRole(role) {
      if (role == "admin") {
        role = "Administrador";
      }

      if (role == "user") {
        role = "Afiliado";
      }

      if (role == "investor") {
        role = "Investidor";
      }

      if (role == "business-manager") {
        role = "Gerente de Negócios";
      }

      if (role == "corporate-admin") {
        role = "Administrador Corporativo";
      }

      return role;
    },
    logColor(log) {
      let logColor = "green";

      if (log.action == "loggedin") {
        logColor = "green";
      }

      if (log.action == "disapproved") {
        logColor = "red";
      }

      if (log.action == "loggedout") {
        logColor = "purple";
      }

      return logColor;
    },
    printDocs() {
      window.print();
    },
    edit(id) {
      this.$router.push(`/${this.$route.fullPath.split("/")[1]}/editar/${id}`);
    },
    view(property) {
      window.open("/ativos/ver/" + property.id, "_blank");
    },
    formatStatus(status) {
      let theStatus = "";
      if (status == "active") {
        theStatus = "Ativo";
      }

      if (status == "disabled") {
        theStatus = "Desativado";
      }

      if (status == "blocked") {
        theStatus = "Bloqueado";
      }

      if (status == "negotiation") {
        theStatus = "Em Negociação";
      }

      return theStatus;
    },
  },
};
</script>

<style>
@media print {
  .ant-layout-sider,
  header.ant-layout-header,
  .ant-page-header,
  .box.extra,
  .gutter-row.sidebar,
  .box.bg.tabs,
  #whatsapp,
  .update-btn {
    display: none !important;
  }

  .sidebar.ant-col,
  .gutter-row.ant-col.ant-col-18 {
    width: 100%;
  }

  .conectar.edit .box.bg {
    box-shadow: none !important;
    border: 1px solid #eee;
  }
}
</style>
