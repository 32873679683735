t<template>
  <div class="page">
    <a-page-header class="bg" sub-title=" " @back="() => $router.go(-1)">
      <template slot="backIcon">
        <a-icon type="left-circle" /> Voltar {{ $route.fullPath.split("/")[1] }}
      </template>
    </a-page-header>

    <a-form
      v-if="$root.checkCapabilities(this.capabilities, 'users', 'edit')"
      :form="form"
      @submit="handleSubmit"
    >
      <a-row id="print" :gutter="25">
        <a-col
          class="gutter-row sidebar"
          :span="$root.windowWidth > 1240 ? 6 : 24"
        >
          <a-row v-show="!loadingPage">
            <a-col class="box a-center bg mb-20" :span="24">
              <avatar :form="form" :user="user" page="edit-user" :edit="true" />

              <div class="name">
                {{ user.first_name }}
                {{ user.last_name }}
              </div>

              <div class="email">{{ user.email }}</div>

              <div class="rate mb-10">
                <a-rate
                  :class="!$root.isAdmin() ? 'readonly-transparent' : ''"
                  v-model="userRate"
                  allow-half
                >
                  <a-icon slot="character" type="star" />
                </a-rate>
              </div>

              <a-tooltip placement="top">
                <template slot="title">
                  <span>Ver detalhes</span>
                </template>
                <a style="top: 3px; position: relative" @click="view(user.id)">
                  <img
                    class="relative"
                    src="../../assets/images/view.png"
                    alt="ver"
                    width="25"
                    style="top: -6px"
                  />
                </a>
              </a-tooltip>

              <span v-if="user.is_approved == 0" class="approve">
                <a-popconfirm
                  title="Tem certeza que deseja APROVAR?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="approveUser(user.id)"
                  :icon="false"
                >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Aprovar</span>
                    </template>
                    <a class="ml-15">
                      <img
                        class="relative"
                        src="../../assets/images/check.png"
                        alt="ver"
                        width="25"
                        style="top: -4px"
                    /></a>
                  </a-tooltip>
                </a-popconfirm>
              </span>

              <span v-if="user.is_approved == 0" class="approve">
                <a-popconfirm
                  title="Tem certeza que deseja REPROVAR?"
                  ok-text="Sim"
                  cancel-text="Não"
                  @confirm="disapproveUser(user.id)"
                  :icon="false"
                >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Reprovar</span>
                    </template>
                    <a class="ml-15">
                      <img
                        class="relative"
                        src="../../assets/images/close.png"
                        alt="ver"
                        width="25"
                        style="top: -4px"
                      />
                    </a>
                  </a-tooltip>
                </a-popconfirm>
              </span>
            </a-col>
            <a-col v-show="$root.isAdmin()" class="box bg mb-20" :span="24">
              <div class="line">
                <div class="text">
                  <a-form-item class="mb-10 underline-wrapper text">
                    <label>Usuário</label>
                    <a-select
                      class="underline-input"
                      :class="!$root.isAdmin() ? 'readonly' : ''"
                      placeholder="Função"
                      show-search
                      v-decorator="[
                        'role',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório.',
                            },
                          ],
                        },
                      ]"
                      style="width: 100%"
                    >
                      <a-select-option
                        v-for="(item, index) of filterRoles(roles)"
                        :key="index"
                        :value="item.value"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>

                <div
                  v-if="
                    form.getFieldValue('role') != 'admin' &&
                      form.getFieldValue('role') != 'investor' &&
                      form.getFieldValue('role') != 'business-manager'
                  "
                  class="text"
                >
                  <a-form-item class="mb-10 underline-wrapper text">
                    <label>Perfil do Afiliado</label>
                    <a-select
                      class="underline-input"
                      :class="!$root.isAdmin() ? 'readonly' : ''"
                      placeholder="Selecione"
                      show-search
                      v-decorator="[
                        'user_profile',
                        {
                          rules: [
                            {
                              required: false,
                              message: 'Obrigatório.',
                            },
                          ],
                        },
                      ]"
                      style="width: 100%"
                    >
                      <a-select-option
                        v-for="(item, index) of resgisterTypes"
                        :key="index"
                        :value="item.value"
                      >
                        {{ item.value }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>

                <div class="text">
                  <a-form-item class="mb-10 underline-wrapper text"
                    ><label>Empresa</label>
                    <a-select
                      class="underline-input"
                      :class="!$root.isAdmin() ? 'readonly' : ''"
                      placeholder="Selecione"
                      show-search
                      v-decorator="['company']"
                      style="width: 100%"
                      allowClear
                    >
                      <a-select-option
                        v-for="(item, index) of companies"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.trading_name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <!-- {{ user.meta }} -->
                </div>

                <div v-if="!$root.isAdmin()" class="none">
                  <a-form-item>
                    <a-input
                      class="readonly"
                      placeholder=""
                      v-decorator="['created_by']"
                    >
                    </a-input>
                  </a-form-item>
                </div>

                <div v-if="$root.isAdmin()" class="text">
                  <a-form-item class="mb-10 underline-wrapper text">
                    <label>Supervisionado por:</label>
                    <a-select
                      class="underline-input"
                      placeholder="Selecione"
                      :filter-option="filterOption"
                      show-search
                      allow-clear
                      v-decorator="[
                        'created_by',
                        {
                          rules: [
                            {
                              required: false,
                              message: 'Obrigatório.',
                            },
                          ],
                        },
                      ]"
                      style="width: 100%"
                    >
                      <a-select-option
                        v-for="(item, index) of listUsers"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.first_name }}
                        {{ item.last_name }} (ID {{ item.id }})
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </div>
            </a-col>
            <a-col class="box mb-20 extra" :span="24">
              <a-row type="flex">
                <a-col v-if="$root.isAdmin()" flex="50px">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Alterar senha</span>
                    </template>
                    <a-button @click="changePassword()">
                      <a-icon type="lock" />
                    </a-button>
                  </a-tooltip>
                </a-col>

                <a-col flex="auto">
                  <!-- <pre> {{ user.is_approved }}</pre> -->

                  <a-form-item style="display: none">
                    <a-input v-decorator="['status']"> </a-input>
                  </a-form-item>

                  <div v-if="$root.isAdmin()">
                    <a-popconfirm
                      v-if="user.status == '1'"
                      title="Tem certeza que deseja desativar?"
                      ok-text="Sim"
                      cancel-text="Cancelar"
                      @confirm="deactivateUser"
                    >
                      <a-button
                        type="danger"
                        ghost
                        style="width: 100%"
                        :loading="loadingStatusChange"
                      >
                        Desativar usuário
                      </a-button>
                    </a-popconfirm>

                    <a-popconfirm
                      v-if="user.status == '0'"
                      title="Tem certeza que deseja ativar?"
                      ok-text="Sim"
                      cancel-text="Cancelar"
                      @confirm="activateUser"
                      :loading="loadingStatusChange"
                    >
                      <a-button
                        class="update-btn"
                        type="danger"
                        ghost
                        style="width: 100%"
                      >
                        Ativar usuário
                      </a-button>
                    </a-popconfirm>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row v-if="loadingPage">
            <a-col class="box a-center bg mb-20" :span="24">
              <a-skeleton active />
            </a-col>

            <a-col class="box a-center bg mb-20" :span="24">
              <a-skeleton active />
            </a-col>
          </a-row>
        </a-col>
        <a-col class="gutter-row" :span="$root.windowWidth > 1240 ? 18 : 24">
          <div v-show="!loadingPage" class="box tabs bg">
            <a-tabs default-active-key="data" @change="onChangeTabs">
              <a-tab-pane key="data" tab="Dados" forceRender>
                <DefaultDataFormEdit :user="user" :form="form" />
              </a-tab-pane>

              <a-tab-pane
                v-if="
                  ($root.isAdmin() || $root.isInvestor()) &&
                    form.getFieldValue('role') == 'investor'
                "
                key="interests"
                forceRender
                :class="!$root.isAdmin() ? 'readonly' : ''"
              >
                <template slot="tab">
                  <span :class="investorProfileFieldsAlert ? 'red' : ''">
                    Perfil de Interesse
                  </span>
                  <a-icon
                    v-if="investorProfileFieldsAlert"
                    :class="investorProfileFieldsAlert ? 'red' : ''"
                    type="warning"
                  />
                </template>
                <InvestorProfile :user="user" :form="form" />
              </a-tab-pane>

              <a-tab-pane
                v-if="$root.isAdmin()"
                key="interview"
                tab="Entrevista"
                forceRender
                :class="!$root.isAdmin() ? 'readonly' : ''"
              >
                <UserInterview :user="user" :form="form" />
              </a-tab-pane>
            </a-tabs>

            <a-row type="flex" justify="end">
              <a-col>
                <a-button
                  html-type="submit"
                  class="update-btn"
                  style="width: 120px"
                  :loading="loadingUserUpdate"
                  >Salvar</a-button
                >
              </a-col>
            </a-row>
          </div>
          <div class="box tabs bg" v-if="loadingPage">
            <a-skeleton active /><a-skeleton active /><a-skeleton
              active
            /><a-skeleton active />
          </div>
        </a-col>
      </a-row>
    </a-form>

    <a-modal
      v-model="modals.changePassword"
      title="Alterar a senha"
      footer=""
      width="320px"
    >
      <a-form-model
        v-if="modals.changePassword"
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item class="underline-outer" has-feedback prop="pass">
          <a-input
            class="underline-input"
            v-model="ruleForm.pass"
            type="password"
            autocomplete="off"
            placeholder="Insira sua nova senha"
          />
        </a-form-model-item>
        <a-form-model-item has-feedback prop="checkPass">
          <a-input
            class="underline-input"
            v-model="ruleForm.checkPass"
            type="password"
            autocomplete="off"
            placeholder="Repita a nova senha"
          />
        </a-form-model-item>

        <a-button
          class="update-btn"
          @click="submitForm('ruleForm')"
          style="width: 100%"
          :loading="loadingChangePassword"
        >
          Alterar a senha
        </a-button>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { parse, format } from "date-fns";
import UserInterview from "./forms/UserInterview.vue";
import DefaultDataFormEdit from "./forms/DefaultDataFormEdit.vue";
import roles from "../../lists/roles.json";
import Avatar from "../../components/atoms/Avatar.vue";
import capabilities from "../../mixins/general/capabilities.js";
import InvestorProfile from "./forms/InvestorProfile.vue";
import usersMixins from "@/mixins/users/usersMixins.js";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default {
  components: { UserInterview, DefaultDataFormEdit, Avatar, InvestorProfile },
  mixins: [capabilities, usersMixins],
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Insira a senha."));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Insira a senha novamente,"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("As senhas não são iguais."));
      } else {
        callback();
      }
    };
    return {
      form: this.$form.createForm(this),
      loadingPage: false,
      investorProfileFieldsAlert: false,
      resgisterTypes: [
        {
          label: "Proprietário de terreno",
          value: "Proprietário de terreno",
        },
        { label: "Intermediador", value: "Intermediador" },
      ],
      user: {},
      userRate: "0",
      theAvatar: "",
      companies: [],
      modals: {
        changePassword: false,
      },
      loadingChangePassword: false,
      loadingStatusChange: false,
      loadingUserUpdate: false,
      updatePassword: {
        password: "",
        repeatPassword: "",
      },
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "change" }],
        checkPass: [{ validator: validatePass2, trigger: "change" }],
      },
      roles,
      columns: [
        {
          title: "Nome",
          key: "first_name",
          scopedSlots: { customRender: "first_name" },
        },
        {
          title: "Celular",
          key: "mobile_phone",
          scopedSlots: { customRender: "mobile_phone" },
        },
        {
          title: "CPF",
          key: "cpf",
          scopedSlots: { customRender: "cpf" },
        },

        {
          title: "Data de cadastro",
          key: "created",
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "right",
        },
      ],
    };
  },
  filters: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
  beforeMount() {
    setTimeout(() => {
      let body = document.body;
      body.classList.add("conectar");
      body.classList.add("users");
      body.classList.add("edit");
    }, 20);

    this.loadingPage = true;
    this.getUser();
    setTimeout(() => {
      this.getCompanies();
    }, 500);
  },
  mounted() {
    this.filters.orderBy = "first_name";
    this.filters.order = "ascend";
    this.pagination.perPage = 9999;
    this.filters.status.selected = [1];
    this.filters.isApproved.selected = [1];

    this.getUsers();
  },
  beforeDestroy() {
    let body = document.body;
    body.classList.remove("users");
    body.classList.remove("edit");
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterRoles(roles) {
      let filteredRoles = [];

      if (!this.$root.isAdmin()) {
        roles.forEach((role) => {
          if (role.value == this.$store.state.userData.role) {
            filteredRoles.push(role);
          }
        });
      } else {
        filteredRoles = roles;
      }

      return filteredRoles;
    },
    onChangeTabs(tab) {
      if (tab == "interests") this.investorProfileFieldsAlert = false;
    },
    getUser() {
      this.$http
        .post("/user/read-one.php", {
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.user = data;
          this.loadingPage = false;
          setTimeout(() => {
            this.form.setFieldsValue({
              [`first_name`]: data.first_name,
              [`last_name`]: data.last_name,
              [`created_by`]:
                data.raw.created_by != 0 ? data.raw.created_by : undefined,
              [`cpf`]: data.cpf,
              [`rg`]: data.meta.rg,
              [`rg_doc_emission`]: data.meta.rg_doc_emission,
              [`rg_doc_emission_state`]: data.meta.rg_doc_emission_state,
              [`birthday`]: data.meta.birthday,
              [`status`]: data.status,
              [`profession`]: data.meta.profession,
              [`email`]: data.email,
              [`phone`]: data.phone,
              [`mobile_phone`]: data.mobile_phone,
              [`role`]: data.role,
              [`cep`]: data.meta.cep,
              [`location_street`]: data.meta.location_street,
              [`location_number`]: data.meta.location_number,
              [`location_neighborhood`]: data.meta.location_neighborhood,
              [`location_city`]: data.meta.location_city,
              [`location_state`]: data.meta.location_state,
              [`location_complement`]: data.meta.location_complement,
              [`interview_answer_1`]: data.meta.interview_answer_1,
              [`interview_answer_1_extra`]: data.meta.interview_answer_1_extra,
              [`interview_answer_2`]: data.meta.interview_answer_2,
              [`interview_answer_2_extra`]: data.meta.interview_answer_2_extra,
              [`interview_answer_3`]: data.meta.interview_answer_3,
              [`interview_answer_3_extra_1`]: data.meta
                .interview_answer_3_extra_1,
              [`interview_answer_3_extra_2`]: data.meta
                .interview_answer_3_extra_2,
              [`interview_answer_4`]: data.meta.interview_answer_4,
              [`interview_answer_5`]: data.meta.interview_answer_5,
              [`interview_answer_6`]: data.meta.interview_answer_6,
              [`user_profile`]: data.meta.user_profile,
              [`company`]:
                data.meta.company != undefined
                  ? data.meta.company.id
                  : undefined,
            });

            setTimeout(() => {
              this.form.setFieldsValue({
                [`business_type_of_interest`]: data.business_type_of_interest
                  ? JSON.parse(data.business_type_of_interest)
                  : undefined,
                [`area_size_of_interest`]: data.area_size_of_interest
                  ? JSON.parse(data.area_size_of_interest)
                  : undefined,
                [`region_of_interest`]: data.region_of_interest
                  ? JSON.parse(data.region_of_interest)
                  : undefined,
                [`state_of_interest`]: data.state_of_interest
                  ? JSON.parse(data.state_of_interest)
                  : undefined,
                [`population_of_interest`]: data.population_of_interest
                  ? JSON.parse(data.population_of_interest)
                  : undefined,
              });
            }, 200);
          }, 100);

          this.userRate =
            this.user.meta.rate != undefined ? `${this.user.meta.rate}` : "0";

          document.title = `${data.id} - ${data.first_name} ${data.last_name} - Conectar`;
        })
        .catch(({ response }) => {
          response;
        });
    },
    getCompanies() {
      this.$http
        .post("/company/list.php", {
          page: 1,
          per_page: 100,
        })
        .then(({ data }) => {
          this.companies = data.data;
        })
        .catch(({ response }) => {
          response;
          this.companies = [];
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log("ERROS:", err, "VALORES", {
          ...values,
          id: this.$route.params.id,
          rate: this.userRate,
        });
        if (!err) {
          values.business_type_of_interest = values.business_type_of_interest
            ? JSON.stringify(values.business_type_of_interest)
            : "[]";
          values.area_size_of_interest = values.area_size_of_interest
            ? JSON.stringify(values.area_size_of_interest)
            : "[]";
          values.region_of_interest = values.region_of_interest
            ? JSON.stringify(values.region_of_interest)
            : "[]";
          values.state_of_interest = values.state_of_interest
            ? JSON.stringify(values.state_of_interest)
            : "[]";
          values.population_of_interest = values.population_of_interest
            ? JSON.stringify(values.population_of_interest)
            : "[]";

          values.company = values.company != undefined ? values.company : "";
          values.company_id = values.company != undefined ? values.company : "";

          console.log("ERROS:", err, "VALORES", {
            ...values,
            id: this.$route.params.id,
            rate: this.userRate,
          });

          let actions = ["update-user"];
          actions;

          if (values.email != this.user.email) {
            actions.push("changed-email");
            values.old_email = this.user.email;
          }

          if (this.$root.isAdmin()) {
            let lastInterview = {
              interview_answer_1: this.user.meta.interview_answer_1,
              interview_answer_2: this.user.meta.interview_answer_2,
              interview_answer_3: this.user.meta.interview_answer_3,
              interview_answer_3_extra_1: this.user.meta
                .interview_answer_3_extra_1,
              interview_answer_3_extra_2: this.user.meta
                .interview_answer_3_extra_2,
              interview_answer_4: this.user.meta.interview_answer_4,
              interview_answer_5: this.user.meta.interview_answer_5,
              interview_answer_6: this.user.meta.interview_answer_6,
            };

            let updatedInterview = {
              interview_answer_1: values.interview_answer_1,
              interview_answer_2: values.interview_answer_2,
              interview_answer_3: values.interview_answer_3,
              interview_answer_3_extra_1: values.interview_answer_3_extra_1,
              interview_answer_3_extra_2: values.interview_answer_3_extra_2,
              interview_answer_4: values.interview_answer_4,
              interview_answer_5: values.interview_answer_5,
              interview_answer_6: values.interview_answer_6,
            };

            if (
              JSON.stringify(lastInterview) != JSON.stringify(updatedInterview)
            ) {
              console.log("diferente");
              actions.push("update-interview");
            }
          }

          this.loadingUserUpdate = true;

          this.$http
            .post("/user/update.php", {
              ...values,
              id: this.$route.params.id,
              rate: this.userRate,
              actions: actions,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
              modified_user: {
                name: `${this.user.first_name} ${this.user.last_name}`,
                id: this.user.id,
              },
            })
            .then(({ data }) => {
              this.loadingUserUpdate = false;
              this.$message.success(data.message);

              if (this.$route.fullPath.split("/")[1] == "usuarios") {
                this.$router.push(
                  `/${this.$route.fullPath.split("/")[1]}/administradores`
                );
              } else {
                if (this.user.is_approved == "0") {
                  this.$router.push(
                    `/${
                      this.$route.fullPath.split("/")[1]
                    }/aguardando-aprovacao`
                  );
                } else {
                  if (values.role == "investor") {
                    this.$router.push(
                      `/${this.$route.fullPath.split("/")[1]}/investidores`
                    );
                  } else if (
                    values.role == "user" &&
                    values.user_profile == "Intermediador"
                  ) {
                    this.$router.push(
                      `/${
                        this.$route.fullPath.split("/")[1]
                      }/intermediadores-negocios`
                    );
                  } else if (
                    values.role == "user" &&
                    values.user_profile == "Proprietário de terreno"
                  ) {
                    this.$router.push(
                      `/${
                        this.$route.fullPath.split("/")[1]
                      }/proprietarios-terreno`
                    );
                  } else if (values.role == "business-manager") {
                    this.$router.push(
                      `/${
                        this.$route.fullPath.split("/")[1]
                      }/gestores-regionais`
                    );
                  } else {
                    this.$router.push(
                      `/${this.$route.fullPath.split("/")[1]}/lista`
                    );
                  }
                }
              }
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingUserUpdate = false;
            });
        } else {
          this.$message.warning(
            "Alguns campos não foram preenchidos. Verifique todas abas."
          );
          this.investorProfileFieldsAlert = true;
        }
      });
    },
    deactivateUser() {
      this.loadingStatusChange = true;
      this.$http
        .post("/user/active-user.php", {
          id: this.user.id,
          activated_by: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
          modified_user: {
            name: `${this.user.first_name} ${this.user.last_name}`,
            id: this.user.id,
          },
          status: 0,
        })
        .then(({ data }) => {
          this.loadingStatusChange = false;
          this.user.status = 0;
          this.form.setFieldsValue({
            [`status`]: 0,
          });
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          response;
          this.loadingStatusChange = false;
        });
    },
    activateUser() {
      this.loadingStatusChange = true;
      this.$http
        .post("/user/active-user.php", {
          id: this.user.id,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
          modified_user: {
            name: `${this.user.first_name} ${this.user.last_name}`,
            id: this.user.id,
          },
          status: 1,
        })
        .then(({ data }) => {
          this.loadingStatusChange = false;
          this.user.status = 1;
          this.form.setFieldsValue({
            [`status`]: 1,
          });
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          response;
          this.loadingStatusChange = false;
        });
    },
    approveUser(id) {
      this.$http
        .post("/user/approve.php", {
          id: id,
          status: true,
          is_approved: true,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
          modified_user: {
            name: `${this.user.first_name} ${this.user.last_name}`,
            id: this.user.id,
            email: this.user.email,
          },
        })
        .then(({ data }) => {
          this.$message.success(data.message, 7);
          window.open(this.$route.fullPath, "_self");
        })
        .catch(({ response }) => {
          response;
        });
    },
    disapproveUser(id) {
      this.$http
        .post("/user/approve.php", {
          id: id,
          status: false,
          is_approved: "disapproved",
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
          modified_user: {
            name: `${this.user.first_name} ${this.user.last_name}`,
            id: this.user.id,
            email: this.user.email,
          },
        })
        .then(({ data }) => {
          this.$message.success(data.message, 7);
          window.open(this.$route.fullPath, "_self");
        })
        .catch(({ response }) => {
          response;
        });
    },
    formatDateTimeline(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      theDate = format(theDate, "dd/MM/yyyy HH:mm");

      return `${theDate.split(" ")[0]} <br> ${theDate.split(" ")[1]} `;
    },
    userRole(role) {
      if (role == "admin") {
        role = "Administrador";
      }

      if (role == "user") {
        role = "Afiliado";
      }

      if (role == "investor") {
        role = "Investidor";
      }

      if (role == "business-manager") {
        role = "Gerente de Negócios";
      }

      if (role == "corporate-admin") {
        role = "Administrador Corporativo";
      }

      return role;
    },
    logColor(log) {
      let logColor = "green";

      if (log.action == "loggedin") {
        logColor = "green";
      }

      if (log.action == "loggedout") {
        logColor = "purple";
      }

      return logColor;
    },
    printDocs() {
      document.title = `${this.user.id} - ${this.user.first_name}`;
      window.print();
      document.title = `${this.user.id} - ${this.user.first_name}`;
    },
    submitForm(formName) {
      this.loadingChangePassword = true;
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          this.$http
            .post("/user/change-password-auth.php", {
              id: this.user.id,
              password: this.ruleForm.pass,
              confirm: this.ruleForm.checkPass,
              email: this.user.email,
            })
            .then(({ data }) => {
              data;
              this.loadingChangePassword = false;
              this.modals.changePassword = false;
              this.$message.success("Atualizado com sucesso!");
              this.ruleForm = {
                pass: "",
                checkPass: "",
              };
            })
            .catch(({ response }) => {
              response;
              this.loadingChangePassword = false;
              this.$message.error("Houve algum problema ao atualizar a senha.");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changePassword() {
      this.modals.changePassword = true;
    },
    handleChange(info) {
      if (this.isValidFile) {
        getBase64(info.file.originFileObj, (imageBase64) => {
          this.imageBase64 = imageBase64;
          this.theAvatar = imageBase64;

          setTimeout(() => {
            this.form.setFieldsValue({
              avatar: imageBase64,
            });
          }, 1);
        });
      }
    },
    beforeUpload(file) {
      const isValidImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isValidImage) {
        this.$message.error("Este arquivo não é válido!");
        return;
      }
      const validSize = file.size / 1024 / 1024 < 2;
      if (!validSize) {
        this.$message.error("A imagem deve ter menos que 2MB!");
        return;
      }
      this.isValidFile = true;
      return isValidImage && validSize;
    },
    removeAvatar() {
      this.user.avatar = "";
      this.form.setFieldsValue({
        avatar: "",
      });
      this.theAvatar = "";
    },
    haveTheField(field) {
      if (field == "avatar") {
        return this.form.getFieldValue(field) || this.user[field];
      } else {
        return this.form.getFieldValue(field) || this.user[field];
      }
    },
    view(id) {
      this.$router.push(`/${this.$route.fullPath.split("/")[1]}/ver/${id}`);
    },
  },
};
</script>

<style>
@media print {
  .ant-layout-sider,
  header.ant-layout-header,
  .ant-page-header,
  .box.extra,
  .update-btn {
    display: none !important;
  }

  .sidebar.ant-col,
  .gutter-row.ant-col.ant-col-18 {
    width: 100%;
  }

  .conectar.edit .box.bg {
    box-shadow: none !important;
    border: 1px solid #eee;
  }
}
</style>
