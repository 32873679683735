<template>
    <div>
        <a-col class="mb-20" :span="24">
            <a-form-item class="mb-10 underline-wrapper">
                <label
                    :class="
                        form.getFieldValue('business_type_of_interest')
                            ? 'filled'
                            : ''
                    "
                    >Vocação da área</label
                >
                <a-select
                    class="underline-input multi"
                    show-search
                    mode="multiple"
                    placeholder="Selecione a vocação da área"
                    v-decorator="[
                        'business_type_of_interest',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                },
                            ],
                        },
                    ]"
                >
                    <a-select-option
                        v-for="(item, index) of vocations"
                        :key="index"
                        :value="item.label"
                    >
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-col>

        <a-col class="mb-20" :span="24">
            <a-form-item class="mb-10 underline-wrapper">
                <label
                    :class="
                        form.getFieldValue('area_size_of_interest')
                            ? 'filled'
                            : ''
                    "
                    >Tamanho das áreas</label
                >
                <a-select
                    class="underline-input multi"
                    show-search
                    mode="multiple"
                    placeholder="Selecione tamanho das áreas"
                    v-decorator="[
                        'area_size_of_interest',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                },
                            ],
                        },
                    ]"
                >
                    <a-select-option
                        v-for="(item, index) of investorAreaSizes"
                        :key="index"
                        :value="item.value"
                    >
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-col>

        <a-col class="mb-20" :span="24">
            <a-form-item class="mb-10 underline-wrapper">
                <label
                    :class="
                        form.getFieldValue('state_of_interest') ? 'filled' : ''
                    "
                    >Estados de interesse</label
                >
                <a-select
                    class="underline-input multi"
                    show-search
                    mode="multiple"
                    placeholder="Selecione estados"
                    v-decorator="[
                        'state_of_interest',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                },
                            ],
                        },
                    ]"
                >
                    <a-select-option
                        v-for="(item, index) of states"
                        :key="index"
                        :value="item.initials"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-col>

        <a-col :span="24">
            <a-form-item class="mb-10 underline-wrapper">
                <label
                    :class="
                        form.getFieldValue('population_of_interest')
                            ? 'filled'
                            : ''
                    "
                    >Número de habitantes da cidade</label
                >
                <a-select
                    class="underline-input multi"
                    show-search
                    mode="multiple"
                    placeholder="Selecione o número de habitantes"
                    v-decorator="[
                        'population_of_interest',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                },
                            ],
                        },
                    ]"
                >
                    <a-select-option
                        v-for="(item, index) of investorPopulation"
                        :key="index"
                        :value="item.label"
                    >
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
    </div>
</template>

<script>
import investorPopulation from "@/lists/investorPopulation.json";
import investorAreaSizes from "@/lists/investorAreaSizes.json";
import states from "@/lists/states.json";
import regions from "@/lists/regions.json";
import vocations from "@/lists/vocations.json";

export default {
    props: {
        user: Object,
        form: Object,
    },
    data() {
        return {
            investorPopulation,
            investorAreaSizes,
            states,
            regions,
            vocations,
        };
    },
};
</script>

<style lang="sass" scoped></style>
