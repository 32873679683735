var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('company_name')
                                ? 'filled'
                                : ''},[_vm._v("Razão Social")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'company_name',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'company_name',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Razão Social"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"right-circle"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 600,
                            span: 24,
                        },
                        {
                            min: 601,
                            max: 9999,
                            span: 12,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('trading_name')
                                ? 'filled'
                                : ''},[_vm._v("Nome Fantasia")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'trading_name',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'trading_name',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Nome Fantasia"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"right-circle"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 600,
                            span: 24,
                        },
                        {
                            min: 601,
                            max: 9999,
                            span: 12,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('cnpj') ? 'filled' : ''},[_vm._v("CNPJ")]),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"},{name:"decorator",rawName:"v-decorator",value:([
                            'cnpj',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'cnpj',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"CNPJ"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"right-circle"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('company_email')
                                ? 'filled'
                                : ''},[_vm._v("E-mail da Empresa ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'company_email',
                            {
                                rules: [
                                    {
                                        type: 'email',
                                        message:
                                            'Escreva um e-mail válido.',
                                    },
                                    {
                                        required: true,
                                        message:
                                            'Escreva seu e-mail no campo.',
                                    } ],
                            } ]),expression:"[\n                            'company_email',\n                            {\n                                rules: [\n                                    {\n                                        type: 'email',\n                                        message:\n                                            'Escreva um e-mail válido.',\n                                    },\n                                    {\n                                        required: true,\n                                        message:\n                                            'Escreva seu e-mail no campo.',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"E-mail da Empresa","value":""}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 600,
                            span: 24,
                        },
                        {
                            min: 601,
                            max: 9999,
                            span: 12,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('company_phone')
                                ? 'filled'
                                : ''},[_vm._v("Telefone fixo")]),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####']),expression:"['(##) ####-####']"},{name:"decorator",rawName:"v-decorator",value:([
                            'company_phone',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'company_phone',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Telefone fixo"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"phone"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 600,
                            span: 24,
                        },
                        {
                            min: 601,
                            max: 9999,
                            span: 12,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('company_cep')
                                ? 'filled'
                                : ''},[_vm._v("CEP")]),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(['#####-###']),expression:"['#####-###']"},{name:"decorator",rawName:"v-decorator",value:([
                            'company_cep',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'company_cep',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"CEP"},on:{"keyup":function($event){return _vm.getLocationCompany()}}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"environment"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue(
                                'company_location_street'
                            )
                                ? 'filled'
                                : ''},[_vm._v("Endereço")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'company_location_street',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'company_location_street',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Endereço"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"environment"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 600,
                            span: 24,
                        },
                        {
                            min: 601,
                            max: 9999,
                            span: 8,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue(
                                'company_location_number'
                            )
                                ? 'filled'
                                : ''},[_vm._v("Número")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'company_location_number',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'company_location_number',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Número"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"environment"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 600,
                            span: 24,
                        },
                        {
                            min: 601,
                            max: 9999,
                            span: 16,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue(
                                'company_location_complement'
                            )
                                ? 'filled'
                                : ''},[_vm._v("Complemento")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['company_location_complement']),expression:"['company_location_complement']"}],staticClass:"underline-input",attrs:{"placeholder":"Complemento"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"environment"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 600,
                            span: 24,
                        },
                        {
                            min: 601,
                            max: 9999,
                            span: 10,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('company_location_city')
                                ? 'filled'
                                : ''},[_vm._v("Cidade")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'company_location_city',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'company_location_city',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Cidade"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"environment"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 600,
                            span: 24,
                        },
                        {
                            min: 601,
                            max: 9999,
                            span: 4,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue(
                                'company_location_state'
                            )
                                ? 'filled'
                                : ''},[_vm._v("Estado")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'company_location_state',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'company_location_state',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"show-search":"","placeholder":"ES"}},_vm._l((_vm.states),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.initials}},[_vm._v(" "+_vm._s(item.initials.toUpperCase())+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 600,
                            span: 24,
                        },
                        {
                            min: 601,
                            max: 9999,
                            span: 10,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue(
                                'company_location_neighborhood'
                            )
                                ? 'filled'
                                : ''},[_vm._v("Bairro")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'company_location_neighborhood',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'company_location_neighborhood',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Bairro"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"environment"},slot:"prefix"})],1)],1)],1),_c('a-col',{staticClass:"mb-20 mt-30",attrs:{"span":24}},[_c('a-switch',{attrs:{"size":"small"},model:{value:(_vm.addCompanyOwner),callback:function ($$v) {_vm.addCompanyOwner=$$v},expression:"addCompanyOwner"}}),_vm._v(" Deseja informar o proprietário da empresa? ")],1),(_vm.addCompanyOwner)?_c('a-col',{attrs:{"span":20}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('a-auto-complete',{staticStyle:{"width":"100%"},attrs:{"data-source":_vm.listAffiliates.map(
                                function (ref) {
                                                    var first_name = ref.first_name;
                                                    var last_name = ref.last_name;
                                                    var id = ref.id;
                                                    var role = ref.role;

                                                    return ({
                                    value: id,
                                    text: (id + " - " + first_name + " " + last_name + " (" + (_vm.userRole(
                                        role
                                    )) + ")"),
                                });
}
                            ),"placeholder":"Buscar afiliados..."},on:{"search":_vm.searchUsers,"select":_vm.onOwnerSelected}},[_c('a-input',{staticClass:"underline-input"},[_c('a-icon',{attrs:{"slot":"prefix","type":"search"},slot:"prefix"})],1)],1)],1)],1):_vm._e(),(_vm.addCompanyOwner)?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('owner_id')
                                ? 'filled'
                                : ''},[_vm._v("ID")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'owner_id',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'owner_id',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"ID","disabled":""}})],1)],1):_vm._e(),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"underline-wrapper a-center mt-30"},[_c('a-button',{attrs:{"type":"primary","size":"large","html-type":"submit","loading":_vm.loading}},[_vm._v(" Cadastrar empresa ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }