<template>
  <a-form :form="form" @submit="handleSubmit">
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Primeiro nome</label>
          <a-input
            class="underline-input"
            placeholder="Primeiro nome"
            v-decorator="[
              'first_name',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="right-circle"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Sobrenome</label>
          <a-input
            class="underline-input"
            placeholder="Sobrenome"
            v-decorator="[
              'last_name',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="right-circle"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">CPF</label>
          <a-input
            class="underline-input"
            placeholder="Insira"
            v-mask="'###.###.###-##'"
            @blur="verifyTypedCPF"
            v-decorator="[
              'cpf',
              {
                rules: [
                  {
                    required: true,
                    min: 9,
                    message: 'Preencha todos números.',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="idcard"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Nascimento</label>

          <a-date-picker
            class="underline-input"
            placeholder="Nascimento"
            format="DD/MM/YYYY"
            v-mask="'##/##/####'"
            :showToday="false"
            :disabled-date="adultHoodDate"
            @openChange="defaultDate"
            v-decorator="[
              'birthday',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-date-picker>
        </a-form-item>
      </a-col>

      <a-col :span="9">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">RG</label>
          <a-input
            class="underline-input"
            placeholder="RG"
            v-mask="['##.###.###', '#.###.###', '###.###.###-##']"
            v-decorator="[
              'rg',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                  {
                    min: 9,
                    message: 'Preencha todos números',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="idcard"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="9">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Orgão Emissor</label>
          <a-input
            class="underline-input"
            placeholder="Órgão Emissor"
            v-decorator="[
              'rg_doc_emission',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="idcard"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Estado</label>
          <a-select
            class="underline-input"
            default-value=""
            show-search
            placeholder="ES"
            v-decorator="[
              'rg_doc_emission_state',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Profissão</label>

          <a-select
            class="underline-input"
            placeholder="Profissão"
            show-search
            v-decorator="[
              'profession',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório.',
                  },
                ],
              },
            ]"
            default-value="lucy"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) in professions"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Telefone celular .</label>
          <a-input
            class="underline-input"
            placeholder="Insira"
            @blur="validatePhoneNumber"
            v-mask="['(##) # ####-####']"
            v-decorator="[
              'mobile_phone',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="mobile"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Telefone fixo </label>
          <a-input
            class="underline-input"
            placeholder="Telefone fixo"
            v-mask="['(##) ####-####']"
            v-decorator="['phone']"
          >
            <a-icon
              slot="prefix"
              type="phone"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">E-mail </label>
          <a-input
            class="underline-input"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido.',
                  },
                  {
                    required: true,
                    message: 'Escreva seu e-mail no campo.',
                  },
                ],
              },
            ]"
            placeholder="E-mail"
            value="ledilson@viajarturismo.com.br"
          >
            <a-icon
              slot="prefix"
              type="mail"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">CEP</label>
          <a-input
            class="underline-input"
            placeholder="CEP"
            v-mask="['#####-###']"
            v-decorator="[
              'cep',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
            @keyup="getLocation()"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Endereço</label>
          <a-input
            class="underline-input"
            placeholder="Endereço"
            v-decorator="[
              'location_street',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Número</label>
          <a-input
            class="underline-input"
            placeholder="Número"
            v-decorator="[
              'location_number',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="16">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Bairro</label>
          <a-input
            class="underline-input"
            placeholder="Bairro"
            v-decorator="[
              'location_neighborhood',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="18">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Cidade</label>
          <a-input
            class="underline-input"
            placeholder="Cidade"
            v-decorator="[
              'location_city',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Estado</label>
          <a-select
            class="underline-input"
            default-value=""
            show-search
            placeholder="ES"
            v-decorator="[
              'location_state',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Complemento</label>
          <a-input
            class="underline-input"
            placeholder="Complemento"
            v-decorator="['location_complement']"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col class="mt-10" :span="24">
        <a-form-item class="agree-conectar-login">
          <a-checkbox v-decorator="['agreed_contract']">
            Li e aceito os
          </a-checkbox>
          <a
            @click="openTermsOfService = true"
            style="font-size: 13px; margin-left: -2px"
            >termos do contrato</a
          >
        </a-form-item>
      </a-col>

      <a-col class="mb-40" :span="24">
        <a-form-item>
          <a-button
            type="primary"
            size="large"
            html-type="submit"
            :loading="loading"
            shape="round"
            style="width: 100%"
          >
            Cadastrar
          </a-button>
        </a-form-item>
      </a-col>
    </a-row>

    <a-modal
      v-model="openTermsOfService"
      title="Termo de uso do APP CONECTAR"
      footer
      width="700px"
    >
      <div
        v-if="preRegisterData.user_profile == 'Investidor'"
        v-html="termsOfUseInvestor"
      ></div>
      <div v-else v-html="termsOfUse"></div>
    </a-modal>

    <a-modal
      class="success-modal"
      v-model="showSuccessModal"
      footer=""
      centered
      @cancel="closeSuccessModal"
    >
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="4">
          <div class="gutter-box">
            <img src="../../assets/images/success.png" alt="sucesso" />
          </div>
        </a-col>
        <a-col class="gutter-row" :span="20">
          <div class="gutter-box">
            <h2>Sucesso!</h2>
            <p>
              <strong>Seu cadastro foi efetuado com sucesso.</strong>
            </p>
            <p>
              Em breve você receberá o contato da nossa equipe de recrutamento e
              seu acesso será liberado por e-mail.
            </p>
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </a-form>
</template>

<script>
import axios from "axios";
import subYears from "date-fns/subYears";
import { format } from "date-fns";
import states from "../../lists/states.json";
import professions from "../../lists/professions.json";

export default {
  props: {
    preRegisterData: Object,
  },
  components: {},
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR",
    },
    title: "Cadastro – Conectar",
    meta: [
      {
        name: "descrition",
        content: "Página de cadastro de usuários.",
      },
    ],
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      showSuccessModal: false,
      termsOfUse: "",
      termsOfUseInvestor: "",
      openTermsOfService: false,
      states,
      professions,
    };
  },
  mounted() {
    // this.form.setFieldsValue({
    //     first_name: "Ledilson",
    //     last_name: "Motta",
    //     email: "ledde.motta@gmail.com",
    //     cpf: "091.642.466-98",
    //     birthday: "1990-09-18",
    //     rg: "028.335.96",
    //     rg_doc_emission: "PM",
    //     rg_doc_emission_state: "MG",
    //     profession: "Adminstração",
    //     mobile_phone: "31991395984",
    //     phone: "3135520082",
    //     cep: "35400000",
    //     location_street: "Rua José Mendes",
    //     location_number: "1000",
    //     location_state: "MG",
    //     location_city: "Ouro Preto",
    //     location_neighborhood: "Saramenha",
    //     location_complement: "Casa",
    // });
  },
  beforeMount() {
    setTimeout(() => {
      let body = document.body;
      body.classList.add("conectar");
      body.classList.add("register");
    }, 20);

    this.$http
      .post("/external-apis/get-wp-pages.php", {
        id: "8370",
      })
      .then(({ data }) => {
        this.termsOfUse = data.content;
      })
      .catch(({ response }) => {
        response;
      });

    this.$http
      .post("/external-apis/get-wp-pages.php", {
        id: "8560",
      })
      .then(({ data }) => {
        this.termsOfUseInvestor = data.content;
      })
      .catch(({ response }) => {
        response;
      });
  },
  beforeDestroy() {
    let body = document.body;
    body.classList.remove("conectar");
    body.classList.remove("register");
  },
  methods: {
    validatePhoneNumber(val) {
      // Remove non-numeric characters from the phone number
      let phoneNumber = val.target.value
        ? val.target.value.replace(/\D/g, "")
        : "";

      // Check if the phone number has the correct length
      if (phoneNumber.length !== 11) {
        this.form.setFieldsValue({
          [`mobile_phone`]: undefined,
        });
        return false;
      }

      // Check if the phone number has repeated digits
      if (
        [
          "00000000000",
          "11111111111",
          "22222222222",
          "33333333333",
          "44444444444",
          "55555555555",
          "66666666666",
          "77777777777",
          "88888888888",
          "99999999999",
        ].includes(phoneNumber)
      ) {
        this.form.setFieldsValue({
          [`mobile_phone`]: undefined,
        });
        return false;
      }

      // Phone number is valid
      return true;
    },
    verifyTypedCPF(val) {
      // Remover caracteres especiais do CPF
      let cpf = val.target.value ? val.target.value.replace(/\D/g, "") : "";

      if (!/^\d{11}$/.test(cpf)) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });
        return false;
      }

      if (/^(\d)\1{10}$/.test(cpf)) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });

        return false;
      }

      if (
        [
          "00000000000",
          "11111111111",
          "00011122233",
          "22222222222",
          "33333333333",
          "44444444444",
          "55555555555",
          "66666666666",
          "77777777777",
          "88888888888",
          "99999999999",
        ].includes(cpf)
      ) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });

        return false;
      }

      return true;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.loading = true;
      this.form.validateFields((err, values) => {
        console.log(err, {
          ...values,
          ...this.preRegisterData,
          role:
            this.preRegisterData.user_profile == "Investidor"
              ? "investor"
              : "user",
          device: navigator.userAgent,
        });

        if (!err) {
          this.$http
            .post("/user/register.php", {
              ...values,
              ...this.preRegisterData,
              role:
                this.preRegisterData.user_profile == "Investidor"
                  ? "investor"
                  : "user",
              device: navigator.userAgent,
            })
            .then(({ data }) => {
              data;
              this.loading = false;
              this.showSuccessModal = true;

              setTimeout(() => {
                this.form.setFieldsValue({
                  first_name: "",
                  last_name: "",
                  email: "",
                  cpf: "",
                  mobile_phone: "",
                  phone: "",
                  cep: "",
                  location_street: "",
                  location_number: "",
                  location_state: "",
                  location_city: "",
                  location_neighborhood: "",
                  location_complement: "",
                });
              }, 500);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
    getLocation() {
      setTimeout(() => {
        if (this.form.getFieldValue("cep")) {
          if (this.form.getFieldValue("cep").replace("-", "").length == 8) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.form.getFieldValue("cep").replace("-", "") +
                  "/json/"
              )
              .then(({ data }) => {
                data;
                this.cepLoading = false;
                this.form.setFieldsValue({
                  location_street: data.logradouro,
                  location_state: data.uf,
                  location_city: data.localidade,
                  location_neighborhood: data.bairro,
                  location_number: data.complemento,
                });
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 110);
    },
    defaultDate() {
      setTimeout(() => {
        this.form.setFieldsValue({
          birthday: this.form.getFieldValue("birthday")
            ? this.form.getFieldValue("birthday")
            : format(subYears(new Date(), 18), "MM/dd/yyyy"),
        });
      }, 1);
    },
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 18);
    },
    closeSuccessModal() {
      setTimeout(() => {
        this.$router.push(`/login`);
      }, 500);
    },
  },
};
</script>

<style lang="sass">
.agree-conectar-login
  height: 20px
  display: block !important
  width: 100%
  line-height: 10px
  padding: 0 !important
  margin: 0 0 -10px
</style>
