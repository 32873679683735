<template>
  <div>
    <a-table
      :columns="columns"
      :locale="{ emptyText: 'Nenhum afiliado cadastrado para este usuário' }"
      :data-source="listUsers"
      :pagination="false"
      :scroll="{ x: 800 }"
    >
      <a slot="id" slot-scope="text, record">{{ record.id }}</a>
      <a slot="first_name" slot-scope="record">
        <a-row :gutter="15" type="flex" justify="start" align="middle">
          <a-col>
            <a-avatar :size="40" :src="record.avatar" icon="user" />
            <a-icon
              v-if="record.role == 'corporate-admin'"
              type="star"
              theme="filled"
            />
          </a-col>
          <a-col>
            <div class="name">
              {{ record.first_name }}
              {{ record.last_name }}
            </div>
            <div class="email">
              {{ record.email }}
            </div>
          </a-col>
        </a-row>
      </a>
      <a slot="mobile_phone" slot-scope="text, record">{{
        record.mobile_phone
      }}</a>
      <a slot="cpf" slot-scope="text, record">{{ record.cpf }}</a>

      <a slot="created" slot-scope="text, record">{{
        record.created | formatDate
      }}</a>
      <a slot="action" slot-scope="text, record">
        <a-tooltip placement="top">
          <template slot="title">
            <span>Ver</span>
          </template>
          <a class="ml-10" @click="viewAffiliate(record.id)"
            ><a-icon type="view-svg"
          /></a>
        </a-tooltip>
      </a>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="pagination.page"
        :page-size.sync="pagination.perPage"
        :total="pagination.total"
        @change="changePage"
        @showSizeChange="changePageSize"
      />
    </div>
  </div>
</template>

<script>
import usersMixins from "@/mixins/users/usersMixins.js";
import { parse, format } from "date-fns";

export default {
  props: {
    affiliateId: String,
  },
  mixins: [usersMixins],
  data() {
    return {
      columns: [
        {
          title: "ID",
          key: "id",
          scopedSlots: { customRender: "id" },
          width: 60
        },
        {
          title: "Nome",
          key: "first_name",
          scopedSlots: { customRender: "first_name" },
          width: 300
        },
        {
          title: "Celular",
          key: "mobile_phone",
          scopedSlots: { customRender: "mobile_phone" },
        },
        {
          title: "Data de cadastro",
          key: "created",
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "right",
          fixed: "right",
          width: 60,
        },
      ],
    };
  },
  filters: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
  mounted() {
    this.filters.createdBy.selected = [this.affiliateId];
    this.filters.status.selected = [1];
    this.filters.isApproved.selected = [1];
    this.pagination.perPage = 10;
    this.getUsers();
  },
  methods: {
    viewAffiliate(id) {
      window.open("/afiliados/ver/" + id, "_blank");
    },
  },
};
</script>

<style lang="sass" scoped></style>
