<template>
    <div class="page">
        <a-page-header class="bg" sub-title=" " @back="() => $router.go(-1)">
            <template slot="backIcon">
                <a-icon type="left-circle" /> Voltar
            </template>
        </a-page-header>
        <!-- 
        <pre> {{company}} </pre> -->

        <a-row id="print" :gutter="25">
            <a-col
                class="gutter-row sidebar"
                :span="$root.windowWidth > 1240 ? 6 : 24"
            >
                <a-row>
                    <a-col class="box a-center bg mb-20" :span="24">
                        <a-avatar
                            class="mt-20 mb-10"
                            :size="150"
                            icon="user"
                            :src="company.logo"
                        />

                        <div class="name">
                            {{ company.trading_name }}
                        </div>

                        <div class="email">{{ company.email }}</div>

                        <div class="rate mb-10">
                            <a-rate v-model="companyRate" disabled>
                                <a-icon slot="character" type="star" />
                            </a-rate>
                        </div>

                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Editar</span>
                            </template>
                            <a @click="edit(company.id)">
                                <a-icon type="edit" />
                            </a>
                        </a-tooltip>
                    </a-col>

                    <a-col class="box bg mb-20" :span="24">
                        <div class="line">
                            <div class="title">Proprietário</div>
                            <div class="text">
                                <a-row type="flex" justify="space-between">
                                    <a-col>
                                        {{ company.owner.first_name }}
                                        {{ company.owner.last_name }}
                                    </a-col>
                                    <a-col>
                                        <a-icon
                                            class="link-btn"
                                            type="login"
                                            @click="
                                                $router.push(
                                                    '/afiliados/ver/' +
                                                        company.owner.id
                                                )
                                            "
                                        />
                                    </a-col>
                                </a-row>
                            </div>
                        </div>
                        <div
                            v-if="company.affiliates.data.length > 0"
                            class="line"
                        >
                            <div class="title">Afiliados</div>
                            <div class="text">
                                {{
                                    company.affiliates.meta.total == "1"
                                        ? company.affiliates.meta.total +
                                          " usuário ativo"
                                        : company.affiliates.meta.total +
                                          " usuários ativos"
                                }}
                            </div>
                        </div>
                    </a-col>

                    <a-col class="box mb-20 extra" :span="24">
                        <a-row type="flex">
                            <a-col :span="24">
                                <a-button
                                    @click="printDocs()"
                                    style="width: 100%"
                                >
                                    <a-icon type="printer" /> </a-button
                            ></a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </a-col>
            <a-col
                class="gutter-row"
                :span="$root.windowWidth > 1240 ? 18 : 24"
            >
                <div class="box tabs bg">
                    <a-tabs default-active-key="data">
                        <a-tab-pane key="data" tab="Dados" forceRender>
                            <h3>Dados da empresa</h3>

                            <a-row class="data-row" :gutter="20">
                                <a-col
                                    :span="$root.windowWidth > 1240 ? 19 : 24"
                                >
                                    <div class="label">Razão social</div>
                                    <div class="value">
                                        {{ company.company_name }}
                                    </div>
                                </a-col>
                                <a-col
                                    :span="$root.windowWidth > 1240 ? 5 : 24"
                                >
                                    <div class="label">CNPJ</div>
                                    <div class="value">
                                        {{ company.cnpj }}
                                    </div>
                                </a-col>
                            </a-row>

                            <h3 class="mt-20">Dados de contato da empresa</h3>

                            <a-row class="data-row" :gutter="20">
                                <a-col
                                    :span="$root.windowWidth > 1240 ? 8 : 24"
                                >
                                    <div class="label">E-mail</div>
                                    <div class="value">
                                        {{ company.email }}
                                    </div>
                                </a-col>
                                <a-col
                                    v-show="company.phone"
                                    :span="$root.windowWidth > 1240 ? 5 : 24"
                                >
                                    <div class="label">Telefone fixo</div>
                                    <div class="value">
                                        {{ company.phone }}
                                    </div>
                                </a-col>
                                <a-col
                                    v-show="company.mobile_phone"
                                    :span="$root.windowWidth > 1240 ? 5 : 24"
                                >
                                    <div class="label">
                                        Telefone celular
                                    </div>
                                    <div class="value">
                                        {{ company.mobile_phone }}
                                    </div>
                                </a-col>
                            </a-row>

                            <h3 class="mt-20" v-show="company.cep">Endereço</h3>

                            <a-row class="data-row" :gutter="20">
                                <a-col
                                    v-show="company.cep"
                                    :span="$root.windowWidth > 1240 ? 5 : 24"
                                >
                                    <div class="label">CEP</div>
                                    <div class="value">
                                        {{ company.cep }}
                                    </div>
                                </a-col>
                                <a-col
                                    v-show="company.location_street"
                                    :span="$root.windowWidth > 1240 ? 19 : 24"
                                >
                                    <div class="label">Endereço</div>
                                    <div class="value">
                                        {{ company.location_street }}
                                    </div>
                                </a-col>
                                <a-col
                                    v-show="company.location_number"
                                    :span="$root.windowWidth > 1240 ? 3 : 24"
                                >
                                    <div class="label">Número</div>
                                    <div class="value">
                                        {{ company.location_number }}
                                    </div>
                                </a-col>
                                <a-col
                                    v-show="company.location_complement"
                                    :span="$root.windowWidth > 1240 ? 7 : 24"
                                >
                                    <div class="label">Complemento</div>
                                    <div class="value">
                                        {{ company.location_complement }}
                                    </div>
                                </a-col>
                                <a-col
                                    v-show="company.location_neighborhood"
                                    :span="$root.windowWidth > 1240 ? 5 : 24"
                                >
                                    <div class="label">Bairro</div>
                                    <div class="value">
                                        {{ company.location_neighborhood }}
                                    </div>
                                </a-col>
                                <a-col
                                    v-show="company.location_city"
                                    :span="$root.windowWidth > 1240 ? 5 : 24"
                                >
                                    <div class="label">Cidade</div>
                                    <div class="value">
                                        {{ company.location_city }}
                                    </div>
                                </a-col>
                                <a-col
                                    v-show="company.location_state"
                                    :span="$root.windowWidth > 1240 ? 4 : 24"
                                >
                                    <div class="label">Estado</div>
                                    <div class="value">
                                        {{ company.location_state }}
                                    </div>
                                </a-col>
                            </a-row>
                        </a-tab-pane>

                        <a-tab-pane
                            key="affiliates"
                            tab="Afiliados"
                            force-render
                        >
                            <a-table
                                v-if="company.affiliates.data.length > 0"
                                :columns="columns"
                                :data-source="company.affiliates.data"
                                :pagination="false"
                            >
                                <a slot="id" slot-scope="text, record">{{
                                    record.id
                                }}</a>
                                <a slot="first_name" slot-scope="text, record">
                                    <a-row
                                        :gutter="15"
                                        type="flex"
                                        justify="start"
                                        align="middle"
                                    >
                                        <a-col>
                                            <a-avatar :size="54" icon="user" />
                                        </a-col>
                                        <a-col>
                                            <div class="name">
                                                {{ record.first_name }}
                                                {{ record.last_name }}
                                            </div>
                                            <div class="email">
                                                {{ record.email }}
                                            </div>
                                        </a-col>
                                    </a-row>
                                </a>
                                <a
                                    slot="mobile_phone"
                                    slot-scope="text, record"
                                    >{{ record.mobile_phone }}</a
                                >
                                <a slot="cpf" slot-scope="text, record">{{
                                    record.cpf
                                }}</a>

                                <a slot="created" slot-scope="text, record">{{
                                    record.created | formatDate
                                }}</a>
                                <a slot="action" slot-scope="text, record">
                                    <a-tooltip placement="top">
                                        <template slot="title">
                                            <span>Ver</span>
                                        </template>
                                        <a
                                            class="ml-10"
                                            @click="view(record.id)"
                                            ><a-icon type="view-svg"
                                        /></a>
                                    </a-tooltip>
                                </a>
                            </a-table>
                            <div v-if="company.affiliates.data.length == 0">
                                Nenhum afiliado até o momento.
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { parse, format } from "date-fns";
import capabilities from "../../mixins/general/capabilities.js";

export default {
    mixins: [capabilities],
    components: {},
    filters: {
        formatDate(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
    },
    data() {
        return {
            form: this.$form.createForm(this),
            company: {},
            companyRate: 0,
            theLogo: "",
            columns: [
                {
                    title: "ID",
                    key: "id",
                    scopedSlots: { customRender: "id" },
                },
                {
                    title: "Nome",
                    key: "first_name",
                    scopedSlots: { customRender: "first_name" },
                },
                {
                    title: "Celular",
                    key: "mobile_phone",
                    scopedSlots: { customRender: "mobile_phone" },
                },
                {
                    title: "CPF",
                    key: "cpf",
                    scopedSlots: { customRender: "cpf" },
                },

                {
                    title: "Data de cadastro",
                    key: "created",
                    scopedSlots: { customRender: "created" },
                },
                {
                    title: "",
                    key: "action",
                    scopedSlots: { customRender: "action" },
                    align: "right",
                },
            ],
        };
    },
    beforeMount() {
        setTimeout(() => {
            let body = document.body;
            body.classList.add("conectar");
            body.classList.add("users");
            body.classList.add("edit");
        }, 20);

        if (
            !this.$root.checkCapabilities(
                this.capabilities,
                "companies",
                "view"
            )
        ) {
            this.$router.push("/");
        }

        this.getCompany();
    },
    beforeDestroy() {
        let body = document.body;
        body.classList.remove("users");
        body.classList.remove("edit");
    },
    methods: {
        getCompany() {
            this.$http
                .post("/company/details.php", {
                    id: this.$route.params.id,
                })
                .then(({ data }) => {
                    this.company = data;
                    this.companyRate =
                        this.company.meta.rate != undefined
                            ? `${this.company.meta.rate}`
                            : "0";
                    document.title = `${data.id} - ${data.trading_name} - Conectar`;
                })
                .catch(({ response }) => {
                    response;
                });
        },
        formatDateTimeline(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            theDate = format(theDate, "dd/MM/yyyy HH:mm");

            return `${theDate.split(" ")[0]} <br> ${theDate.split(" ")[1]} `;
        },
        logColor(log) {
            let logColor = "green";

            if (log.action == "loggedin") {
                logColor = "green";
            }

            if (log.action == "loggedout") {
                logColor = "purple";
            }

            return logColor;
        },
        printDocs() {
            document.title = `${this.company.id} - ${this.company.first_name}`;
            window.print();
            document.title = `${this.company.id} - ${this.company.first_name}`;
        },
        edit(id) {
            this.$router.push(
                `/${this.$route.fullPath.split("/")[1]}/editar/${id}`
            );
        },
        view(id) {
            //this.$router.push("/afiliados/ver/" + id);
            setTimeout(() => {
                window.open("/afiliados/ver/" + id, "_blank");
            }, 500);
        },
    },
};
</script>

<style>
@media print {
    .ant-layout-sider,
    header.ant-layout-header,
    .ant-page-header,
    .box.extra,
    .update-btn {
        display: none !important;
    }

    .sidebar.ant-col,
    .gutter-row.ant-col.ant-col-18 {
        width: 100%;
    }

    .conectar.edit .box.bg {
        box-shadow: none !important;
        border: 1px solid #eee;
    }
}
</style>
