<template>
    <div class="page">
        <div v-if="$route.params.action === 'editar'">
            <edit-company />
        </div>
        <div v-if="$route.params.action === 'ver'">
            <view-company />
        </div>
    </div>
</template>

<script>
import EditCompany from "../../components/companies/EditCompany.vue";
import ViewCompany from "../../components/companies/ViewCompany.vue";

export default {
    components: { EditCompany, ViewCompany },
};
</script>
