<template>
  <div>
    <a-form-item class="travel-avatar-wrapper mb-0">
      <a-upload
        class="avatar-uploader "
        name="file"
        :customRequest="uploadAvatar"
        :file-list="false"
        list-type="picture-card"
        :multiple="false"
        v-decorator="[
          'avatar',
          {
            initialValue: user.avatar,
            rules: [
              {
                required: page == 'edit-user' && $root.isAdmin() ? false : true,
                message: 'Obrigatório',
              },
            ],
          },
        ]"
      >
        <div class="image-uploader-container">
          <a-avatar v-if="file" :src="file" :size="102" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </div>
      </a-upload>

      <a-button
        v-if="file"
        type="danger"
        ghost
        class="remove"
        shape="circle"
        @click="removeAvatar"
      >
        <a-icon type="delete" />
      </a-button>
    </a-form-item>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    form: Object,
    user: Object,
    page: String,
    edit: Boolean,
  },
  mounted() {
    setTimeout(() => {
      this.file = this.user.avatar ? this.user.avatar.split("?id=")[0] : "";
      this.fileId = this.user.avatar ? this.user.avatar.split("?id=")[1] : 0;
    }, 500);
  },
  data() {
    return {
      file: "",
      fileId: 0,
      loading: false,
    };
  },
  methods: {
    uploadAvatar(file) {
      this.loading = true;
      const formData = new FormData();
      formData.append("file[]", file.file);
      const data = formData;

      this.$http
        .post(
          `/media/upload.php?folder=public&user_id=${this.currentUserId()}&is_profile=true&allowed_formats=jpg,jpeg,png`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(({ data }) => {
          this.$message.success(data.message);
          this.loading = false;

          if (this.$store.state.userData.id == this.user.id) {
            let localUserData = {};
            localUserData.email = this.user.email;
            localUserData.first_name = this.user.first_name;
            localUserData.last_name = this.user.last_name;
            localUserData.cpf = this.user.cpf;
            localUserData.id = this.user.id;
            localUserData.role = this.user.role;
            localUserData.avatar = data.url;

            localStorage.setItem("userData", JSON.stringify(localUserData));
          }

          setTimeout(() => {
            this.file = data.url + "?id=" + data.id;
            this.form.setFieldsValue({
              avatar: this.file,
            });
          }, 50);

          setTimeout(() => {
            window.open(window.location.href, "_self");
          }, 100);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loading = false;
        });
    },
    currentUserId() {
      let userId = this.$store.state.userData.id;
      if (userId != this.user.id) userId = this.user.id;
      return userId;
    },
    removeAvatar() {
      this.$http
        .post("/media/delete.php", {
          id: this.fileId,
          url: this.file,
          user_id: this.currentUserId(),
          is_profile: true,
        })
        .then(({ data }) => {
          this.fileId = 0;
          this.file = "";
          this.form.setFieldsValue({
            avatar: undefined,
          });

          if (this.$store.state.userData.id == this.user.id) {
            let localUserData = {};
            localUserData.email = this.user.email;
            localUserData.first_name = this.user.first_name;
            localUserData.last_name = this.user.last_name;
            localUserData.cpf = this.user.cpf;
            localUserData.id = this.user.id;
            localUserData.role = this.user.role;
            localUserData.avatar = data.url;

            localStorage.setItem("userData", JSON.stringify(localUserData));
          }

          setTimeout(() => {
            window.open(window.location.href, "_self");
          }, 100);
        });
    },
  },
};
</script>
