<template>
  <div class="page">
    <div v-if="$route.params.action === 'aguardando-aprovacao'">
      <ListUnnaprovedUsers />
    </div>

    <div v-if="$route.params.action === 'lista'">
      <ListUsers />
    </div>

    <div v-if="$route.params.action === 'investidores'">
      <ListInvestors />
    </div>

    <div v-if="$route.params.action === 'proprietarios-terreno'">
      <ListPropertiesOwners />
    </div>

    <div v-if="$route.params.action === 'intermediadores-negocios'">
      <ListItermediatorsUsers />
    </div>

    <div v-if="$route.params.action === 'gestores-regionais'">
      <ListBusinessManagers />
    </div>

    <div v-if="$route.params.action === 'editar'">
      <EditUsers />
    </div>

    <div v-if="$route.params.action === 'ver'">
      <ViewUsers />
    </div>

    <div v-if="$route.params.action === 'administradores'">
      <ListAdminUsers />
    </div>
  </div>
</template>

<script>
import EditUsers from "@/components/users/EditUsers.vue";
import ListAdminUsers from "@/components/users/ListAdminUsers.vue";
import ListUnnaprovedUsers from "@/components/users/ListUnnaprovedUsers.vue";
import ListUsers from "@/components/users/ListUsers.vue";
import ListInvestors from "@/components/users/ListInvestors.vue";
import ListBusinessManagers from "@/components/users/ListBusinessManagers.vue";
import ListItermediatorsUsers from "@/components/users/ListItermediatorsUsers.vue";
import ListPropertiesOwners from "@/components/users/ListPropertiesOwners.vue";
import ViewUsers from "@/components/users/ViewUsers.vue";

export default {
  components: {
    ListBusinessManagers,
    ListItermediatorsUsers,
    ListPropertiesOwners,
    ListUnnaprovedUsers,
    ListUsers,
    ListInvestors,
    ListAdminUsers,
    EditUsers,
    ViewUsers,
  },
};
</script>
