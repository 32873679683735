<template>
  <div>
    <h3>Dados pessoais</h3>

    <a-row class="data-row" :gutter="20">
      <a-col :span="$root.windowWidth > 961 ? 5 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Nome</label>
          <a-input
            :disabled="$root.isAdmin() ? false : true"
            class="underline-input"
            placeholder="Nome"
            v-decorator="[
              'first_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório.',
                  },
                ],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 5 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Sobrenome</label>
          <a-input
            :disabled="$root.isAdmin() ? false : true"
            class="underline-input"
            placeholder="Nome"
            v-decorator="[
              'last_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório.',
                  },
                ],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 4 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">CPF</label>
          <a-input
            :disabled="$root.isAdmin() ? false : true"
            class="underline-input"
            placeholder="CPF"
            v-mask="'###.###.###-##'"
            @blur="verifyTypedCPF"
            v-decorator="[
              'cpf',
              {
                rules: [
                  {
                    required: true,
                    min: 9,
                    message: 'Preencha todos números.',
                  },
                ],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 3 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">RG</label>
          <a-input
            :disabled="$root.isAdmin() ? false : true"
            class="underline-input"
            placeholder="RG"
            v-mask="['##.###.###', '#.###.###']"
            v-decorator="[
              'rg',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                  {
                    min: 9,
                    message: 'Preencha todos números',
                  },
                ],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 4 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Órgão Emissor</label>
          <a-input
            class="underline-input"
            placeholder="Órgão Emissor"
            v-decorator="[
              'rg_doc_emission',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="$root.windowWidth > 961 ? 3 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Estado Emissor</label>
          <a-select
            class="underline-input"
            default-value=""
            show-search
            placeholder="ES"
            v-decorator="[
              'rg_doc_emission_state',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="$root.windowWidth > 961 ? 4 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Nascimento</label>
          <a-date-picker
            :disabled="$root.isAdmin() ? false : true"
            class="underline-input"
            placeholder="Nascimento"
            format="DD/MM/YYYY"
            v-mask="'##/##/####'"
            :showToday="false"
            :disabled-date="adultHoodDate"
            @openChange="defaultDate"
            v-decorator="[
              'birthday',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-date-picker>
        </a-form-item>
      </a-col>

      <a-col :span="$root.windowWidth > 961 ? 6 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Profissão</label>
          <a-select
            class="underline-input"
            placeholder="Profissão"
            show-search
            v-decorator="[
              'profession',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório.',
                  },
                ],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) in professions"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <h3>Dados de contato</h3>

    <a-row class="data-row" :gutter="20">
      <a-col :span="$root.windowWidth > 961 ? 10 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">E-mail</label>
          <a-input
            class="underline-input"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido.',
                  },
                  {
                    required: true,
                    message: 'Escreva seu e-mail no campo.',
                  },
                ],
              },
            ]"
            placeholder="E-mail"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 5 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Telefone celular</label>
          <a-input
            class="underline-input"
            placeholder="Telefone celular"
            v-mask="['(##) # ####-####']"
            @blur="validatePhoneNumber"
            v-decorator="[
              'mobile_phone',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 4 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Telefone fixo</label>
          <a-input
            class="underline-input"
            placeholder="Telefone fixo"
            v-mask="['(##) ####-####']"
            v-decorator="['phone']"
          >
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>

    <h3>Endereço</h3>

    <a-row class="data-row" :gutter="20">
      <a-col :span="$root.windowWidth > 961 ? 5 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">CEP</label>
          <a-input
            class="underline-input"
            placeholder="CEP"
            v-mask="['#####-###']"
            v-decorator="[
              'cep',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
            @keyup="getLocation()"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 19 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Endereço</label>
          <a-input
            class="underline-input"
            placeholder="Endereço"
            v-decorator="[
              'location_street',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 3 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Número</label>
          <a-input
            class="underline-input"
            placeholder="Número"
            v-decorator="[
              'location_number',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 7 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Complemento</label>
          <a-input
            class="underline-input"
            placeholder="Complemento"
            v-decorator="['location_complement']"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 5 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Bairro</label>
          <a-input
            class="underline-input"
            placeholder="Bairro"
            v-decorator="[
              'location_neighborhood',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 5 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Cidade</label>
          <a-input
            class="underline-input"
            placeholder="Cidade"
            v-decorator="[
              'location_city',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="$root.windowWidth > 961 ? 4 : 24">
        <a-form-item class="mb-10 underline-wrapper">
          <label class="label">Estado</label>
          <a-select
            class="underline-input"
            show-search
            placeholder="ES"
            v-decorator="[
              'location_state',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { format, parse } from "date-fns";
import subYears from "date-fns/subYears";
import axios from "axios";
import professions from "../../../lists/professions.json";
import states from "../../../lists/states.json";

export default {
  name: "DefaultDataForm",
  props: {
    user: Object,
    form: Object,
  },
  filters: {
    formatDate(date) {
      if (date) {
        let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
        return format(theDate, "dd/MM/yyyy");
      }
    },
  },
  data() {
    return {
      states,
      professions,
    };
  },
  methods: {
    validatePhoneNumber(val) {
      // Remove non-numeric characters from the phone number
      let phoneNumber = val.target.value
        ? val.target.value.replace(/\D/g, "")
        : "";

      // Check if the phone number has the correct length
      if (phoneNumber.length !== 11) {
        this.form.setFieldsValue({
          [`mobile_phone`]: undefined,
        });
        return false;
      }

      // Check if the phone number has repeated digits
      if (
        [
          "00000000000",
          "11111111111",
          "22222222222",
          "33333333333",
          "44444444444",
          "55555555555",
          "66666666666",
          "77777777777",
          "88888888888",
          "99999999999",
        ].includes(phoneNumber)
      ) {
        this.form.setFieldsValue({
          [`mobile_phone`]: undefined,
        });
        return false;
      }

      // Phone number is valid
      return true;
    },
    verifyTypedCPF(val) {
      // Remover caracteres especiais do CPF
      let cpf = val.target.value ? val.target.value.replace(/\D/g, "") : "";

      if (!/^\d{11}$/.test(cpf)) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });
        return false;
      }

      if (/^(\d)\1{10}$/.test(cpf)) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });

        return false;
      }

      if (
        [
          "00000000000",
          "11111111111",
          "00011122233",
          "22222222222",
          "33333333333",
          "44444444444",
          "55555555555",
          "66666666666",
          "77777777777",
          "88888888888",
          "99999999999",
        ].includes(cpf)
      ) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });

        return false;
      }

      return true;
    },
    getLocation() {
      setTimeout(() => {
        if (this.form.getFieldValue("cep")) {
          if (this.form.getFieldValue("cep").replace("-", "").length == 8) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.form.getFieldValue("cep").replace("-", "") +
                  "/json/"
              )
              .then(({ data }) => {
                data;
                this.cepLoading = false;
                this.form.setFieldsValue({
                  location_street: data.logradouro,
                  location_state: data.uf,
                  location_city: data.localidade,
                  location_neighborhood: data.bairro,
                  location_complement: data.complemento,
                });
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 10);
    },
    defaultDate() {
      setTimeout(() => {
        this.form.setFieldsValue({
          birthday: this.form.getFieldValue("birthday")
            ? this.form.getFieldValue("birthday")
            : format(subYears(new Date(), 18), "MM/dd/yyyy"),
        });
      }, 1);
    },
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 18);
    },
  },
};
</script>
