export default {
    props: {

    },
    data() {
        return {
            registration_docs: {
                list: [],
                file: [],
                loading: false
            },
            kmz: {
                list: [],
                file: [],
                loading: false
            },
            pictures: {
                list: [],
                file: [],
                loading: false
            },
            topography: {
                list: [],
                file: [],
                loading: false
            },
            iptu: {
                list: [],
                file: [],
                loading: false
            },
        }
    },
    methods: {
        uploadRegistrationFile(file) {

            this.registration_docs.loading = true;
            const formData = new FormData();
            formData.append("file[]", file.file);
            const data = formData;

            this.$http
                .post(
                    `/media/upload.php?folder=private&user_id=${this.$store.state.userData.id}&allowed_formats=jpg,jpeg,png,gif,pdf`,
                    data, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then(({
                    data
                }) => {
                    this.$message.success(data.message);
                    this.registration_docs.loading = false;

                    setTimeout(() => {
                        this.registration_docs.file.push({
                            id: data.id,
                            url: data.url,
                            name: data.name,
                            uid: file.file.uid,
                        });

                        this.registration_docs.list.push(file.file);

                        if (this.form3 != undefined) {

                            this.form3.setFieldsValue({
                                area_registration_docs: JSON.stringify(this.registration_docs.file),
                            });

                        }

                        if (this.editPropertyForm != undefined) {

                            this.editPropertyForm.setFieldsValue({
                                area_registration_docs: JSON.stringify(this.registration_docs.file),
                            });

                        }

                    }, 50);
                })
                .catch(({
                    response
                }) => {
                    this.$message.error(response.data.message);
                    this.registration_docs.loading = false;
                });
        },
        removeRegistrationFile(file) {

            console.log(file, this.registration_docs.file, this.form3);

            for (var i in this.registration_docs.file) {
                if (this.registration_docs.file[i].uid == file.uid) {
                    this.$http
                        .post("/media/delete.php", {
                            id: this.registration_docs.file[i].id,
                            url: this.registration_docs.file[i].url,
                        })
                        .then(({
                            data
                        }) => {
                            data;
                        })
                        .catch(({
                            response
                        }) => {
                            response;
                        });
                    this.registration_docs.file.splice(i, 1);
                    this.registration_docs.list.splice(i, 1);
                    break;
                }
            }

            if (this.editPropertyForm != undefined) {

                setTimeout(() => {
                    if (this.registration_docs.file.length > 0) {
                        this.editPropertyForm.setFieldsValue({
                            area_registration_docs: JSON.stringify(this.registration_docs.file),
                        });
                    } else {
                        this.editPropertyForm.setFieldsValue({
                            area_registration_docs: undefined,
                        });
                    }
                }, 50);

            }

            if (this.form3 != undefined) {

                setTimeout(() => {
                    if (this.registration_docs.file.length > 0) {
                        this.form3.setFieldsValue({
                            area_registration_docs: JSON.stringify(this.registration_docs.file),
                        });
                    } else {
                        this.form3.setFieldsValue({
                            area_registration_docs: undefined,
                        });
                    }
                }, 50);

            }
        },
        uploadKMZFile(file) {

            this.kmz.loading = true;
            const formData = new FormData();
            formData.append("file[]", file.file);
            const data = formData;

            this.$http
                .post(
                    `/media/upload.php?folder=public&user_id=${this.$store.state.userData.id}&allowed_formats=kmz`,
                    data, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then(({
                    data
                }) => {
                    this.$message.success(data.message);
                    this.kmz.loading = false;

                    setTimeout(() => {
                        this.kmz.file.push({
                            id: data.id,
                            url: data.url,
                            name: data.name,
                            uid: file.file.uid,
                        });

                        this.kmz.list.push(file.file);

                        if (this.editPropertyForm != undefined) {
                            this.editPropertyForm.setFieldsValue({
                                kmz_file: JSON.stringify(this.kmz.file),
                            });
                        }

                        if (this.form3 != undefined) {
                            this.form3.setFieldsValue({
                                kmz_file: JSON.stringify(this.kmz.file),
                            });
                        }


                    }, 50);
                })
                .catch(({
                    response
                }) => {
                    this.$message.error(response.data.message);
                    this.kmz.loading = false;
                });
        },
        removeKMZFile(file) {
            for (var i in this.kmz.file) {
                if (this.kmz.file[i].uid == file.uid) {
                    this.$http
                        .post("/media/delete.php", {
                            id: this.kmz.file[i].id,
                            url: this.kmz.file[i].url,
                        })
                        .then(({
                            data
                        }) => {
                            data;
                        })
                        .catch(({
                            response
                        }) => {
                            response;
                        });
                    this.kmz.file.splice(i, 1);
                    this.kmz.list.splice(i, 1);
                    break;
                }
            }

            if (this.editPropertyForm != undefined) {
                setTimeout(() => {
                    if (this.kmz.file.length > 0) {
                        this.editPropertyForm.setFieldsValue({
                            kmz_file: JSON.stringify(this.kmz.file),
                        });
                    } else {
                        this.editPropertyForm.setFieldsValue({
                            kmz_file: undefined,
                        });
                    }
                }, 50);
            }

            if (this.form3 != undefined) {
                setTimeout(() => {
                    if (this.kmz.file.length > 0) {
                        this.form3.setFieldsValue({
                            kmz_file: JSON.stringify(this.kmz.file),
                        });
                    } else {
                        this.form3.setFieldsValue({
                            kmz_file: undefined,
                        });
                    }
                }, 50);
            }
        },
        uploadPicturesFile(file) {

            this.pictures.loading = true;
            const formData = new FormData();
            formData.append("file[]", file.file);
            const data = formData;

            this.$http
                .post(
                    `/media/upload.php?folder=private&user_id=${this.$store.state.userData.id}&allowed_formats=jpg,jpeg,png,pdf`,
                    data, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then(({
                    data
                }) => {
                    this.$message.success(data.message);
                    this.pictures.loading = false;

                    setTimeout(() => {
                        this.pictures.file.push({
                            id: data.id,
                            url: data.url,
                            name: data.name,
                            uid: file.file.uid,
                        });

                        this.pictures.list.push(file.file);

                        if (this.editPropertyForm != undefined) {
                            this.editPropertyForm.setFieldsValue({
                                property_pictures: JSON.stringify(this.pictures.file),
                            });
                        }

                        if (this.form3 != undefined) {
                            this.form3.setFieldsValue({
                                property_pictures: JSON.stringify(this.pictures.file),
                            });
                        }

                    }, 50);
                })
                .catch(({
                    response
                }) => {
                    this.$message.error(response.data.message);
                    this.pictures.loading = false;
                });
        },
        removePicturesFile(file) {
            for (var i in this.pictures.file) {
                if (this.pictures.file[i].uid == file.uid) {
                    this.$http
                        .post("/media/delete.php", {
                            id: this.pictures.file[i].id,
                            url: this.pictures.file[i].url,
                        })
                        .then(({
                            data
                        }) => {
                            data;
                        })
                        .catch(({
                            response
                        }) => {
                            response;
                        });
                    this.pictures.file.splice(i, 1);
                    this.pictures.list.splice(i, 1);
                    break;
                }
            }

            if (this.editPropertyForm != undefined) {
                setTimeout(() => {
                    if (this.pictures.file.length > 0) {

                        this.editPropertyForm.setFieldsValue({
                            property_pictures: JSON.stringify(this.pictures.file),
                        });
                    } else {
                        this.editPropertyForm.setFieldsValue({
                            property_pictures: undefined,
                        });
                    }
                }, 50);
            }

            if (this.form3 != undefined) {
                setTimeout(() => {
                    if (this.pictures.file.length > 0) {

                        this.form3.setFieldsValue({
                            property_pictures: JSON.stringify(this.pictures.file),
                        });
                    } else {
                        this.form3.setFieldsValue({
                            property_pictures: undefined,
                        });
                    }
                }, 50);
            }
        },
        uploadTopographyFile(file) {

            this.topography.loading = true;
            const formData = new FormData();
            formData.append("file[]", file.file);
            const data = formData;

            this.$http
                .post(
                    `/media/upload.php?folder=private&user_id=${this.$store.state.userData.id}&allowed_formats=jpg,jpeg,png,gif,pdf`,
                    data, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then(({
                    data
                }) => {
                    this.$message.success(data.message);
                    this.topography.loading = false;

                    setTimeout(() => {
                        this.topography.file.push({
                            id: data.id,
                            url: data.url,
                            name: data.name,
                            uid: file.file.uid,
                        });

                        this.topography.list.push(file.file);

                        if (this.editPropertyForm != undefined) {
                            this.editPropertyForm.setFieldsValue({
                                topography_docs: JSON.stringify(this.topography.file),
                            });
                        }

                        if (this.form3 != undefined) {
                            this.form3.setFieldsValue({
                                topography_docs: JSON.stringify(this.topography.file),
                            });
                        }

                    }, 50);
                })
                .catch(({
                    response
                }) => {
                    this.$message.error(response.data.message);
                    this.topography.loading = false;
                });
        },
        removeTopographyFile(file) {
            for (var i in this.topography.file) {
                if (this.topography.file[i].uid == file.uid) {
                    this.$http
                        .post("/media/delete.php", {
                            id: this.topography.file[i].id,
                            url: this.topography.file[i].url,
                        })
                        .then(({
                            data
                        }) => {
                            data;
                        })
                        .catch(({
                            response
                        }) => {
                            response;
                        });
                    this.topography.file.splice(i, 1);
                    this.topography.list.splice(i, 1);
                    break;
                }
            }

            if (this.editPropertyForm != undefined) {
                setTimeout(() => {
                    if (this.topography.file.length > 0) {

                        this.editPropertyForm.setFieldsValue({
                            topography_docs: JSON.stringify(this.topography.file),
                        });
                    } else {
                        this.editPropertyForm.setFieldsValue({
                            topography_docs: undefined,
                        });
                    }
                }, 50);
            }

            if (this.form3 != undefined) {
                setTimeout(() => {
                    if (this.topography.file.length > 0) {

                        this.form3.setFieldsValue({
                            topography_docs: JSON.stringify(this.topography.file),
                        });
                    } else {
                        this.form3.setFieldsValue({
                            topography_docs: undefined,
                        });
                    }
                }, 50);
            }
        },
        uploadIPTUFile(file) {

            this.iptu.loading = true;
            const formData = new FormData();
            formData.append("file[]", file.file);
            const data = formData;

            this.$http
                .post(
                    `/media/upload.php?folder=private&user_id=${this.$store.state.userData.id}&allowed_formats=jpg,jpeg,png,gif,pdf`,
                    data, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then(({
                    data
                }) => {
                    this.$message.success(data.message);
                    this.iptu.loading = false;

                    setTimeout(() => {
                        this.iptu.file.push({
                            id: data.id,
                            url: data.url,
                            name: data.name,
                            uid: file.file.uid,
                        });

                        this.iptu.list.push(file.file);

                        if (this.editPropertyForm != undefined) {
                            this.editPropertyForm.setFieldsValue({
                                iptu_docs: JSON.stringify(this.iptu.file),
                            });
                        }

                        if (this.form3 != undefined) {
                            this.form3.setFieldsValue({
                                iptu_docs: JSON.stringify(this.iptu.file),
                            });
                        }

                    }, 50);
                })
                .catch(({
                    response
                }) => {
                    this.$message.error(response.data.message);
                    this.iptu.loading = false;
                });
        },
        removeIPTUFile(file) {
            for (var i in this.iptu.file) {
                if (this.iptu.file[i].uid == file.uid) {
                    this.$http
                        .post("/media/delete.php", {
                            id: this.iptu.file[i].id,
                            url: this.iptu.file[i].url,
                        })
                        .then(({
                            data
                        }) => {
                            data;
                        })
                        .catch(({
                            response
                        }) => {
                            response;
                        });
                    this.iptu.file.splice(i, 1);
                    this.iptu.list.splice(i, 1);
                    break;
                }
            }

            if (this.editPropertyForm != undefined) {
                setTimeout(() => {
                    if (this.iptu.file.length > 0) {
                        this.editPropertyForm.setFieldsValue({
                            iptu_docs: JSON.stringify(this.iptu.file),
                        });
                    } else {
                        this.editPropertyForm.setFieldsValue({
                            iptu_docs: undefined,
                        });
                    }
                }, 50);
            }

            if (this.form3 != undefined) {
                setTimeout(() => {
                    if (this.iptu.file.length > 0) {
                        this.form3.setFieldsValue({
                            iptu_docs: JSON.stringify(this.iptu.file),
                        });
                    } else {
                        this.form3.setFieldsValue({
                            iptu_docs: undefined,
                        });
                    }
                }, 50);
            }
        },
    }
}