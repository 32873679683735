<template>
  <a-form :form="form" @submit="handleSubmit">
    <a-row v-if="registerCompany.activeStep > 1">
      <a-col class="company-user mb-10 a-center" :span="24">
        <strong class="cprimary f16">
          <a-icon type="user" class="mr-10" />

          <font class="txt1"
            >{{ this.form.getFieldValue("trading_name") }}
          </font>

          <font v-if="this.form.getFieldValue('first_name')" class="txt2">
            - {{ this.form.getFieldValue("first_name") }}
            {{ this.form.getFieldValue("last_name") }}
          </font>
        </strong>
      </a-col>
    </a-row>

    <a-progress
      class="mb-20"
      :stroke-color="{
        '0%': '#1d3557',
        '100%': '#457b9d',
      }"
      :percent="registerCompany.percents"
    />

    <a-row :gutter="16" v-show="registerCompany.activeStep == '1'">
      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">CNPJ</label>
          <a-input
            class="underline-input"
            placeholder="CNPJ"
            v-mask="'##.###.###/####-##'"
            v-decorator="[
              'cnpj',
              {
                rules: [
                  {
                    required: validateFirstStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="right-circle"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Razão Social</label>
          <a-input
            class="underline-input"
            placeholder="Razão Social"
            v-decorator="[
              'company_name',
              {
                rules: [
                  {
                    required: validateFirstStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="right-circle"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Nome Fantasia</label>
          <a-input
            class="underline-input"
            placeholder="Nome Fantasia"
            v-decorator="[
              'trading_name',
              {
                rules: [
                  {
                    required: validateFirstStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="right-circle"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Telefone fixo</label>
          <a-input
            class="underline-input"
            placeholder="Telefone fixo"
            v-mask="['(##) ####-####']"
            v-decorator="[
              'company_phone',
              {
                rules: [
                  {
                    required: validateFirstStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="phone"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">E-mail da Empresa </label>
          <a-input
            class="underline-input"
            v-decorator="[
              'company_email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido.',
                  },
                  {
                    required: validateFirstStep(),
                    message: 'Escreva seu e-mail no campo.',
                  },
                ],
              },
            ]"
            placeholder="E-mail da Empresa"
            value=""
          >
            <a-icon
              slot="prefix"
              type="mail"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">CEP</label>
          <a-input
            class="underline-input"
            placeholder="CEP"
            v-mask="['#####-###']"
            v-decorator="[
              'company_cep',
              {
                rules: [
                  {
                    required: validateFirstStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            @keyup="getLocationCompany()"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Endereço</label>
          <a-input
            class="underline-input"
            placeholder="Endereço"
            v-decorator="[
              'company_location_street',
              {
                rules: [
                  {
                    required: validateFirstStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Número</label>
          <a-input
            class="underline-input"
            placeholder="Número"
            v-decorator="[
              'company_location_number',
              {
                rules: [
                  {
                    required: validateFirstStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="16">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Bairro</label>
          <a-input
            class="underline-input"
            placeholder="Bairro"
            v-decorator="[
              'company_location_neighborhood',
              {
                rules: [
                  {
                    required: validateFirstStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="18">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Cidade</label>
          <a-input
            class="underline-input"
            placeholder="Cidade"
            v-decorator="[
              'company_location_city',
              {
                rules: [
                  {
                    required: validateFirstStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Estado</label>
          <a-select
            class="underline-input"
            show-search
            placeholder="ES"
            v-decorator="[
              'company_location_state',
              {
                rules: [
                  {
                    required: validateFirstStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Complemento</label>
          <a-input
            class="underline-input"
            placeholder="Complemento"
            v-decorator="['company_location_complement']"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col class="mb-40" :span="24">
        <a-button
          class="mt-20"
          type="primary"
          shape="round"
          size="large"
          @click="goToSecondStep"
          style="width: 100%"
          ghost
        >
          Próximo
        </a-button>
      </a-col>
    </a-row>

    <a-row :gutter="16" v-show="registerCompany.activeStep == '2'">
      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Primeiro nome</label>
          <a-input
            class="underline-input"
            placeholder="Primeiro nome"
            v-decorator="[
              'first_name',
              {
                rules: [
                  {
                    required: validateSecondStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="right-circle"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Sobrenome</label>
          <a-input
            class="underline-input"
            placeholder="Sobrenome"
            v-decorator="[
              'last_name',
              {
                rules: [
                  {
                    required: validateSecondStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="right-circle"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">CPF</label>
          <a-input
            class="underline-input"
            placeholder="Insira"
            v-mask="'###.###.###-##'"
            @blur="verifyTypedCPF"
            v-decorator="[
              'cpf',
              {
                rules: [
                  {
                    required: validateSecondStep(),
                    min: 9,
                    message: 'Preencha todos números.',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="idcard"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Nascimento</label>

          <a-date-picker
            class="underline-input"
            placeholder="Insira"
            format="DD/MM/YYYY"
            v-mask="'##/##/####'"
            :showToday="false"
            :disabled-date="adultHoodDate"
            @openChange="defaultDate"
            v-decorator="[
              'birthday',
              {
                rules: [
                  {
                    required: validateSecondStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-date-picker>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Profissão</label>

          <a-select
            class="underline-input"
            placeholder="Profissão"
            show-search
            v-decorator="[
              'profession',
              {
                rules: [
                  {
                    required: validateSecondStep(),
                    message: 'Obrigatório.',
                  },
                ],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) in professions"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="9">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">RG</label>
          <a-input
            class="underline-input"
            placeholder="RG"
            v-mask="['##.###.###', '#.###.###', '###.###.###-##']"
            v-decorator="[
              'rg',
              {
                rules: [
                  {
                    required: validateSecondStep(),
                    message: 'Obrigatório',
                  },
                  {
                    min: 9,
                    message: 'Preencha todos números',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="idcard"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="9">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Orgão Emissor</label>
          <a-input
            class="underline-input"
            placeholder="Órgão Emissor"
            v-decorator="[
              'rg_doc_emission',
              {
                rules: [
                  {
                    required: validateSecondStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="idcard"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Estado</label>
          <a-select
            class="underline-input"
            show-search
            placeholder="ES"
            v-decorator="[
              'rg_doc_emission_state',
              {
                rules: [
                  {
                    required: validateSecondStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Telefone celular</label>
          <a-input
            class="underline-input"
            placeholder="Telefone celular"
            v-mask="['(##) # ####-####']"
            @blur="validatePhoneNumber"
            v-decorator="[
              'mobile_phone',
              {
                rules: [
                  {
                    required: validateSecondStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="mobile"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Telefone fixo </label>
          <a-input
            class="underline-input"
            placeholder="Telefone fixo"
            v-mask="['(##) ####-####']"
            v-decorator="['phone']"
          >
            <a-icon
              slot="prefix"
              type="phone"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">E-mail </label>
          <a-input
            class="underline-input"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido.',
                  },
                  {
                    required: validateSecondStep(),
                    message: 'Escreva seu e-mail no campo.',
                  },
                ],
              },
            ]"
            placeholder="E-mail"
            value=""
          >
            <a-icon
              slot="prefix"
              type="mail"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-button
          class="mt-20"
          type="primary"
          size="large"
          shape="round"
          @click="backToFirstStep"
          ghost
        >
          <a-icon type="left" />
        </a-button>
      </a-col>

      <a-col class="mb-40" :span="19">
        <a-button
          class="mt-20"
          type="primary"
          shape="round"
          size="large"
          @click="goToLastStep"
          style="width: 100%"
          ghost
        >
          Próximo
        </a-button>
      </a-col>
    </a-row>

    <a-row :gutter="16" v-show="registerCompany.activeStep == '3'">
      <a-col :span="12">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">CEP</label>
          <a-input
            class="underline-input"
            placeholder="CEP"
            v-mask="['#####-###']"
            v-decorator="[
              'cep',
              {
                rules: [
                  {
                    required: validateThirdStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            @keyup="getLocation()"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Endereço</label>
          <a-input
            class="underline-input"
            placeholder="Endereço"
            v-decorator="[
              'location_street',
              {
                rules: [
                  {
                    required: validateThirdStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Número</label>
          <a-input
            class="underline-input"
            placeholder="Número"
            v-decorator="[
              'location_number',
              {
                rules: [
                  {
                    required: validateThirdStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="16">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Bairro</label>
          <a-input
            class="underline-input"
            placeholder="Bairro"
            v-decorator="[
              'location_neighborhood',
              {
                rules: [
                  {
                    required: validateThirdStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="18">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Cidade</label>
          <a-input
            class="underline-input"
            placeholder="Cidade"
            v-decorator="[
              'location_city',
              {
                rules: [
                  {
                    required: validateThirdStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Estado</label>
          <a-select
            class="underline-input"
            show-search
            placeholder="ES"
            v-decorator="[
              'location_state',
              {
                rules: [
                  {
                    required: validateThirdStep(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="'filled'">Complemento</label>
          <a-input
            class="underline-input"
            placeholder="Complemento"
            v-decorator="['location_complement']"
          >
            <a-icon
              slot="prefix"
              type="environment"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col class="mt-10" :span="24">
        <a-form-item class="agree-conectar-login">
          <a-checkbox v-decorator="['agreed_contract']">
            Li e aceito os
          </a-checkbox>
          <a
            @click="openTermsOfService = true"
            style="font-size: 13px; margin-left: -2px"
            >termos do contrato</a
          >
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-button
          class="mt-20"
          type="primary"
          size="large"
          @click="backToSecondStep"
          shape="round"
          ghost
        >
          <a-icon type="left" />
        </a-button>
      </a-col>

      <a-col :span="19">
        <a-form-item class="mt-20">
          <a-button
            type="primary"
            shape="round"
            size="large"
            html-type="submit"
            :loading="loading"
            style="width: 100%"
          >
            Cadastrar
          </a-button>
        </a-form-item>
      </a-col>
    </a-row>

    <a-modal
      v-model="openTermsOfService"
      title="Termo de uso do APP CONECTAR"
      footer
      width="700px"
      @ok="openTermsOfService = false"
      @cancel="openTermsOfService = false"
    >
      <div
        v-if="preRegisterData.user_profile == 'Investidor'"
        v-html="termsOfUseInvestor"
      ></div>
      <div v-else v-html="termsOfUse"></div>
    </a-modal>

    <a-modal
      class="success-modal"
      v-model="showSuccessModal"
      footer=""
      centered
      @cancel="closeSuccessModal"
    >
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="4">
          <div class="gutter-box">
            <img src="../../assets/images/success.png" alt="sucesso" />
          </div>
        </a-col>
        <a-col class="gutter-row" :span="20">
          <div class="gutter-box">
            <h2>Sucesso!</h2>
            <p>
              <strong>Seu cadastro foi efetuado com sucesso.</strong>
            </p>
            <p>
              Em breve você receberá o contato da nossa equipe de recrutamento e
              seu acesso será liberado por e-mail.
            </p>
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </a-form>
</template>

<script>
import axios from "axios";
import professions from "../../lists/professions.json";
import states from "../../lists/states.json";

export default {
  props: {
    preRegisterData: Object,
  },
  components: {},
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR",
    },
    title: "Cadastro – Conectar",
    meta: [
      {
        name: "descrition",
        content: "Página de cadastro de usuários.",
      },
    ],
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      termsOfUse: "",
      termsOfUseInvestor: "",
      professions,
      states,
      showSuccessModal: false,
      selectedPersonType: "Pessoa Física",
      openTermsOfService: false,
      sent: false,
      personTypes: [
        { label: "Pessoa Física", value: "Pessoa Física" },
        { label: "Pessoa Jurídica", value: "Pessoa Jurídica" },
      ],
      registerCompany: {
        activeStep: 1,
        percents: 10,
        firstStep: true,
        secondStep: false,
        thirdStep: false,
      },
    };
  },
  mounted() {
    this.$http
      .post("/external-apis/get-wp-pages.php", {
        id: "8370",
      })
      .then(({ data }) => {
        this.termsOfUse = data.content;
      })
      .catch(({ response }) => {
        response;
      });

    this.$http
      .post("/external-apis/get-wp-pages.php", {
        id: "8560",
      })
      .then(({ data }) => {
        this.termsOfUseInvestor = data.content;
      })
      .catch(({ response }) => {
        response;
      });

   
  },
  methods: {
    validatePhoneNumber(val) {
      // Remove non-numeric characters from the phone number
      let phoneNumber = val.target.value
        ? val.target.value.replace(/\D/g, "")
        : "";

      // Check if the phone number has the correct length
      if (phoneNumber.length !== 11) {
        this.form.setFieldsValue({
          [`mobile_phone`]: undefined,
        });
        return false;
      }

      // Check if the phone number has repeated digits
      if (
        [
          "00000000000",
          "11111111111",
          "22222222222",
          "33333333333",
          "44444444444",
          "55555555555",
          "66666666666",
          "77777777777",
          "88888888888",
          "99999999999",
        ].includes(phoneNumber)
      ) {
        this.form.setFieldsValue({
          [`mobile_phone`]: undefined,
        });
        return false;
      }

      // Phone number is valid
      return true;
    },
    verifyTypedCPF(val) {
      // Remover caracteres especiais do CPF
      let cpf = val.target.value ? val.target.value.replace(/\D/g, "") : "";

      if (!/^\d{11}$/.test(cpf)) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });
        return false;
      }

      if (/^(\d)\1{10}$/.test(cpf)) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });

        return false;
      }

      if (
        [
          "00000000000",
          "11111111111",
          "00011122233",
          "22222222222",
          "33333333333",
          "44444444444",
          "55555555555",
          "66666666666",
          "77777777777",
          "88888888888",
          "99999999999",
        ].includes(cpf)
      ) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });

        return false;
      }

      return true;
    },
    goToSecondStep(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);
        if (!err) {
          this.registerCompany.activeStep = 2;
          this.registerCompany.firstStep = false;
          this.registerCompany.secondStep = true;
          this.registerCompany.thirdStep = false;
          this.registerCompany.percents = 50;
        } else {
          this.registerCompany.activeStep = 1;
          this.registerCompany.firstStep = true;
          this.registerCompany.secondStep = false;
          this.registerCompany.thirdStep = false;
          this.registerCompany.percents = 10;
        }
      });
    },
    backToFirstStep() {
      this.registerCompany.activeStep = 1;
      this.registerCompany.firstStep = true;
      this.registerCompany.secondStep = false;
      this.registerCompany.thirdStep = false;
      this.registerCompany.percents = 10;
    },
    goToLastStep(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);
        if (!err) {
          this.registerCompany.activeStep = 3;
          this.registerCompany.firstStep = false;
          this.registerCompany.secondStep = false;
          this.registerCompany.thirdStep = true;
          this.registerCompany.percents = 90;
        } else {
          this.registerCompany.activeStep = 2;
          this.registerCompany.firstStep = false;
          this.registerCompany.secondStep = true;
          this.registerCompany.thirdStep = false;
          this.registerCompany.percents = 50;
        }
      });
    },
    backToSecondStep() {
      this.registerCompany.activeStep = 2;
      this.registerCompany.firstStep = false;
      this.registerCompany.secondStep = true;
      this.registerCompany.thirdStep = false;
      this.registerCompany.percents = 50;
    },
    validateFirstStep() {
      let flag = false;

      if (this.registerCompany.activeStep == 1) {
        flag = true;
      }

      return flag;
    },
    validateSecondStep() {
      let flag = false;

      if (this.registerCompany.activeStep == 2) {
        flag = true;
      }

      return flag;
    },
    validateThirdStep() {
      let flag = false;

      if (this.registerCompany.activeStep == 3) {
        flag = true;
      }

      return flag;
    },
    dynamicValidationCompany() {
      let flag = false;
      return flag;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.loading = true;
      this.form.validateFields((err, values) => {
        console.log(err, values);
        let sendValues = {
          ...values,
          ...this.preRegisterData,
          company_status: 0,
          is_approved: 0,
          role:
            this.preRegisterData.user_profile == "Investidor"
              ? "investor"
              : "corporate-admin",
          device: navigator.userAgent,
        };

        console.log(sendValues);
        if (!err) {
          this.$http
            .post("/user/register.php", sendValues)
            .then(({ data }) => {
              this.$http
                .post("/company/register.php", {
                  ...values,
                  owner_id: data.id,
                  status: 1,
                })
                .then(({ data }) => {
                  data;
                  this.loading = false;
                  this.showSuccessModal = true;
                  this.registerCompany.percents = 100;

                  setTimeout(() => {
                    this.form.setFieldsValue({
                      first_name: "",
                      last_name: "",
                      email: "",
                      cpf: "",
                      mobile_phone: "",
                      phone: "",
                      cep: "",
                      location_street: "",
                      location_number: "",
                      location_state: "",
                      location_city: "",
                      location_neighborhood: "",
                      location_complement: "",
                    });
                  }, 500);
                })
                .catch(({ response }) => {
                  this.$message.error(response.data.message);
                  this.loading = false;
                });
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
    getLocation() {
      setTimeout(() => {
        if (this.form.getFieldValue("cep")) {
          if (this.form.getFieldValue("cep").replace("-", "").length == 8) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.form.getFieldValue("cep").replace("-", "") +
                  "/json/"
              )
              .then(({ data }) => {
                data;
                this.cepLoading = false;
                this.form.setFieldsValue({
                  location_street: data.logradouro,
                  location_state: data.uf,
                  location_city: data.localidade,
                  location_neighborhood: data.bairro,
                  location_number: data.complemento,
                });
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 110);
    },
    getLocationCompany() {
      // console.log("aa");
      setTimeout(() => {
        if (this.form.getFieldValue("company_cep")) {
          if (
            this.form.getFieldValue("company_cep").replace("-", "").length == 8
          ) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.form.getFieldValue("company_cep").replace("-", "") +
                  "/json/"
              )
              .then(({ data }) => {
                data;
                this.cepLoading = false;
                this.form.setFieldsValue({
                  company_location_street: data.logradouro,
                  company_location_state: data.uf,
                  company_location_city: data.localidade,
                  company_location_neighborhood: data.bairro,
                  company_location_number: data.complemento,
                });
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 110);
    },
    closeSuccessModal() {
      setTimeout(() => {
        this.$router.push(`/login`);
      }, 500);
    },
  },
};
</script>

<style lang="sass" scoped>
.agree-conectar-login
  height: 20px !important
  display: block !important
  width: 100% !important
  line-height: 10px !important
  padding: 0 !important
  margin: 0 0 20px !important
  min-height: auto !important
</style>
