<template>
  <div>
    <a-row type="flex" justify="space-between" style="padding: 0 0 0 10px">
      <a-col>
        <div>
          <router-link to="/" tag="span">
            <img
              src="@/assets/images/conectar-ico.png"
              alt="logo"
              width="48"
              style=" position: relative;  top: -3px; cursor: pointer "
            />
          </router-link>
        </div>
      </a-col>

      <a-col flex="240px">
        <a-row type="flex" justify="end">
          <a-col
            v-if="
              $root.haveAvatar() &&
                $root.checkCapabilities(capabilities, 'properties', 'create')
            "
            class="top-bar-item"
            :flex="$root.windowWidth > 600 ? '200px' : '60px'"
          >
            <div class="action immobiles" @click="requestProperty">
              <img
                class="relative"
                src="@/assets/images/request-property.png"
                alt="ver"
                width="28"
                style="top: -11px"
              />
              <font class="font" style="font-weight: 500">
                {{ $root.windowWidth > 600 ? "Solicitar ativo" : "" }}
              </font>
            </div>
          </a-col>

          <a-col
            v-if="
              $root.haveAvatar() &&
                $root.checkCapabilities(capabilities, 'properties', 'create')
            "
            class="top-bar-item"
            :flex="'60px'"
          >
            <div class="action immobiles" @click="createProperty">
              <img
                class="relative"
                src="@/assets/images/add-property.png"
                alt="ver"
                width="30"
                style="top: -11px"
              />
            </div>
          </a-col>

          <a-col
            v-if="$root.haveAvatar() && totalUnread > 0"
            class="top-bar-item notifications"
            flex="60px"
          >
            <a-popover
              :placement="
                $root.reponsive([
                  {
                    min: 0,
                    max: 960,
                    span: 'bottomRight',
                  },
                  {
                    min: 961,
                    max: 9999,
                    span: 'bottom',
                  },
                ])
              "
              trigger="click"
              width="300px"
              class="notifications"
            >
              <template slot="content">
                <ListNotifications @totalUnread="totalUnreadedNotifications" />
              </template>

              <template slot="title">
                <span>Notificações</span>
              </template>

              <div class="action">
                <a-badge :count="totalUnread">
                  <img
                    class="relative"
                    src="@/assets/images/bell.png"
                    alt="ver"
                    width="30"
                    style="top: -7px"
                  />
                </a-badge>
              </div>
            </a-popover>
          </a-col>
          <a-col
            @click="openMobileMenu = true"
            class="top-bar-item"
            flex="60px"
          >
            <a-icon type="menu" />
          </a-col>

          <a-drawer
            class="mobile-menu"
            placement="right"
            :closable="true"
            :visible="openMobileMenu"
            @ok="openMobileMenu = false"
            @close="openMobileMenu = false"
            width="100%"
          >
            <div class="system-logo" @click="openMobileMenu = false">
              <img
                src="@/assets/images/conectar-white.png"
                alt="logo"
                @click="$router.push('/')"
              />
            </div>

            <div class="profile-pic" @click="openMobileMenu = false">
              <a-avatar
                v-if="$store.state.userData.avatar"
                class="c-pointer avatar"
                @click="$router.push('/perfil')"
                :src="$store.state.userData.avatar"
                :size="160"
              />
              <a-avatar
                v-if="!$store.state.userData.avatar"
                class="c-pointer avatar"
                @click="$router.push('/perfil')"
                icon="user"
                style="color:  #82cfdd;; backgroundColor:  #001529"
                :size="160"
              />

              <div class="name">
                {{ $store.state.userData.first_name }}
                {{ $store.state.userData.last_name }}
              </div>
              <div  v-if="$store.state.userData.meta.profession != undefined" class="job">
                {{ $store.state.userData.meta.profession }}

                <font v-if="$store.state.userData.meta.company.company_name">
                  na
                  <font class="upper">{{
                    $store.state.userData.meta.company.company_name
                  }}</font>
                </font>
              </div>
            </div>

            <a-row class="menu-box pd-20">
              <a-col
                v-if="$root.isAdmin()"
                @click="openMobileMenu = false"
                class="item"
                :span="24"
              >
                <a-button
                  @click="$router.push('/ativos/aguardando')"
                  type="primary"
                >
                  <a-icon type="carry-out" />
                  Ativos aguardando
                </a-button>
              </a-col>

              <a-col
                v-if="!$root.isInvestor()"
                @click="openMobileMenu = false"
                class="item"
                :span="24"
              >
                <a-button @click="!$root.isInvestor()" type="primary">
                  <a-icon type="environment" />
                  Ativos
                </a-button>
              </a-col>

              <a-col
                v-if="$root.isInvestor()"
                @click="openMobileMenu = false"
                class="item"
                :span="24"
              >
                <a-button @click="$router.push('/ativos/lista')" type="primary">
                  <a-icon type="environment" />
                  Meus Ativos
                </a-button>
              </a-col>

              <a-col
                v-if="$root.isInvestor()"
                @click="openMobileMenu = false"
                class="item"
                :span="24"
              >
                <a-button
                  @click="$router.push('/ativos/investidor')"
                  type="primary"
                >
                  <a-icon type="environment" />
                  Ativos com meu perfil
                </a-button>
              </a-col>

              <a-col
                v-if="
                  $root.checkCapabilities(capabilities, 'properties', 'list')
                "
                @click="openMobileMenu = false"
                class="item"
                :span="24"
              >
                <a-button @click="createProperty" type="primary">
                  <i
                    class="fas fa-map-pin"
                    style="
                                                      margin-right: 10px;
                                                  "
                  ></i>
                  Cadastrar Ativo
                </a-button>
              </a-col>

              <a-col
                v-if="
                  $root.checkCapabilities(capabilities, 'properties', 'list')
                "
                @click="openMobileMenu = false"
                class="item"
                :span="24"
              >
                <a-button @click="requestProperty" type="primary">
                  <i
                    class="fas fa-map-marked-alt"
                    style="
                                                      margin-right: 10px;
                                                  "
                  ></i>
                  Solicitar Ativo
                </a-button>
              </a-col>

              <div v-for="(item, index) in menu" :key="index">
                <a-col
                  v-if="item.show"
                  @click="openMobileMenu = false"
                  class="item"
                  :span="24"
                >
                  <a-button @click="$router.push(item.link)" type="primary">
                    <i v-if="item.fwIcon" class="icon" :class="item.fwIcon"></i>
                    <a-icon
                      v-if="item.antdIcon"
                      class="icon"
                      :type="item.antdIcon"
                    />
                    {{ item.name }}
                  </a-button>
                </a-col>
              </div>

              <a-col
                v-if="$root.isAdmin()"
                @click="openMobileMenu = false"
                class="item"
                :span="24"
              >
                <a-button
                  @click="$router.push('/usuarios/administradores')"
                  type="primary"
                >
                  <a-icon type="team" />
                  Administradores
                </a-button>
              </a-col>

              <a-col
                v-if="$root.isAdmin()"
                @click="openMobileMenu = false"
                class="item"
                :span="24"
              >
                <a-button
                  @click="$router.push('/configuracoes')"
                  type="primary"
                >
                  <a-icon type="setting" />
                  Configurações
                </a-button>
              </a-col>

              <a-col @click="openMobileMenu = false" class="item" :span="24">
                <a-button @click="$router.push('/quem-somos')" type="primary">
                  <a-icon type="contacts" />
                  Quem somos
                </a-button>
              </a-col>

              <a-col @click="openMobileMenu = false" class="item" :span="24">
                <a-button
                  @click="$router.push('/politica-privacidade')"
                  type="primary"
                >
                  <a-icon type="safety" />
                  Política de Privacidade
                </a-button>
              </a-col>

              <a-col @click="openMobileMenu = false" class="item" :span="24">
                <a-button @click="$router.push('/faq')" type="primary">
                  <a-icon type="bulb" />
                  FAQ
                </a-button>
              </a-col>

              <a-col @click="openMobileMenu = false" class="item" :span="24">
                <a-button type="danger" ghost @click="logOut()">
                  <a-icon type="poweroff" />
                  Sair
                </a-button>
              </a-col>
            </a-row>
          </a-drawer>
        </a-row>
      </a-col>
    </a-row>

    <a-drawer
      class="property-drawer"
      placement="right"
      :width="$root.windowWidth > 600 ? '740px' : ''"
      :closable="true"
      :visible="drawers.requestProperty"
      @close="closePropertyDrawer"
    >
      <template slot="title">
        <img
          class="relative mr-5"
          src="@/assets/images/request-property-white.png"
          alt="ver"
          width="30"
          style="top: -2px"
        />
        SOLICITAR ATIVO
      </template>

      <RequestProperty
        v-if="drawers.requestProperty"
        @listPropertiesAfterCreate="listPropertiesAfterCreate"
        @closeDrawerAfterCreate="closePropertyDrawer"
      />
    </a-drawer>

    <a-drawer
      class="property-drawer"
      placement="right"
      :width="$root.windowWidth > 740 ? '740px' : '100%'"
      :closable="true"
      :visible="drawers.propety"
      @close="closePropertyDrawer"
    >
      <template slot="title">
        <img
          class="relative"
          src="@/assets/images/add-property-white.png"
          alt="add"
          width="30"
          style="top: -2px"
        />
        Cadastrar Ativo Imobiliário
      </template>
      <create-property
        v-if="drawers.propety"
        @listPropertiesAfterCreate="listPropertiesAfterCreate"
        @closeDrawerAfterCreate="closePropertyDrawer"
      />
    </a-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CreateProperty from "@/components/properties/CreateProperty.vue";
import RequestProperty from "@/components/properties/RequestProperty.vue";
import ListNotifications from "../../components/notifications/ListNotifications.vue";

export default {
  name: "HeaderMobile",
  props: {
    totalUnread: String,
  },
  components: { CreateProperty, RequestProperty, ListNotifications },
  data() {
    return {
      openMobileMenu: false,
      menu: [],
      drawers: {
        propety: false,
        requestProperty: false,
        user: false,
      },
    };
  },
  computed: {
    ...mapState(["capabilities"]),
  },
  mounted() {
    setTimeout(() => {
      this.menu = [
        {
          name: "Afiliados Aguardando",
          link: "/afiliados/aguardando-aprovacao",
          fwIcon: "",
          antdIcon: "solution",
          show: this.$root.isAdmin(),
        },
        {
          name: "Afiliados",
          link: "/afiliados/lista",
          fwIcon: "",
          antdIcon: "team",
          show:
            this.$root.isAdmin() ||
            this.$root.checkCapabilities(
              this.$store.state.capabilities,
              "users",
              "list"
            )
              ? true
              : false,
        },
        {
          name: "Investidores",
          link: "/afiliados/investidores",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
        {
          name: "Proprietários de terreno",
          link: "/afiliados/proprietarios-terreno",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
        {
          name: "Gestores regionais",
          link: "/afiliados/gestores-regionais",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
        {
          name: "Intermediadores de negócios",
          link: "/afiliados/intermediadores-negocios",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
      ];
    }, 1000);
  },
  methods: {
    ...mapActions(["setCapabilities"]),
    requestProperty() {
      this.drawers.requestProperty = true;
    },
    createProperty() {
      this.drawers.propety = true;
    },
    closePropertyDrawer() {
      this.drawers.propety = false;
      this.drawers.requestProperty = false;
    },
    listPropertiesAfterCreate() {
      this.$router.push("/ativos/lista");
    },
    totalUnreadedNotifications(total) {
      this.totalUnread = total;
    },
    logOut() {
      let user = JSON.parse(localStorage.userData);
      if (this.tokenExpired) {
        this.expiredTokenModal = true;
      } else {
        this.$http
          .post("/system-logs/create.php", {
            id: user.id,
            content: `<font class="user">${user.first_name} ${user.last_name}</font> deslogou do sistema.`,
            action: "loggedout",
          })
          .then(({ data }) => {
            data;
            localStorage.removeItem("userData");
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
            window.open("/login", "_self");
          })
          .catch(({ response }) => {
            response;
          });
      }
    },
  },
};
</script>
<style lang="sass">
.mobile-menu
  .ant-drawer-body
    padding: 0
</style>

<style lang="sass" scoped>
.menu-box
  padding: 0 20px
.system-logo
  text-align: center
  padding: 20px
  margin-top: 20px
  img
    cursor: pointer
.profile-pic
  background: #fff
  text-align: center
  padding: 10px
  border-right: 1px solid #ddd
  margin-top: 50px
  margin-bottom: 20px
  .avatar
    margin-top: -67px
    border: 5px solid #fff
  .name
    font-weight: 600
    margin-top: 5px
    font-size: 16px
  .job
    font-size: 13px
    color: #999
</style>
