import { mapState, mapActions } from "vuex";

export default {
  beforeMount() {
    this.setCapabilities();
  },
  computed: {
    ...mapState(["capabilities"]),
  },
  methods: {
    ...mapActions(["setCapabilities"]),
  },
};