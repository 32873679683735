<template>
    <div class="pages">
        <a-page-header class="bg">
            <template slot="backIcon">
                <a-icon type="left-circle" /> Voltar
            </template>
        </a-page-header>
        <div class="box">
            <div v-if="loadedPage">
                <h1>{{ title }}</h1>
                <a-collapse
                    :bordered="false"
                    accordion
                    expandIconPosition="right"
                >
                    <a-collapse-panel
                        v-for="(question, index) in faq"
                        :key="index"
                        :header="question.pergunta"
                        :style="customStyle"
                    >
                        <p>{{ question.resposta }}</p>
                    </a-collapse-panel>
                </a-collapse>
            </div>
            <div v-if="!loadedPage">
                <h1><a-skeleton title active /></h1>
                <a-skeleton active />
                <a-skeleton active />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            title: "",
            content: "",
            faq: [],
            loadedPage: false,
            customStyle:
                "border-radius: 4px;margin-bottom: 10px;border: 0;overflow: hidden",
        };
    },
    beforeCreate() {
        this.$http
            .post("/external-apis/get-wp-pages.php", {
                id: "8120",
            })
            .then(({ data }) => {
                this.loadedPage = true;
                this.title = data.title;
                this.content = data.content;
                this.faq = data.faq;
            })
            .catch(({ response }) => {
                response;
            });
    },
};
</script>
