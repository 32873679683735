<template>
    <div class="register-page box">
        <div class="mb-20">
            <a @click="$router.go(-1)"> <a-icon type="arrow-left" /> Voltar </a>
        </div>

        <div class="logo-conectar mb-20">
            <center>
                <img
                    src="../../assets/images/conectar.png"
                    alt="logo"
                    width="260"
                />
            </center>
        </div>

        <div class="mb-20">
            <a-steps v-model="currentStep" type="navigation" size="small">
                <a-step status="process" title="Perfil" />
                <a-step
                    :status="allowNextStep() ? 'wait' : 'process'"
                    :disabled="allowNextStep()"
                    title="Dados"
                />
            </a-steps>
        </div>
        <a-form :form="form">
            <div v-show="currentStep == 0">
                <div class="text mb-30">
                    Informe abaixo qual é o seu perfil:
                </div>

                <div class="mb-30">
                    <div>
                        <a-form-item>
                            <a-radio-group
                                class="register-type"
                                v-model="selectedRegisterType"
                                :options="resgisterTypes"
                        /></a-form-item>
                    </div>

                    <div class="investor-profile">
                        <InvestorProfile
                            v-if="selectedRegisterType == 'Investidor'"
                            :form="form"
                        />
                    </div>
                </div>

                <div>
                    <a-button
                        class="mt-20 mb-40"
                        type="primary"
                        shape="round"
                        size="large"
                        :disabled="allowNextStep()"
                        @click="currentStep = 1"
                        style="width: 100%"
                        ghost
                    >
                        Cadastrar Dados <a-icon type="arrow-right" />
                    </a-button>
                </div>
            </div>

            <div v-show="currentStep == 1">
                <a-form-item>
                    <a-radio-group
                        class="register-type"
                        v-model="selectedPersonType"
                        :options="personTypes"
                /></a-form-item>

                <RegisterPerson
                    v-if="
                        selectedPersonType == 'Pessoa Física' &&
                            selectedRegisterType
                    "
                    :preRegisterData="{
                        user_profile: selectedRegisterType,
                        business_type_of_interest: form.getFieldValue(
                            'business_type_of_interest'
                        )
                            ? JSON.stringify(
                                  form.getFieldValue(
                                      'business_type_of_interest'
                                  )
                              )
                            : '[]',
                        area_size_of_interest: form.getFieldValue(
                            'area_size_of_interest'
                        )
                            ? JSON.stringify(
                                  form.getFieldValue('area_size_of_interest')
                              )
                            : '[]',
                        state_of_interest: form.getFieldValue(
                            'state_of_interest'
                        )
                            ? JSON.stringify(
                                  form.getFieldValue('state_of_interest')
                              )
                            : '[]',
                        population_of_interest: form.getFieldValue(
                            'population_of_interest'
                        )
                            ? JSON.stringify(
                                  form.getFieldValue('population_of_interest')
                              )
                            : '[]',
                        region_of_interest: '[]',
                    }"
                />
                <RegisterCompany
                    v-if="
                        selectedPersonType == 'Pessoa Jurídica' &&
                            selectedRegisterType
                    "
                    :preRegisterData="{
                        user_profile: selectedRegisterType,
                        business_type_of_interest: form.getFieldValue(
                            'business_type_of_interest'
                        )
                            ? JSON.stringify(
                                  form.getFieldValue(
                                      'business_type_of_interest'
                                  )
                              )
                            : '[]',
                        area_size_of_interest: form.getFieldValue(
                            'area_size_of_interest'
                        )
                            ? JSON.stringify(
                                  form.getFieldValue('area_size_of_interest')
                              )
                            : '[]',
                        state_of_interest: form.getFieldValue(
                            'state_of_interest'
                        )
                            ? JSON.stringify(
                                  form.getFieldValue('state_of_interest')
                              )
                            : '[]',
                        population_of_interest: form.getFieldValue(
                            'population_of_interest'
                        )
                            ? JSON.stringify(
                                  form.getFieldValue('population_of_interest')
                              )
                            : '[]',
                        region_of_interest: '[]',
                    }"
                />
            </div>
        </a-form>
    </div>
</template>

<script>
import InvestorProfile from "../users/forms/InvestorProfile.vue";
import RegisterCompany from "./RegisterCompany.vue";
import RegisterPerson from "./RegisterPerson.vue";

export default {
    components: { InvestorProfile, RegisterCompany, RegisterPerson },
    data() {
        return {
            currentStep: 0,
            form: this.$form.createForm(this),
            resgisterTypes: [
                {
                    label: "Proprietário de terreno",
                    value: "Proprietário de terreno",
                },
                { label: "Intermediador", value: "Intermediador" },
                { label: "Investidor", value: "Investidor" },
            ],
            selectedRegisterType: "",
            selectedPersonType: "Pessoa Física",
            personTypes: [
                { label: "Pessoa Física", value: "Pessoa Física" },
                { label: "Pessoa Jurídica", value: "Pessoa Jurídica" },
            ],
        };
    },
    methods: {
        allowNextStep() {
            let flag = true;

            if (
                this.selectedRegisterType == "Investidor" &&
                this.form.getFieldValue("business_type_of_interest") != "" &&
                this.form.getFieldValue("area_size_of_interest") &&
                this.form.getFieldValue("state_of_interest") &&
                this.form.getFieldValue("population_of_interest")
            ) {
                flag = false;
            }

            if (
                this.selectedRegisterType == "Intermediador" ||
                this.selectedRegisterType == "Proprietário de terreno"
            ) {
                flag = false;
            }

            return flag;
        },
    },
};
</script>

<style>
.register-page .register-type {
    width: 100%;
}

.register-page .register-type label {
    display: block;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    border-radius: 6px;
}

.register-page .investor-profile .mb-20 {
    padding: 0 !important;
}

.register-page .register-type label.ant-radio-wrapper-checked {
    background: #163967;
    color: #fff;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .ant-steps-navigation > .ant-steps-item.ant-steps-item-active::before {
        display: none !important;
    }
}
</style>

<style lang="sass" scoped>
.box
  width: 80%
  max-width: 400px
  margin: 0 auto
  display: block
  .text
    font-size: 16px
    color: #333
.register-type
  label
    display: block
    border: 1px solid #ddd
    padding: 10px
    margin-bottom: 10px
    font-weight: 500
    border-radius: 6px
</style>
