<template>
  <div class="login">
    <a-row
      :gutter="[20, 20]"
      align="middle"
      justify="center"
      type="flex"
      style="margin: 0"
    >
      <a-col class="form" :span="7" :xs="24" :sm="24" :md="24" :lg="24" :xl="7">
        <ForgotPasswordForm v-if="$route.meta.slug === 'esqueci-minha-senha'" />
        <ChangePassword v-else-if="$route.meta.slug === 'key'" />
        <ChangePassword v-else-if="$route.meta.slug === 'password'" />
        <RegisterPage v-else-if="$route.meta.slug === 'cadastrar'" />
        <LoginForm v-else />
      </a-col>
      <a-col
        v-if="this.$root.windowWidth > 1200"
        class="bg"
        :span="17"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="17"
      >
        <div class="image" />
        <div class="txt txt1">Bem Vindo ao</div>
        <div class="txt txt2">APP Conectar</div>
        <div class="txt txt3">
          Conectando empresas. Gerando valores.
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import LoginForm from "@/components/login/LoginForm";
import ForgotPasswordForm from "@/components/login/ForgotPasswordForm";
import ChangePassword from "@/components/login/ChangePassword.vue";
import RegisterPage from "@/components/login/RegisterPage.vue";

export default {
  components: {
    ChangePassword,
    RegisterPage,
    LoginForm,
    ForgotPasswordForm,
  },
  beforeCreate() {
    if (this.$store.state.isLoggedIn) {
      this.$router.push(`/`);
    }
  },
};
</script>
