var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
            _vm.$root.reponsive([
                {
                    min: 0,
                    max: 620,
                    span: false,
                },
                {
                    min: 621,
                    max: 9999,
                    span: true,
                } ])
        )?_c('a-steps',{staticClass:"mb-20",attrs:{"type":"navigation","size":"small"},on:{"change":_vm.changeSteps},model:{value:(_vm.createProperty.activeStep),callback:function ($$v) {_vm.$set(_vm.createProperty, "activeStep", $$v)},expression:"createProperty.activeStep"}},[_c('a-step',{attrs:{"status":"process","title":"Localização"}}),_c('a-step',{attrs:{"status":_vm.createProperty.activeStep >= 1 ? 'process' : 'wait',"disabled":_vm.createProperty.activeStep <= 1,"title":"Dados do Ativo"}}),_c('a-step',{attrs:{"status":_vm.createProperty.activeStep >= 2 ? 'process' : 'wait',"disabled":_vm.createProperty.activeStep <= 2,"title":"Documentos"}})],1):_vm._e(),_c('a-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.createProperty.activeStep == '0'),expression:"createProperty.activeStep == '0'"}],attrs:{"gutter":16}},[_c('a-form',{staticStyle:{"padding":"0 10px"},attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{staticStyle:{"padding":"0 10px"}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"mb-0"},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'is_owner',
                                {
                                    initialValue: 'yes',
                                    rules: [
                                        {
                                            required: _vm.validateFourthStep(),
                                            message: 'Obrigatório',
                                        } ],
                                } ]),expression:"[\n                                'is_owner',\n                                {\n                                    initialValue: 'yes',\n                                    rules: [\n                                        {\n                                            required: validateFourthStep(),\n                                            message: 'Obrigatório',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"default-value":"yes"}},[_c('a-radio',{attrs:{"value":"yes"}},[_vm._v(" Sou proprietário do Ativo ")]),_c('a-radio',{attrs:{"value":"no"}},[_vm._v(" Sou Intermediador ")])],1)],1)],1),_c('div',{staticClass:"clearfix"}),(_vm.form.getFieldValue('is_owner') == 'no')?_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                                {
                                    min: 0,
                                    max: 620,
                                    span: 24,
                                },
                                {
                                    min: 621,
                                    max: 9999,
                                    span: 12,
                                } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:_vm.form.getFieldValue('owner_first_name')
                                        ? 'filled'
                                        : ''},[_vm._v("Nome do proprietário do ativo")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'owner_first_name',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Obrigatório',
                                            } ],
                                    } ]),expression:"[\n                                    'owner_first_name',\n                                    {\n                                        rules: [\n                                            {\n                                                required: true,\n                                                message: 'Obrigatório',\n                                            },\n                                        ],\n                                    },\n                                ]"}],staticClass:"underline-input",attrs:{"placeholder":"Nome do proprietário do ativo"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                                {
                                    min: 0,
                                    max: 620,
                                    span: 24,
                                },
                                {
                                    min: 621,
                                    max: 9999,
                                    span: 12,
                                } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:_vm.form.getFieldValue('owner_last_name')
                                        ? 'filled'
                                        : ''},[_vm._v("Sobrenome do proprietário do ativo")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'owner_last_name',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Obrigatório',
                                            } ],
                                    } ]),expression:"[\n                                    'owner_last_name',\n                                    {\n                                        rules: [\n                                            {\n                                                required: true,\n                                                message: 'Obrigatório',\n                                            },\n                                        ],\n                                    },\n                                ]"}],staticClass:"underline-input",attrs:{"placeholder":"Sobrenome do proprietário do ativo"}})],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"clearfix"}),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 16,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('name') ? 'filled' : ''},[_vm._v("Nome do ativo Imobiliário")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'name',
                            {
                                rules: [
                                    {
                                        required: _vm.validateFirstStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'name',\n                            {\n                                rules: [\n                                    {\n                                        required: validateFirstStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Nome do ativo Imobiliário"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 8,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('cep') ? 'filled' : ''},[_vm._v("CEP")]),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(['#####-###']),expression:"['#####-###']"},{name:"decorator",rawName:"v-decorator",value:([
                            'cep',
                            {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'cep',\n                            {\n                                rules: [\n                                    {\n                                        required: false,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"CEP (opcional)"},on:{"keyup":function($event){return _vm.getLocation()}}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 19,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('location_street')
                                ? 'filled'
                                : ''},[_vm._v("Endereço")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'location_street',
                            {
                                rules: [
                                    {
                                        required: _vm.validateFirstStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'location_street',\n                            {\n                                rules: [\n                                    {\n                                        required: validateFirstStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Endereço"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 5,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('location_number')
                                ? 'filled'
                                : ''},[_vm._v("Número")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'location_number',
                            {
                                rules: [
                                    {
                                        required: _vm.validateFirstStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'location_number',\n                            {\n                                rules: [\n                                    {\n                                        required: validateFirstStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Número"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 16,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('location_complement')
                                ? 'filled'
                                : ''},[_vm._v("Complemento")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['location_complement']),expression:"['location_complement']"}],staticClass:"underline-input",attrs:{"placeholder":"Complemento"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 8,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('location_neighborhood')
                                ? 'filled'
                                : ''},[_vm._v("Bairro")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'location_neighborhood',
                            {
                                rules: [
                                    {
                                        required: _vm.validateFirstStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'location_neighborhood',\n                            {\n                                rules: [\n                                    {\n                                        required: validateFirstStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Bairro"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 10,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('location_city')
                                ? 'filled'
                                : ''},[_vm._v("Cidade")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'location_city',
                            {
                                rules: [
                                    {
                                        required: _vm.validateFirstStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'location_city',\n                            {\n                                rules: [\n                                    {\n                                        required: validateFirstStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Cidade"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 6,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('location_state')
                                ? 'filled'
                                : ''},[_vm._v("Estado")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'location_state',
                            {
                                rules: [
                                    {
                                        required: _vm.validateFirstStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'location_state',\n                            {\n                                rules: [\n                                    {\n                                        required: validateFirstStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"show-search":"","placeholder":"Estado"}},_vm._l((_vm.states),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.initials}},[_vm._v(" "+_vm._s(item.initials.toUpperCase())+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 8,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:this.form.getFieldValue('population')
                                ? 'filled'
                                : ''},[_vm._v("Habitantes")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'population',
                            {
                                rules: [
                                    {
                                        required: _vm.validateFirstStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'population',\n                            {\n                                rules: [\n                                    {\n                                        required: validateFirstStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"show-search":"","placeholder":"Habitantes"}},_vm._l((_vm.population),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('div',{staticClass:"clearfix"}),_c('a-row',{staticStyle:{"padding":"0 10px"},attrs:{"type":"flex","justify":"end"}},[_c('a-col',[_c('a-button',{staticClass:"mt-20",staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"large"},on:{"click":_vm.goToSecondStep}},[_vm._v(" Continuar "),_c('a-icon',{attrs:{"type":"arrow-right"}})],1)],1)],1)],1)],1),_c('a-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.createProperty.activeStep == '1'),expression:"createProperty.activeStep == '1'"}],attrs:{"gutter":16}},[_c('a-form',{staticStyle:{"padding":"0 10px"},attrs:{"form":_vm.form2},on:{"submit":_vm.handleSubmit}},[_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 8,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:_vm.form2.getFieldValue('registration')
                                ? 'filled'
                                : ''},[_vm._v("Nº da Matrícula")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'registration',
                            {
                                rules: [
                                    {
                                        required: _vm.validateSecondStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'registration',\n                            {\n                                rules: [\n                                    {\n                                        required: validateSecondStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"type":"number","placeholder":"Nº da Matrícula"}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 6,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:_vm.form2.getFieldValue('zone') ? 'filled' : ''},[_vm._v("Zoneamento")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'zone',
                            {
                                rules: [
                                    {
                                        required: _vm.validateSecondStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'zone',\n                            {\n                                rules: [\n                                    {\n                                        required: validateSecondStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"show-search":"","placeholder":"Zoneamento"}},[_c('a-select-option',{attrs:{"value":"Residencial"}},[_vm._v(" Residencial ")]),_c('a-select-option',{attrs:{"value":"Industrial"}},[_vm._v(" Industrial ")]),_c('a-select-option',{attrs:{"value":"Misto"}},[_vm._v(" Misto ")])],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 10,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:_vm.form2.getFieldValue('metreage') ? 'filled' : ''},[_vm._v("Metragem (m²) ")]),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                            currency: {
                                prefix: '',
                                suffix: '',
                            },
                            distractionFree: false,
                            valueAsInteger: false,
                            precision: 3,
                            autoDecimalMode: true,
                            allowNegative: false,
                        }),expression:"{\n                            currency: {\n                                prefix: '',\n                                suffix: '',\n                            },\n                            distractionFree: false,\n                            valueAsInteger: false,\n                            precision: 3,\n                            autoDecimalMode: true,\n                            allowNegative: false,\n                        }"},{name:"decorator",rawName:"v-decorator",value:([
                            'metreage',
                            {
                                rules: [
                                    {
                                        required: _vm.validateSecondStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'metreage',\n                            {\n                                rules: [\n                                    {\n                                        required: validateSecondStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Metragem (m²)"}})],1),_c('div',{staticStyle:{"font-size":"9px","font-weight":"600","position":"absolute","bottom":"-2px"}},[_vm._v(" "+_vm._s(_vm.checkDecimals(_vm.form2.getFieldValue("metreage")))+" ")])],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 8,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:_vm.form2.getFieldValue('onus') ? 'filled' : ''},[_vm._v("Possui ônus?")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'onus',
                            {
                                rules: [
                                    {
                                        required: _vm.validateSecondStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'onus',\n                            {\n                                rules: [\n                                    {\n                                        required: validateSecondStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"show-search":"","placeholder":"Possui ônus?"}},[_c('a-select-option',{attrs:{"value":"Sim"}},[_vm._v(" Sim ")]),_c('a-select-option',{attrs:{"value":"Não"}},[_vm._v(" Não ")])],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 8,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:_vm.form2.getFieldValue('business_type')
                                ? 'filled'
                                : ''},[_vm._v("Vocação")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'business_type',
                            {
                                rules: [
                                    {
                                        required: _vm.validateSecondStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'business_type',\n                            {\n                                rules: [\n                                    {\n                                        required: validateSecondStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"show-search":"","placeholder":"Vocação"}},_vm._l((_vm.vocations),function(v,index){return _c('a-select-option',{key:index,attrs:{"value":v.value}},[_vm._v(" "+_vm._s(v.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 8,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:_vm.form2.getFieldValue('situation') ? 'filled' : ''},[_vm._v("Situação ")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'situation',
                            {
                                rules: [
                                    {
                                        required: _vm.validateSecondStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'situation',\n                            {\n                                rules: [\n                                    {\n                                        required: validateSecondStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"show-search":"","placeholder":"Situação"}},[_c('a-select-option',{attrs:{"value":"Ocupado"}},[_vm._v(" Ocupado ")]),_c('a-select-option',{attrs:{"value":"Desocupado"}},[_vm._v(" Desocupado ")])],1)],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 8,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:_vm.form2.getFieldValue('value') ? 'filled' : ''},[_vm._v("Valor (R$)")]),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                            currency: {
                                prefix: '',
                                suffix: '',
                            },
                            distractionFree: false,
                            valueAsInteger: false,
                            precision: 2,
                            autoDecimalMode: true,
                            allowNegative: false,
                        }),expression:"{\n                            currency: {\n                                prefix: '',\n                                suffix: '',\n                            },\n                            distractionFree: false,\n                            valueAsInteger: false,\n                            precision: 2,\n                            autoDecimalMode: true,\n                            allowNegative: false,\n                        }"},{name:"decorator",rawName:"v-decorator",value:([
                            'value',
                            {
                                rules: [
                                    {
                                        required: _vm.checkNegotiation
                                            ? false
                                            : _vm.validateSecondStep(),
                                        message: 'Obrigatório',
                                    } ],
                            } ]),expression:"[\n                            'value',\n                            {\n                                rules: [\n                                    {\n                                        required: checkNegotiation\n                                            ? false\n                                            : validateSecondStep(),\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"underline-input",attrs:{"placeholder":"Valor (R$)","disabled":_vm.checkNegotiation}})],1)],1),_c('a-col',{attrs:{"span":_vm.$root.reponsive([
                        {
                            min: 0,
                            max: 620,
                            span: 24,
                        },
                        {
                            min: 621,
                            max: 9999,
                            span: 12,
                        } ])}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['negotiate_value']),expression:"['negotiate_value']"}],attrs:{"checked":_vm.checkNegotiation},on:{"change":_vm.changeCheckNegotiation}},[_vm._v(" Valor a negociar ")])],1)],1),_c('a-row',{staticStyle:{"padding":"20px 10px 0","clear":"both"},attrs:{"gutter":20}},[_c('a-col',{staticClass:" ",attrs:{"span":24}},[_c('a-form-item',{staticClass:"underline-wrapper textarea-dashed textarea"},[_c('label',{class:_vm.form2.getFieldValue('property_description')
                                    ? 'filled'
                                    : ''},[_vm._v("Descrição do ativo")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'property_description',
                                {
                                    rules: [
                                        {
                                            required: _vm.validateSecondStep(),
                                            message: 'Obrigatório',
                                        } ],
                                } ]),expression:"[\n                                'property_description',\n                                {\n                                    rules: [\n                                        {\n                                            required: validateSecondStep(),\n                                            message: 'Obrigatório',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"placeholder":"Descreva por completo o ativo imobiliario","auto-size":{ minRows: 5, maxRows: 5 }}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper textarea-dashed textarea"},[_c('label',{class:_vm.form2.getFieldValue('commercial_proposal')
                                    ? 'filled'
                                    : ''},[_vm._v("Proposta comercial")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'commercial_proposal',
                                {
                                    rules: [
                                        {
                                            required: _vm.validateSecondStep(),
                                            message: 'Obrigatório',
                                        } ],
                                } ]),expression:"[\n                                'commercial_proposal',\n                                {\n                                    rules: [\n                                        {\n                                            required: validateSecondStep(),\n                                            message: 'Obrigatório',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"placeholder":"Descreva sua proposta comercial","auto-size":{ minRows: 5, maxRows: 5 }}})],1)],1),_c('div',{staticClass:"clearfix"}),_c('a-row',{staticStyle:{"padding":"0 10px"},attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_c('a-button',{staticClass:"mt-20",staticStyle:{"width":"100%"},attrs:{"size":"large"},on:{"click":_vm.backToFirstStep}},[_c('a-icon',{attrs:{"type":"arrow-left"}}),_vm._v(" Voltar ")],1)],1),_c('a-col',[_c('a-button',{staticClass:"mt-20",attrs:{"type":"primary","size":"large"},on:{"click":_vm.goToThirdStep}},[_vm._v(" Continuar "),_c('a-icon',{attrs:{"type":"arrow-right"}})],1)],1)],1)],1)],1)],1),_c('a-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.createProperty.activeStep == '2'),expression:"createProperty.activeStep == '2'"}],attrs:{"gutter":16}},[_c('a-form',{staticStyle:{"padding":"0 10px"},attrs:{"form":_vm.form3},on:{"submit":_vm.handleSubmit}},[_c('a-row',{staticStyle:{"padding":"0 10px"}},[_c('a-col',{staticClass:"media-upload",attrs:{"span":24}},[_c('div',{staticClass:"label"},[_vm._v(" Matrícula do terreno "),_c('font',{staticStyle:{"color":"red"}},[_vm._v("*")])],1),_c('a-form-item',[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'area_registration_docs',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        } ],
                                } ]),expression:"[\n                                'area_registration_docs',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message: 'Obrigatório',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"name":"file","customRequest":_vm.uploadRegistrationFile,"file-list":_vm.registration_docs.list,"remove":_vm.removeRegistrationFile,"multiple":false,"accept":".pdf,.png,.jpg,.jpeg"}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"picture"}})],1),_c('p',{staticClass:"ant-upload-text"},[_c('font',{staticClass:"font"},[_vm._v("Clique aqui")]),_vm._v(" ou arraste o arquivo ")],1)]),(_vm.registration_docs.loading)?_c('a-icon',{attrs:{"type":"loading"}}):_vm._e()],1)],1),_c('a-divider'),_c('a-col',{staticClass:"mb-30 media-upload",attrs:{"span":24}},[_c('div',{staticClass:"label"},[_vm._v(" Marcação do terreno (KMZ) "),_c('font',{staticStyle:{"color":"red"}},[_vm._v("*")])],1),_c('a-form-item',[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'kmz_file',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        } ],
                                } ]),expression:"[\n                                'kmz_file',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message: 'Obrigatório',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"name":"file","customRequest":_vm.uploadKMZFile,"file-list":_vm.kmz.list,"remove":_vm.removeKMZFile,"multiple":false,"accept":".kmz"}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"picture"}})],1),_c('p',{staticClass:"ant-upload-text"},[_c('font',{staticClass:"font"},[_vm._v("Clique aqui")]),_vm._v(" ou arraste o arquivo ")],1)]),(_vm.kmz.loading)?_c('a-icon',{attrs:{"type":"loading"}}):_vm._e()],1)],1),_c('a-divider'),_c('a-col',{staticClass:"media-upload",attrs:{"span":24}},[_c('div',{staticClass:"label"},[_vm._v(" Projetos, espelho do IPTU e outros documentos/fotos ")]),_c('a-form-item',[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'property_pictures',
                                {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Obrigatório',
                                        } ],
                                } ]),expression:"[\n                                'property_pictures',\n                                {\n                                    rules: [\n                                        {\n                                            required: false,\n                                            message: 'Obrigatório',\n                                        },\n                                    ],\n                                },\n                            ]"}],attrs:{"name":"file","accept":".pdf,.png,.jpg,.jpeg","customRequest":_vm.uploadPicturesFile,"file-list":_vm.pictures.list,"remove":_vm.removePicturesFile,"multiple":false}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"picture"}})],1),_c('p',{staticClass:"ant-upload-text"},[_c('font',{staticClass:"font"},[_vm._v("Clique aqui")]),_vm._v(" ou arraste o arquivo ")],1)]),(_vm.pictures.loading)?_c('a-icon',{attrs:{"type":"loading"}}):_vm._e()],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"mb-10 agree-conectar"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'agreed_terms',
                                {
                                    rules: [
                                        {
                                            required: _vm.validateThirdStep(),
                                            message: 'Obrigatório',
                                        } ],
                                } ]),expression:"[\n                                'agreed_terms',\n                                {\n                                    rules: [\n                                        {\n                                            required: validateThirdStep(),\n                                            message: 'Obrigatório',\n                                        },\n                                    ],\n                                },\n                            ]"}]},[_vm._v(" Concordo com os ")]),_c('a',{staticStyle:{"font-size":"13px","margin-left":"-2px"},on:{"click":function($event){_vm.openTermsOfService = true}}},[_vm._v("Termos da Conectar Investimentos")]),_vm._v(". ")],1),_c('a-modal',{attrs:{"title":"Termo de uso do APP CONECTAR","footer":"","width":"700px"},on:{"click":function($event){_vm.openTermsOfService = false}},model:{value:(_vm.openTermsOfService),callback:function ($$v) {_vm.openTermsOfService=$$v},expression:"openTermsOfService"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.termsOfUse)}})])],1)],1),_c('div',{staticClass:"clearfix"}),_c('a-row',{staticStyle:{"padding":"0 10px"},attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_c('a-button',{staticClass:"mt-20",staticStyle:{"width":"100%"},attrs:{"size":"large"},on:{"click":_vm.backToSecondStep}},[_c('a-icon',{attrs:{"type":"arrow-left"}}),_vm._v(" Voltar ")],1)],1),_c('a-col',[_c('a-button',{attrs:{"type":"primary","size":"large","loading":_vm.loadingCreateProperty},on:{"click":_vm.submitProperty}},[_vm._v(" Cadastrar ativo ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }