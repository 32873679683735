<template>
    <div id="print-area">
        <a-row class="header">
            <a-col :span="12">
                <img src="../../assets/images/conectar.png" width="240" />
            </a-col>

            <a-col class="extra" :span="12">
                Av. Edson Haddad, 505 Monte Líbano – Lavras/MG
                <br />
                conectar@conectarinvestimentos.com
                <br />
                +55 35 98825 6443
            </a-col>
        </a-row>

        <a-row class="body">
            <a-col class="row" :span="16">
                <label>Nome do ativo imobiliário:</label>
                {{ property.name }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Avaliação:</label>
                <a-rate v-model="property.meta.rate" allow-half disabled>
                    <a-icon slot="character" type="star" />
                </a-rate>
            </a-col>

            <a-col class="row" :span="5">
                <label>Status:</label>
                {{ formatStatus(property.status) }}
            </a-col>

            <a-col class="row" :span="11">
                <label>Habitantes:</label>
                {{ property.population }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Valor:</label>
                {{ property.value ? "R$ " + formatPrice(property.value) : "" }}
            </a-col>

            <a-col class="row" :span="12">
                <label>Data do cadastro:</label>
                {{ formatDate(property.created) }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Cadastrado por:</label>
                {{ property.owner.first_name }} {{ property.owner.last_name }}
            </a-col>

            <a-col class="row" :span="24">
                <label>Endereço:</label>
                {{ property.location_street }}, {{ property.location_number }},
                {{ property.location_neighborhood }}, {{ property.location_city
                }}{{ property.cep ? ", " + property.cep : "" }} -
                {{ property.location_state }}
                {{ property.cep ? " | " + property.location_complement : "" }}
            </a-col>

            <a-col class="row" :span="24">
                <h3 style="margin:0">Dados Gerais</h3>
            </a-col>

            <a-col class="row" :span="8">
                <label>Matrícula:</label>
                {{ property.registration }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Possui ônus:</label>
                {{ property.onus }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Zoneamento:</label>
                {{ property.meta.zone }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Vocação:</label>
                {{ property.business_type }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Situação:</label>
                {{ property.situation }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Metragem:</label>
                {{ property.metreage }} m²
            </a-col>

            <a-col class="row" :span="24">
                <label>Descrição do Ativo:</label>
                {{ property.property_description }}
            </a-col>

            <a-col class="row" :span="24">
                <label>Proposta comercial:</label>
                {{ property.commercial_proposal }}
            </a-col>

            <a-col class="row" :span="24">
                <label>Marcação do terreno (KMZ):</label>

                <div
                    id="mapa2"
                    class="mt-10"
                    style="width:100%; height: 250px;"
                ></div>
            </a-col>
        </a-row>
        <!-- 
        <br /><br /><br />
        <pre> {{ property }}</pre> -->
    </div>
</template>

<script>
import { parse, format } from "date-fns";
export default {
    props: {
        property: Object,
    },
    filters: {
        formatDate(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
    },
    mounted() {
        setTimeout(() => {
            let map = new window.google.maps.Map(
                document.getElementById("mapa2"),
                {
                    zoom: 16,
                }
            );

            let kml_parameters = {
                url: this.property.meta.kmz_file[0].url,
                map: map,
            };
            new window.google.maps.KmlLayer(kml_parameters);
        }, 2000);
    },
    methods: {
        formatDate(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
        formatPrice(value) {
            if (value) {
                return parseFloat(value).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                });
            }
        },
        formatStatus(status) {
            let theStatus = "";
            if (status == "active") {
                theStatus = "Ativo";
            }

            if (status == "disabled") {
                theStatus = "Desativado";
            }

            if (status == "blocked") {
                theStatus = "Bloqueado";
            }

            if (status == "negotiation") {
                theStatus = "Em Negociação";
            }

            return theStatus;
        },
    },
};
</script>


<style lang="sass">
#print-area
    .header
        padding: 20px
        background: #FFF
        .extra
            font-size: 11px
            font-weight: 600
            color: #999
            text-align: right
    .body
        padding: 20px
        background: #FFF
        margin-bottom: 10px
        .row
            padding-bottom: 20px
    label
        color: #333
        font-weight: 600
        font-size: 12px
        display: block
</style>
