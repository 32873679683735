<template>
    <a-form class="login-form" :form="form" @submit="handleSubmit">
        <a @click="$router.push('/login')">
            <a-icon type="arrow-left" /> Voltar
        </a>

        <div class="logo">
            <img src="../../assets/images/conectar.png" alt="logo" />
        </div>

        <h1 class="mt-30" v-if="$route.meta.slug === 'key'">Alterar Senha</h1>
        <h1 class="mt-30" v-if="$route.meta.slug === 'password'">
            Definição de Senha
        </h1>

        <a-form-item>
            <a-input
                class="underline-input"
                v-decorator="[
                    'password',
                    {
                        rules: [
                            {
                                required: true,
                                message:
                                    $route.meta.slug === 'key'
                                        ? 'Escreva sua nova senha.'
                                        : 'Escreva a sua senha.',
                            },
                        ],
                    },
                ]"
                type="password"
                :placeholder="
                    $route.meta.slug === 'key'
                        ? 'Escreva sua nova senha.'
                        : 'Escreva a sua senha'
                "
            >
                <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0, 0, 0, 0.25)"
                />
            </a-input>
        </a-form-item>

        <a-form-item>
            <a-input
                class="underline-input"
                v-decorator="[
                    'confirm',
                    {
                        rules: [
                            {
                                required: true,
                                message:
                                    $route.meta.slug === 'key'
                                        ? 'Repita a sua nova senha.'
                                        : 'Repita a sua senha.',
                            },
                            {
                                validator: compareToFirstPassword,
                            },
                        ],
                    },
                ]"
                type="password"
                :placeholder="
                    $route.meta.slug === 'key'
                        ? 'Repita sua nova senha'
                        : 'Repita a sua senha'
                "
            >
                <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0, 0, 0, 0.25)"
                />
            </a-input>
        </a-form-item>

        <a-form-item>
            <a-button
                class="mt-20"
                shape="round"
                type="primary"
                size="large"
                html-type="submit"
                :loading="loading"
            >
                Definir senha
            </a-button>
        </a-form-item>
    </a-form>
</template>

<script>
export default {
    metaInfo: {
        htmlAttrs: {
            lang: "pt-BR",
        },
        title: "Definição de senha – Conectar",
        meta: [
            {
                name: "descrition",
                content: "Página para definição de senhas do usuário.",
            },
        ],
    },
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
        };
    },
    mounted() {},
    methods: {
        changePassword(userData) {
            this.$http
                .post("/user/change-password.php", userData)
                .then(({ data }) => {
                    this.$message.success(data.message);
                    this.loading = false;
                    setTimeout(() => {
                        this.$router.push("/login");
                    }, 500);
                })
                .catch(({ response }) => {
                    this.$message.error(response.data.message);
                    this.loading = false;
                });
        },
        compareToFirstPassword(rule, value, callback) {
            const form = this.form;
            if (value && value !== form.getFieldValue("password")) {
                callback("As senhas digitadas não são iguais.");
            } else {
                callback();
            }
        },
        handleSubmit(e) {
            e.preventDefault();
            this.loading = true;
            this.form.validateFields((err, values) => {
                if (!err) {
                    values["hash"] = this.$route.params.hash;
                    this.changePassword(values);
                } else {
                    this.loading = false;
                }
            });
        },
    },
};
</script>
