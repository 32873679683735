<template>
  <div class="page">
    <a-page-header class="bg">
      <a-row type="flex" justify="space-between">
        <a-col>
          <h1>{{ $route.params.action | pageName }}</h1>
        </a-col>
        <a-col>
          <a-button
            v-if="
              (activeTab == 'users' &&
                $root.checkCapabilities(capabilities, 'users', 'create')) ||
                (activeTab == 'disabled-users' &&
                  $root.checkCapabilities(capabilities, 'users', 'create'))
            "
            class="red-btn"
            @click="newUser"
          >
            + Adicionar Afiliado
          </a-button>
        </a-col>
      </a-row>
    </a-page-header>

    <a-tabs v-model="activeTab" @change="changeTab">
      <a-tab-pane
        v-if="
          $root.haveAvatar() &&
            $root.checkCapabilities(capabilities, 'users', 'list')
        "
        key="users"
        tab="Ativos"
      ></a-tab-pane>

      <a-tab-pane
        v-if="$root.isAdmin()"
        key="disabled-users"
        tab="Desativados"
      ></a-tab-pane>
    </a-tabs>

    <a-row
      v-if="activeTab == 'users' || activeTab == 'disabled-users'"
      class="mb-40 filter-fields"
      :gutter="20"
    >
      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <a-form-item
          class="mb-10 pt-0 underline-wrapper"
          style="margin-top: 4px"
        >
          <label class="filled">Afiliado</label>
          <a-input
            class="underline-input"
            placeholder="Localizar usuário..."
            v-model="filters.userName"
            @change="getUsers()"
          >
            <a-icon
              slot="prefix"
              type="search"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label class="filled">Estados</label>
          <a-select
            class="underline-input"
            show-search
            :allow-clear="true"
            placeholder="Selecione"
            v-model="filters.states.selected"
            width="100%"
            @change="onChangeState"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label class="filled">Cidades</label>
          <a-select
            class="underline-input"
            show-search
            :allow-clear="true"
            placeholder="Selecione"
            v-model="filters.cities.selected"
            width="100%"
            @change="getUsers()"
          >
            <a-select-option
              v-for="(item, index) of filterCitiesByState(citiesList)"
              :key="index"
              :value="item.city"
            >
              {{ item.city }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 6,
            },
          ])
        "
      >
        <a-form-item class="underline-wrapper pt-0">
          <label class="filled">Data de cadastro</label>
          <a-range-picker
            class="mt-10"
            v-model="filters.period.selected"
            :format="['DD/MM/YYYY']"
            :value-format="'YYYY-MM-DD'"
            @change="getUsers()"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-table
      v-if="activeTab == 'users' || activeTab == 'disabled-users'"
      :columns="columns"
      :data-source="listUsers"
      :loading="loadingUsers"
      :pagination="false"
      :scroll="{ x: 1300 }"
    >
      <div slot="id" slot-scope="text, record">{{ record.id }}</div>
      <div slot="first_name" slot-scope="text, record">
        <a-row :gutter="5" type="flex" align="middle">
          <a-col :span="4" class="profile-pic">
            <a-avatar :size="40" :src="record.avatar" icon="user" />
            <a-icon
              v-if="record.role == 'corporate-admin'"
              type="star"
              theme="filled"
            />
          </a-col>
          <a-col :span="20">
            <div class="name">
              {{ record.first_name }}
              {{ record.last_name }}
            </div>
            <div class="email">{{ record.email }}</div>
          </a-col>
        </a-row>
      </div>
      <div slot="mobile_phone" slot-scope="text, record">
        {{ record.mobile_phone }}
      </div>
      <div slot="cpf" slot-scope="text, record">{{ record.cpf }}</div>
      <div slot="company" slot-scope="text, record">
        <a-button
          v-if="record.meta['company'] != undefined"
          type="link"
          style="padding: 0"
          @click="viewCompanyDetails(record)"
        >
          <div class="dotted-phrase" style="width: 160px; text-align: left">
            {{ record.meta.company.trading_name }}
          </div>
        </a-button>
      </div>
      <div slot="created" slot-scope="text, record">
        {{ record.created | formatDate }}
      </div>
      <div slot="action" slot-scope="record">
        <a-tooltip
          v-if="$root.checkCapabilities(capabilities, 'users', 'edit')"
          placement="top"
        >
          <template slot="title">
            <span>Editar</span>
          </template>
          <a @click="edit(record)">
            <a-icon type="edit" />
          </a>
        </a-tooltip>

        <a-tooltip
          v-if="$root.checkCapabilities(capabilities, 'users', 'view')"
          placement="top"
        >
          <template slot="title">
            <span>Ver</span>
          </template>
          <a class="ml-10" @click="view(record)"
            ><img
              class="relative"
              src="../../assets/images/view.png"
              alt="ver"
              width="20"
              style="top: -4px"
          /></a>
        </a-tooltip>

        <a-popconfirm
          v-if="$root.checkCapabilities(capabilities, 'users', 'delete')"
          title="Tem certeza que deseja apagar este usuário?"
          ok-text="Sim"
          cancel-text="Não"
          @confirm="deleteUser(record.id)"
        >
          <a-icon slot="icon" type="question-circle-o" style="color: red" />
          <a-tooltip placement="top">
            <template slot="title">
              <span>Apagar</span>
            </template>
            <a class="ml-10"><a-icon type="delete"/></a>
          </a-tooltip>
        </a-popconfirm>
      </div>
    </a-table>

    <div
      v-if="activeTab == 'users' || activeTab == 'disabled-users'"
      class="a-center mt-30"
      style="padding-bottom: 100px"
    >
      <a-pagination
        show-size-changer
        :default-current="pagination.page"
        :page-size.sync="pagination.perPage"
        :total="pagination.total"
        @change="changePage"
        @showSizeChange="changePageSize"
      />
    </div>

    <a-drawer
      title="Adicionar Afiliado"
      placement="right"
      :width="
        $root.reponsive([
          {
            min: 0,
            max: 600,
            span: '100%',
          },
          {
            min: 601,
            max: 9999,
            span: '600px',
          },
        ])
      "
      class="conectar-drawer"
      :closable="true"
      :visible="drawers.createUsers"
      @close="closeUsersDrawer"
    >
      <a-row v-if="$root.isAdmin()" :gutter="16">
        <a-col :span="24">
          <a-form-item class="mb-10">
            <a-radio-group v-model="selectedPersonType" :options="personTypes"
          /></a-form-item>
        </a-col>
      </a-row>
      <CreateUsers
        v-if="drawers.createUsers && selectedPersonType == 'Pessoa Física'"
        @listUsersAfterCreate="listUsersAfterCreate"
        @closeDrawerAfterCreate="closeDrawerAfterCreate"
      />
      <CreateCompanyUser
        v-if="drawers.createUsers && selectedPersonType == 'Pessoa Jurídica'"
        @listUsersAfterCreate="listUsersAfterCreate"
        @closeDrawerAfterCreate="closeDrawerAfterCreate"
      />
    </a-drawer>
  </div>
</template>

<script>
import _ from "lodash";
import { parse, format } from "date-fns";

import states from "../../lists/states.json";
import CreateUsers from "./forms/CreateUsers.vue";
import CreateCompanyUser from "./forms/CreateCompanyUser.vue";
import capabilities from "../../mixins/general/capabilities.js";
import usersMixins from "@/mixins/users/usersMixins.js";

export default {
  components: { CreateUsers, CreateCompanyUser },
  mixins: [capabilities, usersMixins],
  data() {
    return {
      states,
      activeTab: "users",
      selectedPersonType: "Pessoa Física",
      personTypes: [
        { label: "Pessoa Física", value: "Pessoa Física" },
        { label: "Pessoa Jurídica", value: "Pessoa Jurídica" },
      ],
      loadingDisabledUsers: false,
      drawers: {
        users: false,
        createUsers: false,
        createCompanies: false,
      },
      columns: [
        {
          title: "ID",
          key: "id",
          width: 60,
          scopedSlots: { customRender: "id" },
        },
        {
          title: "Nome",
          key: "first_name",
          scopedSlots: { customRender: "first_name" },
          width: 400,
        },
        {
          title: "Celular",
          key: "mobile_phone",
          scopedSlots: { customRender: "mobile_phone" },
          width: 140,
        },
        {
          title: "Empresa",
          key: "company",
          scopedSlots: { customRender: "company" },
          width: 180,
        },
        {
          title: "Data de cadastro",
          key: "created",
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "right",
          fixed: "right",
          width: 120,
        },
      ],
    };
  },
  filters: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
    pageName(value) {
      if (value === "proprietarios-terreno") {
        return "Proprietários de terreno";
      } else if (value === "gestores-regionais") {
        return "Gestores regionais";
      } else if (value === "investidores") {
        return "Investidores";
      } else {
        return value;
      }
    },
  },
  beforeMount() {
    if (!this.$root.isAdmin()) {
      this.$router.push(`/`);
    }

    setTimeout(() => {
      let body = document.body;
      body.classList.add("conectar");
      body.classList.add("users");
      body.classList.add("list");
    }, 20);

    document.title = `${this.pageName(this.$route.params.action)} - Conectar`;
  },
  mounted() {
    this.filters.role.selected = "business-manager";
    this.filters.userProfile.selected = [];

    setTimeout(() => {
      this.getUsers();
    }, 100);
  },
  beforeDestroy() {
    let body = document.body;
    body.classList.remove("users");
    body.classList.remove("list");
    document.title = `${this.pageName(this.$route.params.action)} - Conectar`;
  },
  methods: {
    onChangeState() {
      this.getUsers();
    },
    filterCitiesByState(arr) {
      let filteredData = arr;

      if (this.filters.states.selected != undefined) {
        if (this.filters.states.selected.length > 0) {
          filteredData = _.filter(arr, { state: this.filters.states.selected });
        }
      }

      return filteredData;
    },

    newUser() {
      this.drawers.createUsers = true;
    },
    closeUsersDrawer() {
      this.drawers.createUsers = false;
    },
    deleteUser(id) {
      this.loadingUsers = true;
      this.loadingDisabledUsers = true;
      this.$http
        .post("/user/delete.php", {
          id,
        })
        .then(({ data }) => {
          data;
          this.getUsers();
          this.getDisabledUsers();
          this.$message.success("Apagado!");
        })
        .catch(({ response }) => {
          response;
          this.loadingUsers = false;
          this.loadingDisabledUsers = false;
        });
    },
    view(user) {
      this.$router.push("/afiliados/ver/" + user.id);
    },
    edit(user) {
      this.$router.push("/afiliados/editar/" + user.id);
    },
    listUsersAfterCreate() {
      setTimeout(() => {
        this.getUsers();
        this.getDisabledUsers();
      }, 1000);
    },
    closeDrawerAfterCreate() {
      setTimeout(() => {
        this.drawers.createUsers = false;
        this.drawers.createCompanies = false;
      }, 500);
    },
    viewOwner(id) {
      this.$router.push("/afiliados/ver/" + id);
    },
    viewCompanyDetails(user) {
      this.$router.push("/empresas/ver/" + user.meta.company.id);
    },
    changeTab(key) {
      this.activeTab = key;
      this.getUsers();
    },
  },
};
</script>
