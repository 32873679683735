<template>
  <a-form :form="form" @submit="handleSubmit">
    <a-row :gutter="16">
      <h3>Dados pessoais</h3>
      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 12,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="this.form.getFieldValue('first_name') ? 'filled' : ''"
            >Primeiro nome</label
          >
          <a-input
            class="underline-input"
            placeholder="Primeiro nome"
            v-decorator="[
              'first_name',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 12,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="this.form.getFieldValue('last_name') ? 'filled' : ''"
            >Sobrenome</label
          >
          <a-input
            class="underline-input"
            placeholder="Sobrenome"
            v-decorator="[
              'last_name',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 11,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="this.form.getFieldValue('email') ? 'filled' : ''"
            >E-mail
          </label>
          <a-input
            class="underline-input"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido.',
                  },
                  {
                    required: true,
                    message: 'Escreva seu e-mail no campo.',
                  },
                ],
              },
            ]"
            placeholder="E-mail"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 7,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="this.form.getFieldValue('cpf') ? 'filled' : ''"
            >CPF</label
          >
          <a-input
            class="underline-input"
            placeholder="CPF"
            v-mask="'###.###.###-##'"
            @blur="verifyTypedCPF"
            v-decorator="[
              'cpf',
              {
                rules: [
                  {
                    required: true,
                    min: 9,
                    message: 'Preencha todos números.',
                  },
                ],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 6,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="this.form.getFieldValue('birthday') ? 'filled' : ''"
            >Nascimento</label
          >

          <a-date-picker
            class="underline-input"
            placeholder="Nascimento"
            format="DD/MM/YYYY"
            v-mask="'##/##/####'"
            :showToday="false"
            :disabled-date="adultHoodDate"
            @openChange="defaultDate"
            v-decorator="[
              'birthday',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-date-picker>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 8,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="this.form.getFieldValue('rg') ? 'filled' : ''"
            >RG</label
          >
          <a-input
            class="underline-input"
            placeholder="RG"
            v-mask="['##.###.###', '#.###.###']"
            v-decorator="[
              'rg',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                  {
                    min: 9,
                    message: 'Preencha todos números',
                  },
                ],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 10,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label
            :class="this.form.getFieldValue('rg_doc_emission') ? 'filled' : ''"
            >Órgão Emissor</label
          >
          <a-input
            class="underline-input"
            placeholder="Órgão Emissor"
            v-decorator="[
              'rg_doc_emission',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 6,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label
            :class="
              this.form.getFieldValue('rg_doc_emission_state') ? 'filled' : ''
            "
            >Estado da Emissão</label
          >
          <a-select
            class="underline-input"
            show-search
            placeholder="ES"
            v-decorator="[
              'rg_doc_emission_state',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 10,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="this.form.getFieldValue('profession') ? 'filled' : ''"
            >Profissão</label
          >

          <a-select
            class="underline-input"
            placeholder="Profissão"
            show-search
            v-decorator="[
              'profession',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório.',
                  },
                ],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) in professions"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 7,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label
            :class="this.form.getFieldValue('mobile_phone') ? 'filled' : ''"
            >Telefone celular</label
          >
          <a-input
            class="underline-input"
            placeholder="Insira"
            v-mask="['(##) # ####-####']"
            @blur="validatePhoneNumber"
            v-decorator="[
              'mobile_phone',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 7,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="this.form.getFieldValue('phone') ? 'filled' : ''"
            >Telefone fixo
          </label>
          <a-input
            class="underline-input"
            placeholder="Telefone fixo"
            v-mask="['(##) ####-####']"
            v-decorator="['phone']"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        v-if="$root.isAdmin()"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 10,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="this.form.getFieldValue('role') ? 'filled' : ''"
            >Função do usuário</label
          >

          <a-select
            class="underline-input"
            placeholder="Função do usuário"
            v-decorator="[
              'role',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório.',
                  },
                ],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) in roles"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        v-if="!$root.isAdmin()"
        :span="24"
        class="mb-20 mt-30"
        style="display: none"
      >
        <a-form-item>
          <input
            type="text"
            v-decorator="[
              'role',
              {
                initialValue: $root.isCorporateAdmin()
                  ? 'corporate-affiliate'
                  : 'user',
              },
            ]"
          />
        </a-form-item>
      </a-col>

      <a-col v-if="form.getFieldValue('role') == 'investor'" :span="24">
        <h3>Perfil de interesse</h3>
      </a-col>

      <InvestorProfile
        v-if="form.getFieldValue('role') == 'investor'"
        :form="form"
      />

      <a-col :span="24">
        <h3>Endereço</h3>
      </a-col>

      <a-col
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 12,
            },
          ])
        "
      >
        <a-form-item class="mb-10 underline-wrapper">
          <label :class="this.form.getFieldValue('cep') ? 'filled' : ''"
            >CEP</label
          >
          <a-input
            class="underline-input"
            placeholder="CEP"
            v-mask="['#####-###']"
            v-decorator="[
              'cep',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
            @change="getLocation()"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label
            :class="this.form.getFieldValue('location_street') ? 'filled' : ''"
            >Endereço</label
          >
          <a-input
            class="underline-input"
            placeholder="Endereço"
            v-decorator="[
              'location_street',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item class="mb-10 underline-wrapper">
          <label
            :class="this.form.getFieldValue('location_number') ? 'filled' : ''"
            >Número</label
          >
          <a-input
            class="underline-input"
            placeholder="Número"
            v-decorator="[
              'location_number',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="16">
        <a-form-item class="mb-10 underline-wrapper">
          <label
            :class="
              this.form.getFieldValue('location_neighborhood') ? 'filled' : ''
            "
            >Bairro</label
          >
          <a-input
            class="underline-input"
            placeholder="Bairro"
            v-decorator="[
              'location_neighborhood',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="18">
        <a-form-item class="mb-10 underline-wrapper">
          <label
            :class="this.form.getFieldValue('location_city') ? 'filled' : ''"
            >Cidade</label
          >
          <a-input
            class="underline-input"
            placeholder="Cidade"
            v-decorator="[
              'location_city',
              {
                rules: [{ required: true, message: 'Obrigatório' }],
              },
            ]"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item class="mb-10 underline-wrapper">
          <label
            :class="this.form.getFieldValue('location_state') ? 'filled' : ''"
            >Estado</label
          >
          <a-select
            class="underline-input"
            show-search
            placeholder="ES"
            v-decorator="[
              'location_state',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="mb-10 underline-wrapper">
          <label
            :class="
              this.form.getFieldValue('location_complement') ? 'filled' : ''
            "
            >Complemento</label
          >
          <a-input
            class="underline-input"
            placeholder="Complemento"
            v-decorator="['location_complement']"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24" class="mb-20 mt-30">
        <a-switch v-model="userPassword" size="small" />
        Deseja enviar definição de senha para
        <strong>
          {{
            this.form.getFieldValue("email")
              ? this.form.getFieldValue("email")
              : "o email informado"
          }}</strong
        >?
      </a-col>

      <a-col
        v-if="setPassword()"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 12,
            },
          ])
        "
      >
        <a-form-item>
          <a-input
            class="underline-input"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: setPassword(),
                    message: 'Digite a senha',
                  },
                ],
              },
            ]"
            type="password"
            placeholder="Digite a senha"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        v-if="setPassword()"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 12,
            },
          ])
        "
      >
        <a-form-item>
          <a-input
            class="underline-input"
            v-decorator="[
              'confirm_password',
              {
                rules: [
                  {
                    required: setPassword(),
                    message: 'Repita a senha.',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              },
            ]"
            type="password"
            placeholder="Repita a senha"
          >
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item class="underline-wrapper a-center mt-30">
          <a-button
            type="primary"
            size="large"
            html-type="submit"
            :loading="loading"
          >
            Cadastrar usuário
          </a-button>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import axios from "axios";
import subYears from "date-fns/subYears";
import { format } from "date-fns";
import states from "../../../lists/states.json";
import professions from "../../../lists/professions.json";
import roles from "../../../lists/roles.json";
import InvestorProfile from "./InvestorProfile.vue";

export default {
  components: { InvestorProfile },
  data() {
    return {
      form: this.$form.createForm(this),
      userPassword: true,
      loading: false,
      states,
      professions,
      roles,
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        this.loading = true;
        let userData = values;

        userData = {
          ...userData,
          agreed_contract: 1,
          rate: 0,
          status: 1,
          is_approved: 0,
          sendEmail: this.userPassword,
          created_by:
            this.$store.state.userData.role != "admin"
              ? this.$store.state.userData.id
              : 0,
        };

        if (this.userPassword) {
          userData = {
            ...userData,
            password: "Cf$QK?6/2vcDjSe" + new Date().getTime(),
            confirm_password: "Cf$QK?6/2vcDjSe" + new Date().getTime(),
          };
        }

        userData.business_type_of_interest = userData.business_type_of_interest
          ? JSON.stringify(userData.business_type_of_interest)
          : "[]";
        userData.area_size_of_interest = userData.area_size_of_interest
          ? JSON.stringify(userData.area_size_of_interest)
          : "[]";
        userData.region_of_interest = userData.region_of_interest
          ? JSON.stringify(userData.region_of_interest)
          : "[]";
        userData.state_of_interest = userData.state_of_interest
          ? JSON.stringify(userData.state_of_interest)
          : "[]";
        userData.population_of_interest = userData.population_of_interest
          ? JSON.stringify(userData.population_of_interest)
          : "[]";

        console.log("createUsers", err, userData);

        if (!err) {
          this.$http
            .post("/user/create.php", userData)
            .then(({ data }) => {
              data;
              this.loading = false;
              this.$emit("listUsersAfterCreate", true);
              this.$emit("closeDrawerAfterCreate", true);
              this.$message.success(data.message);

              setTimeout(() => {
                this.form.setFieldsValue({
                  first_name: "",
                  last_name: "",
                  email: "",
                  cpf: "",
                  mobile_phone: "",
                  phone: "",
                  birthday: "",
                  rg: "",
                  rg_doc_emission: "",
                  rg_doc_emission_state: "",
                  company: "",
                  profession: "",
                  cep: "",
                  location_street: "",
                  location_number: "",
                  location_state: "",
                  location_city: "",
                  location_neighborhood: "",
                  location_complement: "",
                });
              }, 500);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
    validatePhoneNumber(val) {
      // Remove non-numeric characters from the phone number
      let phoneNumber = val.target.value
        ? val.target.value.replace(/\D/g, "")
        : "";

      // Check if the phone number has the correct length
      if (phoneNumber.length !== 11) {
        this.form.setFieldsValue({
          [`mobile_phone`]: undefined,
        });
        return false;
      }

      // Check if the phone number has repeated digits
      if (
        [
          "00000000000",
          "11111111111",
          "22222222222",
          "33333333333",
          "44444444444",
          "55555555555",
          "66666666666",
          "77777777777",
          "88888888888",
          "99999999999",
        ].includes(phoneNumber)
      ) {
        this.form.setFieldsValue({
          [`mobile_phone`]: undefined,
        });
        return false;
      }

      // Phone number is valid
      return true;
    },
    verifyTypedCPF(val) {
      console.log(val.target.value);

      // Remover caracteres especiais do CPF
      let cpf = val.target.value ? val.target.value.replace(/\D/g, "") : "";

      if (!/^\d{11}$/.test(cpf)) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });
        return false;
      }

      if (/^(\d)\1{10}$/.test(cpf)) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });

        return false;
      }

      if (
        [
          "00000000000",
          "11111111111",
          "00011122233",
          "22222222222",
          "33333333333",
          "44444444444",
          "55555555555",
          "66666666666",
          "77777777777",
          "88888888888",
          "99999999999",
        ].includes(cpf)
      ) {
        this.form.setFieldsValue({
          [`cpf`]: undefined,
        });

        return false;
      }

      return true;
    },
    async compareToFirstPassword(rule, value, callback) {
      rule;
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("As senhas digitadas não são iguais.");
      } else {
        callback();
      }
    },
    setPassword() {
      return !this.userPassword;
    },
    getLocation() {
      setTimeout(() => {
        if (this.form.getFieldValue("cep")) {
          if (this.form.getFieldValue("cep").replace("-", "").length == 8) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.form.getFieldValue("cep").replace("-", "") +
                  "/json/"
              )
              .then(({ data }) => {
                data;
                this.cepLoading = false;
                this.form.setFieldsValue({
                  location_street: data.logradouro,
                  location_state: data.uf,
                  location_city: data.localidade,
                  location_neighborhood: data.bairro,
                  location_complement: data.complemento,
                });
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 110);
    },
    defaultDate() {
      setTimeout(() => {
        this.form.setFieldsValue({
          birthday: this.form.getFieldValue("birthday")
            ? this.form.getFieldValue("birthday")
            : format(subYears(new Date(), 18), "MM/dd/yyyy"),
        });
      }, 1);
    },
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 18);
    },
    closeSuccessModal() {
      setTimeout(() => {
        this.$router.push(`/login`);
      }, 500);
    },
  },
};
</script>
