<template>
  <div>
    <div class="files-section pdf" v-if="files.pdf.files.length > 0">
      <div class="title">
        <font class="txt"> ARQUIVOS PDF</font>
      </div>
      <span v-for="(file, index) in files.pdf.files" :key="index">
        <a target="_blank" :href="file" :title="file">
          <a-card hoverable style="width: 100px">
            <a-icon class="pdf-file" type="file-pdf" />
            <a-card-meta :title="`ARQUIVO ${index + 1}`"> </a-card-meta>
          </a-card>
        </a>
      </span>
    </div>

    <div
      class="files-section"
      v-if="
        files.png.files.length > 0 ||
          files.jpg.files.length > 0 ||
          files.jpeg.files.length > 0
      "
    >
      <div class="title">
        <font class="txt"> ARQUIVOS DE IMAGEM</font>
      </div>
      <viewer class="viewer" :options="options">
        <img v-for="src in files.png.files" :key="src" :src="src" />
        <img v-for="src in files.jpg.files" :key="src" :src="src" />
        <img v-for="src in files.gif.files" :key="src" :src="src" />
        <img v-for="src in files.jpeg.files" :key="src" :src="src" />
      </viewer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    files: Object,
  },
  data() {
    return {
      options: {
        title: false,
      },
    };
  },
};
</script>