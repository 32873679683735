<template>
  <div class="pages" style="padding-bottom: 100px">
    <a-page-header class="bg">
      <template slot="backIcon">
        <a-icon type="left-circle" /> Voltar
      </template>
    </a-page-header>

    <div class="box ">
      <div v-if="loadedPage">
        <h1>{{ title }}</h1>
        <div v-html="content"></div>
      </div>
      <div v-if="!loadedPage">
        <h1><a-skeleton title active /></h1>
        <a-skeleton active />
        <a-skeleton active />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      content: "",
      loadedPage: false,
    };
  },
  beforeCreate() {
    this.$http
      .post("/external-apis/get-wp-pages.php", {
        id: "2",
      })
      .then(({ data }) => {
        this.loadedPage = true;
        this.title = data.title;
        this.content = data.content;
      })
      .catch(({ response }) => {
        response;
      });
  },
};
</script>

<style>
iframe {
  width: 100% !important;
}
</style>
