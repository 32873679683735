<template>
    <a-form class="login-form" :form="form" @submit="handleSubmit">
        <div class="logo">
            <img src="../../assets/images/conectar.png" alt="logo" />
        </div>

        <h1 class="mb-20">Login</h1>

        <a-form-item class="underline-outer">
            <a-input
                class="underline-input"
                size="large"
                v-decorator="['access_key']"
                placeholder="Insira seu E-mail ou CPF"
                @keyup="detectFieldValue"
            >
                <a-icon
                    slot="prefix"
                    :type="isEmail ? 'mail' : 'idcard'"
                    style="color: rgba(0, 0, 0, 0.25)"
                />
            </a-input>
        </a-form-item>

        <a-form-item class="underline-wrapper mb-20">
            <a-input-password
                class="underline-input"
                size="large"
                v-decorator="[
                    'password',
                    {
                        rules: [
                            {
                                required: true,
                                message: 'Escreva sua senha.',
                            },
                        ],
                    },
                ]"
                type="password"
                placeholder="Insira sua senha"
            >
                <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0, 0, 0, 0.25)"
                />
            </a-input-password>
        </a-form-item>
        <a-form-item class="remember-forgot mb-20">
            <a-checkbox
                v-decorator="[
                    'remember',
                    {
                        valuePropName: 'checked',
                        initialValue: true,
                    },
                ]"
            >
                Lembrar
            </a-checkbox>

            <a class="login-form-forgot" @click="forgotPassword()">
                Esqueceu sua senha?
            </a>
        </a-form-item>

        <a-form-item>
            <a-button
                type="primary"
                size="large"
                html-type="submit"
                :loading="loading"
                class="mb-20"
                shape="round"
            >
                LOGIN
            </a-button>

            <a-button
                class="register"
                size="large"
                shape="round"
                @click="$router.push(`/cadastro`)"
            >
                CADASTRAR
            </a-button>
        </a-form-item>
    </a-form>
</template>

<script>
export default {
    metaInfo: {
        htmlAttrs: {
            lang: "pt-BR",
        },
        title: "Login – Conectar",
        meta: [
            {
                name: "description",
                content: "Página de acesso ao sistema.",
            },
        ],
    },
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
            isCPF: false,
            isEmail: true,
        };
    },
    methods: {
        detectFieldValue(val) {
            if (val.target.value.length > 2) {
                if (
                    Number.isInteger(
                        parseInt(
                            val.target.value
                                .replace(".", "")
                                .replace(".", "")
                                .replace("-", "")
                        )
                    )
                ) {
                    this.isCPF = true;
                    this.isEmail = false;
                } else {
                    this.isCPF = false;
                    this.isEmail = true;
                }
            } else {
                this.isEmail = true;
                this.isCPF = false;
            }
        },
        userAuthentication(userData) {
            userData.isCPF = this.isCPF;
            userData.isEmail = this.isEmail;

            if (this.isCPF) {
                let theKey = userData.access_key;
                userData.access_key = `${theKey.substring(
                    0,
                    3
                )}.${theKey.substring(3, 6)}.${theKey.substring(
                    6,
                    9
                )}-${theKey.substring(9, 11)}${theKey.substring(11, 110)}`;
            }

            this.$http
                .post("auth/token.php", userData)
                .then(({ data }) => {
                    this.$root.token = data.jwt;
                    this.$root.tokenExpiration = data.expireAt;

                    localStorage.setItem("token", data.jwt);
                    localStorage.setItem("isLoggedIn", data.isLoggedIn);
                    localStorage.setItem("tokenExpiration", data.expireAt);
                    localStorage.setItem(
                        "userData",
                        JSON.stringify(data.userData)
                    );

                    this.$message.success(data.message);
                    setTimeout(() => {
                        window.open("/", "_self");
                    }, 500);

                    this.loading = false;
                })
                .catch(({ response }) => {
                    this.$message.error(response.data.message);
                    this.loading = false;
                });
        },
        forgotPassword() {
            this.$router.push(`/esqueci-minha-senha`);
        },
        handleSubmit(e) {
            e.preventDefault();
            this.loading = true;
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.userAuthentication(values);
                    console.log("Received values of form: ", values);
                } else {
                    this.loading = false;
                }
            });
        },
    },
};
</script>
