<template>
  <div class="page">
    <a-page-header class="bg" sub-title=" " @back="() => $router.go(-1)">
      <template slot="backIcon">
        <a-icon type="left-circle" /> Voltar
      </template>
    </a-page-header>
    <!-- <pre>
        {{registration_docs}} </pre> -->

    <a-form
      v-if="$root.checkCapabilities(capabilities, 'properties', 'list')"
      :form="editPropertyForm"
      @submit="handleSubmit"
    >
      <a-row id="print" :gutter="25">
        <a-col
          class="gutter-row sidebar mb-20"
          :span="$root.windowWidth > 1240 ? 6 : 24"
        >
          <a-row>
            <a-col class="box no-line bg mb-20" :span="24">
              <div class="line">
                <!-- <div class="title">

                                </div> -->
                <div class="text">
                  <a-form-item class="mb-0 underline-wrapper">
                    <label>
                      Nome do ativo Imobiliário
                    </label>
                    <a-input
                      class="underline-input"
                      placeholder="Escreva o nome do ativo Imobiliário..."
                      v-decorator="[
                        'name',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                    </a-input>
                  </a-form-item>
                </div>
              </div>

              <div class="line">
                <div class="text">
                  <a-form-item class="mb-10">
                    <a-radio-group
                      size="small"
                      v-decorator="[
                        'is_owner',
                        {
                          initialValue: 'yes',
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <a-radio
                        value="yes"
                        style="
                                                    font-size: 12px;
                                                    font-weight: 600;
                                                "
                      >
                        Sou proprietário
                      </a-radio>
                      <a-radio
                        value="no"
                        style="
                                                    font-size: 12px;
                                                    font-weight: 600;
                                                "
                      >
                        Sou Intermediador
                      </a-radio>
                    </a-radio-group>
                  </a-form-item>
                  <a-form-item
                    v-if="editPropertyForm.getFieldValue('is_owner') == 'no'"
                    class="mb-10 underline-wrapper"
                  >
                    <label>
                      Nome do proprietário do ativo
                    </label>
                    <a-input
                      class="underline-input"
                      placeholder="Nome do proprietário do ativo"
                      v-decorator="[
                        'owner_first_name',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                    </a-input>
                  </a-form-item>

                  <a-form-item
                    v-if="editPropertyForm.getFieldValue('is_owner') == 'no'"
                    class="mb-10 underline-wrapper"
                  >
                    <label>
                      Sobrenome do proprietário do ativo
                    </label>
                    <a-input
                      class="underline-input"
                      placeholder="Sobrenome do proprietário do ativo"
                      v-decorator="[
                        'owner_last_name',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                    </a-input>
                  </a-form-item>

                  <div class="clearfix" />
                </div>
              </div>

              <div class="line">
                <!-- <div class="title">Status</div> -->
                <div class="text">
                  <a-form-item class="underline-wrapper">
                    <label> Status </label>
                    <a-select
                      class="underline-input"
                      show-search
                      placeholder="Status"
                      v-decorator="[
                        'status',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <a-select-option
                        v-for="(item, index) of status"
                        :key="index"
                        :value="item.value"
                      >
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </div>

              <div class="line">
                <!-- <div class="title">Valor (R$)</div> -->
                <div class="text">
                  <a-form-item class="underline-wrapper">
                    <label> Valor (R$) </label>
                    <a-input
                      class="underline-input"
                      placeholder="Valor (R$)"
                      :disabled="checkNegotiation"
                      v-currency="{
                        currency: {
                          prefix: '',
                          suffix: '',
                        },
                        distractionFree: false,
                        valueAsInteger: false,
                        precision: 2,
                        autoDecimalMode: true,
                        allowNegative: false,
                      }"
                      v-decorator="[
                        'value',
                        {
                          rules: [
                            {
                              required: !checkNegotiation,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                    </a-input>
                  </a-form-item>
                  <a-form-item
                    style="
                                            margin-top: -10px;
                                            margin-bottom: -1px;
                                        "
                  >
                    <a-checkbox
                      v-decorator="['negotiate_value']"
                      :checked="checkNegotiation"
                      @change="changeCheckNegotiation"
                      style="
                                                font-size: 12px;
                                                font-weight: 600;
                                            "
                    >
                      Valor a negociar
                    </a-checkbox>
                  </a-form-item>
                </div>
              </div>

              <div class="line">
                <!-- <div class="title">Avaliação</div> -->
                <div class="text">
                  <a-form-item class="underline-wrapper">
                    <label> Avaliação </label>

                    <div class="rate mb-10">
                      <a-rate v-model="propertyRate" allow-half>
                        <a-icon slot="character" type="star" />
                      </a-rate>
                    </div>
                  </a-form-item>
                </div>
              </div>

              <a-row>
                <a-col
                  class="a-center"
                  style="
                                        border-top: 1px solid #ddd;
                                        padding-top: 14px;
                                    "
                >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Ver</span>
                    </template>
                    <a
                      @click="view(property.id)"
                      style="
                                                height: 40px;
                                                line-height: 10px;
                                                font-size: 24px;
                                            "
                    >
                      <a-icon class="cblue" type="eye" />
                    </a>
                  </a-tooltip>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
        <a-col class="gutter-row" :span="$root.windowWidth > 1240 ? 18 : 24">
          <div class="box tabs bg">
            <a-tabs default-active-key="data">
              <a-tab-pane key="data" tab="Informações" forceRender>
                <h3>Localização</h3>
                <a-row class="data-row" :gutter="20">
                  <a-col :span="$root.windowWidth > 961 ? 5 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">CEP</label>
                      <a-input
                        class="underline-input"
                        placeholder="CEP (opcional)"
                        v-mask="['#####-###']"
                        v-decorator="[
                          'cep',
                          {
                            rules: [
                              {
                                required: false,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                        @keyup="getLocation()"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col :span="$root.windowWidth > 961 ? 16 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Endereço</label>
                      <a-input
                        class="underline-input"
                        placeholder="Endereço"
                        v-decorator="[
                          'location_street',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="$root.windowWidth > 961 ? 3 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Número</label>
                      <a-input
                        class="underline-input"
                        placeholder="Número"
                        v-decorator="[
                          'location_number',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="$root.windowWidth > 961 ? 7 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Complemento</label>
                      <a-input
                        class="underline-input"
                        placeholder="Complemento"
                        v-decorator="['location_complement']"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="$root.windowWidth > 961 ? 5 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Bairro</label>
                      <a-input
                        class="underline-input"
                        placeholder="Bairro"
                        v-decorator="[
                          'location_neighborhood',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="$root.windowWidth > 961 ? 8 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Cidade</label>
                      <a-input
                        class="underline-input"
                        placeholder="Cidade"
                        v-decorator="[
                          'location_city',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="$root.windowWidth > 961 ? 4 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Estado</label>
                      <a-select
                        class="underline-input"
                        show-search
                        placeholder="ES"
                        v-decorator="[
                          'location_state',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                        <a-select-option
                          v-for="(item, index) of states"
                          :key="index"
                          :value="item.initials"
                        >
                          {{ item.initials.toUpperCase() }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :span="$root.windowWidth > 961 ? 7 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Habitantes</label>
                      <a-select
                        class="underline-input"
                        show-search
                        placeholder="Habitantes"
                        v-decorator="[
                          'population',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                        <a-select-option
                          v-for="(item, index) of population"
                          :key="index"
                          :value="item.label"
                        >
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>

                <h3>Dados Gerais</h3>

                <a-row class="data-row" :gutter="20">
                  <a-col :span="$root.windowWidth > 961 ? 5 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">
                        Matrícula
                      </label>
                      <a-input
                        class="underline-input"
                        placeholder="Nº da matrícula"
                        v-decorator="[
                          'registration',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col :span="$root.windowWidth > 961 ? 5 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">
                        Zoneamento
                      </label>
                      <a-select
                        class="underline-input"
                        show-search
                        placeholder="Zoneamento"
                        v-decorator="[
                          'zone',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                        <a-select-option value="Residencial">
                          Residencial
                        </a-select-option>
                        <a-select-option value="Industrial">
                          Industrial
                        </a-select-option>
                        <a-select-option value="Misto">
                          Misto
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="$root.windowWidth > 961 ? 4 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Possui ônus</label>
                      <a-select
                        class="underline-input"
                        show-search
                        placeholder="Selecione"
                        v-decorator="[
                          'onus',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                        <a-select-option value="Sim">
                          Sim
                        </a-select-option>
                        <a-select-option value="Não">
                          Não
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :span="$root.windowWidth > 961 ? 6 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Vocação</label>
                      <a-select
                        class="underline-input"
                        show-search
                        placeholder="Selecione"
                        v-decorator="[
                          'business_type',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                        <a-select-option
                          v-for="(v, index) in vocations"
                          :key="index"
                          :value="v.value"
                        >
                          {{ v.label }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :span="$root.windowWidth > 961 ? 4 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Situação</label>
                      <a-select
                        class="underline-input"
                        show-search
                        placeholder="Situação"
                        v-decorator="[
                          'situation',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                        <a-select-option value="Ocupado">
                          Ocupado
                        </a-select-option>
                        <a-select-option value="Desocupado">
                          Desocupado
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :span="$root.windowWidth > 961 ? 7 : 24">
                    <a-form-item class="mb-10 underline-wrapper">
                      <label class="label">Metragem (m²)</label>
                      <!-- {{ clearNumber(fake_metreage) }}
                                            <a-input
                                                placeholder="Metragem (m²)"
                                                v-model="fake_metreage"
                                                v-money="{
                                                    precision: 3,
                                                    prefix: '',
                                                    suffix: ' m²',
                                                }"
                                                class="v-money underline-input"
                                                v-bind="money"
                                            /> -->
                      <a-input
                        class="underline-input"
                        placeholder="Metragem (m²)"
                        v-currency="{
                          currency: {
                            prefix: '',
                            suffix: '',
                          },
                          distractionFree: false,
                          valueAsInteger: false,
                          precision: 3,
                          autoDecimalMode: true,
                          allowNegative: false,
                        }"
                        v-decorator="[
                          'metreage',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      >
                      </a-input>
                    </a-form-item>
                    <div
                      style="
                                                font-size: 9px;
                                                font-weight: 600;
                                                position: absolute;
                                                bottom: -2px;
                                            "
                    >
                      {{
                        checkDecimals(
                          editPropertyForm.getFieldValue("metreage")
                        )
                      }}
                    </div>
                  </a-col>
                </a-row>

                <a-row class="data-row   mt-20" :gutter="20">
                  <a-col :span="24">
                    <a-form-item
                      class="mb-10 underline-wrapper textarea-dashed textarea"
                      ><label>Descrição do Ativo</label>
                      <a-textarea
                        placeholder="Descreva por completo o ativo imobiliario"
                        :auto-size="{
                          minRows: 5,
                          maxRows: 5,
                        }"
                        v-decorator="[
                          'property_description',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>

                <a-row class="data-row" :gutter="20">
                  <a-col :span="24">
                    <a-form-item
                      class="mb-10 underline-wrapper textarea-dashed textarea"
                    >
                      <label>Proposta comercial</label>
                      <a-textarea
                        placeholder="Descreva sua proposta comercial"
                        :auto-size="{
                          minRows: 5,
                          maxRows: 5,
                        }"
                        v-decorator="[
                          'commercial_proposal',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Obrigatório',
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-tab-pane>
              <a-tab-pane key="files" tab="Arquivos" forceRender>
                <a-col class="mb-30 media-upload" :span="24">
                  <!-- <pre>
                                    {{ registration_docs }}
                                    <hr />
                                    {{
                                        editPropertyForm.getFieldValue(
                                            "registration_docs"
                                        )
                                    }}

                                    </pre> -->

                  <a-form-item>
                    <label class="label">
                      Matrícula do terreno
                      <font style="color: red">*</font>
                    </label>
                    <a-upload-dragger
                      name="file"
                      accept=".pdf,.png,.jpg,.jpeg"
                      :customRequest="uploadRegistrationFile"
                      :file-list="registration_docs.list"
                      :default-file-list="registration_docs.list"
                      :remove="removeRegistrationFile"
                      :multiple="false"
                      v-decorator="[
                        'area_registration_docs',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <p class="ant-upload-drag-icon">
                        <a-icon type="picture" />
                      </p>
                      <p class="ant-upload-text">
                        <font class="font">Clique aqui</font>
                        ou arraste o arquivo
                      </p>
                    </a-upload-dragger>

                    <a-icon v-if="registration_docs.loading" type="loading" />
                  </a-form-item>
                </a-col>

                <a-col class="mb-30 media-upload" :span="24">
                  <a-form-item>
                    <label class="label">
                      Projetos, espelho do IPTU e outros documentos/fotos
                    </label>
                    <a-upload-dragger
                      name="file"
                      accept=".pdf,.png,.jpg,.jpeg"
                      :customRequest="uploadPicturesFile"
                      :file-list="pictures.list"
                      :default-file-list="pictures.list"
                      :remove="removePicturesFile"
                      :multiple="false"
                      v-decorator="[
                        'property_pictures',
                        {
                          rules: [
                            {
                              required: false,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <p class="ant-upload-drag-icon">
                        <a-icon type="picture" />
                      </p>
                      <p class="ant-upload-text">
                        <font class="font">Clique aqui</font>
                        ou arraste o arquivo
                      </p>
                    </a-upload-dragger>
                    <a-icon v-if="pictures.loading" type="loading" />
                  </a-form-item>
                </a-col>
              </a-tab-pane>

              <a-tab-pane key="kmz" tab="KMZ" forceRender>
                <a-col class="mb-30 media-upload" :span="24">
                  <!-- {{ kmz.list }}  -->
                  <a-form-item>
                    <label class="label">
                      Marcação do terreno (KMZ)
                      <font style="color: red">*</font>
                    </label>
                    <a-upload-dragger
                      name="file"
                      accept=".kmz"
                      :customRequest="uploadKMZFile"
                      :file-list="kmz.list"
                      :default-file-list="kmz.list"
                      :remove="removeKMZFile"
                      :multiple="false"
                      v-decorator="[
                        'kmz_file',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <p class="ant-upload-drag-icon">
                        <a-icon type="picture" />
                      </p>
                      <p class="ant-upload-text">
                        <font class="font">Clique aqui</font>
                        ou arraste o arquivo
                      </p>
                    </a-upload-dragger>

                    <a-icon v-if="kmz.loading" type="loading" />
                  </a-form-item>
                </a-col>
                <!--
                            <pre> {{ property }} </pre> -->
              </a-tab-pane>

              <a-tab-pane class="notes" key="notes" tab="Anotações" forceRender>
                <label class="label"> Nova anotação: </label>

                <vue-editor
                  v-model="writtenNote"
                  placeholder="Digite o texto aqui"
                  :editor-toolbar="customToolbar"
                ></vue-editor>

                <div class="obs mb-10">
                  <span style="color: red">*importante:</span>
                  Após a sua anotação ser salva não será possível removê-la.
                </div>

                <a-row type="flex" justify="end">
                  <a-col>
                    <a-button
                      @click="publishNote"
                      class="update-btn"
                      style="width: 120px"
                      :loading="loadingPropertyNotesUpdate"
                      >Publicar nota</a-button
                    >
                  </a-col>
                </a-row>

                <a-spin
                  v-if="loadingNotes"
                  class="mt-30"
                  tip="Carregando notas..."
                >
                  <div class="spin-content">...</div>
                </a-spin>
                <a-timeline
                  v-if="listNotes.length > 0 && !loadingNotes"
                  mode="left"
                  class="conectar-timeline mt-20 mb-20"
                  style="height: 270px"
                >
                  <a-timeline-item
                    v-for="(note, index) in listNotes"
                    :key="index"
                    color="green"
                  >
                    <div class="datetime">
                      <a-icon type="clock-circle-o" />
                      <div
                        class="time"
                        v-html="formatDateTimeline(note.created)"
                      ></div>
                    </div>
                    <div class="author">
                      {{ note.user.first_name }}
                      {{ note.user.last_name }}
                    </div>
                    <div class="content" v-html="note.content" />
                  </a-timeline-item>
                </a-timeline>
                <div v-if="listNotes.length == 0">
                  Nenhuma anotação registrada até o momento.
                </div>
              </a-tab-pane>
            </a-tabs>
            <a-row type="flex" justify="end">
              <a-col>
                <a-button
                  html-type="submit"
                  class="update-btn"
                  style="width: 120px"
                  :loading="loadingPropertyUpdate"
                  >Salvar</a-button
                >
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { parse, format } from "date-fns";
import states from "../../lists/states.json";
import axios from "axios";
import population from "../../lists/population.json";
import uploadFiles from "../../mixins/property/uploadFiles.js";
import capabilities from "../../mixins/general/capabilities.js";
import vocations from "../../lists/vocations.json";

export default {
  components: { VueEditor },
  mixins: [uploadFiles, capabilities],
  filters: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
  data() {
    return {
      editPropertyForm: this.$form.createForm(this),
      checkNegotiation: false,
      property: {},
      vocations,
      population,
      fileList: "",
      writtenNote: "",
      fake_metreage: "",
      propertyRate: 0,
      states,
      loadingPropertyNotesUpdate: false,
      loadingNotes: false,
      loadingPropertyUpdate: false,
      theLogo: "",
      status: [
        { label: "Ativo", value: "active" },
        { label: "Desativado", value: "disabled" },
        { label: "Em negociação", value: "negotiation" },
        { label: "Bloqueado", value: "blocked" },
      ],
      notes: [],
      listNotes: [],
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"],
        [
          "align",
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          "link",
          "blockquote",
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["clean"],
      ],
      marks: {
        0: "0",
        100000: "100.000",
        250000: "250.000",
        500000: "500.000",
      },
    };
  },
  beforeMount() {
    setTimeout(() => {
      let body = document.body;
      body.classList.add("conectar");
      body.classList.add("users");
      body.classList.add("view");
    }, 20);
    this.getProperty();

    // if (
    //     !this.$root.checkCapabilities(
    //         this.capabilities,
    //         "properties",
    //         "edit"
    //     )
    // ) {
    //     this.$router.push("/");
    // }
  },

  beforeDestroy() {
    let body = document.body;
    body.classList.remove("users");
    body.classList.remove("view");
  },
  methods: {
    clearNumber(number) {
      return number
        .replace("R$ ", "")
        .replace(" m²", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
    },
    checkDecimals(number) {
      let txt = ``;
      if (number) {
        txt = `*Atenção aos 3 zeros após vírgula. (${Number(
          parseFloat(
            number
              .replace(".", "")
              .replace(".", "")
              .replace(".", "")
              .replace(".", "")
              .replace(".", "")
              .replace(".", "")
              .replace(",", ".")
          ).toFixed(0)
        ).toLocaleString("pt-BR")} m²)`;
      }

      return txt;
    },
    getProperty() {
      this.$http
        .post("/property/details.php", {
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.property = data;
          this.editPropertyForm.setFieldsValue({
            owner_id: data.owner_id,
            name: data.name,
            cep: data.cep,
            location_state: data.location_state,
            location_city: data.location_city,
            location_street: data.location_street,
            location_number: data.location_number,
            location_neighborhood: data.location_neighborhood,
            location_complement: data.location_complement,
            population: data.population,
            registration: data.registration,
            zone: data.meta.zone,
            onus: data.onus,
            negotiate_value: data.meta.negotiate_value,
            area: data.area,
            metreage: data.metreage,
            business_type: data.business_type,
            property_description: data.property_description,
            commercial_proposal: data.commercial_proposal,
            value: data.value ? data.value.replace(".", ",") : undefined,
            situation: data.situation,
            status: data.status,
            is_owner:
              data.meta.is_owner != undefined ? data.meta.is_owner : "yes",
          });

          setTimeout(() => {
            this.registration_docs.list = JSON.parse(
              data.meta.area_registration_docs
            ).map((file) => {
              return {
                ...file,
                name: file.name != undefined ? file.name : file.url,
              };
            });

            this.registration_docs.file = JSON.parse(
              data.meta.area_registration_docs
            ).map((file) => {
              return {
                ...file,
                name: file.name != undefined ? file.name : file.url,
              };
            });

            this.kmz.list = JSON.parse(data.meta.kmz_file).map((file) => {
              return {
                ...file,
                name: file.name != undefined ? file.name : file.url,
              };
            });

            this.kmz.file = JSON.parse(data.meta.kmz_file).map((file) => {
              return {
                ...file,
                name: file.name != undefined ? file.name : file.url,
              };
            });

            this.pictures.list = JSON.parse(data.meta.property_pictures).map(
              (file) => {
                return {
                  ...file,
                  name: file.name != undefined ? file.name : file.url,
                };
              }
            );

            this.pictures.file = JSON.parse(data.meta.property_pictures).map(
              (file) => {
                return {
                  ...file,
                  name: file.name != undefined ? file.name : file.url,
                };
              }
            );
          }, 100);

          setTimeout(() => {
            this.editPropertyForm.setFieldsValue({
              owner_first_name: data.meta.owner_first_name,
              owner_last_name: data.meta.owner_last_name,
              kmz_file: data.meta.kmz_file,
              area_registration_docs: data.meta.area_registration_docs,
              iptu_docs: data.meta.iptu_docs,
              property_pictures: data.meta.property_pictures,
              topography_docs: data.meta.topography_docs,
            });
          }, 1000);

          this.checkNegotiation =
            data.meta.negotiate_value != undefined
              ? data.meta.negotiate_value == 1
                ? true
                : false
              : false;

          this.propertyRate =
            this.property.meta.rate != undefined
              ? this.property.meta.rate
              : "0";

          document.title = `${data.id} - ${data.name} - Conectar`;
          this.getNotes();
        })
        .catch(({ response }) => {
          response;
        });
    },
    changeCheckNegotiation(e) {
      this.checkNegotiation = e.target.checked;

      this.editPropertyForm.setFieldsValue({
        value: "",
      });
    },
    getNotes() {
      this.loadingNotes = true;
      this.$http
        .post("/note/list.php", {
          mid: this.property.id,
        })
        .then(({ data }) => {
          this.listNotes = data.data;
          this.loadingNotes = false;
        })
        .catch(({ response }) => {
          response;
          // this.$message.error(response.data.message);
          this.loadingNotes = false;
        });
    },
    publishNote() {
      if (this.writtenNote) {
        this.$http
          .post("/note/create.php", {
            mid: this.property.id,
            user_id: this.$store.state.userData.id,
            module: "property",
            content: this.writtenNote,
          })
          .then(({ data }) => {
            this.loadingPropertyNotesUpdate = false;
            this.$message.success(data.message);
            this.getNotes();
            this.writtenNote = undefined;
          })
          .catch(({ response }) => {
            this.loadingPropertyNotesUpdate = false;
            this.$message.error(response.data.message);
          });
      } else {
        this.$message.error("Escreva algo no campo de anotações.");
      }
    },
    formatDateTimeline(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      theDate = format(theDate, "dd/MM/yyyy HH:mm");

      return `${theDate.split(" ")[0]} <br> ${theDate.split(" ")[1]} `;
    },
    printDocs() {
      document.title = `${this.property.id} - ${this.property.first_name}`;
      window.print();
      document.title = `${this.property.id} - ${this.property.first_name}`;
    },
    edit(id) {
      this.$router.push(`/${this.$route.fullPath.split("/")[1]}/editar/${id}`);
    },
    view(id) {
      this.$router.push(`/${this.$route.fullPath.split("/")[1]}/ver/${id}`);
    },
    getLocation() {
      setTimeout(() => {
        if (this.editPropertyForm.getFieldValue("cep")) {
          if (
            this.editPropertyForm.getFieldValue("cep").replace("-", "")
              .length == 8
          ) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.editPropertyForm.getFieldValue("cep").replace("-", "") +
                  "/json/"
              )
              .then(({ data }) => {
                data;
                this.cepLoading = false;
                this.editPropertyForm.setFieldsValue({
                  location_street: data.logradouro,
                  location_state: data.uf,
                  location_city: data.localidade,
                  location_neighborhood: data.bairro,
                  location_complement: data.complemento,
                });
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 10);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.editPropertyForm.validateFields((err, values) => {
        values.owner_id = this.property.owner.id;
        values.id = this.property.id;
        values.rate = this.propertyRate;
        values.value =
          values.value != undefined
            ? values.value
                .replace(".", "")
                .replace(".", "")
                .replace(",", ".")
            : "";
        values.metreage = values.metreage
          .replace(".", "")
          .replace(".", "")
          .replace(",", ".");

        console.log("ERROS:", err, "VALORES", {
          ...values,
        });

        console.log(err, values);

        if (!err) {
          this.loadingPropertyUpdate = true;
          this.$http
            .post("/property/update.php", {
              ...values,
            })
            .then(({ data }) => {
              this.loadingPropertyUpdate = false;
              this.$message.success(data.message);
              this.$router.push(`/${this.$route.fullPath.split("/")[1]}/lista`);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingPropertyUpdate = false;
            });
        } else {
          this.$message.warning(
            "Alguns campos obrigatórios ainda não foram preenchidos."
          );
        }
      });
    },
  },
};
</script>
