<template>
  <div class="panel home">
    <a-row class="conectar-counters mb-10" :gutter="10">
      <a-col
        v-if="$root.checkCapabilities(capabilities, 'properties', 'list')"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <div class="box">
          <div class="number">
            {{ textTotals(totalProperties, "ativo") }}
          </div>
          <div class="name">
            Ativos
          </div>
        </div>
      </a-col>

      <a-col
        v-if="$root.isInvestor()"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <div class="box">
          <div class="number">
            {{ totalInvestorProfileProperties }}
          </div>
          <div class="name">
            Ativos com meu perfil
          </div>
        </div>
      </a-col>

      <a-col
        v-if="$root.isAdmin()"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <div class="box">
          <div class="number">
            {{ totalPropertiesWaiting }}
          </div>
          <div class="name">
            Ativos Aguardando
          </div>
        </div>
      </a-col>

      <a-col
        v-if="$root.isAdmin()"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <div class="box">
          <div class="number">
            {{ textTotals(totalActiveUsers, "usuário") }}
          </div>
          <div class="name">
            Usuários ativos
          </div>
        </div>
      </a-col>

      <a-col
        v-if="$root.isAdmin()"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <div class="box">
          <div class="number">
            {{ textTotals(totalUsersWaiting, "usuário") }}
          </div>
          <div class="name">
            Usuários aguardando
          </div>
        </div>
      </a-col>

      <a-col
        v-if="$root.checkCapabilities(capabilities, 'companies', 'list')"
        :span="
          $root.reponsive([
            {
              min: 0,
              max: 600,
              span: 24,
            },
            {
              min: 601,
              max: 9999,
              span: 4,
            },
          ])
        "
      >
        <div class="box">
          <div class="number">
            {{ textTotals(totalCompanies, "empresa") }}
          </div>
          <div class="name">
            Empresas
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="15">
      <a-col
        class="mb-30"
        v-if="$root.isAdmin()"
        :span="$root.windowWidth > 1240 ? 12 : 24"
      >
        <div class="table-box">
          <a-table
            :columns="columns"
            :data-source="listUsers"
            :pagination="false"
            :scroll="{ x: 760 }"
            :loading="loadingUsers"
          >
            <template slot="title">
              Usuários aguardando aprovação
            </template>
            <a slot="first_name" slot-scope="text, record"
              >{{ record.id }} - {{ record.first_name }}
              {{ record.last_name }}</a
            >
            <a slot="mobile_phone" slot-scope="text, record">{{
              record.mobile_phone
            }}</a>
            <a slot="created" slot-scope="text, record">{{
              record.created | formatDate
            }}</a>
            <span slot="action" slot-scope="record">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Ver</span>
                </template>
                <a @click="viewUser(record)">
                  <img src="@/assets/images/view.png" alt="ver" width="20" />
                </a>
              </a-tooltip>

              <a-popconfirm
                title="Tem certeza que deseja APROVAR?"
                ok-text="Sim"
                cancel-text="Não"
                @confirm="approveUser(record)"
                :icon="false"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Aprovar</span>
                  </template>
                  <a class="ml-10"
                    ><img src="@/assets/images/check.png" alt="ver" width="20"
                  /></a>
                </a-tooltip>
              </a-popconfirm>

              <a-popconfirm
                title="Tem certeza que deseja REPROVAR este usuário?"
                ok-text="Sim"
                cancel-text="Não"
                @confirm="disapproveUser(record)"
              >
                <a-icon
                  slot="icon"
                  type="question-circle-o"
                  style="color: red"
                />
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Reprovar</span>
                  </template>
                  <a class="ml-10"
                    ><img src="@/assets/images/close.png" alt="ver" width="20"
                  /></a>
                </a-tooltip>
              </a-popconfirm>
            </span>
          </a-table>
          <a-button
            v-if="listUsers.length > 0"
            @click="$router.push('/afiliados/aguardando-aprovacao')"
            class="view-more"
            type="link"
          >
            Ver mais...
          </a-button>
          <a-drawer
            v-if="drawers.users"
            placement="right"
            :width="$root.windowWidth > 960 ? '700px' : '100%'"
            :closable="true"
            :visible="drawers.users"
            @close="closeUserDrawer"
          >
            <template slot="title">
              <a-icon type="user" /> {{ theUser.first_name }}
              {{ theUser.last_name }} - ID: {{ theUser.id }}
            </template>

            <strong>DADOS DO AFILIADO:</strong> <br /><br />
            <strong> Nome completo:</strong>
            {{ theUser.first_name }}
            {{ theUser.last_name }} <br />
            <strong>E-mail:</strong> {{ theUser.email }}
            <br />
            <strong>CPF:</strong> {{ theUser.cpf }} <br /><strong
              >Celular:</strong
            >
            {{ theUser.mobile_phone }}
            <br />
            <strong>Telefone Fixo:</strong> {{ theUser.phone }}
            <br />
            <strong>Endereço:</strong>
            {{
              theUser.meta.location_street != undefined
                ? theUser.meta.location_street + ", "
                : ""
            }}
            {{
              theUser.meta.location_number != undefined
                ? theUser.meta.location_number + ", "
                : ""
            }}
            {{
              theUser.meta.location_neighborhood != undefined
                ? theUser.meta.location_neighborhood + ", "
                : ""
            }}
            {{
              theUser.meta.location_city != undefined
                ? theUser.meta.location_city + ", "
                : ""
            }}
            {{
              theUser.meta.location_state != undefined
                ? theUser.meta.location_state + ", "
                : ""
            }}
            {{ theUser.meta.cep != undefined ? theUser.meta.cep : "" }}

            <br />

            <div v-if="theUser.role == 'investor'">
              <strong>Tipo de usuário:</strong> Investidor
              <br />
              <strong> Vocação da área:</strong>
              {{ formatJsonView(theUser.business_type_of_interest) }}
              <br />
              <strong> Tamanho de áreas (m²): </strong>
              {{ formatJsonView(theUser.area_size_of_interest, "area") }}
              <br />
              <strong> Estados de interesse:</strong>
              {{ formatJsonView(theUser.state_of_interest) }}
              <br />
              <strong> População de interesse:</strong>
              {{ formatJsonView(theUser.population_of_interest) }}
            </div>
            <strong>Data de cadastro:</strong>
            {{ theUser.created | formatDate }}

            <div v-if="haveCompany(theUser.meta)">
              <a-divider />
              <strong>DADOS DA EMPRESA:</strong> <br />
              <br />
              <strong>Nome da empresa:</strong>
              {{ theUser.meta.company.trading_name }}
              <br />
              <strong>CNPJ:</strong>
              {{ theUser.meta.company.cnpj }}

              <br />

              <strong>Razão Social:</strong>
              {{ theUser.meta.company.company_name }}

              <br />

              <strong>E-mail empresarial:</strong>
              {{ theUser.meta.company.email }}
              <br />
              <strong>Telefone da empresa:</strong>
              {{ theUser.meta.company.phone }}
              <br />
              <strong>Endereço da empresa:</strong>
              {{
                theUser.meta.company.location_street != undefined
                  ? theUser.meta.company.location_street + ", "
                  : ""
              }}
              {{
                theUser.meta.company.location_number != undefined
                  ? theUser.meta.company.location_number + ", "
                  : ""
              }}
              {{
                theUser.meta.company.location_neighborhood != undefined
                  ? theUser.meta.company.location_neighborhood + ", "
                  : ""
              }}
              {{
                theUser.meta.company.location_city != undefined
                  ? theUser.meta.company.location_city + ", "
                  : ""
              }}
              {{
                theUser.meta.company.location_state != undefined
                  ? theUser.meta.company.location_state + ", "
                  : ""
              }}
              {{
                theUser.meta.company.cep != undefined
                  ? theUser.meta.company.cep
                  : ""
              }}
            </div>

            <center class="mt-40">
              <a-button type="primary" @click="view(theUser.id)">
                Ver mais detalhes
              </a-button>
            </center>
          </a-drawer>
        </div>
      </a-col>

      <a-col
        class="mb-30"
        v-if="$root.isAdmin()"
        :span="$root.windowWidth > 1240 ? 12 : 24"
      >
        <div class="table-box">
          <a-table
            :columns="propertiesColumns"
            :data-source="listProperties"
            :loading="loadingProperties"
            :pagination="false"
            :scroll="{ x: 760 }"
          >
            <template slot="title">
              Ativos aguardando aprovação
            </template>
            <div slot="name" slot-scope="text, record">
              <div class="dotted-phrase">
                {{ record.id }} - {{ record.name }}
              </div>
            </div>
            <a slot="location_state" slot-scope="text, record">{{
              record.location_state
            }}</a>
            <a slot="created" slot-scope="text, record">{{
              record.created | formatDate
            }}</a>

            <span slot="action" slot-scope="record">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Ver</span>
                </template>
                <a @click="viewProperty(record.id)">
                  <img src="@/assets/images/view.png" alt="ver" width="20" />
                </a>
              </a-tooltip>

              <a-popconfirm
                title="Tem certeza que deseja APROVAR?"
                ok-text="Sim"
                cancel-text="Não"
                @confirm="approveProperty(record)"
                :icon="false"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Aprovar</span>
                  </template>
                  <a class="ml-10"
                    ><img src="@/assets/images/check.png" alt="ver" width="20"
                  /></a>
                </a-tooltip>
              </a-popconfirm>

              <a-popconfirm
                title="Tem certeza que deseja REPROVAR este ativo?"
                ok-text="Sim"
                cancel-text="Não"
                @confirm="disapproveProperty(record)"
              >
                <a-icon
                  slot="icon"
                  type="question-circle-o"
                  style="color: red"
                />
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Reprovar</span>
                  </template>
                  <a class="ml-10"
                    ><img src="@/assets/images/close.png" alt="ver" width="20"
                  /></a>
                </a-tooltip>
              </a-popconfirm>
            </span>
          </a-table>
          <a-button
            v-if="totalProperties > 0"
            @click="$router.push('/ativos/aguardando')"
            class="view-more"
            type="link"
          >
            Ver mais...
          </a-button>
        </div>
      </a-col>

      <a-col
        class="mb-30"
        v-if="
          !$root.isAdmin() &&
            $root.checkCapabilities(capabilities, 'properties', 'list')
        "
        :span="$root.windowWidth > 1240 ? 12 : 24"
      >
        <div class="table-box">
          <a-table
            :columns="propertiesColumns"
            :data-source="listProperties"
            :loading="loadingProperties"
            :pagination="false"
            :scroll="{ x: 760 }"
          >
            <template slot="title">
              Últimos ativos imobiliários
            </template>
            <div slot="name" slot-scope="text, record">
              <div class="dotted-phrase">
                {{ record.id }} - {{ record.name }}
              </div>
            </div>
            <a slot="location_state" slot-scope="text, record">{{
              record.location_state
            }}</a>
            <a slot="created" slot-scope="text, record">{{
              record.created | formatDate
            }}</a>

            <span slot="action" slot-scope="record">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Ver</span>
                </template>
                <a @click="viewProperty(record.id)">
                  <img src="@/assets/images/view.png" alt="ver" width="20" />
                </a>
              </a-tooltip>
            </span>
          </a-table>
          <a-button
            v-if="totalProperties > 0"
            @click="$router.push('/ativos/lista')"
            class="view-more"
            type="link"
          >
            Ver mais...
          </a-button>
        </div>
      </a-col>

      <DashboardListInvestorProfileProperties
        v-if="
          $root.isInvestor() &&
            $root.checkCapabilities(capabilities, 'properties', 'list')
        "
        @emitTotalInvestorProfileProperties="emitTotalInvestorProfileProperties"
      />
    </a-row>
  </div>
</template>

<script>
import { parse, format } from "date-fns";
import capabilities from "../../mixins/general/capabilities.js";
import investorAreaSizes from "@/lists/investorAreaSizes.json";
import DashboardListInvestorProfileProperties from "./DashboardListInvestorProfileProperties.vue";

export default {
  name: "Dashboard",
  mixins: [capabilities],
  components: { DashboardListInvestorProfileProperties },
  data() {
    return {
      investorAreaSizes,
      listUsers: [],
      listProperties: [],
      listInvestorProperties: [],
      totalUsersWaiting: 0,
      totalImmobiles: 0,
      totalActiveUsers: 0,
      totalCompanies: 0,
      totalProperties: 0,
      totalPropertiesWaiting: 0,
      totalInvestorProfileProperties: 0,
      theUser: {
        meta: {},
      },

      loadingUsers: true,
      loadingProperties: true,
      drawers: {
        users: false,
      },
      propertiesColumns: [
        {
          title: "Nome",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 200,
        },
        {
          title: "Estado",
          key: "location_state",
          scopedSlots: { customRender: "location_state" },
          width: 50,
        },
        {
          title: "Cadastro",
          key: "created",
          scopedSlots: { customRender: "created" },
          width: 140,
        },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "right",
          fixed: "right",
          width: 100,
        },
      ],
      columns: [
        {
          title: "Nome",
          key: "first_name",
          scopedSlots: { customRender: "first_name" },
          width: 220,
        },
        {
          title: "Celular",
          key: "mobile_phone",
          scopedSlots: { customRender: "mobile_phone" },
          width: 140,
        },
        {
          title: "Cadastro",
          key: "created",
          scopedSlots: { customRender: "created" },
          width: 140,
        },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "right",
          fixed: "right",
          width: 100,
        },
      ],
    };
  },
  filters: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
  beforeMount() {
    if (this.$root.isAdmin()) {
      this.getUnnaprovedUsers();
      this.getComapanies();
    }

    this.getProperties();
    this.getActiveProperties();
  },
  methods: {
    theArea(area) {
      let theArea = area;
      this.investorAreaSizes.forEach((a) => {
        if (a.value == area) theArea = a.label;
      });
      return theArea;
    },
    emitTotalInvestorProfileProperties(total) {
      this.totalInvestorProfileProperties = total;
    },
    formatJsonView(data, type) {
      let text = "";
      JSON.parse(data).forEach((item) => {
        if (type == undefined) {
          text += `${item}, `;
        }
        if (type == "area") {
          text += `${this.theArea(item)}, `;
        }
      });

      return text.slice(0, -2);
    },
    formatDate(date) {
      console.log("datefns", date);
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
    textTotals(total) {
      let text = "";
      if (total == 0) {
        text = "0";
      }

      if (total == 1) {
        text = "1 ";
      }

      if (total > 1) {
        text = total;
      }

      return text;
    },
    getComapanies() {
      this.$http
        .post("/company/list.php", {
          page: 1,
          per_page: 1,
        })
        .then(({ data }) => {
          this.totalCompanies = data.meta.total;
        })
        .catch(({ response }) => {
          response;
        });
    },
    getActiveProperties() {
      let params = "?page=1&per_page=10";
      if (!this.$root.isAdmin()) {
        params += "&owner_id=" + this.$store.state.userData.id;
      }

      if (this.$root.isAdmin()) {
        params += "&is_approved=1&status=active&order=desc";
      }

      this.$http
        .post("/property/list.php" + params)
        .then(({ data }) => {
          this.totalProperties = data.meta.all;
        })
        .catch(({ response }) => {
          response;
          this.totalProperties = 0;
        });
    },
    getProperties() {
      let params = "?page=1&per_page=10";
      if (!this.$root.isAdmin()) {
        params += "&owner_id=" + this.$store.state.userData.id;
      }

      if (this.$root.isAdmin()) {
        params += "&is_approved=no&status=active&order=desc";
      }

      this.loadingProperties = true;

      this.$http
        .post("/property/list.php" + params)
        .then(({ data }) => {
          this.listProperties = data.data;
          this.loadingProperties = false;
          // this.totalProperties = data.meta.all;
          this.totalPropertiesWaiting = data.meta.total;
        })
        .catch(({ response }) => {
          response;
          this.loadingProperties = false;
          this.listProperties = response.data.data;
          // this.totalProperties = 0;
          this.totalPropertiesWaiting = 0;
        });
    },
    getApprovedUsers() {
      this.$http
        .post("/user/read.php", {
          role: ["user", "corporate-admin", "business-manager", "investor"],
          is_approved: 1,
          page: 1,
          per_page: 1,
        })
        .then(({ data }) => {
          this.totalActiveUsers = data.meta.total;
        })
        .catch(({ response }) => {
          response;
        });
    },
    approveProperty(property) {
      this.$http
        .post("/property/update-field.php", {
          id: property.id,
          field: "is_approved",
          value: true,
        })
        .then(({ data }) => {
          this.getProperties();

          this.$http
            .post("/property/send-email-to-investors.php", property)
            .then(({ data }) => {
              data;

              this.$message.success(data.message, 10);
            })
            .catch(({ response }) => {
              response;
            });

          this.$message.success(
            `Ativo ID ${property.id} aprovado com sucesso!`
          );

          data;

          this.$http
            .post("/system-logs/create.php", {
              id: this.$store.state.userData.id,
              user_id: this.$store.state.userData.id,
              action: "approve-property",
              content: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> aprovou o Ativo ID ${property.id}.`,
            })
            .then(({ data }) => {
              data;
            });
        })
        .catch(({ response }) => {
          response;
        });
    },
    disapproveProperty(property) {
      this.$http
        .post("/property/update-field.php", {
          id: property.id,
          field: "status",
          value: "disabled",
        })
        .then(({ data }) => {
          this.getProperties();

          this.$message.success(
            `Ativo ID ${property.id} reprovado com sucesso.`
          );

          data;

          this.$http
            .post("/system-logs/create.php", {
              id: this.$store.state.userData.id,
              user_id: this.$store.state.userData.id,
              action: "disapprove-property",
              content: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> reprovou o Ativo ID ${property.id}.`,
            })
            .then(({ data }) => {
              data;
            });
        })
        .catch(({ response }) => {
          response;
        });
    },
    getUnnaprovedUsers() {
      this.$http
        .post("/user/read.php", {
          role: ["user", "corporate-admin", "investor"],
          is_approved: 0,
          page: 1,
          per_page: 10,
        })
        .then(({ data }) => {
          this.loadingUsers = false;
          this.listUsers = data.data;
          this.getApprovedUsers();
          this.totalUsersWaiting = data.meta.total;
        })
        .catch(({ response }) => {
          response;
          this.loadingUsers = false;
          this.listUsers = [];
          this.totalUsersWaiting = 0;
        });
    },
    approveUser(user) {
      this.loadingUsers = true;
      this.$http
        .post("/user/approve.php", {
          id: user.id,
          status: true,
          is_approved: true,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
          modified_user: {
            name: `${user.first_name} ${user.last_name}`,
            id: user.id,
            email: user.email,
          },
        })
        .then(({ data }) => {
          this.getUnnaprovedUsers();
          this.$message.success(data.message, 7);
        })
        .catch(({ response }) => {
          response;
        });
    },
    disapproveUser(user) {
      this.loadingUsers = true;
      this.$http
        .post("/user/approve.php", {
          id: user.id,
          status: false,
          is_approved: "disapproved",
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
          modified_user: {
            name: `${user.first_name} ${user.last_name}`,
            id: user.id,
            email: user.email,
          },
        })
        .then(({ data }) => {
          this.getUnnaprovedUsers();
          this.$message.success(data.message, 7);
        })
        .catch(({ response }) => {
          this.loadingUsers = false;
          response;
        });
    },
    deleteUser(id) {
      this.loadingUsers = true;
      this.$http
        .post("/user/delete.php", {
          id,
        })
        .then(({ data }) => {
          data;
          this.getUnnaprovedUsers();
          this.$message.success("Apagado!");
        })
        .catch(({ response }) => {
          response;
        });
    },
    viewUser(user) {
      this.theUser = user;
      this.drawers.users = true;
    },
    view(id) {
      this.$router.push("/afiliados/ver/" + id);
    },
    closeUserDrawer() {
      this.drawers.users = false;
      this.theUser = { meta: {} };
    },
    viewProperty(id) {
      this.$router.push("/ativos/ver/" + id);
    },
    haveCompany(meta) {
      let flag = false;

      if (meta["company"] != undefined) {
        flag = true;

        if (meta["company"] != false) {
          flag = true;
        } else {
          flag = false;
        }
      }

      return flag;
    },
  },
};
</script>

<style lang="sass">
.panel.home
  .ant-table-title
    color: #e73945
    font-weight: 600
    font-size: 14px
    padding: 0 10px
</style>

<style lang="sass" scoped>

.conectar-counters
  .box
    border-radius: 6px
    border: 1px solid #e8e8e8
    background: #FFF
    overflow: hidden
    text-align: center
    font-weight: 600
    margin-bottom: 10px
    .number
      background: #001529
      color: #FFF
      padding: 5px 10px
      font-size: 24px
    .name
      padding: 5px 10px
      font-size: 12px
      color: #001529


@media screen and (max-width: 560px)
    .panel-icos
        display: none !important
</style>
