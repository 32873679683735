import { render, staticRenderFns } from "./InvestorProfile.vue?vue&type=template&id=56d83c56&scoped=true&"
import script from "./InvestorProfile.vue?vue&type=script&lang=js&"
export * from "./InvestorProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d83c56",
  null
  
)

export default component.exports