<template>
    <div class="page">
        <EditProfile />
    </div>
</template>

<script>
import EditProfile from "../../components/users/EditProfile.vue";
export default {
    components: { EditProfile },
};
</script>