<template>
    <div>
        <a-form :form="form" @submit="handleSubmit" style="padding:0 10px">
            <a-col :span="24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label
                        :class="
                            form.getFieldValue('demand') ? 'filled' : 'filled'
                        "
                        >Demanda</label
                    >
                    <a-input
                        class="mt-10"
                        placeholder="Título da demanda"
                        v-decorator="[
                            'demand',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col class="mt-20" :span="24">
                <a-form-item class="underline-wrapper textarea-dashed textarea">
                    <label
                        :class="
                            form.getFieldValue('property_size')
                                ? 'filled'
                                : 'filled'
                        "
                        >Tamanho estimado da área ou do projeto:</label
                    >

                    <a-textarea
                        placeholder="Número de lotes, número de unidades habitacionais, buit to suit, etc"
                        :auto-size="{ minRows: 3, maxRows: 3 }"
                        v-decorator="[
                            'property_size',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    />
                </a-form-item>
            </a-col>

            <a-col class="mt-20" :span="24">
                <a-form-item class="underline-wrapper textarea-dashed textarea">
                    <label
                        :class="
                            form.getFieldValue('product_type')
                                ? 'filled'
                                : 'filled'
                        "
                        >Tipologia de produto</label
                    >

                    <a-textarea
                        placeholder="Comercial, residencial popular, residencial médio alto padrão, loteamento, BTS, Ativo com renda, etc.."
                        :auto-size="{ minRows: 3, maxRows: 3 }"
                        v-decorator="[
                            'product_type',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    />
                </a-form-item>
            </a-col>

            <a-col class="mt-20" :span="24">
                <a-form-item class="underline-wrapper textarea-dashed textarea">
                    <label
                        :class="
                            form.getFieldValue('cities') ? 'filled' : 'filled'
                        "
                        >Cidades
                    </label>

                    <a-textarea
                        placeholder="Quais são as cidades alvo"
                        :auto-size="{ minRows: 3, maxRows: 3 }"
                        v-decorator="[
                            'cities',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    />
                </a-form-item>
            </a-col>

            <a-col class="mt-20" :span="24">
                <a-form-item class="underline-wrapper textarea-dashed textarea">
                    <label
                        :class="
                            form.getFieldValue('regions_states')
                                ? 'filled'
                                : 'filled'
                        "
                        >Região/Estado
                    </label>

                    <a-textarea
                        placeholder="Sudeste, Centro Oeste e/ou SP, MG, GO"
                        :auto-size="{ minRows: 3, maxRows: 3 }"
                        v-decorator="[
                            'regions_states',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    />
                </a-form-item>
            </a-col>

            <a-col class="mt-20" :span="24">
                <a-form-item class="underline-wrapper textarea-dashed textarea">
                    <label
                        :class="
                            form.getFieldValue('commercial_condition')
                                ? 'filled'
                                : 'filled'
                        "
                        >Condição comercial
                    </label>

                    <a-textarea
                        placeholder="Permuta, Permuta com aporte, Permuta com imóveis, compra e venda a vista ou parcelada"
                        :auto-size="{ minRows: 3, maxRows: 3 }"
                        v-decorator="[
                            'commercial_condition',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    />
                </a-form-item>
            </a-col>

            <a-col class="mt-20" :span="24">
                <a-form-item class="underline-wrapper textarea-dashed textarea">
                    <label
                        :class="
                            form.getFieldValue('description')
                                ? 'filled'
                                : 'filled'
                        "
                        >Descrição da demanda completa
                    </label>

                    <a-textarea
                        placeholder="Descrever informações completas e relevantes da demanda"
                        :auto-size="{ minRows: 5, maxRows: 5 }"
                        v-decorator="[
                            'description',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    />
                </a-form-item>
            </a-col>

            <a-col class="a-center mt-30" :span="24">
                <a-button
                    html-type="submit"
                    type="primary"
                    size="large"
                    :loading="loadingRequestProperty"
                    >Solicitar Ativo</a-button
                >
                <br /><br /><br /><br />
            </a-col>
        </a-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: this.$form.createForm(this),
            loadingRequestProperty: false,
        };
    },
    mounted() {
        // this.form.setFieldsValue({
        //     demand: "Terreno plano em MG",
        //     property_size: "Cerca de 100.000,00 metros",
        //     product_type: "Residencial",
        //     cities: "Ouro Preto, Belo Horizonte",
        //     regions_states: "Região Sudeste, Minas Gerais",
        //     commercial_condition: "Compra parcelada",
        //     description: "Seria um terreno para construção casas para aluguel",
        // });
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();

            this.form.validateFields((err, values) => {
                values.modified_by = {
                    name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                    email: `${this.$store.state.userData.email}`,
                    id: this.$store.state.userData.id,
                };

                console.log("ERROS:", err, "VALORES", values);

                if (!err) {
                    this.loadingRequestProperty = true;
                    this.$http
                        .post("/property/send-request.php", values)
                        .then(({ data }) => {
                            this.loadingRequestProperty = false;
                            this.$message.success(data.message);

                            setTimeout(() => {
                                this.$emit("listPropertiesAfterCreate", true);
                                this.$emit("closeDrawerAfterCreate", true);

                                this.form.setFieldsValue({
                                    cities: "",
                                    commercial_condition: "",
                                    demand: "",
                                    description: "",
                                    product_type: "",
                                    property_size: "",
                                    regions_states: "",
                                });
                            }, 500);
                        })
                        .catch(({ response }) => {
                            this.$message.error(response.data.message);
                            this.loadingCreateProperty = false;
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
