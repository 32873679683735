<template>
    <div class="page settings">
        <a-page-header class="bg" @back="() => $router.go(-1)">
            <template slot="backIcon">
                <a-icon type="left-circle" />
            </template>
            <h1>Configurações</h1>
        </a-page-header>

        <a-form :form="form" @submit="handleSubmit">
            <a-tabs default-active-key="1">
                <a-tab-pane key="users" tab="Usuários">
                    <a-alert
                        class="mb-30"
                        type="info"
                        message="Utilize os campos abaixo para informar as permissões dos usuários do sistema:"
                        banner
                    />
                    <a-collapse
                        default-active-key="0"
                        class="collapse-modules"
                        :bordered="false"
                    >
                        <a-collapse-panel
                            v-for="(module, index) in settings.capabilities"
                            :key="index"
                            :header="'Módulo: ' + module.name"
                        >
                            <a-row>
                                <a-col :span="24">
                                    <a-row
                                        v-for="(role, index) in module.roles"
                                        :key="index"
                                    >
                                        <a-checkbox-group
                                            v-model="role.capabilitiesList"
                                            @change="onChange"
                                            style="width: 100%"
                                        >
                                            <a-col :span="24">
                                                <a-row>
                                                    <a-col
                                                        class="name"
                                                        :class="
                                                            $root.windowWidth <
                                                            960
                                                                ? 'mb-10 mt-20'
                                                                : ''
                                                        "
                                                        :span="
                                                            $root.windowWidth >
                                                            960
                                                                ? 4
                                                                : 24
                                                        "
                                                    >
                                                        {{ role.name }}
                                                    </a-col>
                                                    <a-col
                                                        v-for="(
                                                            option, index
                                                        ) in options"
                                                        :key="index"
                                                        :span="
                                                            $root.windowWidth >
                                                            960
                                                                ? 2
                                                                : 24
                                                        "
                                                        ><a-checkbox
                                                            :value="
                                                                option.value
                                                            "
                                                            :disabled="
                                                                role.slug ==
                                                                'admin'
                                                                    ? false
                                                                    : false
                                                            "
                                                        >
                                                            {{ option.label }}
                                                        </a-checkbox>
                                                    </a-col>
                                                </a-row>
                                            </a-col>
                                        </a-checkbox-group>
                                    </a-row>
                                </a-col>
                            </a-row>
                        </a-collapse-panel>
                    </a-collapse>

                    <a-button
                        type="primary"
                        size="large"
                        html-type="submit"
                        :loading="loading"
                        class="mt-40"
                    >
                        SALVAR ALTERAÇÕES
                    </a-button>
                    <!-- <br /><br />
                    <pre>{{ settings }}</pre> -->
                </a-tab-pane>
                <a-tab-pane key="notifications" tab="Notificações" force-render>
                    <a-row>
                        <a-col :span="$root.windowWidth > 960 ? 12 : 24">
                            <a-alert
                                class="mb-30"
                                type="info"
                                message="Utilize os campos abaixo para enviar mensagens de notificação para os usuários do sistema:"
                                banner
                            />

                            <a-row :gutter="[20, 20]" class="mb-30">
                                <a-col :span="24">
                                    <label class="label mb-10">
                                        Selecione o destinatário:
                                    </label>
                                    <a-radio-group
                                        v-model="notification.receiver"
                                        name="radioGroup"
                                    >
                                        <a-radio value="all">
                                            Todos usuários do sistema
                                        </a-radio>
                                        <a-radio value="specific">
                                            Usuário específico
                                        </a-radio>
                                        <a-radio value="group">
                                            Grupo de usuários
                                        </a-radio>
                                    </a-radio-group>
                                </a-col>

                                <a-col
                                    v-if="notification.receiver == 'group'"
                                    :span="
                                        $root.reponsive([
                                            {
                                                min: 0,
                                                max: 620,
                                                span: 24,
                                            },
                                            {
                                                min: 621,
                                                max: 9999,
                                                span: 12,
                                            },
                                        ])
                                    "
                                >
                                    <label class="label">
                                        Selecione o grupo:
                                    </label>
                                    <a-select
                                        class="underline-input"
                                        placeholder="Função"
                                        show-search
                                        v-model="notification.role"
                                        style="width: 100%"
                                    >
                                        <a-select-option
                                            v-for="(item, index) of roles"
                                            :key="index"
                                            :value="item.value"
                                        >
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-col>

                                <a-col
                                    v-if="notification.receiver == 'specific'"
                                    :span="
                                        $root.reponsive([
                                            {
                                                min: 0,
                                                max: 620,
                                                span: 24,
                                            },
                                            {
                                                min: 621,
                                                max: 9999,
                                                span: 12,
                                            },
                                        ])
                                    "
                                >
                                    <label class="label mb-10">
                                        ID do usuário:
                                    </label>
                                    <a-input
                                        type="number"
                                        class="underline-input"
                                        v-model="notification.user_id"
                                        placeholder="Escreva o ID"
                                    />
                                </a-col>

                                <div class="clearfix" />

                                <a-col
                                    :span="
                                        $root.reponsive([
                                            {
                                                min: 0,
                                                max: 620,
                                                span: 24,
                                            },
                                            {
                                                min: 621,
                                                max: 9999,
                                                span: 12,
                                            },
                                        ])
                                    "
                                >
                                    <label class="label mb-10">
                                        Título da notificação:
                                    </label>
                                    <a-input
                                        class="underline-input"
                                        v-model="notification.title"
                                        placeholder="Escreva o título"
                                    />
                                </a-col>

                                <a-col
                                    :span="
                                        $root.reponsive([
                                            {
                                                min: 0,
                                                max: 620,
                                                span: 24,
                                            },
                                            {
                                                min: 621,
                                                max: 9999,
                                                span: 12,
                                            },
                                        ])
                                    "
                                >
                                    <label class="label mb-10">
                                        Link de destino
                                    </label>
                                    <a-input
                                        class="underline-input"
                                        v-model="notification.url"
                                        placeholder="Ex: /ativos/lista ou https://conectarinvestimentos.com"
                                    />
                                </a-col>

                                <a-col :span="24">
                                    <label class="label mb-10">
                                        Selecione o destinatário:
                                    </label>
                                    <vue-editor
                                        v-model="notification.content"
                                        placeholder="Digite o texto aqui"
                                        :editor-toolbar="customToolbar"
                                    ></vue-editor>
                                </a-col>

                                <a-col :span="24">
                                    <a-button
                                        type="primary"
                                        :loading="notification.loading"
                                        @click="sendNotifications()"
                                    >
                                        Enviar notificação
                                    </a-button>
                                </a-col>
                            </a-row>
                            <!-- <pre>{{ notification }} </pre> -->
                        </a-col>
                    </a-row>
                </a-tab-pane>
                <!--   <a-tab-pane key="3" tab="Tab 3">
        Content of Tab Pane 3
      </a-tab-pane> -->
            </a-tabs>

            <a-form-item>
                <a-input
                    v-decorator="[
                        'settings',
                        { initialValue: JSON.stringify(settings) },
                    ]"
                    placeholder="Settings"
                    style="display: none"
                />
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import roles from "../../lists/roles.json";
export default {
    components: { VueEditor },
    data() {
        return {
            form: this.$form.createForm(this),
            roles,
            notification: {
                role: undefined,
                title: undefined,
                content: undefined,
                receiver: "all",
                user_id: undefined,
                url: undefined,
                loading: false,
            },
            settings: {
                capabilities: [
                    {
                        module: "users",
                        name: "Usuários",
                        roles: [
                            {
                                id: 1,
                                name: "Administrador",
                                slug: "admin",
                                capabilitiesList: [],
                            },
                            {
                                id: 2,
                                name: "Afiliado",
                                slug: "user",
                                capabilitiesList: [],
                            },
                            {
                                id: 3,
                                name: "Gerente de Negócios",
                                slug: "business-manager",
                                capabilitiesList: [],
                            },
                            {
                                id: 4,
                                name: "Administrador Corporativo",
                                slug: "corporate-admin",
                                capabilitiesList: [],
                            },
                            {
                                id: 5,
                                name: "Afiliado Corporativo",
                                slug: "corporate-affiliate",
                                capabilitiesList: [],
                            },
                            {
                                id: 6,
                                name: "Investidor",
                                slug: "investor",
                                capabilitiesList: [],
                            },
                        ],
                    },
                    {
                        module: "properties",
                        name: "Ativos",
                        roles: [
                            {
                                id: 1,
                                name: "Administrador",
                                slug: "admin",
                                capabilitiesList: [],
                            },
                            {
                                id: 2,
                                name: "Afiliado",
                                slug: "user",
                                capabilitiesList: [],
                            },
                            {
                                id: 3,
                                name: "Gerente de Negócios",
                                slug: "business-manager",
                                capabilitiesList: [],
                            },
                            {
                                id: 4,
                                name: "Administrador Corporativo",
                                slug: "corporate-admin",
                                capabilitiesList: [],
                            },
                            {
                                id: 5,
                                name: "Afiliado Corporativo",
                                slug: "corporate-affiliate",
                                capabilitiesList: [],
                            },
                            {
                                id: 6,
                                name: "Investidor",
                                slug: "investor",
                                capabilitiesList: [],
                            },
                        ],
                    },
                    {
                        module: "companies",
                        name: "Empresas",
                        roles: [
                            {
                                id: 1,
                                name: "Administrador",
                                slug: "admin",
                                capabilitiesList: [],
                            },
                            {
                                id: 2,
                                name: "Afiliado",
                                slug: "user",
                                capabilitiesList: [],
                            },
                            {
                                id: 3,
                                name: "Gerente de Negócios",
                                slug: "business-manager",
                                capabilitiesList: [],
                            },
                            {
                                id: 4,
                                name: "Administrador Corporativo",
                                slug: "corporate-admin",
                                capabilitiesList: [],
                            },
                            {
                                id: 5,
                                name: "Afiliado Corporativo",
                                slug: "corporate-affiliate",
                                capabilitiesList: [],
                            },
                            {
                                id: 6,
                                name: "Investidor",
                                slug: "investor",
                                capabilitiesList: [],
                            },
                        ],
                    },
                    {
                        module: "AAA",
                        name: "AAA",
                        roles: [],
                    },
                ],
                aaa: {},
            },
            loading: false,
            options: [
                { label: "Criar", value: "create" },
                { label: "Editar", value: "edit" },
                { label: "Ver", value: "view" },
                { label: "Listar", value: "list" },
                { label: "Deletar", value: "delete" },
            ],
            customToolbar: [
                [
                    {
                        header: [false, 1, 2, 3, 4, 5, 6],
                    },
                ],
                ["bold", "italic", "underline", "strike"],
                [
                    "align",
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                    "link",
                    "blockquote",
                    {
                        color: [],
                    },
                    {
                        background: [],
                    },
                ],
                ["clean"],
            ],
        };
    },
    beforeMount() {
        setTimeout(() => {
            let body = document.body;
            body.classList.add("conectar");
            body.classList.add("properties");
            body.classList.add("list");
            body.classList.add("unnaproved");
        }, 20);

        if (!this.$root.isAdmin()) {
            this.$router.push("/");
        }

        this.getSettings();
    },
    beforeDestroy() {
        let body = document.body;
        body.classList.remove("users");
        body.classList.remove("list");
        body.classList.remove("unnaproved");
    },
    methods: {
        getSettings() {
            this.$http
                .post("/option/get.php", {
                    id: 2,
                })
                .then(({ data }) => {
                    let dbCapabilities = JSON.parse(data.value).capabilities;
                    //this.settings.capabilities = dbCapabilities;

                    // let aa = this.settings.capabilities.map((index, section) => {

                    //     if (section.module == dbCapabilities[index].module) {
                    //         return dbCapabilities[index];
                    //     }
                    // });

                    this.settings.capabilities = dbCapabilities;
                })
                .catch(({ res }) => {
                    res;
                });
        },
        sendNotifications() {
            if (this.notification.content) {
                this.notification.loading = true;
                this.$http
                    .post("/notification/send.php", this.notification)
                    .then(({ data }) => {
                        data;
                        this.$message.success(data.message);
                        this.notification.loading = false;
                    })
                    .catch(({ response }) => {
                        response;
                        this.$message.error(response.message);
                        this.notification.loading = false;
                    });
            } else {
                this.$message.warning("Escreva uma mensagem no campo.");
            }
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                console.log(err, values);
                if (!err) {
                    this.loading = true;
                    console.log("Received values of form: ", values);
                    this.$http
                        .post("/option/update.php", {
                            id: 2,
                            value: JSON.stringify(this.settings),
                        })
                        .then(({ data }) => {
                            data;
                            this.$message.success("Atualizado com sucesso!");
                            this.loading = false;
                        })
                        .catch(({ res }) => {
                            res;
                            this.$message.error("Não foi possível atualizar.");
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            });
        },
    },
};
</script>

<style lang="sass">
.settings
    .label
        font-size: 11px
        font-weight: 600
        color: #444
        display: block
        z-index: 1
</style>
