<template>
    <div id="print-area">
        <a-row class="header">
            <a-col :span="12">
                <img src="../../assets/images/conectar.png" width="240" />
            </a-col>

            <a-col class="extra" :span="12">
                Av. Edson Haddad, 505 Monte Líbano – Lavras/MG
                <br />
                conectar@conectarinvestimentos.com
                <br />
                +55 35 98825 6443
            </a-col>
        </a-row>

        <a-row class="body">
            <a-col class="row" :span="12">
                <label>Nome:</label>
                {{ user.first_name }} {{ user.last_name }}
            </a-col>

            <a-col class="row" :span="12">
                <label>E-mail:</label>
                {{ user.email }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Nascimento:</label>
                {{ user.meta.birthday | formatDate }}
            </a-col>

            <a-col class="row" :span="8">
                <label>CPF:</label>
                {{ user.cpf }}
            </a-col>

            <a-col class="row" :span="8">
                <label>RG:</label>
                {{ user.meta.rg }} - {{ user.meta.rg_doc_emission
                }}{{ user.meta.rg_doc_emission_state }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Celular:</label>
                {{ user.mobile_phone }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Telefone:</label>
                {{ user.phone }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Função:</label>
                {{ userRole(user.role) }}
            </a-col>

            <a-col class="row" :span="8">
                <label>Profissão:</label>
                {{ user.meta.profession }}
            </a-col>

            <a-col class="row" :span="12">
                <label>Avaliação:</label>
                <a-rate v-model="user.meta.rate" allow-half disabled>
                    <a-icon slot="character" type="star" />
                </a-rate>
            </a-col>

            <a-col class="row" :span="24">
                <label>Endereço:</label>
                {{ user.meta.location_street }},
                {{ user.meta.location_number }},
                {{ user.meta.location_neighborhood }},
                {{ user.meta.location_city
                }}{{ user.meta.cep ? ", " + user.meta.cep : "" }} -
                {{ user.meta.location_state }}
                {{
                    user.meta.location_complement != undefined
                        ? " | " + user.meta.location_complement
                        : ""
                }}
            </a-col>

            <a-col
                v-if="user.meta.company != undefined && user.meta.company"
                class="row"
                :span="24"
            >
                <h3 style="margin:0">Dados da Empresa</h3>
            </a-col>

            <a-col
                v-if="user.meta.company != undefined && user.meta.company"
                class="row"
                :span="8"
            >
                <label>Razão Social:</label>
                {{ user.meta.company.company_name }}
            </a-col>

            <a-col
                v-if="user.meta.company != undefined && user.meta.company"
                class="row"
                :span="8"
            >
                <label>Nome Fantasia:</label>
                {{ user.meta.company.trading_name }}
            </a-col>

            <a-col
                v-if="user.meta.company != undefined && user.meta.company"
                class="row"
                :span="8"
            >
                <label>CNPJ:</label>{{ user.meta.company.cnpj }}
            </a-col>

            <a-col class="row" :span="24">
                <h3 style="margin:0">Entrevista</h3>
            </a-col>

            <a-col class="row" :span="24">
                <label>
                    01 - Corretor autônomo ou trabalha em imobiliária?</label
                >
                {{ user.meta.interview_answer_1 }}
                {{
                    user.meta.interview_answer_1_extra
                        ? user.meta.interview_answer_1_extra
                        : ""
                }}
            </a-col>

            <a-col class="row" :span="24">
                <label> 02 - Atua em quais segmentos?</label>
                {{ user.meta.interview_answer_2 }}
                {{
                    user.meta.interview_answer_1_extra
                        ? user.meta.interview_answer_2_extra
                        : ""
                }}
            </a-col>

            <a-col class="row" :span="24">
                <label> 03 - Possui CRECI?</label>
                {{ user.meta.interview_answer_3 }}
                {{
                    user.meta.interview_answer_3_extra_1
                        ? ", " + user.meta.interview_answer_3_extra_1
                        : ""
                }}
                {{
                    user.meta.interview_answer_3_extra_2
                        ? user.meta.interview_answer_3_extra_2
                        : ""
                }}
            </a-col>

            <a-col class="row" :span="24">
                <label> 04 - Trabalha como intermediador de ativos há </label>
                {{ user.meta.interview_answer_4 }}
            </a-col>

            <a-col class="row" :span="24">
                <label> 05 - Renda atual </label>
                {{ user.meta.interview_answer_5 }}
            </a-col>

            <a-col class="row" :span="24">
                <label> 06 - Outras informações </label>
                {{ user.meta.interview_answer_6 }}
            </a-col>
        </a-row>
        <!-- 
        <br /><br /><br />
        <pre> {{ user }}</pre> -->
    </div>
</template>

<script>
import { format, parse } from "date-fns";
export default {
    props: {
        user: Object,
    },
    filters: {
        formatDate(date) {
            if (date != undefined) {
                if (date.length == 8) {
                    let theDate = parse(date, "yyyyMMdd", new Date());
                    return format(theDate, "dd/MM/yyyy");
                }

                if (date.length == 10) {
                    return date;
                }

                if (date.length > 22) {
                    let theDate = parse(
                        date.split("T")[0],
                        "yyyy-MM-dd",
                        new Date()
                    );
                    return format(theDate, "dd/MM/yyyy");
                }
            }
        },
    },
    mounted() {},
    methods: {
        userRole(role) {
            if (role == "admin") {
                role = "Administrador";
            }

            if (role == "user") {
                role = "Afiliado";
            }

            if (role == "investor") {
                role = "Investidor";
            }

            if (role == "business-manager") {
                role = "Gerente de Negócios";
            }

            if (role == "corporate-admin") {
                role = "Administrador Corporativo";
            }

            return role;
        },
    },
};
</script>


<style lang="sass">
#print-area
    .header
        padding: 20px
        background: #FFF
        .extra
            font-size: 11px
            font-weight: 600
            color: #999
            text-align: right
    .body
        padding: 20px
        background: #FFF
        margin-bottom: 10px
        .row
            padding-bottom: 20px
    label
        color: #333
        font-weight: 600
        font-size: 12px
        display: block
</style>