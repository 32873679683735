<template>
    <div>
        <h3>Dados da empresa</h3>

        <a-row class="data-row" :gutter="20">
            <a-col :span="$root.windowWidth > 1240 ? 10 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">Razão social</label>
                    <a-input
                        class="underline-input"
                        placeholder="Razão social"
                        v-decorator="[
                            'company_name',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório.',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 7 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">Nome Fantasia</label>
                    <a-input
                        class="underline-input"
                        placeholder="Nome Fantasia"
                        v-decorator="[
                            'trading_name',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório.',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 5 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">CNPJ</label>
                    <a-input
                        class="underline-input"
                        placeholder="CNPJ"
                        v-mask="'##.###.###/####-##'"
                        v-decorator="[
                            'cnpj',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório.',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
        </a-row>

        <h3>Dados de contato</h3>

        <a-row class="data-row" :gutter="20">
            <a-col :span="$root.windowWidth > 1240 ? 10 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">E-mail</label>
                    <a-input
                        class="underline-input"
                        v-decorator="[
                            'company_email',
                            {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Escreva um e-mail válido.',
                                    },
                                    {
                                        required: true,
                                        message: 'Escreva seu e-mail no campo.',
                                    },
                                ],
                            },
                        ]"
                        placeholder="E-mail"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 5 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">Telefone celular</label>
                    <a-input
                        class="underline-input"
                        placeholder="Telefone celular"
                        v-mask="['(##) # ####-####']"
                        v-decorator="[
                            'company_mobile_phone',
                            {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 4 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">Telefone fixo</label>
                    <a-input
                        class="underline-input"
                        placeholder="Telefone fixo"
                        v-mask="['(##) ####-####']"
                        v-decorator="[
                            'company_phone',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
        </a-row>

        <h3>Endereço</h3>

        <a-row class="data-row" :gutter="20">
            <a-col :span="$root.windowWidth > 1240 ? 5 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">CEP</label>
                    <a-input
                        class="underline-input"
                        placeholder="CEP"
                        v-mask="['#####-###']"
                        v-decorator="[
                            'company_cep',
                            {
                                rules: [
                                    { required: true, message: 'Obrigatório' },
                                ],
                            },
                        ]"
                        @change="getLocation()"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 19 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">Endereço</label>
                    <a-input
                        class="underline-input"
                        placeholder="Endereço"
                        v-decorator="[
                            'company_location_street',
                            {
                                rules: [
                                    { required: true, message: 'Obrigatório' },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 3 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">Número</label>
                    <a-input
                        class="underline-input"
                        placeholder="Número"
                        v-decorator="[
                            'company_location_number',
                            {
                                rules: [
                                    { required: true, message: 'Obrigatório' },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 7 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">Complemento</label>
                    <a-input
                        class="underline-input"
                        placeholder="Complemento"
                        v-decorator="['company_location_complement']"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 5 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">Bairro</label>
                    <a-input
                        class="underline-input"
                        placeholder="Bairro"
                        v-decorator="[
                            'company_location_neighborhood',
                            {
                                rules: [
                                    { required: true, message: 'Obrigatório' },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 5 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">Cidade</label>
                    <a-input
                        class="underline-input"
                        placeholder="Cidade"
                        v-decorator="[
                            'company_location_city',
                            {
                                rules: [
                                    { required: true, message: 'Obrigatório' },
                                ],
                            },
                        ]"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
            <a-col :span="$root.windowWidth > 1240 ? 4 : 24">
                <a-form-item class="mb-10 underline-wrapper">
                    <label class="label">Estado</label>
                    <a-select
                        class="underline-input"
                        show-search
                        placeholder="ES"
                        v-decorator="[
                            'company_location_state',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of states"
                            :key="index"
                            :value="item.initials"
                        >
                            {{ item.initials.toUpperCase() }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { format, parse } from "date-fns";
import subYears from "date-fns/subYears";
import axios from "axios";
import professions from "../../../lists/professions.json";
import states from "../../../lists/states.json";

export default {
    name: "DefaultDataForm",
    props: {
        company: Object,
        form: Object,
    },
    filters: {
        formatDate(date) {
            if (date) {
                let theDate = parse(
                    date.split("T")[0],
                    "yyyy-MM-dd",
                    new Date()
                );
                return format(theDate, "dd/MM/yyyy");
            }
        },
    },
    data() {
        return {
            states,
            professions,
        };
    },
    beforeMount() {},
    methods: {
        getLocation() {
            setTimeout(() => {
                if (this.form.getFieldValue("cep")) {
                    if (
                        this.form.getFieldValue("cep").replace("-", "")
                            .length == 8
                    ) {
                        this.cepLoading = true;
                        axios
                            .get(
                                "https://viacep.com.br/ws/" +
                                    this.form
                                        .getFieldValue("cep")
                                        .replace("-", "") +
                                    "/json/"
                            )
                            .then(({ data }) => {
                                data;
                                this.cepLoading = false;
                                this.form.setFieldsValue({
                                    location_street: data.logradouro,
                                    location_state: data.uf,
                                    location_city: data.localidade,
                                    location_neighborhood: data.bairro,
                                    location_complement: data.complemento,
                                });
                            })
                            .catch(({ response }) => {
                                response;
                                this.$message.error(
                                    "Houve um problema ao recuperar a localização."
                                );
                                this.cepLoading = false;
                            });
                    }
                }
            }, 110);
        },
        defaultDate() {
            setTimeout(() => {
                this.form.setFieldsValue({
                    birthday: this.form.getFieldValue("birthday")
                        ? this.form.getFieldValue("birthday")
                        : format(subYears(new Date(), 18), "MM/dd/yyyy"),
                });
            }, 1);
        },
        adultHoodDate(current) {
            return current && current > subYears(new Date(), 18);
        },
    },
};
</script>
