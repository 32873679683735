var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticStyle:{"padding":"0 10px"},attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"mb-10 underline-wrapper"},[_c('label',{class:_vm.form.getFieldValue('demand') ? 'filled' : 'filled'},[_vm._v("Demanda")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'demand',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                } ],
                        } ]),expression:"[\n                        'demand',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],staticClass:"mt-10",attrs:{"placeholder":"Título da demanda"}})],1)],1),_c('a-col',{staticClass:"mt-20",attrs:{"span":24}},[_c('a-form-item',{staticClass:"underline-wrapper textarea-dashed textarea"},[_c('label',{class:_vm.form.getFieldValue('property_size')
                            ? 'filled'
                            : 'filled'},[_vm._v("Tamanho estimado da área ou do projeto:")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'property_size',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                } ],
                        } ]),expression:"[\n                        'property_size',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],attrs:{"placeholder":"Número de lotes, número de unidades habitacionais, buit to suit, etc","auto-size":{ minRows: 3, maxRows: 3 }}})],1)],1),_c('a-col',{staticClass:"mt-20",attrs:{"span":24}},[_c('a-form-item',{staticClass:"underline-wrapper textarea-dashed textarea"},[_c('label',{class:_vm.form.getFieldValue('product_type')
                            ? 'filled'
                            : 'filled'},[_vm._v("Tipologia de produto")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'product_type',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                } ],
                        } ]),expression:"[\n                        'product_type',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],attrs:{"placeholder":"Comercial, residencial popular, residencial médio alto padrão, loteamento, BTS, Ativo com renda, etc..","auto-size":{ minRows: 3, maxRows: 3 }}})],1)],1),_c('a-col',{staticClass:"mt-20",attrs:{"span":24}},[_c('a-form-item',{staticClass:"underline-wrapper textarea-dashed textarea"},[_c('label',{class:_vm.form.getFieldValue('cities') ? 'filled' : 'filled'},[_vm._v("Cidades ")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'cities',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                } ],
                        } ]),expression:"[\n                        'cities',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],attrs:{"placeholder":"Quais são as cidades alvo","auto-size":{ minRows: 3, maxRows: 3 }}})],1)],1),_c('a-col',{staticClass:"mt-20",attrs:{"span":24}},[_c('a-form-item',{staticClass:"underline-wrapper textarea-dashed textarea"},[_c('label',{class:_vm.form.getFieldValue('regions_states')
                            ? 'filled'
                            : 'filled'},[_vm._v("Região/Estado ")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'regions_states',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                } ],
                        } ]),expression:"[\n                        'regions_states',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],attrs:{"placeholder":"Sudeste, Centro Oeste e/ou SP, MG, GO","auto-size":{ minRows: 3, maxRows: 3 }}})],1)],1),_c('a-col',{staticClass:"mt-20",attrs:{"span":24}},[_c('a-form-item',{staticClass:"underline-wrapper textarea-dashed textarea"},[_c('label',{class:_vm.form.getFieldValue('commercial_condition')
                            ? 'filled'
                            : 'filled'},[_vm._v("Condição comercial ")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'commercial_condition',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                } ],
                        } ]),expression:"[\n                        'commercial_condition',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],attrs:{"placeholder":"Permuta, Permuta com aporte, Permuta com imóveis, compra e venda a vista ou parcelada","auto-size":{ minRows: 3, maxRows: 3 }}})],1)],1),_c('a-col',{staticClass:"mt-20",attrs:{"span":24}},[_c('a-form-item',{staticClass:"underline-wrapper textarea-dashed textarea"},[_c('label',{class:_vm.form.getFieldValue('description')
                            ? 'filled'
                            : 'filled'},[_vm._v("Descrição da demanda completa ")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'description',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Obrigatório',
                                } ],
                        } ]),expression:"[\n                        'description',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],attrs:{"placeholder":"Descrever informações completas e relevantes da demanda","auto-size":{ minRows: 5, maxRows: 5 }}})],1)],1),_c('a-col',{staticClass:"a-center mt-30",attrs:{"span":24}},[_c('a-button',{attrs:{"html-type":"submit","type":"primary","size":"large","loading":_vm.loadingRequestProperty}},[_vm._v("Solicitar Ativo")]),_c('br'),_c('br'),_c('br'),_c('br')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }