<template>
    <div>
        <a-row class="interview" :gutter="16">
            <a-col class="col-01" :span="$root.windowWidth > 1240 ? 12 : 24">
                <div class="question-line">
                    <h4>01 - Corretor autônomo ou trabalha em imobiliária?</h4>
                    <div class="options">
                        <a-form-item>
                            <a-radio-group
                                v-decorator="[
                                    'interview_answer_1',
                                    {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <a-radio
                                    v-for="(option, index) in interview
                                        .first_question.options"
                                    :key="index"
                                    :style="radioStyle"
                                    :value="option"
                                >
                                    {{ option }}
                                </a-radio>
                            </a-radio-group>
                        </a-form-item>

                        <a-row
                            v-show="firstQuestion()"
                            type="flex"
                            justify="start"
                            :gutter="5"
                        >
                            <a-col class="font"> Imobiliária: </a-col>
                            <a-col>
                                <a-form-item class="underline-wrapper text">
                                    <a-input
                                        class="underline-input"
                                        placeholder="Insira o nome da imobiliaria"
                                        v-decorator="[
                                            'interview_answer_1_extra',
                                            {
                                                rules: [
                                                    {
                                                        required: firstQuestion(),
                                                        message: 'Obrigatório',
                                                    },
                                                ],
                                            },
                                        ]"
                                    >
                                    </a-input>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </div>
                </div>

                <div class="question-line">
                    <h4>02 - Atua em quais segmentos?</h4>
                    <div class="options">
                        <a-form-item class="mb-10">
                            <a-radio-group
                                v-decorator="[
                                    'interview_answer_2',
                                    {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <a-radio
                                    v-for="(option, index) in interview
                                        .second_question.options"
                                    :key="index"
                                    :style="radioStyle"
                                    :value="option"
                                >
                                    {{ option }}
                                </a-radio>
                            </a-radio-group>
                        </a-form-item>

                        <a-row
                            v-show="secondQuestionExtra()"
                            type="flex"
                            justify="start"
                            :gutter="5"
                        >
                            <a-col class="font"> Quais? </a-col>
                            <a-col>
                                <a-form-item
                                    class="mb-10 underline-wrapper text"
                                >
                                    <a-input
                                        class="underline-input"
                                        placeholder="Expecifique"
                                        v-decorator="[
                                            'interview_answer_2_extra',
                                            {
                                                rules: [
                                                    {
                                                        required: secondQuestionExtra(),
                                                        message: 'Obrigatório',
                                                    },
                                                ],
                                            },
                                        ]"
                                    >
                                    </a-input>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </div>
                </div>

                <div class="question-line">
                    <h4>03 - Possui CRECI?</h4>
                    <div class="options">
                        <a-form-item class="mb-10">
                            <a-radio-group
                                v-decorator="[
                                    'interview_answer_3',
                                    {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <a-radio
                                    v-for="(option, index) in interview
                                        .third_question.options"
                                    :key="index"
                                    :style="radioStyle"
                                    :value="option"
                                >
                                    {{ option }}
                                </a-radio>
                            </a-radio-group>
                        </a-form-item>
                        <a-row
                            type="flex"
                            justify="start"
                            v-show="thirdQuestionExtra()"
                            :gutter="6"
                        >
                            <a-col class="font">
                                CRECI/
                            </a-col>
                            <a-col>
                                <a-form-item
                                    class="mb-10 underline-wrapper text"
                                >
                                    <a-select
                                        class="underline-input"
                                        placeholder="ES"
                                        show-search
                                        v-decorator="[
                                            'interview_answer_3_extra_1',
                                            {
                                                rules: [
                                                    {
                                                        required: thirdQuestionExtra(),
                                                        message: 'Obrigatório.',
                                                    },
                                                ],
                                            },
                                        ]"
                                        style="width: 60px"
                                    >
                                        <a-select-option
                                            v-for="(item, index) of states"
                                            :key="index"
                                            :value="item.initials"
                                        >
                                            {{ item.initials.toUpperCase() }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col>
                                <a-form-item
                                    class="mb-10 underline-wrapper text"
                                >
                                    <a-input
                                        class="underline-input"
                                        placeholder="Expecifique"
                                        v-decorator="[
                                            'interview_answer_3_extra_2',
                                            {
                                                rules: [
                                                    {
                                                        required: thirdQuestionExtra(),
                                                        message: 'Obrigatório',
                                                    },
                                                ],
                                            },
                                        ]"
                                    >
                                    </a-input>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </a-col>

            <a-col :offset="1" :span="$root.windowWidth > 1240 ? 11 : 24">
                <div class="question-line">
                    <h4>04 - Trabalha como intermediador de ativos há</h4>
                    <div class="options">
                        <a-form-item class="mb-10">
                            <a-radio-group
                                v-decorator="[
                                    'interview_answer_4',
                                    {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <a-radio
                                    v-for="(option, index) in interview
                                        .fourth_question.options"
                                    :key="index"
                                    :style="radioStyle"
                                    :value="option"
                                >
                                    {{ option }}
                                </a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </div>
                </div>

                <div class="question-line">
                    <h4>05 - Renda atual</h4>
                    <div class="options">
                        <a-form-item class="mb-10">
                            <a-radio-group
                                v-decorator="[
                                    'interview_answer_5',
                                    {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <a-radio
                                    v-for="(option, index) in interview
                                        .fifth_question.options"
                                    :key="index"
                                    :style="radioStyle"
                                    :value="option"
                                >
                                    {{ option }}
                                </a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </div>
                </div>

                <div class="question-line">
                    <h4>06 - Outras informações</h4>
                    <div class="options">
                        <a-form-item class="mb-10">
                            <a-textarea
                                placeholder=""
                                :rows="4"
                                v-decorator="['interview_answer_6']"
                            />
                        </a-form-item>
                    </div>
                </div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {
    name: "UserInterview",
    props: {
        user: Object,
        form: Object,
    },
    filters: {},
    data() {
        return {
            interview: {
                first_question: {
                    options: ["Corretor Autônomo", "Trabalha em imobiliária"],
                },
                second_question: {
                    options: [
                        "Venda de terrenos",
                        "Venda de casas e/ou apartamento",
                        "Outros",
                    ],
                },
                third_question: {
                    options: ["Sim", "Não", "Em processo"],
                },
                fourth_question: {
                    options: ["0 a 5 anos", "+ 5 anos"],
                },
                fifth_question: {
                    options: [
                        "até R$ 5.000,00",
                        "de R$ 5.001,00 a R$ 10.000,00",
                        "de R$ 10.001,00 a R$ 20.000,00",
                        "acima de R$ 20.001,00",
                    ],
                },
            },
            states: [
                { name: "Acre", initials: "AC" },
                { name: "Alagoas", initials: "AL" },
                { name: "Amapá", initials: "AP" },
                { name: "Amazonas", initials: "AM" },
                { name: "Bahia", initials: "BA" },
                { name: "Ceará", initials: "CE" },
                { name: "Distrito Federal", initials: "DF" },
                { name: "Espírito Santo", initials: "ES" },
                { name: "Goiás", initials: "GO" },
                { name: "Maranhão", initials: "MA" },
                { name: "Mato Grosso", initials: "MT" },
                { name: "Mato Grosso do Sul", initials: "MS" },
                { name: "Minas Gerais", initials: "MG" },
                { name: "Pará", initials: "PA" },
                { name: "Paraíba", initials: "PB" },
                { name: "Paraná", initials: "PR" },
                { name: "Pernambuco", initials: "PE" },
                { name: "Piauí", initials: "PI" },
                { name: "Rio de Janeiro", initials: "RJ" },
                { name: "Rio Grande do Norte", initials: "RN" },
                { name: "Rio Grande do Sul", initials: "RS" },
                { name: "Rondônia", initials: "RO" },
                { name: "Roraima", initials: "RR" },
                { name: "Santa Catarina", initials: "SC" },
                { name: "São Paulo", initials: "SP" },
                { name: "Sergipe", initials: "SE" },
                { name: "Tocantins", initials: "TO" },
            ],
            radioStyle: {
                display: "block",
                height: "30px",
                lineHeight: "30px",
            },
        };
    },
    beforeMount() {},
    methods: {
        firstQuestion() {
            let flag = false;

            if (
                this.form.getFieldValue("interview_answer_1") ==
                "Trabalha em imobiliária"
            ) {
                flag = true;
            }

            return flag;
        },
        secondQuestionExtra() {
            let flag = false;

            if (this.form.getFieldValue("interview_answer_2") == "Outros") {
                flag = true;
            }

            return flag;
        },
        thirdQuestionExtra() {
            let flag = false;

            if (this.form.getFieldValue("interview_answer_3") == "Sim") {
                flag = true;
            }

            return flag;
        },
    },
};
</script>
