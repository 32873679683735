var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form-item',{staticClass:"travel-avatar-wrapper mb-0"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'avatar',
        {
          initialValue: _vm.user.avatar,
          rules: [
            {
              required: _vm.page == 'edit-user' && _vm.$root.isAdmin() ? false : true,
              message: 'Obrigatório',
            } ],
        } ]),expression:"[\n        'avatar',\n        {\n          initialValue: user.avatar,\n          rules: [\n            {\n              required: page == 'edit-user' && $root.isAdmin() ? false : true,\n              message: 'Obrigatório',\n            },\n          ],\n        },\n      ]"}],staticClass:"avatar-uploader ",attrs:{"name":"file","customRequest":_vm.uploadAvatar,"file-list":false,"list-type":"picture-card","multiple":false}},[_c('div',{staticClass:"image-uploader-container"},[(_vm.file)?_c('a-avatar',{attrs:{"src":_vm.file,"size":102}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" Upload ")])],1)],1)]),(_vm.file)?_c('a-button',{staticClass:"remove",attrs:{"type":"danger","ghost":"","shape":"circle"},on:{"click":_vm.removeAvatar}},[_c('a-icon',{attrs:{"type":"delete"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }