<template>
  <div>
    <div class="logo">
      <router-link to="/" tag="span">
        <img src="@/assets/images/conectar-white.png" alt="logo" />
      </router-link>
    </div>

    <div class="profile-pic">
      <a-avatar
        v-if="$store.state.userData.avatar"
        class="c-pointer avatar"
        @click="$router.push('/perfil')"
        :src="$store.state.userData.avatar"
        :size="160"
      />
      <a-avatar
        v-if="!$store.state.userData.avatar"
        class="c-pointer avatar"
        @click="$router.push('/perfil')"
        icon="user"
        style="color:  #82cfdd;; backgroundColor:  #001529"
        :size="160"
      />
      <div class="name">
        {{ $store.state.userData.first_name }}
        {{ $store.state.userData.last_name }}
      </div>
      <div
        v-if="$store.state.userData.meta.profession != undefined"
        class="job"
      >
        {{ $store.state.userData.meta.profession }}

        <font v-if="$store.state.userData.meta.company.company_name">
          na
          <font class="upper">{{
            $store.state.userData.meta.company.company_name
          }}</font>
        </font>
      </div>
    </div>

    <div v-if="$root.haveAvatar()" class="connect-menu">
      <div
        v-if="$root.checkCapabilities(capabilities, 'properties', 'list')"
        class="connect-title"
      >
        ATIVOS IMOBILIÁRIOS
      </div>

      <a-menu
        v-if="$root.checkCapabilities(capabilities, 'properties', 'list')"
        theme="dark"
        mode="inline"
      >
        <router-link
          v-if="
            $root.checkCapabilities(capabilities, 'properties', 'list') &&
              $root.isAdmin()
          "
          class="ant-menu-item"
          exact-active-class="ant-menu-item-selected"
          tag="li"
          to="/ativos/aguardando"
          ><a-icon type="carry-out" /><span>Aguardando</span></router-link
        >

        <router-link
          v-if="!$root.isInvestor()"
          class="ant-menu-item"
          exact-active-class="ant-menu-item-selected"
          tag="li"
          to="/ativos/lista"
          ><a-icon type="environment" /><span>Ativos</span></router-link
        >

        <router-link
          v-if="$root.isInvestor()"
          class="ant-menu-item"
          exact-active-class="ant-menu-item-selected"
          tag="li"
          to="/ativos/lista"
          ><a-icon type="environment" /> <span>Meus ativos</span></router-link
        >

        <router-link
          v-if="$root.isInvestor()"
          class="ant-menu-item"
          exact-active-class="ant-menu-item-selected"
          tag="li"
          to="/ativos/investidor"
          ><a-icon type="environment" />
          <span>Ativos c/ meu perfil</span></router-link
        >

        <a-menu-item
          class="ant-menu-item request-property"
          @click="createProperty"
        >
          <i class="fas fa-map-pin"></i>
          <span style="margin: 0 10px 0 14px">Cadastrar Ativo</span>
        </a-menu-item>

        <a-menu-item
          class="ant-menu-item request-property"
          @click="requestProperty"
        >
          <i class="fas fa-map-marked-alt"></i>
          <span class="ml-8">Solicitar Ativo</span>
        </a-menu-item>
      </a-menu>

      <div
        v-if="$root.checkCapabilities(capabilities, 'users', 'list')"
        class="connect-title mt-20"
      >
        USUÁRIOS
      </div>

      <div v-for="(item, index) in menu" :key="index">
        <a
          v-if="item.show"
          @click="$router.push(item.link)"
          class="item "
          style="color: #FFF"
        >
          <i v-if="item.fwIcon" class="icon" :class="item.fwIcon"></i>
          <a-icon v-if="item.antdIcon" class="icon" :type="item.antdIcon" />
          {{ item.name }}
        </a>
      </div>

      <a-menu theme="dark" mode="inline"> </a-menu>

      <div class="connect-title mt-20">PÁGINAS</div>
      <a-menu theme="dark" mode="inline">
        <router-link
          class="ant-menu-item"
          exact-active-class="ant-menu-item-selected"
          tag="li"
          to="/quem-somos"
          ><a-icon type="contacts" /> <span>Quem somos</span></router-link
        >

        <router-link
          class="ant-menu-item"
          exact-active-class="ant-menu-item-selected"
          tag="li"
          to="/politica-privacidade"
          ><a-icon type="safety" />
          <span>Política de Privacidade</span></router-link
        >
        <router-link
          class="ant-menu-item"
          exact-active-class="ant-menu-item-selected"
          tag="li"
          to="/faq"
          ><a-icon type="bulb" /> <span>FAQ</span></router-link
        >
      </a-menu>

      <a-row v-if="$root.isAdmin()">
        <div class="connect-title mt-20">
          GESTÃO CONECTAR
        </div>
        <a-menu theme="dark" mode="inline">
          <router-link
            class="ant-menu-item"
            exact-active-class="ant-menu-item-selected"
            tag="li"
            to="/usuarios/administradores"
            ><a-icon type="user" />
            <span>Administradores</span>
          </router-link>
        </a-menu>
      </a-row>
    </div>

    <a-drawer
      class="property-drawer"
      placement="right"
      :width="$root.windowWidth > 600 ? '740px' : ''"
      :closable="true"
      :visible="drawers.propety"
      @close="closePropertyDrawer"
    >
      <template slot="title">
        <img
          class="relative mr-5"
          src="@/assets/images/add-property-white.png"
          alt="ver"
          width="30"
          style="top: -2px"
        />
        CADASTRAR ATIVO
      </template>
      <CreateProperty
        v-if="drawers.propety"
        @listPropertiesAfterCreate="listPropertiesAfterCreate"
        @closeDrawerAfterCreate="closePropertyDrawer"
      />
    </a-drawer>

    <a-drawer
      class="property-drawer"
      placement="right"
      :width="$root.windowWidth > 600 ? '740px' : ''"
      :closable="true"
      :visible="drawers.requestProperty"
      @close="closePropertyDrawer"
    >
      <template slot="title">
        <img
          class="relative mr-5"
          src="@/assets/images/request-property-white.png"
          alt="ver"
          width="30"
          style="top: -2px"
        />
        SOLICITAR ATIVO
      </template>

      <RequestProperty
        v-if="drawers.requestProperty"
        @listPropertiesAfterCreate="listPropertiesAfterCreate"
        @closeDrawerAfterCreate="closePropertyDrawer"
      />
    </a-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

// components
import CreateProperty from "../../components/properties/CreateProperty.vue";
import RequestProperty from "../../components/properties/RequestProperty.vue";

export default {
  name: "SideMenu",
  components: { CreateProperty, RequestProperty },
  data() {
    return {
      menu: [],
      drawers: {
        propety: false,
        requestProperty: false,
        user: false,
      },
    };
  },
  computed: {
    ...mapState(["capabilities"]),
  },
  mounted() {
    setTimeout(() => {
      this.menu = [
        {
          name: "Afiliados Aguardando",
          link: "/afiliados/aguardando-aprovacao",
          fwIcon: "",
          antdIcon: "solution",
          show: this.$root.isAdmin(),
        },
        {
          name: "Afiliados",
          link: "/afiliados/lista",
          fwIcon: "",
          antdIcon: "team",
          show:
            this.$root.isAdmin() ||
            this.$root.checkCapabilities(
              this.$store.state.capabilities,
              "users",
              "list"
            )
              ? true
              : false,
        },
        {
          name: "Investidores",
          link: "/afiliados/investidores",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
        {
          name: "Proprietários de terreno",
          link: "/afiliados/proprietarios-terreno",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
        {
          name: "Gestores regionais",
          link: "/afiliados/gestores-regionais",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
        {
          name: "Intermediadores de negócios",
          link: "/afiliados/intermediadores-negocios",
          fwIcon: "",
          antdIcon: "team",
          show: this.$root.isAdmin(),
        },
      ];
    }, 1000);
  },
  methods: {
    ...mapActions(["setCapabilities"]),
    requestProperty() {
      this.drawers.requestProperty = true;
    },
    createProperty() {
      this.drawers.propety = true;
    },
    closePropertyDrawer() {
      this.drawers.propety = false;
      this.drawers.requestProperty = false;
    },
    listPropertiesAfterCreate() {
      this.$router.push("/ativos/lista");
    },
  },
};
</script>

<style lang="sass" scoped>
.connect-menu
  padding-bottom: 50px

.profile-pic
  color: #333
  margin: 10px 0 0 0
  background: #fff
  text-align: center
  padding: 10px
  border-right: 1px solid #ddd
  margin-top: 80px
  .avatar
    margin-top: -67px
    border: 5px solid #fff
  .name
    font-weight: 600
    margin-top: 5px
  .job
    font-size: 12px
    color: #999
.item
  padding: 7px 16px
  font-size: 14px
  color: #FFF
  display: block
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
.icon
  margin-right: 5px
.item:hover
  color: #FFF
</style>
