<template>
  <a-col class="mb-30" :span="$root.windowWidth > 1240 ? 12 : 24">
    <div class="table-box">
      <a-table
        :columns="propertiesColumns"
        :data-source="listProperties"
        :loading="loadingProperties"
        :pagination="false"
        :scroll="{ x: 760 }"
      >
        <template slot="title">
          Últimos ativos imobiliários com meu perfil
        </template>
        <div slot="name" slot-scope="text, record">
          <div class="dotted-phrase">{{ record.id }} - {{ record.name }}</div>
        </div>
        <a slot="location_state" slot-scope="text, record">{{
          record.location_state
        }}</a>
        <a slot="created" slot-scope="text, record">{{
          record.created | formatDate
        }}</a>

        <span slot="action" slot-scope="record">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Ver</span>
            </template>
            <a @click="viewProperty(record.id)">
              <img src="@/assets/images/view.png" alt="ver" width="20" />
            </a>
          </a-tooltip>
        </span>
      </a-table>
      <a-button
        v-if="totalProperties > 0"
        @click="$router.push('/ativos/lista')"
        class="view-more"
        type="link"
      >
        Ver mais...
      </a-button>
    </div>
  </a-col>
</template>

<script>
import capabilities from "@/mixins/general/capabilities.js";
import propertiesMixins from "@/mixins/property/propertiesMixins";

export default {
  name: "DashboardListInvestorProfileProperties",
  mixins: [capabilities, propertiesMixins],
  data() {
    return {
      user: {},
      propertiesColumns: [
        {
          title: "Nome",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 200,
        },
        {
          title: "Estado",
          key: "location_state",
          scopedSlots: { customRender: "location_state" },
          width: 50,
        },
        {
          title: "Cadastro",
          key: "created",
          scopedSlots: { customRender: "created" },
          width: 140,
        },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "right",
          fixed: "right",
          width: 50,
        },
      ],
    };
  },
  mounted() {
    this.getUserById();
  },
  methods: {
    viewProperty(id) {
      this.$router.push("/ativos/ver/" + id);
    },
    getUserById() {
      this.$http
        .post("/user/read-one.php", {
          id: this.$store.state.userData.id,
        })
        .then(({ data }) => {
          this.user = data;

          this.filters.states.selected = this.user.state_of_interest
            ? JSON.parse(this.user.state_of_interest)
            : ["Não informado"];
          this.filters.businessType.selected = this.user
            .business_type_of_interest
            ? JSON.parse(this.user.business_type_of_interest)
            : ["Não informado"];

          this.filters.population.selected = this.user.population_of_interest
            ? JSON.parse(this.user.population_of_interest)
            : ["Não informado"];

          this.filters.metreage.selected = this.user.area_size_of_interest
            ? JSON.parse(this.user.area_size_of_interest)
            : ["0-0"];

          this.getProperties();
        });
    },
    afterLoadProperties() {
      this.$emit("emitTotalInvestorProfileProperties", this.pagination.total);
    },
  },
};
</script>